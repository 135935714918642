import { createBrowserHistory, createMemoryHistory } from 'history';

interface HistoryProps {
  isPopUpKakao?: boolean;
  isPopUpRequest?: boolean;
  isPopUpMenu?: boolean;
  isPopUpNews?: boolean;
  isPopUpVideo?: boolean;
  process?: number;
}

export default process.env.IS_SSR ? createMemoryHistory<HistoryProps>() : createBrowserHistory<HistoryProps>();
