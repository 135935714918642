import React from 'react';
import { css } from 'aphrodite';

import { CreateBetaStyle } from '../../../../shared/presentation/shared/CreateStyle';

export const VerticalSpace: React.FC = () => <div className={css(styles.space)}/>;

const styles = CreateBetaStyle({
  space: {
    flexGrow: 1,
    flexShrink: 1,
  },
}, { isNoRatio: true });
