import React, { useEffect, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { css } from 'aphrodite';
import moment from 'moment';
import axios from 'axios';

import { RequestWrapperWithHeader } from '../../../request-page-2022/shared-component/RequestWrapperWithHeader';
import { RequestFont } from '../../../request-page-2022/shared/RequestFont';
import { CreateBetaStyle } from '../../../../shared/presentation/shared/CreateStyle';
import { RequestColor } from '../../../request-page-2022/assets/ColorContents';
import { RequestButtonWrapper } from '../../../request-page-2022/shared-component/RequestButtonWrapper';
import { RequestButton } from '../../../request-page-2022/shared-component/RequestButton';
import { useInjection } from '../../../../shared/presentation/InversifyProvider';
import { Payment, PAYMENT_SERVICE } from '../../../../payment/domain/Payment';
import { useMyHistory } from '../../../../shared/presentation/HistoryHelper';
import { PAYMENT_PATH } from '../PaymentConstant';
import { usePaymentFormState } from '../../PaymentContents';
import { alertMessageState } from '../../../request-page-2022/shared-component/ShowAlertFrame';
import { PaymentTypePopup } from '../../components/PaymentTypePopup';

export const ConfirmIncome: React.FC = () => {
  const [totalPrice, setTotalPrice] = useState(0);
  const [refundList, setRefundList] = useState<any[]>([]);
  const [isPopup, setIsPopup] = useState(false);
  useEffect(() => {
    const list = customer.refundList.split('/').map(v => ({
      year: v.split(':')[0],
      msg: v.split(':')[1],
      refund: v.split(':')[2]
    }));
    setRefundList(list);
    let total = 0;
    list.map(v => {
      total += Number(v.refund);
    });
    setTotalPrice(total);
  }, []);
  const payment = useInjection<Payment>(PAYMENT_SERVICE);
  const [customer, setCustomer] = usePaymentFormState();
  const setAlert = useSetRecoilState(alertMessageState);
  const originPath = window?.location?.origin;
  const history = useMyHistory();
  const onClickPayment = (payMethod: string) => {
    payment?.request({
      name: '종소세 수수료 결제',
      payMethod,
      amount: Number(customer.fee),
      // amount: 1000,
      // buyer_tel: '01029822287',
      buyer: {
        email: '',
        name: customer.name,
        tel: customer.phone,
      },
      merchantId: customer.recordId + '@' + moment(),
      // redirect: originPath ? `${originPath}/fee-complete` : 'https://the-refund.co.kr/fee-complete',
      redirect: originPath ? `${originPath}/payment02/complete` : `https://www.taxagent.co.kr/payment02/2`,
      callback: async (response) => {
        setIsPopup(false);
        // console.log(response);
        if (!response.success) {
          console.log('결제에 실패하였습니다. ' + response.error_msg);
          axios.post(
            'https://api.ailabscorp.com/total-income-the-refund/status', {
              recordId: customer.recordId,
              paidStatus: '결제오류',
              message: response.error_msg,
            });
          setAlert({ message: '결제에 실패했습니다. 재시도 해주세요.' });
          return;
        }
        const { data } = await axios.post('https://api.ailabscorp.com/total-income-the-refund/verify', {
          paymentId: response.imp_uid,
          merchantId: response.merchant_uid,
        });
        if (data.status === 'failed') {
          axios.post(
            'https://api.ailabscorp.com/total-income-the-refund/status', {
              recordId: customer.recordId,
              paidFeeStatus: data.message,
            });
          setAlert({ message: `${data.message} \n 상담원에게 문의해주세요.` });
        }
        setCustomer({ type: response.status });
        if (response.status === 'ready') {
          if (response.pay_method === 'vbank') {
            axios.post(
              'https://api.ailabscorp.com/total-income-the-refund/save/vbank', {
                recordId: customer.recordId,
                paidType: '가상계좌',
                paidStatus: '결제대기',
                virtualAccount: response.vbank_name + ': ' + response.vbank_num,
              });
            const { data } = await axios.get(`https://api.ailabscorp.com/total-income-the-refund/find/alimtalk?recordId=${customer.recordId}`);
            await axios.post(
              'https://api.ailabscorp.com/alimtalk-api', {
                phone: data.phone,
                templateCode: 'the-refund_2022_account2',
                values: [
                  data.name,
                  response.vbank_name + ' ' + response.vbank_num,
                  Number(customer.fee).toLocaleString(),
                  data.managerName,
                  data.managerPhone
                ],
                tel: '07043225312',
              });
            history.push(PAYMENT_PATH.step04);
            return;
          }
        }
        // console.log(response);
        // let message = '결제가 완료되었습니다.';
        // message += '고유ID : ' + response.imp_uid;
        // message += '상점 거래ID : ' + response.merchant_uid;
        // message += '결제 금액 : ' + response.paid_amount;
        // message += '카드 승인번호 : ' + response.apply_num;
        // console.log(message);
        axios.get(`https://api.ailabscorp.com/total-income-the-refund/date?recordId=${customer.recordId}`);
        axios.post(
          'https://api.ailabscorp.com/total-income-the-refund/type', {
            recordId: customer.recordId,
            paidType: '카드결제',
          });
        axios.post(
          'https://api.ailabscorp.com/total-income-the-refund/status', {
            recordId: customer.recordId,
            paidStatus: '결제완료',
          });
        history.push(PAYMENT_PATH.step04);
      }
    });
  };

  return (
    <>
      {isPopup && (
        <PaymentTypePopup
          onClickCredit={() => {
            // setIsPopup(false);
            onClickPayment('card');
          }}
          onClickCancel={() => setIsPopup(false)}
          onClickVirtualAccount={() => {
            // setIsPopup(false);
            onClickPayment('vbank');
          }}
        />
      )}
      <RequestWrapperWithHeader>
        <div className={css(styles.wrapper)}>
          <p className={css(RequestFont.TITLE, styles.title)}>
            고객님의<br/>
            예상 환급액은<br/>
            <span>{totalPrice.toLocaleString()}</span>원 입니다
          </p>
          <p className={css(styles.table_title)}>최근 5년 중 환급가능 금액</p>
          <div className={css(styles.fee_frame)}>
            <div className={css(styles.fee__top)}>
              {refundList.map((item, index) =>
                <div key={'refund_' + index} className={css(styles.fee_list)}>
                  <div className={css(styles.fee_standard)}>
                    <p>{item.year}년도</p>
                  </div>
                  <div className={css(styles.fee_price, RequestFont.FONT_WEIGHT_MEDIUM)}>
                    {Number(item.refund) !== 0
                      ? <p>{Number(item.refund).toLocaleString()}원</p>
                      : <p>{item.msg}</p>
                    }
                  </div>
                </div>
              )}
            </div>
            <div
              className={css(styles.fee_list, styles.fee__padding, RequestFont.BLUE_100, RequestFont.FONT_WEIGHT_MEDIUM)}>
              <p>돌려받을 금액 합계</p>
              <p>{Number(totalPrice).toLocaleString()}원</p>
            </div>
          </div>
          <p className={css(styles.table_title)}>환급신청 수수료</p>
          <div className={css(styles.table_frame)}>
            <div className={css(styles.table__top)}>
              <p>수수료</p>
              <p>{Number(customer.fee).toLocaleString()}원</p>
            </div>
          </div>
          <RequestButtonWrapper>
            <RequestButton title={'결제하고 신청하기'} onClick={() => setIsPopup(true)}/>
          </RequestButtonWrapper>
        </div>
      </RequestWrapperWithHeader>
    </>
  );
};

const styles = CreateBetaStyle({
  wrapper: {
    width: '100%',

    paddingBottom: '111px',
  },
  title: {
    marginBottom: '24px',
  },
  table_title: {
    fontSize: '18px',
    color: RequestColor.GRAY_100,
    fontWeight: 500,

    marginBottom: '24px',
  },
  table_title__confirm_button: {
    fontSize: '14px',
    color: RequestColor.GRAY_80,

    cursor: 'pointer',
  },
  table_title__confirm__arrow: {
    width: '5px',
    height: '10px',

    marginLeft: '8px',
  },
  fee_frame: {
    width: '100%',
    height: '330px',

    borderRadius: '5px',
    borderBottom: `solid 1px ${RequestColor.GRAY_30}`,

    backgroundColor: RequestColor.GRAY_10,

    fontSize: '16px',
    color: RequestColor.GRAY_80,
    lineHeight: '26px',

    marginBottom: '24px',
  },
  border_bottom: {
    borderBottom: `solid 1px ${RequestColor.GRAY_30}`,
  },
  fee__top: {
    padding: '10px 5% 4px',

    borderBottom: `solid 1px ${RequestColor.GRAY_30}`,
  },
  fee_list: {
    width: '100%',
    height: '51px',

    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  fee_standard: {},
  fee_price: {
    textAlign: 'right',
  },
  fee__padding: {
    padding: '0px 5%',
  },
  table_frame: {
    width: '100%',
    height: '62px',

    backgroundColor: RequestColor.GRAY_10,

    border: `solid 1px ${RequestColor.GRAY_30}`,
    borderRadius: '5px',

    fontSize: '16px',
    lineHeight: '26px',
    color: RequestColor.GRAY_80,
  },
  table__top: {
    width: '100%',
    height: '62px',

    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    padding: '0px 5%',

    // borderBottom: `solid 1px ${RequestColor.GRAY_30}`,

    color: RequestColor.BLUE,
    fontWeight: 500,
  },
  table__bottom: {
    padding: '8px 5% 0px',
  },
});