import { RequestRepository } from '../domain/RequestRepository';
import { CustomerRepository } from '../domain/CustomerRepository';
import { KakaoMessageSender } from '../domain/KakaoMessageSender';
import { ScriptLogger } from '../domain/ScriptLogger';
import { AirtableRequestRepository } from '../infra/AirtableRequestRepository';
import { ServerKakaoMessageSender } from '../infra/ServerKakaoMessageSender';
import { CombinedLogger } from '../infra/CombinedLogger';
import { NaverLogger } from '../infra/NaverLogger';
import { GoogleAdsLogger } from '../infra/GoogleAdsLogger';
import { KakaoLogger } from '../infra/KakaoLogger';
import { DaumReportLogger } from '../infra/DaumReportLogger';
import { GoogleAnalyticsLogger } from '../infra/GoogleAnalyticsLogger';
import { FacebookPixelLogger } from '../infra/FacebookPixelLogger';
import { Logger } from '../domain/Logger';
import { SmartLogger } from '../infra/SmartLogger';

export class Container {
  public static requestRepository: RequestRepository;
  public static customerRepository: CustomerRepository;
  public static kakaoMessageSender: KakaoMessageSender;
  public static scriptLogger: ScriptLogger;
  public static logger: Logger;
}

export function setUpContainer() {
  Container.customerRepository = new CustomerRepository(
    process.env.REACT_APP_CUSTOMER_API_URL,
  );
  Container.requestRepository = new AirtableRequestRepository(
    process.env.REACT_APP_AIRTABLE_BASE_ID,
    process.env.REACT_APP_AIRTABLE_API_KEY,
    process.env.REACT_APP_AIRTABLE_TABLE_NAME,
    process.env.REACT_APP_AIRTABLE_SITE_TYPE,
  );
  Container.kakaoMessageSender = new ServerKakaoMessageSender(
    process.env.REACT_APP_MESSAGE_API_URL,
  );

  Container.scriptLogger = new CombinedLogger(
    [
      new NaverLogger(),
      new GoogleAdsLogger(
        process.env.REACT_APP_GOOGLE_ADS_ID,
        process.env.REACT_APP_GOOGLE_ADS_BASE_ID,
      ),
      new KakaoLogger(process.env.REACT_APP_KAKAO_MOMENT_ID),
      new DaumReportLogger(process.env.REACT_APP_DAUM_REPORT_ID),
      new FacebookPixelLogger(),
      new SmartLogger(),
    ],
    [
      new GoogleAnalyticsLogger(process.env.REACT_APP_GOOGLE_ANALYTICS_ID),
      new GoogleAnalyticsLogger(process.env.REACT_APP_GOOGLE_ANALYTICS_ID_V4)
    ],
  );
}
