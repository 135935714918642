import React, { useEffect, useRef, useState } from 'react';
import { css } from 'aphrodite';
import Cookies from 'universal-cookie';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { useAsync } from 'react-async';

import { SharedRequestStyle } from './shared/SharedRequestStyle';
import { CustomerSpace } from './shared/CustomSpace';
import { ReactComponent as Kakao } from '../../shared/assets/svgs/kakao.svg';
import AgreementPopup from './popup/AgreementPopup';
import { requestForCertState } from '../store/TaxagentRequestStore';
import { useInjection } from '../../shared/presentation/InversifyProvider';
import { isLoadingState } from '../../shared/presentation/LoadingFrame';
import { TAX_RETURN_SERVICE, TaxReturnService, taxReturnState } from '../store/TaxReturnStore';
import { SocialSecurityNoInput } from './SocialSecurityNoInput';
import { IResizeFrame, withResizeFrame } from '../../shared/WithResizeFrame';
import { setTimer } from './HomeTaxCertificationTimer';
import { TAX_RETURN_REQUEST_REPOSITORY, TaxReturnReopository } from '../domain/TaxReturnReopository';

const RECORD_ID_EXPIRE_TIME = 60 * 30;

function HomeTaxKakaoLoginTaxReturn(props: IResizeFrame) {
  const { isMobile, isTablet, history } = props;
  const cookies = new Cookies();
  const nameRef = useRef<HTMLInputElement>(null);
  const phoneRef = useRef<HTMLInputElement>(null);
  const [isAgreementPopup, setIsAgreementPopup] = useState(false);
  const [info, setInfo] = useRecoilState(taxReturnState);
  const [innerHeight, setInnerHeight] = useState(window.innerHeight);
  const taxReturnService = useInjection<TaxReturnService>(TAX_RETURN_SERVICE);
  const setIsLoading = useSetRecoilState(isLoadingState);
  const setRequestForCert = useSetRecoilState(requestForCertState);
  const taxReturnRepository = useInjection<TaxReturnReopository>(TAX_RETURN_REQUEST_REPOSITORY)

  const { isLoading, error } = useAsync({
    promiseFn: taxReturnService?.fetchBasicInfoFromCookie,
    setInfo,
    repository: taxReturnRepository,
  });

  useEffect(() => {
    setIsLoading(isLoading)
  }, [isLoading, error])

  useEffect(() => {
    setInterval(() => setInnerHeight(window.innerHeight), 100)
  }, [])

  const onClickInput = (item: any) => {
    if (!item.isPersonalNumber) {
      item.ref?.current?.focus();
    }
  };

  function showAgreementPopup() {
    if (info.formErrorMessage) {
      alert(info.formErrorMessage);
      return;
    }

    setIsAgreementPopup(true);
  }

  async function goToNextPage() {
    setIsAgreementPopup(false);
    setIsLoading(true);
    try {
      const spreadSheetId = await taxReturnService
        ?.processRequestLoginStep(
          info,
          request => setRequestForCert(request)
        );

      taxReturnService?.saveUser(
        info.copy().setSpreadSheetId(spreadSheetId),
        _recordId => {
          cookies.set('tax_return_record_id', _recordId, {maxAge: RECORD_ID_EXPIRE_TIME});
          setInfo(info.copy().setRecordId(_recordId));
        }
      );
    } catch (e) {
      const status = e.response?.status ?? 500;
      console.log(e.response);
      switch (status) {
        case 500:
          alert('간편인증 로그인에 실패했습니다.');
          return;
        case 400:
          alert('사용자 정보(성함, 연락처, 주민등록번호)를 확인 후 다시 시도하세요.');
          return;
        default:
          alert('통신 불량으로 신청 사항이 전송되지 않았습니다. 다시 신청해주세요.');
      }
      setIsLoading(false);
    }
    setTimer(cookies);
    setTimeout(() => {
      setIsLoading(false);
      history.push('/tax-return-request/1');
      window.scrollTo(0, 0);
    }, 500)
  }

  const styles = SharedRequestStyle(isMobile, isTablet, (isMobile || isTablet) ? innerHeight : undefined);

  const INPUT_BASIC = (isMobile: boolean) => ([
    {
      title: '성함',
      value: info.name,
      ref: nameRef,
      handle: (value: string) => setInfo(_info => _info.copy().setName(value)),
    },
    {
      title: '연락처',
      value: info.phone,
      ref: phoneRef,
      isPhone: true,
      handle: (value: string) => setInfo(_info => _info.copy().setPhone(value)),
    },
    {
      title: '주민등록번호',
      isPersonalNumber: true,
    }
  ]);

  return (
    <div className={css(styles.background)}>
      <div className={css(styles.section_frame)}>
        {isAgreementPopup &&
        <AgreementPopup
          buttonOnClick={() => goToNextPage()}
          naviHeight={window.innerHeight}
          backgroundButtonOnclick={() => setIsAgreementPopup(false)}
          closeOnclick={() => setIsAgreementPopup(false)}
        />
        }
        <div className={css(styles.title)} style={{ whiteSpace: 'pre' }}>
          <Kakao className={css(styles.kakao_image)}/>
          카카오톡 간편인증
        </div>
        <div className={css(styles.info)}>
          고객님의 정확한 환급액 조회를 위해 {(isMobile || isTablet) && <br/>}
          아래 정보를 입력해주세요.
        </div>
        <div className={css(styles.input_box, styles.input_box__padding)}>
          {INPUT_BASIC(isMobile).map((item, index) =>
            <div key={'basic-' + index}>
              <div
                className={css(styles.input__list)}
                onClick={() => onClickInput(item)}
              >
                <div className={css(styles.input__list__title)}>
                  {item.title} <span style={{ color: '#fe3434' }}>*</span>
                </div>
                {item.isPersonalNumber ? (
                  <SocialSecurityNoInput
                    onChangeSsn1={value => setInfo(_info => _info.copy().setSsn1(value))}
                    onChangeSsn2={value => setInfo(_info => _info.copy().setSsn2(value))}
                    ssn1={info.ssn1}
                    ssn2={info.ssn2}
                    {...props}
                  />
                ) : (
                  <input
                    className={css(styles.input_style)}
                    value={item.value}
                    onChange={(e) => item.handle?.(e.target.value)}
                    ref={item.ref}
                    inputMode={item.isPhone ? 'numeric' : undefined}
                  />
                )}
              </div>
            </div>
          )}
        </div>
        <CustomerSpace height={220}/>
        <div
          className={
            css(styles.request_button,
              (!info.isValid && isMobile) &&
              styles.request_button__no_input,
              styles.request_button__position_bottom,
            )}
          onClick={() => showAgreementPopup()}
        >
          인증하기
        </div>
      </div>
    </div>
  );
}

export default withResizeFrame(HomeTaxKakaoLoginTaxReturn);
