import { atom, useRecoilState } from 'recoil';
import React, { useCallback, useMemo } from 'react';
import { css } from 'aphrodite';

import { CreateBetaStyle } from '../../../shared/presentation/shared/CreateStyle';
import { RequestColor } from '../assets/ColorContents';
import RequestVerticalButtons from './RequestVerticalButtons';

type AlertType = {
  title: string;
  message?: string | undefined;
  button: {
    title: string;
    onClick?: () => void;
  }[]
}

export const modalMessageState = atom<AlertType>({
  key: 'modalMessageState',
  default: {
    title: '',
    button: [{
      title: '',
      onClick: () => {
      },
    }]
  },
});

export const ModalPopup: React.FC = () => {
  const [alert, setAlert] = useRecoilState(modalMessageState);
  const isShow = useMemo(() => alert.title !== '', [alert.title]);
  const clickHandler = useCallback((onClick?: () => void) => {
    onClick?.();
    setAlert({
      title: '',
      message: undefined,
      button: []
    });
  }, [alert]);

  const isButtonCouple = alert.button.length === 2;

  return <>
    {isShow && (
      <div className={css(styles.wrapper)}>
        <div className={css(styles.alert_box)}>
          <div className={css(styles.alert__contents)}>
            {/*{alert.title != null && (*/}
            <p className={css(styles.alter__title)}>
              {alert.title}
            </p>
            {/*)}*/}
            {alert.message != null && (
              <p className={css(styles.alter__description)}>
                {alert.message}
              </p>
            )}
          </div>
          <div className={css(styles.button_frame)}>
            {/*<button*/}
            {/*  type={'button'}*/}
            {/*  className={css(RequestFont.ALTER_BUTTON, styles.button, !isButtonCouple && RequestFont.BLUE_100)}*/}
            {/*  onClick={() => clickHandler(alert.button[0].onClick)}*/}
            {/*>*/}
            {/*  {alert.button[0].title}*/}
            {/*</button>*/}
            {/*/!*{alert.button[1] != null && (*!/*/}
            {/*  <button*/}
            {/*    type={'button'}*/}
            {/*    className={css(RequestFont.ALTER_BUTTON, RequestFont.BLUE_100, styles.button)}*/}
            {/*    onClick={() => clickHandler(alert.button[1].onClick)}*/}
            {/*  >*/}
            {/*    확인*/}
            {/*  </button>*/}
            {/*/!*)}*!/*/}
            <RequestVerticalButtons
              items={[
                {
                  title: '다시 인증하기',
                  marginBottom: 12,
                  onClick: () => clickHandler(alert.button[0].onClick),
                },
                {
                  title: '간편인증 건너뛰기',
                  marginBottom: 0,
                  border: `solid 1px ${RequestColor.BLUE}`,
                  backgroundColor: 'white',
                  color: RequestColor.BLUE,
                  onClick: () => clickHandler(alert.button[1].onClick),
                }
              ]}
            />
          </div>
        </div>
      </div>
    )}
  </>;
};

const styles = CreateBetaStyle({
  wrapper: {
    width: '100vw',
    height: '100vh',

    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 600,

    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',

    backgroundColor: 'rgba(32, 35, 40, 0.3)',
  },
  alert_box: {
    position: 'relative',
    padding: '28px 20px 0px',
    width: '100vw',
    maxWidth: '640px',
    // minHeight: '206px',

    // borderRadius: '4px',
    backgroundColor: '#FFF',

    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'center',

  },
  alert__contents: {
    width: '100%',
  },
  alter__title: {
    fontSize: '20px',
    color: RequestColor.GRAY_100,
    fontWeight: 500,

    marginBottom: '8px',

    whiteSpace: 'pre',
  },
  alter__description: {
    // width: '100%',
    fontSize: '15px',
    color: RequestColor.GRAY_80,
    letterSpacing: '-0.2px',
    lineHeight: '26px',

    marginBottom: '28px',
    whiteSpace: 'pre',
  },
  button_frame: {
    width: '100%',

    paddingTop: '20px',
    paddingBottom: '12px',
  },
  button: {
    backgroundColor: 'transparent',

    marginLeft: '20px',

    cursor: 'pointer',
  }
});
