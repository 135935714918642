import { REQUEST_PATH } from "../../taxagent-shared/request-page-2022/pages/SharedConstants";

export const goToRequestPage = () => {
  if (process.env.REACT_APP_ACTIVE === 'FALSE') {
    alert('신청이 마감되었습니다.');
    return;
  }
  window.open(REQUEST_PATH.selectStep, '_self');
  window.scrollTo(0,0);
};

export const goToManualPage = () => window.open('/manual');
export const goToSavetax = () => window.open('https://www.save-tax.co.kr/');
