import React, { useEffect } from 'react';
import { css } from 'aphrodite';
import { useRecoilValue } from 'recoil';

import { CreateStyle } from '../../../shared/presentation/shared/CreateStyle';
import { spoqa } from '../../../shared/css/css-utils';
import { isMobileState, isTabletState } from '../../../shared/presentation/MobileFrame';
import { isShowMenu, useMyHistory } from '../../../shared/presentation/HistoryHelper';
import { REQUEST_PATH } from '../../../taxagent-shared/request-page-2022/pages/SharedConstants';

const goToSaveTax = () => {
  window.location.href = 'https://www.save-tax.co.kr/';
}

export function Menu() {
  const isMobile = useRecoilValue(isMobileState);
  const isTablet = useRecoilValue(isTabletState);
  const history = useMyHistory();

  return (
    <>
      {isShowMenu(history) && (isMobile || isTablet) && (
        <MenuView isMobile={isMobile} isTablet={isTablet}/>
      )}
    </>
  );
}

function MenuView({ isMobile, isTablet }: any) {
  const history = useMyHistory();
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  })

  const goToRequestPage = () => {
    history.push({
      pathname: REQUEST_PATH.selectStep,
      state: {
        isPopUpMenu: false,
      }
    });
  }

  const goToManualPage = () => {
    history.push('/manual')
  }

  const MENU_LIST: { title: string, onClick: () => void }[] = [
    // {
    //   title: '개별상담고객',
    //   onClick: () => {},
    // },
    // {
    //   title: '간편 신청하기',
    //   onClick: goToRequestPage,
    // },
    // {
    //   title: '종합소득세 자료 조회방법',
    //   onClick: goToManualPage,
    // },
    {
      title: '세이브택스 홈페이지',
      onClick: goToSaveTax,
    },
  ];

  const style = MenuStyle(isMobile, isTablet);

  return (
    <div className={css(style.background)}>
      {MENU_LIST.map((item, index) =>
        <div key={'menu-' + index}>
          <div
            className={css(style.menu_style)}
            onClick={item.onClick}
          >
            {item.title}
          </div>
        </div>
      )}
    </div>
  )
}

const MenuStyle = (isMobile: boolean, isTablet: boolean) => CreateStyle({
  background: {
    width: '100%',
    height: '100vh',

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    paddingTop: '150px/@300px',

    backgroundColor: '#2e6af4',

    position: 'fixed',
    top: 0,
    left: 0,

    zIndex: 90,

    fontFamily: spoqa,
  },
  menu_style: {
    fontWeight: 300,
    fontSize: '22px/@32px',
    color: 'white',
    lineHeight: '50px/@90px',
  },
}, isMobile, isTablet, {mobileWidth: 320});
