import React from 'react';
import { useRecoilValue } from 'recoil';
import { css } from 'aphrodite';

import { CreateStyle } from '../../../shared/presentation/shared/CreateStyle';
import { spoqa } from '../../../shared/css/css-utils';
import { BackgroundButton } from '../../../shared/presentation/kakao-popup/BackgroundButton';
import { ReactComponent as Close } from '../../../shared/assets/svgs/close-slim.svg';
import { isMobileState, isTabletState } from '../../../shared/presentation/MobileFrame';

interface Props {
  buttonOnClick?: any,
  backgroundButtonOnclick?: any,
  naviHeight?: number,
  closeOnclick?: any,
}

function AgreementPopup(
  {
    buttonOnClick,
    backgroundButtonOnclick,
    naviHeight,
    closeOnclick,
  }: Props
) {
  const isMobile = useRecoilValue(isMobileState);
  const isTablet = useRecoilValue(isTabletState);

  const styles = AgreementPopUpStyle(isMobile, isTablet, naviHeight);

  const AGREEMENT_LIST = [
    {
      title: '개인정보 이용 동의',
      onClick: () => window.open('/request/privacy_policy'),
    },
    {
      title: '서비스 이용약관 동의',
      onClick: () => window.open('/request/terms_of_service'),
    },
    {
      title: '고유식별정보 처리 동의',
      onClick: () => window.open('/request/unique_info'),
    },
    {
      title: '제3자 정보제공 동의',
      onClick: () => window.open('/request/info_use'),
    },
  ];
  return (
    <div className={css(styles.background__popup)}>
      <BackgroundButton />
      <div className={css(styles.popup_frame)}>
        {!isMobile &&
        <Close
          className={css(styles.close_button)}
          onClick={closeOnclick}
        />}
        <div className={css(styles.title)}>
          약관동의
        </div>
        <div>
          {AGREEMENT_LIST.map((item, index) =>
            <div key={'agreement-' + index}>
              <div className={css(styles.agreement_list)}>
                <div className={css(styles.blue_color)}>
                  (필수)
                </div>
                <div className={css(styles.agreement__title)}>
                  {item.title}
                </div>
                <div
                  className={css(styles.agreement__show)}
                  onClick={item.onClick}
                >
                  보기
                </div>
              </div>
            </div>
          )}
        </div>
        <div
          className={css(styles.agreement_button)}
          onClick={buttonOnClick}
        >
          모두 동의합니다
        </div>
      </div>
    </div>
  );
}

const AgreementPopUpStyle = (isMobile: boolean, isTablet: boolean, naviHeight?: number) => CreateStyle({
  background__popup: {
    width: '100vw',
    height: isMobile ? `${naviHeight}px` : '100vh',

    position: 'fixed',
    left: 0,
    top: 0,

    zIndex: 100,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    fontFamily: spoqa,
  },
  popup_frame: {
    width: '320px/402px@447px',
    height: '259px/364px@411px',

    backgroundColor: 'white',
    borderRadius: '/7px@6px',
    borderTopLeftRadius: '15px/',
    borderTopRightRadius: '15px/',

    paddingTop: '26px/42px@40px',
    paddingLeft: '24px/31px@37px',

    position_: 'absolute/relative@relative',
    bottom: '0px/',
  },
  close_button: {
    width: '/19px@19px',
    height: '/19px@19px',

    position_: '/absolute@absolute',
    right: '/23px@22px',
    top: '/28px@22px',

    cursor: 'pointer',
  },
  title: {
    fontWeight: 500,
    fontSize: '14px/20px@22px',
    color: '#34373A',

    marginBottom: '13px/21px@18px',
  },
  agreement_list: {
    display: 'flex',
    alignItems: 'center',

    fontWeight: 300,
    fontSize: '11px/16px@18px',
    lineHeight: '30px/40px@45px',
  },
  blue_color: {
    color: '#006AFF',

    marginRight: '/@3px',
  },
  agreement__title: {
    width: '220px/268px@300px',

    marginLeft: '4px/3px',
  },
  agreement__show: {
    fontSize: '10px/16px@15px',
    color: '#ABAFB9',

    cursor: 'pointer',
  },
  agreement_button: {
    width: '280px/341px@391px',
    height: '42px/60px@72px',
    borderRadius: '5px/5px@8px',

    backgroundColor: '#006AFF',

    fontWeight: 500,
    fontSize: '12px/17px@20px',
    color: 'white',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    marginTop: '21px/25px@36px',
    marginLeft: '-3px/@-4px',
    paddingBottom: '2px/@2px',

    cursor: 'pointer',
  },
}, isMobile, isTablet);

export default AgreementPopup;
