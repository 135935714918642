import React, { useState } from 'react';
import { css } from 'aphrodite';
import Slider from 'react-slick';
import { useRecoilValue } from 'recoil';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { Image } from '../../../../shared/css/Image';
import { BENEFIT_LIST } from '../../shared/Constant';
import { BenefitStyle } from './BenefitStyle';
import { goToRequestPage } from '../../../../shared/store/TaxagentStore';
import { totalScreenState } from '../../../../shared/presentation/MobileFrame';

function BenefitSection() {
  const { isMobile, isTablet } = useRecoilValue(totalScreenState);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [slider, setSlider] = useState<any>();

  const selectMenu = (index: number) => {
    if (selectedIndex !== index) {
      slider.slickGoTo(index);
      setSelectedIndex(index);
    }
    console.log(selectedIndex, index);
  };

  const sliderSettings = {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    fade: true,
    swipeToSlide: false,
    touchMove: true,
    beforeChange: (old: number, index: number) => {
      setTimeout(() => setSelectedIndex(index), 100);
    }
  };

  function isSelected(index: number) {
    return selectedIndex === index;
  }

  const styles = BenefitStyle(isMobile, isTablet);
  const listItem = BENEFIT_LIST(isMobile, isTablet);
  return (
    <div className={css(styles.background)}>
      <Slider
        ref={c => setSlider(c)}
        {...sliderSettings}
      >
        {listItem.map((item, index) =>
          <div key={'benefit-' + index}>
            <div className={css(styles.slide_page_frame)}>
              <Image
                src={item.image}
                alt={'background'}
                className={css(styles.background__image)}
              />
              <div className={css(styles.main_div)}>
                <div
                  className={css(styles.main__title, isSelected(index) ? styles.transition_basic : styles.box_hidden)}>
                  {item.title}
                </div>
                <div
                  className={css(styles.main__content, isSelected(index) ? styles.transition_delay : styles.box_hidden)}>
                  {(isMobile || isTablet) ? item.subContent : item.content}
                </div>
                {(isMobile || isTablet) && (
                  <div
                    className={css(styles.main__request_button,
                      isSelected(index)
                        ? styles.transition_long_delay
                        : styles.box_hidden
                    )}
                    onClick={goToRequestPage}
                  >
                    {
                      process.env.REACT_APP_ACTIVE === 'FALSE'
                      ? '신청이 마감되었습니다'
                      : '간편 신청하기'
                    }
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </Slider>
      <div className={css(styles.select_div)} id={'benefit-ani'}>
        {listItem.map((item, index) =>
          <div key={'benefit-' + index}>
            <div
              className={
                css(
                  styles.select__element_box,
                  selectedIndex === index &&
                  styles.select__element_event)}
              onClick={() => selectMenu(index)}
            >
              <div className={css(styles.select__title, index !== 0 && styles.select__title__padding)}>
                {item.title}
              </div>
              {(!isMobile && !isTablet) && (
                <div className={css(styles.select__content)}>
                  {item.subContent}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default BenefitSection;
