import React from 'react';
import { css } from 'aphrodite/no-important';

import { CreateStyle } from '../../../../shared/presentation/shared/CreateStyle';
import { spoqa } from '../../../../shared/css/css-utils';

interface Props {
  text: string;
  className?: string;
}

export const MetaTaxDescription: React.FC<Props> = ({ text, className }) => {
  return <p className={[css(styles.description), className].join(' ')}>
    {text}
  </p>
}

const styles = CreateStyle({
  description: {
    marginBottom: '36px',

    fontFamily: spoqa,
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '38px',
    color: '#454F5D',

    whiteSpace: 'pre',
  },
}, true, false, { isNoRatio: true });
