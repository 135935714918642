import { CreateStyle } from '../../../../shared/presentation/shared/CreateStyle';
import { robotoThin, spoqa } from '../../../../shared/css/css-utils';

export const MainStyle = (isMobile: boolean, isTablet: boolean, isPopUp: boolean, innerHeight: number) => CreateStyle({
  background: {
    height: isMobile ? `${innerHeight}px` : isTablet ? '1024px' : '100vh',
    width: '100%',

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    position: 'relative',

    paddingTop: `129px/${isPopUp ? 245 + 47 : 245}px@235px`,

    backgroundColor: 'rgba(0,0,0,0.3)',
    transition: 'height 0.3s',

    overflow: 'hidden',

    fontFamily: spoqa,
  },
  background__image: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    zIndex: -2,

    objectFit: 'cover',
  },
  main_div: {
    color: 'white',

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  main__title: {
    fontWeight: 300,
    fontSize: '20px/43px@39px',
    lineHeight: 1.4,
    whiteSpace: 'pre',

    textAlign: 'center',

    paddingBottom: '14px/15px@27px',
  },
  main__request_number: {
    fontFamily: robotoThin,
    fontSize: '46px/109px@90px',
    fontWeight: 100,
  },
  main__request_number__unit: {
    fontWeight: 300,
    fontSize: '20px/43px@39px',
    lineHeight: 1.4,

    marginLeft: '5px/10px@8px',
  },
  main__standard: {
    fontWeight: 300,
    fontSize: '12px/18px@23px',
    lineHeight: 1.75,

    marginBottom: '21px/30px@41px',

    whiteSpace: 'pre',
  },
  main__request_button: {
    width: '226px/300px@441px',
    height: '41px/56px@80px',

    border: 'solid 1px white',

    fontWeight: 500,
    fontSize: '11px/16px@21px',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    cursor: 'pointer',

    marginBottom: '58px/',
    paddingBottom: '3px/',

    ':hover': {
      backgroundColor: 'white',
      color: process.env.REACT_APP_BASE_COLOR,
      transition: '0.3s',
    },
  },
  main__center_number: {
    fontWeight: 300,
    fontSize: '15px/',
  },
  sub_div: {
    width: '100%',
    height: '63px/72px@150px',

    paddingBottom: '/3px',

    position: 'absolute',
    bottom: 0,
    left: 0,

    display: 'flex',
    alignItems: 'center',

    color: 'white',

    borderTop: 'solid 1px rgba(255, 255, 255,0.2)',
  },
  sub__list: {
    width: '106px/640px@256px',

    display: 'flex',
    flexDirection_: 'column/@column',
    alignItems: 'center',
    justifyContent: 'center',

    fontWeight: isMobile ? 500 : 300,
    fontSize: '10px/16px@24px',
  },
  sub__list__image: {
    width: '20px/26px@49px',
    height: '20px/26px@45px',

    objectFit: 'contain',

    marginRight: '/7px',
    marginBottom: '7px/@16px',
  },
}, isMobile, isTablet);
