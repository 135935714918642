import MenuGraph from '../../assets/svg/graph_img.svg';
import MenuCall from '../../../shared/assets/svgs/call.svg';
import MenuCard from '../../assets/svg/card_img.svg';
import MenuClock from '../../assets/svg/clock_img.svg';
import Clock from '../../assets/images/clock.jpg';
import City from '../../assets/images/city.jpg';
import Card from '../../assets/images/card.jpg';
import BenefitImage1 from '../../assets/images/section1-bg.png';
import BenefitImage2 from '../../assets/images/section2-bg.png';
import BenefitImage3 from '../../assets/images/section3-bg.png';
import Service1 from '../../assets/images/service1.jpg';
import Service2 from '../../assets/images/service2.jpg';
import Service3 from '../../assets/images/service3.jpg';
import Review01 from '../../assets/images/review1.png';
import Review02 from '../../assets/images/review2.png';
import Review03 from '../../assets/images/review3.png';

export const NUMBERS = [
  3,
  23,
  42,
  51,
  90,
  125,
  155,
  204,
  233,
  267,
  313,
  346,
  371,
  394,
  421,
  453,
  499,
  523,
  553,
  587,
  622,
  670,
  718,
  743,
  780,
  813,
  839,
  867,
  910,
  949,
  996,
  1034,
  1078,
  1099,
  1149,
  1189,
  1224,
  1253,
  1277,
  1323,
  1344,
  1350,
];

export const MENU_LIST = (isMobile: boolean, isTablet: boolean) => [
  {
    text: (isMobile || isTablet) ? '국내 최저 수수료' : '국내 최저 신고대리 수수료',
    image: MenuGraph,
  },
  {
    text: '1분 간편신청',
    image: MenuClock,
  },
  {
    text: isMobile ? '카드결제 가능' : '편리한 카드결제 가능',
    image: MenuCard,
  },
];

export const INFO_LIST = (isMobile: boolean) => ([
  {
    title: '1분 간편 신청',
    content: isMobile ? '복잡한 신청과정 없이, 1분만에 누구나 ' +
      '간편하게\n신청이 가능합니다.' : '복잡한 신청과정 없이, 1분만에 누구나\n' +
      '간편하게 신청이 가능합니다.',
    image: Clock,

  },
  {
    title: '전국 어디에서나 간편한 신청',
    content: isMobile ? '방문하지 않으셔도 괜찮습니다. 수도권 외 지역도\n' +
      '홈페이지 또는 전화를 통해 간편하게 신청해주세요.' : '방문하지 않으셔도 괜찮습니다. 수도권 외 지역도\n' +
      '홈페이지 또는 전화를 통해 간편하게 신청해주세요.',
    image: City,

  },
  {
    title: '편리한 카드 결제',
    content: '카드결제, 계좌이체가 모두 가능합니다.\n가장 편리한 수단으로 결제해주세요.',
    image: Card,
    isLast: true,
  },
]);

export const BENEFIT_LIST = (isMobile: boolean, isTablet: boolean) => [
  {
    title: (isMobile || isTablet)
      ? '1. 국내 최저 수수료'
      : '1. 국내 최저 신고대리 수수료',
    image: BenefitImage1,
    content: '세이브택스 종합소득세 신고센터는 50,000원부터 시작되는 국내 최저 신고대리 수수료로\n' +
      '서비스를 제공하고 있습니다. 그럼에도 불구하고, 단순 저가 서비스와 구별되는 최고의\n' +
      '서비스 제공이 가능한 것은 세이브택스가 보유한 수많은 경험과 시스템 때문입니다.',
    subContent: (isMobile || isTablet)
      ? '50,000원부터 시작되는\n국내 최저 수수료로\n최고의 서비스를 받으세요.'
      : '50,000원부터 시작되는 국내 최저 수수료로\n최고의 서비스를 받으세요.',
  },
  {
    title: '2. 최고의 전문성',
    image: BenefitImage2,
    content: '세이브택스 종합소득세 신고센터에서는 6명의 회계사/세무사를 비롯한 경험 많은 신고대리\n' +
      '전문가들이 업무를 진행하고 있습니다. 여기에 체계적 시스템을 통해 고객님 개개인의 상황에\n' +
      '적합한 최선의 종합소득세 신고를 빠르고 정확하게 진행하고 있습니다.\n',
    subContent: (isMobile || isTablet)
      ? '수 많은 경험에 기반한 전문성으로\n고객님께 맞춘 최선의\n절세 방안을 제시해 드립니다.'
      : '수 많은 경험에 기반한 전문성으로 고객님께\n맞춘 최선의 절세 방안을 제시해 드립니다.',
  },
  {
    title: '3. 간편한 신청과정',
    image: BenefitImage3,
    content: '저희 세이브택스 종합소득세 신고센터에서는 누구나 1분 만에 간편하게 신고대리 서비스 신청이\n' +
      '가능합니다. 나머지 복잡한 과정들은 모두 저희가 안내 및 처리 도와드리겠습니다. 지금\n' +
      '바로 홈페이지 또는 전화를 통해 신청해주세요.\n',
    subContent: (isMobile || isTablet)
      ? '단 1분만 투자해주세요.\n나머지는 저희가 꼼꼼하고\n완벽하게 처리해드립니다.'
      : '단 1분만 투자해주세요. 나머지는 저희가\n꼼꼼하고 완벽하게 처리해드립니다.',
  },
];

export const SERVICE_LIST = () => [
  {
    title: '프리랜서 신고대행',
    content: '보험설계사,방문판매원,연예인,\n' +
      '대리운전,학원강사 등 \n사업자등록증이 ' +
      '없는 사업자',
    image: Service1,
    isFirst: true,

  },
  {
    title: '개인사업자 신고대행',
    content: '사업자등록증이 있는 사업자\n' +
      '(도소매업,제조업,식당,\n' +
      '소프트웨어 개발, 서비스업 등)',
    image: Service2,

  },
  {
    title: '기타신고대행',
    content: '근로소득자,연금소득자,\n기타소득자,' +
      '이자배당소득자, \n부양가족공제,' +
      '기타 ',
    image: Service3,
    isLast: true,
  },
];

export const PROCESS_LIST = (isMobile: boolean, isTablet: boolean) => [
  {
    step: 'Step 1',
    title: '접수 및 서류 첨부',
    content: '신청서 접수 및 필요 서류 첨부',
  },
  {
    step: 'Step 2',
    title: '전문가 상담',
    content: '전문가 상담 및 수수료 안내',
  },
  {
    step: 'Step 3',
    title: '수수료 결제',
    content: '전용계좌 입금을 통한 수수료 결제',
  },
  {
    step: 'Step 4',
    title: '신고 완료',
    content: '신고 완료 후 접수증 및 납부서 안내',
    isLast: (isMobile || isTablet) ? true : false,
  },
];

export const TABLE_LIST = [
  {
    title: '수입금액 ',
    oldPrice: '서비스 금액 (Vat 별도) ',
    newPrice: '',
    isFirst: true,
  },
  {
    title: '단순경비율',
    oldPrice: '60,000',
    newPrice: '50,000',
  },
  {
    title: '3,000만원 미만',
    oldPrice: '90,000',
    newPrice: '80,000',
  },
  {
    title: '3,000만원 - 4,500만원',
    oldPrice: '110,000',
    newPrice: '100,000',
  },
  {
    title: '4,500만원 - 6,000만원',
    oldPrice: '160,000',
    newPrice: '150,000',
  },
  {
    title: '6,000만원 - 7,500만원',
    oldPrice: '210,000',
    newPrice: '200,000',
  },
  {
    title: '7,500만원 이상',
    oldPrice: '',
    newPrice: '수입금액* 0.3%',
    isLast: true,
  },
];

export const REVIEW_LIST = (isMobile: boolean, isTablet: boolean) => [
  {
    title01: (isMobile || isTablet) ? '세무에 대해서 무엇보다\n' : '무엇보다 절차가 ',
    title02: (isMobile || isTablet) ? '쉽고 편리' : '쉽고 ',
    title03: (isMobile || isTablet) ? '했어요.' : '제가\n신경쓸게 없는 점이 가장 좋았어요.',
    content: (isMobile || isTablet)
      ? '종합소득세 신고대리 서비스를 많이 이용해 봤는데 기존\n제가 이용해 본 세무사에 비해 쉽고 편' +
      '리한 점이 가장 좋았어요.\n잘 갖추어진 시스템 덕분에 제 입장에서는 간단한 정보 \n몇 가지 제공후' +
      '에는 신경쓸 부분이 없었어요.'
      : '종합소득세 신고대리 서비스를 많이 이용해 봤는데 기존 제가 이용해 본 세무사에 비해 \n쉽고 편' +
      '리한 점이 가장 좋았어요. 잘 갖추어진 시스템 덕분에 제 입장에서는 간단한 정보 \n몇 가지 제공후' +
      '에는 신경쓸 부분이 없었어요.',
    info: 'by 이진우 | 학원강사 | 프리랜서 신고대행',
    image: Review01,
  },
  {
    title01: (isMobile || isTablet) ? '덕분에 ' : '전문성있게 처리해주셔서 ',
    title02: (isMobile || isTablet) ? '환급' : '환급 ',
    title03: (isMobile || isTablet) ? ' 많이 받았어요.' : '잘 받았습니다.',
    content: (isMobile || isTablet)
      ? '저는 프리랜서 업무를 하면서 근로소득, 기타소득이 같이 있는\n복잡한 케이스였어요. 그런데도 ' +
      '전문성 있게 막힘없이 잘 \n처리해주시는 것을 보니 경험이 많다는게 확실히 \n느껴지더라구요. 다음' +
      '번에도 잘 부탁드릴게요!'
      : '저는 프리랜서 업무를 하면서 근로소득, 기타소득이 같이 있는 복잡한 케이스였어요. \n그런데도 ' +
      '전문성 있게 막힘없이 잘 처리해주시는 것을 보니 경험이 많다는게 확실히 느껴지더라구요. \n다음' +
      '번에도 잘 부탁드릴게요!',
    info: 'by 유승우 | 프로그래머 | 프리랜서 신고대행',
    image: Review02,
  },
  {
    title01: (isMobile || isTablet) ? '제가 ' : '저는 ',
    title02: (isMobile || isTablet) ? '근로소득자' : '친절한 서비스 ',
    title03: (isMobile || isTablet) ? '이면서 복잡한\n케이스 였는데 잘 처리해 주셨네요.' : '덕분에 만족도가 높았어요',
    content: (isMobile || isTablet)
      ? '기존 세무사는 바쁘다는 이유로 불친절할때가 있었는데, \n세이브택스는 밝고 친절하게 응대해주' +
      '니 너무 좋았어요. \n그리고 종합소득세 신고가 기존에는 복잡하다고 느껴졌었는데, \n담당 직원분이 ' +
      '꼼꼼하게 잘 설명해주시니 전혀 어렵지 않았어요.'
      : '기존 세무사는 바쁘다는 이유로 불친절할때가 있었는데, 세이브택스는 밝고 친절하게 \n응대해주' +
      '니 너무 좋았어요. 그리고 종합소득세 신고가 기존에는 복잡하다고 느껴졌었는데, \n담당 직원분이 ' +
      '꼼꼼하게 잘 설명해주시니 전혀 어렵지 않았어요.',
    info: 'by 최지윤 | 플라워샵 | 개인사업자 신고대행',
    image: Review03,
  },
];

export const FAQ_LIST = (isMobile: boolean, isTablet: boolean) => ([
  {
    title: '환급액(납부세액)은 얼마나 될까요?',
    content: (isMobile || isTablet)
      ? '대략적인 환급액(납부세액)을 계산하기 위해서는 몇 가지\n간단한 정보들이 필요합니다.' +
      '고객센터(1644-9108)나 \n홈페이지를 통해 문의해주시면 세이브택스의 전문 상담사가 \n' +
      '바로 안내해드리겠습니다.'
      : '대략적인 환급액(납부세액)을 계산하기 위해서는 몇 가지 간단한 정보들이 필요합니다.\n' +
      '고객센터(1644-9108)나 홈페이지를 통해 문의해주시면 세이브택스의\n전문 상담사가 바로 안내해드리겠습니다.',
  },
  {
    title: '납부서는 언제 보내주시나요?',
    content: (isMobile || isTablet)
      ? '신고가 완료되는 즉시 안내 문자를 보내드리고 있으며\n' +
      '접수증, 납부서, 신고서는 고객님의 이메일로 \n받아보실 수 있습니다.'
      : '신고가 완료되는 즉시 안내 문자를 보내드리고 있으며' +
      '접수증, 납부서, 신고서는 \n고객님의 이메일로 받아보실 수 있습니다.',
  },
  {
    title: '신고는 언제 끝나나요?',
    content: (isMobile || isTablet)
      ? '고객님의 접수가 완료된 날로부터 최대 2업무일 이내에 \n신고가 완료됩니다.' +
      '요청자료를 빠르게 회신해주시면 \n더욱 신속하게 결과를 받아보실 수 있습니다.'
      : '고객님의 접수가 완료된 날로부터 최대 2업무일 이내에 신고가 완료됩니다.\n' +
      '요청자료를 빠르게 회신해주시면 더욱 신속하게 결과를 받아보실 수 있습니다.',
  },
  {
    title: '세이브택스 신고대리센터의 장점은\n무엇인가요?',
    content: (isMobile || isTablet)
      ? '국내 최저 신고 수수료, 1분 간편 신청, 수수료 카드결제 \n시스템 등이 타 업체와 차별화되는 장점입니다.\n' +
      '그리고 무엇보다 국내 최대 규모의 신고대리 센터로써 \n고품질의 서비스를 제공하고 있습니다.'
      : '국내 최저 신고 수수료, 1분 간편 신청, 수수료 카드결제 시스템 등이 \n타 업체와 차별화되는 장점입니다.' +
      ' 그리고 무엇보다 \n국내 최대 규모의 신고대리 센터로써 고품질의 서비스를 제공하고 있습니다.',
  },
  {
    title: '회사 다니면서 별도소득이 있는데\n어떻게 되나요?',
    content: (isMobile || isTablet)
      ? '근로소득 이외의 별도 소득이 있으실 경우 종합소득세를 \n신고 및 납부하셔야 합니다.' +
      '고객센터(1644-9108)나 \n홈페이지를 통해 문의해주시면 세이브택스의 전문 상담사가 \n바로 안내해드리겠습니다.\n'
      : '근로소득 이외의 별도 소득이 있으실 경우 종합소득세를 신고 및 납부하셔야 합니다.\n' +
      '고객센터(1644-9108)나 홈페이지를 통해 문의해주시면 \n세이브택스의 전문 상담사가 바로 안내해드리겠습니다.\n'
  },
]);

export const BANK_NAME = [
  '경남은행',
  '광주은행',
  '국민은행',
  '기업은행',
  '농협은행',
  '대구은행',
  '부산은행',
  '산립조합중앙회',
  '산업은행',
  '새마을금고중앙회',
  '수협은행',
  '신한은행',
  '신협중앙회',
  '우리은행',
  '우체국',
  '저축은행',
  '전북은행',
  '제주은행',
  '지역농.축협',
  '한국씨티은행',
  'KEB하나은행',
  'SC은행',
]
