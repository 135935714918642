import React from 'react';
import { css } from 'aphrodite/no-important';

import { CreateBetaStyle } from '../../../shared/presentation/shared/CreateStyle';
import { RequestStyle } from '../../request-page-2022/shared/RequestStyle';
import { RequestColor } from '../../request-page-2022/assets/ColorContents';

interface TypeProps {
  onClickVirtualAccount: () => void;
  onClickCredit: () => void;
  onClickCancel: () => void;
}

export function PaymentTypePopup(
  {
    onClickVirtualAccount,
    onClickCredit,
    onClickCancel,
  }: TypeProps) {
  return (
    <div className={css(styles.wrapper)}>
      <div className={css(styles.frame)}>
        <p className={css(styles.selection)} onClick={onClickVirtualAccount}>가상계좌로 결제</p>
        <p className={css(styles.selection)} onClick={onClickCredit}>신용카드/페이로 결제</p>
        <p className={css(styles.cancel_button)} onClick={onClickCancel}>취소</p>
      </div>
    </div>
  );
}

const styles = CreateBetaStyle({
  wrapper: {
    width: '100vw',
    height: '100vh',

    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 500,

    backgroundColor: 'rgba(32, 35, 40, 0.6)',

    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  frame: {
    width: '100%',
    height: '220px',
    // height: '150px',
    maxWidth: 640,
    backgroundColor: RequestColor.WHITE,

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    borderRadius: '30px 30px 0px 0px',

    paddingTop: '20px',

    fontSize: '18px',
    fontWeight: 500,
    color: RequestColor.GRAY_100,
  },
  selection: {
    height: '56px',

    display: 'flex',
    alignItems: 'center',

    cursor: 'pointer',
  },
  cancel_button: {
    fontSize: '18px',
    color: '#737D88',

    marginTop: '23px',

    cursor: 'pointer',
  },
});