import { css } from 'aphrodite/no-important';
import React from 'react';
import { CreateStyle } from '../../../../shared/presentation/shared/CreateStyle';

interface Props {
  title: string;
  onClick?: () => void;
  isDisabled?: boolean;
  className?: string;
}

export const MetaTaxButton: React.FC<Props> = ({ title, onClick, className, isDisabled = false }) => {
  const handler = isDisabled ? () => {} : onClick;
  return <button className={[css(styles.button, isDisabled && styles.disabled), className].join(' ')} onClick={handler}>
    {title}
  </button>
}

const styles = CreateStyle({
  button: {
    width: '100%',
    height: '62px',
    borderRadius: '10px',
    backgroundColor: '#0094FF',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    fontSize: '17px',
    fontWeight: 700,
    cursor: 'pointer',

    ':hover': {
      backgroundColor: '#1b1d91',
      transition: '0.3s',
    },
  },
  disabled: {
    backgroundColor: '#E0E0E0',
    border: '1px solid #CAE0FF',
    cursor: 'default',
    ':hover': {
      backgroundColor: '#E0E0E0',
    },
  },
}, true, false, { isNoRatio: true });
