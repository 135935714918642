import React from 'react';
import ReactDOM from 'react-dom';
import { Route, Switch } from 'react-router-dom';

import '../index.css';

import * as serviceWorker from '../serviceWorker';
import { setUpContainer } from '../shared/helper/Container';
import '../shared/helper/IEHelper';
import { RootFrame } from '../shared/presentation/RootFrame';
import Home from './pages/home/Home';
import RequestSuccessPopUp from './pages/popup/RequestSuccessPopUp';
import KakaoPopUp from '../shared/presentation/kakao-popup/KakaoPopUp';
import Manual from './pages/manual/Manual';
import TaxReturnRequest from './pages/the-tax-return-request/TaxReturnRequest';
import { MetaTaxTestInputPage } from './pages/test-input/MetaTaxTestLoginStep01';
import { MetaTaxTestInputPage02 } from './pages/test-input/MetaTaxTestLoginStep02';
import { MetaTaxTestCardPoints } from './pages/test-input/MetaTaxTestCardPoints';
import MetaTaxTestCertBankAccount from './pages/test-input/MetaTaxTestBank';
import MetaTaxTestResult from './pages/test-input/MetaTaxTestResult';
import { RegisterPage } from '../taxagent-shared/request-page-2022/RegisterPage';
import { container } from '../shared/inversify.config';
import {
  HomeTaxLoginService,
  TOTAL_INCOME_TAX_HOME_TAX_LOGIN_SERVICE
} from '../taxagent-shared/request-page-2022/pages/3_홈택스_로그인/HomeTaxLoginStore';
import PrivacyPolicyTotalIncome from '../savetax/pages/shared/PrivacyPolicyTotalIncome';
import { RegisterCustomerPage } from '../taxagent-shared/request-page-2022/RegisterCustomerPage';
import TermsOfServiceTotalIncome from '../savetax/pages/shared/TermsOfServiceTotalIncome';
import { Menu } from './pages/menu/Menu';
import { IosPage } from '../taxagent-shared/ios/IosPage';
import { TheRefundPaymentPage } from '../taxagent-shared/the-refund-payment-page/TheRefundPaymentPage';

container.bind(TOTAL_INCOME_TAX_HOME_TAX_LOGIN_SERVICE).to(HomeTaxLoginService);
setUpContainer();

ReactDOM.render(
  <RootFrame project={'taxagent'}>
    <Switch>
      <Route exact path="/meta-tax/test-input/01" component={MetaTaxTestInputPage}/>
      <Route exact path="/meta-tax/test-input/02" component={MetaTaxTestInputPage02}/>
      <Route exact path="/meta-tax/test-input/point" component={MetaTaxTestCardPoints}/>
      <Route exact path="/meta-tax/test-input/bank" component={MetaTaxTestCertBankAccount}/>
      <Route exact path="/meta-tax/test-input/result" component={MetaTaxTestResult}/>
      <Route path="/tax-return-request" component={TaxReturnRequest}/>
      <Route path={'/step/:step'} component={TaxReturnRequest}/>
      <Route path={'/privacy-policy'} component={PrivacyPolicyTotalIncome}/>
      <Route path={'/terms_of_service'} component={TermsOfServiceTotalIncome}/>
      <Route>
        <Route component={KakaoPopUp}/>
        <Route component={RequestSuccessPopUp}/>
        {/*<Route component={RequestDefaultPopup}/>*/}
        {/*<Route component={LastWorkingTimePopUp}/>*/}
        {/*<Route component={WorkingTimePopUp}/>*/}
        <Route component={Menu}/>
        <Switch>
          <Route exact path="/manual" component={Manual}/>
          {/*<Route path='/request' requestcomponent={RequestPage}/>*/}
          {/*<Route path='/code/:code' component={RequestPage}/>*/}
          {/*<Route path={'/add_request/:recordId'} render={() => <RequestPage noHeader/>}/>*/}
          {/*<Route path={'/add_request_personal/:recordId'} render={() => <RequestPage noHeader/>}/>*/}
          {/*<Route path={'/add_request_file/:recordId'} render={() => <RequestPage noHeader/>}/>*/}
          {/*<Route path={'/add_kakao_login/:recordId'} render={() => <RequestPage noHeader/>}/>*/}
          {/*<Route path={'/add_kakao_login02/:recordId'} render={() => <RequestPage noHeader/>}/>*/}
          <Route path={'/register/:step'} component={RegisterPage}/>
          <Route exact path='/register-customer/:customerCode' component={RegisterCustomerPage}/>
          {/*<Route path={'/payment/:step/:recordId'} component={PaymentPage}/>*/}
          {/*<Route path={'/payment/:step'} component={PaymentPage}/>*/}
          <Route path={'/payment02/:step/:recordId'} component={TheRefundPaymentPage}/>
          <Route path={'/payment02/:step'} component={TheRefundPaymentPage}/>
          <Route path={'/ios'} component={IosPage}/>
          <Route component={Home}/>
        </Switch>
      </Route>
    </Switch>
  </RootFrame>
  ,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
