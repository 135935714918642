import React from 'react';
import { useParams } from 'react-router-dom';
import { css } from 'aphrodite';

import { Image } from '../../../../shared/css/Image';
import BackButton from '../../assets/svgs/header-arrow.svg';
import { CreateBetaStyle } from '../../../../shared/presentation/shared/CreateStyle';
import { RequestFont } from '../../shared/RequestFont';
import ChannelService from '../../shared/channel-talk/ChannelService';
import { useMyHistory } from '../../../../shared/presentation/HistoryHelper';
import { RequestColor } from '../../assets/ColorContents';

interface Props {
  onClick?: () => void,
  isVisibleAskBtn?: boolean,
}

const RequestHeader: React.FC<Props> = ({ onClick, isVisibleAskBtn }) => {
  const history = useMyHistory();
  const { step } = useParams<any>();

  const openChannelTalk = () => {
    ChannelService.boot({
      'pluginKey': 'bf8e00f1-5e83-4106-925c-756751c35801',
      'hideChannelButtonOnBoot': true
    });
  };

  const clickHandler = () => {
    // if (Number(step) > 5) {
    //   // history.push(REQUEST_PATH.step05);
    //   return;
    // }
    // history.push('/');
    if (onClick == null) {
      history.goBack();
    }
    onClick?.();
  };

  return (
    <div className={css(styles.header)}>
      <div
        className={css(styles.backIcon)}
        onClick={clickHandler}
      >
        <Image
          src={BackButton}
          alt={'back-btn'}
        />
      </div>
      {isVisibleAskBtn && (
        <div
          className={css(RequestFont.HEADER, styles.askBtn)}
          onClick={openChannelTalk}
        >
          문의하기
        </div>
      )}
    </div>
  );
};

const styles = CreateBetaStyle({
  header: {
    width: '100%',
    height: '56px',
    minHeight: '56px',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    backgroundColor: RequestColor.WHITE,
  },
  backIcon: {
    width: '24px',
    height: '24px',
    transform: 'translateX(-5px)'
  },
  askBtn: {
    cursor: 'pointer',
  }
});

export default RequestHeader;
