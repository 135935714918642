import React from 'react';
import { css } from 'aphrodite';
import { useRecoilValue } from 'recoil';

import { CreateStyle } from '../../../../shared/presentation/shared/CreateStyle';
import { spoqa } from '../../../../shared/css/css-utils';
import BackgroundImage from '../../../assets/images/request_background.jpg';
import { Image } from '../../../../shared/css/Image';
import { totalScreenState } from '../../../../shared/presentation/MobileFrame';
import { goToRequestPage } from '../../../../shared/store/TaxagentStore';

function RequestSection() {
  const { isMobile, isTablet } = useRecoilValue(totalScreenState);

  const styles = MainStyle(isMobile, isTablet);
  return (
    <div className={css(styles.background)}>
      <Image src={BackgroundImage} alt={'background'} className={css(styles.background_image)}/>
      <div className={css(styles.main_div)}>
        <div className={css(styles.main__title)}>
          국내최대 종합소득세 신고대리 서비스 <br/>
          지금 바로 신청하세요!
        </div>
        <div
          className={css(styles.main__request_button)}
          onClick={goToRequestPage}
        >
          {
            process.env.REACT_APP_ACTIVE === 'FALSE'
            ? '신청이 마감되었습니다'
            : '간편 신청하기'
          }
        </div>
      </div>
    </div>
  );
}

const MainStyle = (isMobile: boolean, isTablet: boolean) => CreateStyle({
  background: {
    height: '220px/520px@521px',

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    paddingTop: '55px/176px@135px',

    position: 'relative',

    fontFamily: spoqa,
  },
  background_image: {
    position: 'absolute',
    width: '100%',
    height: '100%',

    top: 0,
    left: 0,
    zIndex: -2,

    objectFit: 'cover',
    objectPosition_: '/0 -100px',
  },
  main_div: {
    color: 'white',

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  main__title: {
    width: '278px/@',
    lineHeight: 1.5,
    fontSize: '18px/35px@43px',
    textAlign: 'center',
    fontWeight: 300,

    marginBottom: '25px/22px@40px',
    whiteSpace: 'pre-wrap',
  },
  main__request_button: {
    width: '280px/248px@600px',
    height: '42px/56px@90px',

    border: 'solid 1px white',

    fontWeight: 500,
    fontSize: '11px/16px@23px',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    cursor: 'pointer',

    paddingBottom: '3px/3px',

    ':hover': {
      backgroundColor: 'white',
      color: process.env.REACT_APP_BASE_COLOR,
      transition: '0.3s',
    },
  },
}, isMobile, isTablet);
export default RequestSection;
