import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as _ from 'lodash';
import { isIOS, isMobileOnly } from 'react-device-detect';

import SimpleAuthRequest from './pages/1_간편인증/SimpleAuthRequest';
import { KakaoAuthView } from './pages/2_카카오_지갑인증/KakaoAuthView';
import { HomeTaxLogin } from './pages/3_홈택스_로그인/HomeTaxLogin';
import HomeTaxPasswordReset from './pages/3_홈택스_로그인/HomeTaxPasswordReset';
import SupportFamily from './pages/5_부양가족/SupportFamily';
import AddSupportFamily from './pages/5_부양가족/AddSupportFamily';
import { AddSupportFamilyForm } from './pages/5_부양가족/AddSupportFamilyForm';
import Toast from './shared-component/toast/Toast';
import { RequestAlertFrame } from './shared-component/RequestAlertFrame';
import { AlertFrame } from './shared-component/ShowAlertFrame';
import { ModalPopup } from './shared-component/ModalPopup';
import { IosPopup } from '../ios/IosPopup';
import { useMyHistory } from '../../shared/presentation/HistoryHelper';
import { SelectRequestType } from './pages/0_접수유형/SelectRequestType';
import { GroupRequest } from './pages/1_1_단체접수/GroupRequest';
import { GroupRequestSuccess } from './pages/2_1_단체_신청완료/GroupRequsetSuccess';
import { ProxyRequest } from './pages/1_간편인증_대리인_추가_정보/ProxyReqeust';
import { LoadingForConfirmRefundView } from './pages/6_환급액_확인_로딩/LoadingForConfirmRefundView';
import { IncomeResult } from './pages/7_소득_확인/IncomeResult';
import { RequestSuccess } from './pages/8_신청완료/RequsetSuccess';
import { ConsentPage } from './pages/4_수임동의/ConsentPage';

const locationKeys: string[] = [];

export const RegisterPage: React.FC = () => {
  const [isKakaoInapp, setIsKakaoInapp] = useState(false);
  const { step } = useParams<any>();
  const history = useMyHistory();

  useEffect(() => {
    if (_.nth(locationKeys, -2) === step) {
      locationKeys.pop();
      if (step > 5) {
        // history.push(REQUEST_PATH.step05);
        return;
      }
      // history.push('/');
      return;
    }
    locationKeys.push(step);
  }, [step]);

  useEffect(() => {
    const isKakaoInapp = navigator.userAgent.includes('KAKAOTALK');
    const originPath = window?.location?.origin;
    if (isKakaoInapp && isMobileOnly) {
      if (isIOS) {
        setIsKakaoInapp(true);
        return;
      }
      window.open(`intent://${originPath.replace('https://', '',).replace('http://', '')}/register/0#Intent;scheme=http;package=com.android.chrome;end`);
      // window.open(originPath)
      // location.href='intent://'+location.href.replace(/https?:\/\//i,'')+'#Intent;scheme=http;package=com.android.chrome;end';
    }
  }, []);

  const StepNode = useMemo(() => mapToStep(step), [step]);
  return (
    <>
      {isKakaoInapp && <IosPopup/>}
      <Toast/>
      <AlertFrame/>
      <RequestAlertFrame/>
      <ModalPopup/>
      {StepNode}
    </>
  );
};

const mapToStep = (step: string) => {
  switch (step) {
    case '0':
      return <SelectRequestType/>;
    case '1':
      return <SimpleAuthRequest/>;
    case '1-1':
      return <ProxyRequest/>;
    case '2':
      return <KakaoAuthView/>;
    case '3':
      return <HomeTaxLogin/>;
    case '3-1':
      return <HomeTaxPasswordReset/>;
    case '4':
      return <ConsentPage/>;
    case '5':
      return <LoadingForConfirmRefundView/>;
    case '6':
      return <SupportFamily/>;
    case '6-1':
      return <AddSupportFamily/>;
    case '6-2':
      return <AddSupportFamilyForm/>;
    case '7':
      return <LoadingForConfirmRefundView/>;
    case '8':
      return <IncomeResult/>;
    case '9':
      return <RequestSuccess/>;
    case 'group01':
      return <GroupRequest/>;
    case 'group02':
      return <GroupRequestSuccess/>;
    default:
      return <SimpleAuthRequest/>;

  }
};
