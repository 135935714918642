import { StyleSheet } from 'aphrodite/no-important';

// @ts-ignore
import { spoqa } from '../../../shared/css/css-utils';
import { RequestColor } from '../assets/ColorContents';

export const RequestFont = StyleSheet.create({
  TITLE: {
    fontFamily: spoqa,
    fontWeight: 500,
    fontSize: 26,
    color: RequestColor.GRAY_100,
    lineHeight: '38px',

    letterSpacing: '-0.2px',
    ':nth-child(1n) > span': {
      color: RequestColor.BLUE,
    }
  },
  MODAL_TITLE: {
    fontFamily: spoqa,
    fontWeight: 700,
    fontSize: 22,
    color: RequestColor.GRAY_100,
    lineHeight: '30px',
  },
  ALERT_TITLE: {
    fontFamily: spoqa,
    fontWeight: 500,
    fontSize: 18,
    color: RequestColor.GRAY_100,
    lineHeight: '26px',
  },
  ALERT_CONTENT: {
    fontFamily: spoqa,
    fontSize: 18,
    color: RequestColor.GRAY_100,
    lineHeight: '26px',
  },
  SUB_TITLE: {
    fontFamily: spoqa,
    fontWeight: 700,
    fontSize: 20,
    color: RequestColor.GRAY_100,
    lineHeight: '28px',
  },
  NOTICE_TITLE: {
    fontFamily: spoqa,
    fontWeight: 700,
    fontSize: 16,
    color: RequestColor.GRAY_100,
    lineHeight: '38px',
  },
  HIGHLIGHT: {
    fontFamily: spoqa,
    fontWeight: 700,
    fontSize: 17,
    color: RequestColor.BLUE,
    lineHeight: '26px',
  },
  BUTTON: {
    fontFamily: spoqa,
    fontSize: 16,
    lineHeight: '20px',
    fontWeight: 500,
  },
  ALTER_BUTTON: {
    fontFamily: spoqa,
    fontSize: 18,
    lineHeight: '28px',
    fontWeight: 500,
    color: RequestColor.GRAY_60,
  },
  INPUT_TEXT: {
    fontFamily: spoqa,
    fontSize: 16,
    color: RequestColor.GRAY_100,
    lineHeight: '24px',
  },
  INPUT_LABEL: {
    fontFamily: spoqa,
    fontWeight: 500,
    fontSize: 14,
    color: RequestColor.GRAY_100,
    lineHeight: '24px',
  },
  BODY: {
    fontFamily: spoqa,
    fontWeight: 500,
    fontSize: 16,
    color: RequestColor.GRAY_60,
    lineHeight: '26px',
  },
  PRE: {
    whiteSpace: 'pre',
  },
  BLUE_100: {
    color: RequestColor.BLUE,
  },
  GRAY_60: {
    color: RequestColor.GRAY_60,
  },
  HEADER: {
    fontFamily: spoqa,
    fontWeight: 700,
    fontSize: 16,
    color: RequestColor.GRAY_80,
    lineHeight: '26px',
  },
  FOOTER_BTN: {
    fontFamily: spoqa,
    fontWeight: 400,
    fontSize: 14,
    color: RequestColor.GRAY_80,
    lineHeight: '26px',
  },
  DESCRIPTION: {
    fontFamily: spoqa,
    fontWeight: 500,
    fontSize: 14,
    color: RequestColor.GRAY_60,
    lineHeight: '26px',
  },
  DESCRIPTION_TITLE: {
    fontFamily: spoqa,
    fontSize: 16,
    color: RequestColor.GRAY_60,
    lineHeight: '26px',
  },
  CONTENT_TITLE: {
    fontFamily: spoqa,
    fontSize: 16,
    color: RequestColor.GRAY_100,
    lineHeight: '38px',
  },
  CONTENT: {
    fontFamily: spoqa,
    fontSize: 16,
    color: RequestColor.GRAY_80,
    lineHeight: '30px',
  },
  TOAST_MESSAGE: {
    fontFamily: spoqa,
    fontSize: 16,
    color: RequestColor.GRAY_10,
    lineHeight: '26px',
  },
  FONT_WEIGHT_MEDIUM: {
    fontWeight: 500,
  },
});
