import { css } from 'aphrodite';
import React, { RefObject } from 'react';

import { CreateBetaStyle } from '../../../../shared/presentation/shared/CreateStyle';
import { FormInput } from './FormInput';
import { RequestColor } from '../../assets/ColorContents';

interface Props {
  labelText: string,
  addStyle?: Object,
  addInputStyle?: Object,
  placeholder: string,
  children?: any
  onFocus?: () => void,
  onBlur?: (value: any) => void,
  onChange?: (value: any) => void,
  onKeydown?: (e: any) => void,
  value?: string,
  refObject?: RefObject<HTMLInputElement>,
  id?: string,
  isNumeric?: boolean,
  inputType?: string,
  isValid?: boolean,
  maxLength?: number,
  isDisabled?: boolean,
}

export const labelStyle = CreateBetaStyle({
  label: {
    fontSize: '14px',
    lineHeight: '24px',
    color: RequestColor.GRAY_100,

    marginTop: '24px',
    marginBottom: '6px',
    marginLeft: '11px',
  },
});

export const FormInputLabel: React.FC<{ label: string }> = ({label}) => <div className={css(labelStyle.label)}>{label}</div>;


export const FormInputWithLabel = (
  {
    labelText,
    addStyle,
    placeholder,
    children,
    onFocus,
    onBlur,
    onChange,
    value,
    onKeydown,
    refObject,
    id,
    isNumeric,
    inputType,
    isValid = true,
    maxLength,
    isDisabled,
    addInputStyle,
  }: Props) => {
  return (
    <div className={css(styles.wrapper)}>
      <div className={css(labelStyle.label, !isValid && styles.color_red)}>{labelText}</div>
      <FormInput
        id={id ?? 'request' + labelText}
        addStyle={addStyle}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        onFocus={onFocus}
        onBlur={onBlur}
        onKeydown={onKeydown}
        refObject={refObject}
        inputType={inputType}
        isValid={isValid}
        maxLength={maxLength}
        isDisabled={isDisabled}
        isNumeric={isNumeric}
        addInputStyle={addInputStyle}
      />
    </div>
  );
};

const styles = CreateBetaStyle({
  wrapper: {
    width: '100%',

    position: 'relative',
  },
  color_red: {
   color: RequestColor.RED,
  }
});
