import { CreateStyle } from '../../../../shared/presentation/shared/CreateStyle';
import { spoqa } from '../../../../shared/css/css-utils';

export const BenefitStyle = (isMobile: boolean, isTablet: boolean) => CreateStyle({
  background: {
    width: '100%',
    height: '440px/700px@1056px',

    color: 'white',
    fontFamily: spoqa,

    position: 'relative',
  },
  box_hidden: {
    opacity: 0,
    transform: 'translateY(70px)',
  },
  background__image: {
    position: 'absolute',
    width: '100%',
    height: '100%',

    top: 0,
    left: 0,
    zIndex: -2,

    objectFit_: 'cover/@cover',
    objectPosition: '60%/@57%',
  },
  main_div: {
    transform: 'translateY(0px)',
  },
  transition_basic: {
    transition: 'transform 0.75s 0s, opacity 0.75s',
  },
  transition_delay: {
    transition: 'transform 0.9s 0s, opacity 0.75s',
  },
  transition_long_delay: {
    transition: 'transform 0.9s 0.1s, opacity 0.75s 0.1s',
  },
  slide_page_frame: {
    width: '100%',
    height: '440px/700px@1056px',

    paddingLeft: '20px/335px@86px',
    paddingTop: '36px/129px@86px',

    position: 'relative',
    zIndex: 10,
  },
  main__title: {
    fontWeight: 300,
    fontSize: '11px/35px@26px',
    lineHeight: '1.91/1.4',
    marginBottom: '6px/8px@28px'
  },
  main__content: {
    width: '232px/',
    fontWeight: 300,
    fontSize: '20px/16px@43px',
    lineHeight: '1.4/1.75@60px',

    whiteSpace: 'pre',

    marginBottom: '18px/@48px',
  },
  main__request_button: {
    width: '278px/@596px',
    height: '42px/@90px',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    fontWeight: 500,
    fontSize: '11px/@23px',
    color: 'white',

    paddingBottom: '2px/',

    border: 'solid 1px white',

    cursor: 'pointer',

    ':hover': {
      backgroundColor: 'white',
      color: process.env.REACT_APP_BASE_COLOR,
      transition: '0.3s',
    },
    zIndex: 10,
  },
  select_div: {
    display: 'flex',

    position: 'absolute',
    bottom: '0px/147px@0px',
    left: '19px/335px@50px',
  },
  select__element_box: {
    width: '93px/421px@223px',
    height: '65px/110px@134px',

    borderTop: 'solid 2px rgba(255,255,255,0.3)',

    paddingTop: '15px/20px@37px',

    cursor: 'pointer',

    position: 'relative',

    '-webkit-tap-highlight-color': 'transparent',
  },
  select__element_event: {
    borderTop: 'solid 2px rgba(255,255,255,1)',
    transition: '0.3s',
  },
  select__title: {
    fontWeight: 300,
    fontSize: '11px/16px@26px',
    lineHeight: 1.75,

    marginBottom: '/6px',

    cursor: 'pointer',

    opacity_: `/@${0.7}`
  },
  select__title__padding: {
    paddingLeft: '7px/@17px',
  },
  select__content: {
    fontWeight: 500,
    fontSize: '/13px',
    lineHeight: 1.85,

    whiteSpace: 'pre',
  },
}, isMobile, isTablet);
