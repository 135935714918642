import React from 'react';
import { css } from 'aphrodite';
import * as _ from 'lodash';
import { atom, useRecoilValue } from 'recoil';

import { IResizeFrame } from '../../../shared/WithResizeFrame';
import { CreateStyle } from '../../../shared/presentation/shared/CreateStyle';
import { MetaTaxHeader } from './component/MetaTaxHeader';
import { spoqa } from '../../../shared/css/css-utils';
import { VerticalSpace } from './component/VerticalSpace';
import { MetaTaxButton } from './component/MetaTaxButton';

const DUMMY = [{
 cardName: 'samsungcard', result: '계좌 입금 신청이 정상적으로 완료 됐습니다.', value: 1,
}]

export const resultPointsState = atom<any[]>({
  key: 'resultPointsState',
  default: DUMMY,
});

const MetaTaxTestResult: React.FC<IResizeFrame> = (props) => {
  const resultPoints = useRecoilValue(resultPointsState);
  const totalResult = _.chain(resultPoints).filter(v => v.isSuccess).map(v => v.value).sum().value();
  return (
    <div className={css(styles.wrapper)}>
      <MetaTaxHeader/>
      <p className={css(styles.description)}>
        <span>{totalResult.toLocaleString()}원</span>이<br/>
        입금되었어요!<br/><br/>
        지금 계좌를 확인해보세요!
      </p>
      <VerticalSpace/>
      <MetaTaxButton title={'확인'}/>
    </div>
  );
};

export default MetaTaxTestResult;

const styles = CreateStyle({
  wrapper: {
    minHeight: '100vh',
    color: 'black',
    padding: '83px 30px 30px/',

    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'center',
  },
  description: {
    fontFamily: spoqa,
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '38px',
    color: '#454F5D',

    whiteSpace: 'pre',
    ':nth-child(1n) > span': {
      color: '#0094FF',
    }
  }
}, true, false, { isNoRatio: true });
