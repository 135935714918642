import React, { useCallback, useEffect, useRef, useState } from 'react';
import { css } from 'aphrodite';
import axios from 'axios';
import { atom, useRecoilState, useSetRecoilState } from 'recoil';

import { RequestWrapperWithHeader } from '../../shared-component/RequestWrapperWithHeader';
import { RequestFont } from '../../shared/RequestFont';
import { CreateBetaStyle } from '../../../../shared/presentation/shared/CreateStyle';
import { VerticalSpace } from '../../../../taxagent/pages/test-input/component/VerticalSpace';
import { useMyHistory } from '../../../../shared/presentation/HistoryHelper';
import { HOME_TAX_URL, REQUEST_PATH } from '../SharedConstants';
import { FormInputWithLabel } from '../../shared-component/input/FormInputWithLabel';
import { isLoadingState } from '../../../../shared/presentation/LoadingFrame';
import { useInjection } from '../../../../shared/presentation/InversifyProvider';
import { HomeTaxLoginService, responseFormat, TOTAL_INCOME_TAX_HOME_TAX_LOGIN_SERVICE } from './HomeTaxLoginStore';
import { useRequestFormValue } from '../../shared/RequestContents';
import { LoginPolicy, LoginResponsePolicy } from './LoginPolicy';
import { useHometaxState } from '../../shared/HometaxState';
import { RequestStyle } from '../../shared/RequestStyle';
import { alertMessageState } from '../../shared-component/ShowAlertFrame';
import { requestAlertMessageState } from '../../shared-component/RequestAlertFrame';
import { useHometaxCookieValue } from '../../shared/HometaxCookieState';
import { RequestButtonWrapper } from '../../shared-component/RequestButtonWrapper';
import RequestVerticalButtons from '../../shared-component/RequestVerticalButtons';
import { RequestColor } from '../../assets/ColorContents';
import { RequestButton } from '../../shared-component/RequestButton';

export const hometaxPasswordState = atom<string>({
  key: 'hometaxPWState',
  default: '',
});

export const HomeTaxLogin: React.FC = () => {
  const history = useMyHistory();
  const setIsLoading = useSetRecoilState(isLoadingState);
  const setAlert = useSetRecoilState(alertMessageState);
  const showAlert = useSetRecoilState(requestAlertMessageState);
  const loginService = useInjection<HomeTaxLoginService>(TOTAL_INCOME_TAX_HOME_TAX_LOGIN_SERVICE);
  const [isSuccess, setIsSuccess] = useState(false);
  const { customerId, isProxy } = useRequestFormValue();
  const [hometaxPassword, setHometaxPassword] = useRecoilState(hometaxPasswordState);
  const cookie = useHometaxCookieValue();
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [{ hometaxId, isIdValid }, setHometax] = useHometaxState();
  const [isHometaxIdExist, setIsHometaxIdExist] = useState(false);
  const pwdInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setIsLoading(true);
    // console.log(hometaxId);
    axios.get('https://api.ailabscorp.com/total-income-tax/user/hometaxId/' + encodeURIComponent(customerId))
      .finally(() => setIsLoading(false))
      .then(({ data }) => {
        if (data == null || data === '') {
          setIsHometaxIdExist(false);
          return;
        }
        setHometax({ hometaxId: data });
        setIsHometaxIdExist(true);
        // if (!data) {
        //   history.push(REQUEST_PATH.step01);
        // }
      });
    return;
    setHometax({ hometaxId: '' });
  }, [customerId, setIsLoading]);

  const clickHandler = useCallback(async () => {
    if (hometaxPassword === '') {
      setIsPasswordValid(false);
      return;
    }
    showToastMessage();
    setIsLoading(true);
    await loginService?.sendLoginRequestToHomeTax({
      id: hometaxId,
      password: Buffer.from(hometaxPassword, 'utf-8').toString('base64'),
      customerId,
    })
      .finally(() => setIsLoading(false))
      .then(({ resultCode }: responseFormat) => {
        if (resultCode === 'Fail') {
          setAlert({ message: '사업자 계정입니다. \n개인 계정으로 다시 로그인 해주세요. ' });
          return;
        }
        // setStep({step: REQUEST_PATH.step09});
        setIsSuccess(true);
      })
      .catch(({ response }) => {
        const message = LoginResponsePolicy.responseMessage(response.data.message);
        setAlert({ message });
      });
  }, [hometaxId, hometaxPassword]);

  useEffect(() => {
    if (isSuccess) {
      if (isProxy) {
        history.push(REQUEST_PATH.step07);
        return;
      }
      history.push(REQUEST_PATH.step05);
    }
  }, [isSuccess]);

  const showToastMessage = useCallback(() => {
    if (!isPasswordValid) {
      pwdInputRef?.current?.focus();
    }
  }, [isPasswordValid]);

  const resetHomeTax = () => {
    if (cookie.WMONID == null) {
      showAlert({
        message: '홈택스에서 비밀번호 재설정 후\n' +
          '다시 신청해주세요.',
        button: [
          {
            title: '홈택스 바로가기',
            onClick: () => window.open(HOME_TAX_URL),
          },
          {
            title: '확인',
          }
        ]
      });
      return;
    }
    history.push(REQUEST_PATH.step04);
  };

  return (
    <RequestWrapperWithHeader className={css(styles.wrapper)}>
      <div className={css(styles.title_box)}>
        <p className={css(RequestFont.TITLE, RequestFont.PRE, styles.title)}>
          신고하실 분의<br/>
          홈택스 계정을 입력해주세요
        </p>
        <p className={css(RequestFont.DESCRIPTION)}>
          *개인사업자일 경우 사업자 계정이 아닌 개인 계정 정보를 입력해 주세요.
        </p>
      </div>
      <div className={css(styles.label_box)}>
        <FormInputWithLabel
          labelText={'홈택스 아이디'}
          placeholder={'홈택스 아이디'}
          value={hometaxId}
          onChange={(value) => setHometax({ hometaxId: value })}
          isDisabled={isHometaxIdExist}
        />
        <FormInputWithLabel
          labelText={'홈택스 비밀번호'}
          placeholder={'홈택스 비밀번호'}
          inputType={'password'}
          value={hometaxPassword}
          onChange={(val: string) => setHometaxPassword(val)}
          addInputStyle={!isPasswordValid && RequestStyle.INPUT_ERROR}
          onBlur={(val: string) => {
            setHometaxPassword(val);
            setIsPasswordValid(LoginPolicy.isValidPassword(val));
          }}
          isValid={isPasswordValid}
          onFocus={() => setIsPasswordValid(true)}
          refObject={pwdInputRef}
          maxLength={15}
        />
      </div>
      <div
        className={css(RequestFont.FOOTER_BTN, styles.reset_password_btn)}
        // onClick={() => history.push(REQUEST_PATH.step04)}
        onClick={() => resetHomeTax()}
      >
        비밀번호 재설정
      </div>
      <VerticalSpace/>
      <RequestButtonWrapper>
        {isProxy ? (
          <RequestVerticalButtons
            items={[
              {
                title: '다음',
                isActive: isIdValid && isPasswordValid,
                onClick: clickHandler,
                marginBottom: 16,
              },
              {
                title: '정보를 모르겠어요',
                border: `solid 1px ${RequestColor.BLUE}`,
                backgroundColor: 'white',
                color: RequestColor.BLUE,
                onClick: () => history.push(REQUEST_PATH.step07)
              }
            ]}
          />
        ) : (
          <RequestButton
            title={'다음'}
            isActive={isIdValid && isPasswordValid}
            onClick={clickHandler}
          />
        )}
      </RequestButtonWrapper>
    </RequestWrapperWithHeader>
  );
};

const styles = CreateBetaStyle({
  title_box: {
    width: '100%',
  },
  title: {
    marginBottom: '8px',
  },
  wrapper: {
    paddingBottom: '100px',
  },
  label: {
    width: '100%',
  },
  label_box: {
    width: '100%',
  },
  reset_password_btn: {
    alignSelf: 'flex-end',
    marginTop: '24px',

    cursor: 'pointer',
  }
});

