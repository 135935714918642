import * as _ from 'lodash';
import { useCookies } from 'react-cookie';
import { useCallback } from 'react';

import { PHONE_FORMAT_POLICY, PhoneFormatPolicy } from '../../../shared/domain/PhoneFormatPolicy';
import { useInjection } from '../../../shared/presentation/InversifyProvider';
import { COOKIE_MAX_AGE } from '../pages/SharedConstants';

const COOKIE_LIST = ['group_name', 'group_phone', 'group_amount', 'group_id', 'group_recommendCode'];

export const useGroupRequestFormState = (): [RequestAuthForm, (props: Partial<RequestAuthForm>) => void, () => void] => {
  const [cookie, setCookie, resetCookie] = useCookies(COOKIE_LIST);
  const phoneFormatPolicy = useInjection<PhoneFormatPolicy>(PHONE_FORMAT_POLICY);
  const form = _.chain(COOKIE_LIST)
    .map(v => ({
      key: v,
      value: cookie[v],
    }))
    .filter(v => v.value != null)
    .mapKeys(v => v.key.replace('group_' , ''))
    .mapValues(v => v.value)
    .value();
  form.phone = phoneFormatPolicy?.changeFormat(form.phone ?? '');
  return [
    new RequestAuthForm(form),
    useCallback((props: Partial<RequestAuthForm>) =>
      _.forEach(props, (value, key) =>
        setCookie('group_' + key, value, {
      path: '/',
      maxAge: COOKIE_MAX_AGE
    })), [setCookie]),
    useCallback(() => {
      COOKIE_LIST.forEach(v => resetCookie(v));
    }, [resetCookie])
  ];
};

export const useSetGroupRequestFormState = (): (props: Partial<RequestAuthForm>) => void => {
  const [, setCookie] = useCookies(COOKIE_LIST);
  return useCallback((props: Partial<RequestAuthForm>) => _.forEach(props, (value, key) => setCookie(key, value, {
    path: '/',
    maxAge: COOKIE_MAX_AGE
  })), [setCookie]);
};

export const useGroupRequestFormValue = (): RequestAuthForm => {
  const [cookie] = useCookies(COOKIE_LIST);
  const phoneFormatPolicy = useInjection<PhoneFormatPolicy>(PHONE_FORMAT_POLICY);
  const form = _.chain(COOKIE_LIST)
    .map(v => ({
      key: v,
      value: cookie[v],
    }))
    .filter(v => v.value != null)
    .mapKeys(v => v.key.replace('group_', ''))
    .mapValues(v => v.value)
    .value();
  form.phone = phoneFormatPolicy?.changeFormat(form.phone ?? '');
  return new RequestAuthForm(form);
};

export class RequestAuthForm {
  id = '';
  phone = '';
  name = '';
  amount = '';
  recommendCode = '';

  constructor(props: Partial<RequestAuthForm>) {
    Object.assign(this, props);
  }

  static isPhoneValid(phone: string): boolean {
    return phone?.replace(/ |-/g, '').length === 10 || phone?.replace(/ |-/g, '').length === 11;
  }

  static isNameValid(name: string): boolean {
    return name.length !== 0;
  }

  static isValidBasicInfo({phone, name}: { phone: string, name: string }): boolean {
    return RequestAuthForm.isPhoneValid(phone)
      && RequestAuthForm.isNameValid(name);
  }
}

