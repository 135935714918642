import React from 'react';

import { CreateBetaStyle } from '../../../shared/presentation/shared/CreateStyle';
import { RequestButton, RequestButtonProps } from './RequestButton';
import { css } from 'aphrodite';

interface Props {
  items: RequestButtonProps[];
}

const RequestVerticalButtons: React.FC<Props> = ({items}: Props) => {
  return <div className={css(styles.wrapper)}>
    {items.map((item, index) => {
      return <RequestButton {...item} key={'btn' + index}/>;
    })}
  </div>;
};

export default RequestVerticalButtons;

const styles = CreateBetaStyle({
  wrapper: {
    width: '100%',

    display: 'flex',
    flexDirection: 'column',

    marginBottom: '12px',
  },
});
