export const REQUEST_PATH: Record<TheRefundRequestPath, string> = {
  selectStep: '/register/0',
  step01: '/register/1',
  step01_1: '/register/1-1',
  step02: '/register/2',
  step03: '/register/3',
  step04: '/register/3-1',
  step05: '/register/4',
  step06: '/register/5',
  step07: '/register/6',
  step08: '/register/6-1',
  step09: '/register/6-2',
  step10: '/register/7',
  step11: '/register/8',
  step12: '/register/9',
  privacyPolicy: '/privacy-policy',
  termsOfService: '/terms-of-service',
  groupStep01: '/register/group01',
  groupStep02: '/register/group02',
};

export type TheRefundRequestPath = 'step01' | 'step02' | 'step03' |
  'step04' | 'step05' | 'step06' | 'step07' |
  'step08' | 'step09' | 'step10' | 'step11' | 'step01_1' | 'step12' |
  'privacyPolicy' | 'termsOfService' | 'groupStep01' | 'selectStep' | 'groupStep02';

export const COOKIE_MAX_AGE = 60 * 60 * 2;


export const HOME_TAX_URL = 'https://www.hometax.go.kr/';
