import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import TaxReturnRequestHeader from './TaxReturnRequestHeader';
import HomeTaxKakaoLoginTaxReturn from '../../../taxagent-shared/request-page/HomeTaxKakaoLoginTaxReturn';
import HomeTaxCertificationTimerTaxReturn from '../../../taxagent-shared/request-page/HomeTaxCertificationTimerTaxReturn';

function TaxReturnRequest() {
  return (
    <div>
      <TaxReturnRequestHeader/>
      <Switch>
        <Route exact path={'/tax-return-request/0'} component={HomeTaxKakaoLoginTaxReturn}/>
        <Route exact path={'/tax-return-request/1'} component={HomeTaxCertificationTimerTaxReturn}/>
        <Route exact path={'/tax-return-request/2'} component={HomeTaxKakaoLoginTaxReturn}/>
        <Route exact path={'/tax-return-request/3'} component={HomeTaxCertificationTimerTaxReturn}/>
        <Redirect from={'/tax-return-request'} to={'/tax-return-request/0'}/>
      </Switch>
    </div>
  )
}


export default TaxReturnRequest;
