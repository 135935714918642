import { css } from 'aphrodite';
import React, { useRef, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import axios from 'axios';

import { RequestWrapperWithHeader } from '../../shared-component/RequestWrapperWithHeader';
import { FormInputWithLabel } from '../../shared-component/input/FormInputWithLabel';
import { FormPhoneInputWithLabel } from '../../shared-component/input/FormPhoneInputWithLabel';
import { CreateBetaStyle } from '../../../../shared/presentation/shared/CreateStyle';
import { RequestFont } from '../../shared/RequestFont';
import { useGroupRequestFormState } from '../../shared/RequestGroupContents';
import { RequestButtonWrapper } from '../../shared-component/RequestButtonWrapper';
import { RequestButton } from '../../shared-component/RequestButton';
import { ModalView } from '../1_간편인증/component/ModalView';
import { RequestAuthForm } from '../../shared/RequestContents';
import { useMyHistory } from '../../../../shared/presentation/HistoryHelper';
import { REQUEST_PATH } from '../SharedConstants';
import { isLoadingState } from '../../../../shared/presentation/LoadingFrame';

export const GroupRequest: React.FC = () => {
  const [request, setRequest] = useGroupRequestFormState();
  const [isNameCorrect, setIsNameCorrect] = useState(true);
  const [isPhoneCorrect, setIsPhoneCorrect] = useState(true);
  const [isAmountCorrect, setIsAmountCorrect] = useState(true);
  const modalRef = useRef<any>(null);
  const setIsLoading = useSetRecoilState(isLoadingState);
  const history = useMyHistory();
  const onAgree = () => {
    setIsLoading(true);
    axios.post('https://api.ailabscorp.com/total-income-tax/group-reception', {
      name: request.name,
      phone: request.phone,
      groupCount: request.amount,
    })
      .then((data) => {
        setRequest({ id: data.data });
      })
      .finally(() => setIsLoading(false));

    history.push(REQUEST_PATH.groupStep02);
  };
  const onClick = () => {
    if (request.name === '') {
      setIsNameCorrect(RequestAuthForm.isNameValid(request.name));
      return;
    }
    if (!RequestAuthForm.isPhoneValid(request.phone)) {
      setIsPhoneCorrect(RequestAuthForm.isPhoneValid(request.phone));
      return;
    }
    if (request.amount === '' || Number(request.amount) < 5) {
      setIsAmountCorrect(false);
      return;
    }
    modalRef.current.show();
  };
  return (
    <RequestWrapperWithHeader className={css(styles.wrapper)}>
      <ModalView ref={modalRef} onAgree={() => onAgree()}/>
      <p className={css(RequestFont.TITLE, styles.title)}>
        연락받으실 분의<br/>
        정보를 입력해주세요.
      </p>
      <div className={css(styles.content)}>
        <FormInputWithLabel
          labelText={'이름'}
          placeholder={'이름'}
          addStyle={styles.space}
          onChange={value => setRequest({ name: value })}
          value={request.name}
          id={'grounp_name'}
          onFocus={() => setIsNameCorrect(true)}
          // refObject={nameInputRef}
          addInputStyle={!isNameCorrect && styles.invalid}
          isValid={isNameCorrect}
        />
        <FormPhoneInputWithLabel
          className={css(styles.space, !isPhoneCorrect && styles.invalid)}
          labelText={'휴대전화 번호'}
          placeholder={'휴대전화 번호'}
          onChange={value => setRequest({ phone: value })}
          isInvalid={!isPhoneCorrect}
          value={request.phone}
          id={'grounp_phone'}
          onFocus={() => setIsPhoneCorrect(true)}
          // refObject={phoneInputRef}
        />
        <FormInputWithLabel
          labelText={'단체인원 (5명 이상)'}
          placeholder={'숫자'}
          addStyle={styles.space}
          onChange={value => {
            const result = value.replace(/[^0-9]/g, '');
            setRequest({ amount: result });
          }}
          value={request.amount}
          id={'grounp_amount'}
          onFocus={() => setIsAmountCorrect(true)}
          inputType={'number'}
          addInputStyle={!isAmountCorrect && styles.invalid}
          isValid={isAmountCorrect}
        />
      </div>
      <RequestButtonWrapper>
        <RequestButton title={'다음'} onClick={onClick}/>
      </RequestButtonWrapper>
    </RequestWrapperWithHeader>
  );
};

const styles = CreateBetaStyle({
  wrapper: {
    paddingBottom: '100px',
  },
  title: {
    width: '100%',
  },
  space: {
    marginBottom: '16px',
  },
  invalid: {
    border: '1px solid red',
  },
  content: {
    width: '100%',
  },
});