import React, { useState } from 'react';
import { css } from 'aphrodite';
import { useRecoilState, useSetRecoilState } from 'recoil';
import axios from 'axios';

import { RequestWrapperWithHeader } from '../../shared-component/RequestWrapperWithHeader';
import { RequestFont } from '../../shared/RequestFont';
import { RequestStyle } from '../../shared/RequestStyle';
import { FormInputWithLabel } from '../../shared-component/input/FormInputWithLabel';
import { FormPhoneInputWithLabel } from '../../shared-component/input/FormPhoneInputWithLabel';
import { FormSSNInputWithLabel } from '../../shared-component/input/FormSSNInputWithLabel';
import { CreateBetaStyle } from '../../../../shared/presentation/shared/CreateStyle';
import { RequestAuthForm, useRequestFormState } from '../../shared/RequestContents';
import { SSN2State } from '../1_간편인증/SimpleAuthRequest';
import { RequestButtonWrapper } from '../../shared-component/RequestButtonWrapper';
import RequestVerticalButtons from '../../shared-component/RequestVerticalButtons';
import { RequestColor } from '../../assets/ColorContents';
import { REQUEST_PATH } from '../SharedConstants';
import { useMyHistory } from '../../../../shared/presentation/HistoryHelper';
import { alertMessageState } from '../../shared-component/ShowAlertFrame';
import { isLoadingState } from '../../../../shared/presentation/LoadingFrame';

export const ProxyRequest: React.FC = () => {
  const [request, setRequest /*resetRequest*/] = useRequestFormState();
  const [ssn2, setSsn2] = useState('');
  const setIsLoading = useSetRecoilState(isLoadingState);
  const history = useMyHistory();
  const setAlert = useSetRecoilState(alertMessageState);

  const [isPhoneCorrect, setIsPhoneCorrect] = useState(true);
  const [isSsnInvalid, setIsSsnInvalid] = useState(false);
  const [isNameCorrect, setIsNameCorrect] = useState(true);
  const validateInfo = () => {
    if (request.name === '') {
      setIsNameCorrect(RequestAuthForm.isNameValid(request.name));
      return false;
    }
    if (!RequestAuthForm.isPhoneValid(request.phone)) {
      setIsPhoneCorrect(RequestAuthForm.isPhoneValid(request.phone));
      return false;
    }
    if (!RequestAuthForm.isSsnValid(request.SSN1 + ssn2)) {
      setIsSsnInvalid(true);
      // axios.post('https://api.ailabscorp.com/slack/message', {
      //   message: {
      //     text: request.name + '님의 주민번호 오류: ' + request.SSN1 + ssn2 + '(step01)',
      //     type: 'error',
      //     channel: 'total-income-ssn-error'
      //   }
      // });
      return false;
    }
    return true;
  };
  const onClick = () => {
    if (!validateInfo()) {
      return;
    }
    setIsLoading(true);
    axios.post('https://api.ailabscorp.com/total-income/hometax', {
      id: request.customerId,
      hometaxName: request.hometaxName,
      hometaxPhone: request.hometaxPhone,
      hometaxSsn: request.hometaxSSN1 + ssn2,
    })
      .then(() => history.push(REQUEST_PATH.step03))
      .catch((e) => setAlert({ message: '정보 저장에 실패했습니다.\n' + e.message }))
      .finally(() => setIsLoading(false));
  };
  return (
    <RequestWrapperWithHeader>
      <div className={css(styles.wrapper)}>
        <p className={css(RequestFont.TITLE, RequestStyle.ROW_CENTER, styles.title)}>
          신고하실 분의<br/>
          정보를 입력해주세요.
        </p>
        <div className={css(styles.content)}>
          <FormInputWithLabel
            labelText={'이름'}
            placeholder={'이름'}
            addStyle={styles.space}
            onChange={value => setRequest({ hometaxName: value })}
            value={request.hometaxName}
            id={'request_name'}
            onFocus={() => setIsNameCorrect(true)}
            // onBlur={handleBlur}
            addInputStyle={!isNameCorrect && styles.invalid}
            // onBlur={value => onNameInputBlur(value)}
            isValid={isNameCorrect}
          />
          <FormPhoneInputWithLabel
            className={css(styles.space, !isPhoneCorrect && styles.invalid)}
            labelText={'휴대전화 번호'}
            placeholder={'휴대전화 번호'}
            onChange={value => setRequest({ hometaxPhone: value })}
            // onBlur={value => onPhoneBlur(value)}
            isInvalid={!isPhoneCorrect}
            value={request.hometaxPhone}
            id={'request_phone'}
            // onFocus={onScrollUp}
            onFocus={() => setIsPhoneCorrect(true)}
          />
          <FormSSNInputWithLabel
            labelText={'주민등록번호'}
            className={css(styles.space, isSsnInvalid && styles.invalid)}
            ssn1Value={request.hometaxSSN1 ?? ''}
            ssn2Value={ssn2}
            onSsn1Change={value => setRequest({ hometaxSSN1: value })}
            onSsn2Change={value => setSsn2(value)}
            isValid={!isSsnInvalid}
            onFocus={() => setIsSsnInvalid(false)}
          />
        </div>
      </div>
      <RequestButtonWrapper>
        <RequestVerticalButtons
          items={[
            {
              title: '다음',
              marginBottom: 16,
              onClick,
            },
            {
              title: '정보를 모르겠어요',
              border: `solid 1px ${RequestColor.BLUE}`,
              backgroundColor: 'white',
              color: RequestColor.BLUE,
              onClick: () => history.push(REQUEST_PATH.step03)
            }
          ]}/>
      </RequestButtonWrapper>
    </RequestWrapperWithHeader>
  );
};

const styles = CreateBetaStyle({
  wrapper: {
    width: '100%',

    marginTop: '8px',
  },
  title: {
    width: '100%',
  },
  content: {
    paddingTop: '16px',
  },
  invalid: {
    border: '1px solid red',
  },
  space: {
    marginBottom: '16px',
  },
});