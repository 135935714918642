import React from 'react';
import { css } from 'aphrodite';

import { CreateStyle } from '../../../../shared/presentation/shared/CreateStyle';
import { spoqa } from '../../../../shared/css/css-utils';

interface Props {
  options: string[];
  onChange?: (index: number) => void;
  value: number;
}

export const PhoneAgencyButtons: React.FC<Props> = ({ options, onChange, value = 0 }) => {
  const isSelect = (input: number) => value === input;

  return <div className={css(styles.wrapper)}>
    {options.map((option, index) =>
      <>
        <div
          key={'select-' + index}
          className={css(styles.button, isSelect(index) && styles.selected)}
          onClick={() => {
            onChange?.(index);
          }}
        >
          {option}
        </div>
        {index % 3 === 2 && (
          <div className={css(styles.bar)}/>
        )}
      </>
    )}
  </div>;
};

const styles = CreateStyle({
  wrapper: {
    marginBottom: '30px',
    display: 'flex',
    alignItems: 'center',
    // justifyContent: 'space-between',
    gap: '10px 20px',
    flexWrap: 'wrap',
  },
  button: {
    flexGrow: 1,
    flexShrink: 1,
    height: '68px',
    width: '10%',
    border: '1px solid #E9EBF1',
    borderRadius: '10px',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    fontFamily: spoqa,
    fontSize: '18px',
    whiteSpace: 'pre',
    textAlign: 'center',
  },
  selected: {
    background: '#E2E2E2'
  },
  bar: {
    width: '100vw',
    height: 0,
  }
}, true, false, { isNoRatio: true });
