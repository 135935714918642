import React from 'react';
import { css } from 'aphrodite';
import { useRecoilValue } from 'recoil';

import { CreateStyle } from '../../../../shared/presentation/shared/CreateStyle';
import { spoqa } from '../../../../shared/css/css-utils';
import { Image } from '../../../../shared/css/Image';
import { INFO_LIST } from '../../shared/Constant';
import { totalScreenState } from '../../../../shared/presentation/MobileFrame';

function SimpleBenefitSection() {
  const { isMobile, isTablet } = useRecoilValue(totalScreenState);

  const styles = SimpleBenefitStyle(isMobile, isTablet);
  return (
    <div className={css(styles.background)}>
      {INFO_LIST(isMobile).map((item, index) =>
        <div key={'info-' + index}>
          <div
            className={css(styles.benefit_div,
              (!item.isLast && (!isMobile && !isTablet)) &&
              styles.border_right)}>
            <Image
              src={item.image}
              alt={'image'}
              className={css(styles.benefit__image_style)}
            />
            <div className={css(styles.benefit__title)}>
              {item.title}
            </div>
            <div className={css(styles.benefit__content)}>
              {item.content}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

const SimpleBenefitStyle = (isMobile: boolean, isTablet: boolean) => CreateStyle({
  background: {
    height: '817px/556px@1980px',
    width: '100%',

    display: 'flex',
    flexDirection_: 'column/@column',
    alignItems_: 'center/@center',

    paddingTop: '41px/100px@82px',
    paddingLeft: '0px/288px',

    fontFamily: spoqa,
  },
  benefit_div: {
    width: 'undefined/398px',
    height: 'undefined/354px',

    marginLeft: '0px/48px',
    marginBottom: '24px/@90px',
  },
  border_right: {
    borderRight: '1px solid rgba(151,151,151,0.3)',
  },
  benefit__image_style: {
    width: '248px/347px@596px',
    height: '155px/244px@372px',

    marginBottom: '8px/20px@24px',
  },
  benefit__title: {
    marginBottom: '2px/5px@5px',

    color: process.env.REACT_APP_BASE_COLOR,

    fontSize: '15px/22px@33px',
    lineHeight: 1.64,
    fontWeight: 300,
  },
  benefit__content: {
    width: '248px/',

    fontSize: '11px/16px@23px',
    lineHeight: 1.75,
    fontWeight: 300,

    whiteSpace: 'pre',
  },
}, isMobile, isTablet);

export default SimpleBenefitSection;
