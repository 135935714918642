export const META_TAX_BANKS: { [key: string]: string } = {
  '산업은행': '002',
  '기업은행': '003',
  '국민은행': '004',
  '수협중앙회': '007',
  '농협은행': '011',
  '지역농축협': '012',
  '우리은행': '020',
  'SC은행': '023',
  '한국씨티은행': '027',
  '대구은행': '031',
  '부산은행': '032',
  '광주은행': '034',
  '제주은행': '035',
  '전북은행': '037',
  '경남은행': '039',
  '새마을금고연합회': '045',
  '신협': '048',
  '저축은행': '050',
  '산림조합': '064',
  '우체국': '071',
  '하나은행': '081',
  '신한은행': '088',
  '케이뱅크': '089',
  '카카오뱅크': '090',
  '유안타증권': '209',
  'KB증권': '218',
  '미래에셋대우': '238',
  '삼성증권': '240',
  '한국투자증권': '243',
  'NH투자증권': '247',
  '교보증권': '261',
  '하이투자증권': '262',
  '현대차증권': '263',
  '키움증권': '264',
  '이베스트투자증권': '265',
  'SK증권': '266',
  '대신증권': '267',
  '한화투자증권': '269',
  '하나금융투자': '270',
  '신한금융투자': '278',
  'DB금융투자': '279',
  '유진투자증권': '280',
  '메리츠종합금융증권': '287',
  '부국증권': '290',
  '신영증권': '291',
  '케이프투자증권': '292'
};
