import React, { useEffect, useRef, useState } from 'react';
import { atom, useRecoilState, useSetRecoilState } from 'recoil';
import axios from 'axios';
import { css } from 'aphrodite';

import { CreateBetaStyle } from '../../../../shared/presentation/shared/CreateStyle';
import { ModalView } from './component/ModalView';
import { DropDownOptionsModalView } from './component/DropDownOptionsModalView';
import { isLoadingState } from '../../../../shared/presentation/LoadingFrame';
import { useMyHistory } from '../../../../shared/presentation/HistoryHelper';
import { alertMessageState } from '../../shared-component/ShowAlertFrame';
import { useStepState } from '../../shared/ProcessState';
import { RequestAuthForm, useRequestFormState } from '../../shared/RequestContents';
import { toastState } from '../../store/RequestStore';
import { REQUEST_PATH } from '../SharedConstants';
import { FormInputWithLabel } from '../../shared-component/input/FormInputWithLabel';
import { FormSSNInputWithLabel } from '../../shared-component/input/FormSSNInputWithLabel';
import { RequestWrapperWithHeader } from '../../shared-component/RequestWrapperWithHeader';
import { RequestColor } from '../../assets/ColorContents';
import { RequestFont } from '../../shared/RequestFont';
import { RequestStyle } from '../../shared/RequestStyle';
import { FormPhoneInputWithLabel } from '../../shared-component/input/FormPhoneInputWithLabel';
import { RequestButtonWrapper } from '../../shared-component/RequestButtonWrapper';
import RequestVerticalButtons from '../../shared-component/RequestVerticalButtons';
import { useHometaxState } from '../../shared/HometaxState';
import { useHometaxCookieState } from '../../shared/HometaxCookieState';
import { KakaoSimpleAuthorizer } from '../2_카카오_지갑인증/KakaoSimpleAuthorizer';
import { encodeBase64 } from '../../utill/base64';
import { useKeywordValue } from '../../shared/KeywordState';

// interface ISimpleAuth {
//   isBtnValid?: (value: boolean) => void,
//   onScrollUp?: () => void
// }

export const yushinSessionIdState = atom<string>({
  key: 'yushinSessionIdState',
  default: '',
});

export const SSN2State = atom<string>({
  key: 'ssn2State',
  default: '',
});

const SimpleAuthRequest: React.FC = () => {
  const setIsLoading = useSetRecoilState(isLoadingState);
  // const setAlert = useSetRecoilState(alertMessageState);
  const [request, setRequest /*resetRequest*/] = useRequestFormState();
  const [ssn2, setSsn2] = useRecoilState(SSN2State);

  // const requestRef = useRef<RequestAuthForm>(request);
  const modalRef = useRef<any>(null);
  const phoneInputRef = useRef<HTMLInputElement>(null);
  const ssnInputRef = useRef<HTMLInputElement>(null);
  const nameInputRef = useRef<HTMLInputElement>(null);

  const [isProxy, setIsProxy] = useState(false);
  const keyword = useKeywordValue();

  const history = useMyHistory();

  // const [, setStep] = useStepState();
  const [, , resetHometax] = useHometaxState();
  const [, , resetCookie] = useHometaxCookieState();

  useEffect(() => {
    if (request.phone?.replace(/[- ]/g, '').length === 11) {
      ssnInputRef.current?.focus();
    }
  }, [request.phone]);

  useEffect(() => {
    resetHometax();
    resetCookie();
  }, []);

  const [isPhoneCorrect, setIsPhoneCorrect] = useState(true);
  // const [isSsnFrontCorrect, setIsSsnFrontCorrect] = useState(true);
  // const [isSsnEndCorrect, setIsSsnEndCorrect] = useState(true);
  const [isSsnInvalid, setIsSsnInvalid] = useState(false);
  const [isNameCorrect, setIsNameCorrect] = useState(true);
  const setToast = useSetRecoilState(toastState);
  const validateInfo = () => {
    if (request.name === '') {
      setIsNameCorrect(RequestAuthForm.isNameValid(request.name));
      // setToast({
      //   isShow: true,
      //   message: '이름을 입력해 주세요',
      // });
      return false;
    }
    if (!RequestAuthForm.isPhoneValid(request.phone)) {
      setIsPhoneCorrect(RequestAuthForm.isPhoneValid(request.phone));
      // setToast({
      //   isShow: true,
      //   message: '올바르지 않은 형식입니다.',
      // });
      return false;
      // return;
    }
    if (!RequestAuthForm.isSsnValid(request.SSN1 + ssn2)) {
      setIsSsnInvalid(true);
      // axios.post('https://msa.ailabscorp.com/slack-message/send', {
      //   message: {
      //     text: request.name + '님의 주민번호 오류: ' + request.SSN1 + ssn2 + '(step01)',
      //     type: 'error',
      //     channel: 'total-income-ssn-error'
      //   }
      // });
      // setToast({
      //   isShow: true,
      //   message: '올바르지 않은 형식입니다.',
      // });
      return false;
    }
    return true;
  };
  const onClick = () => {
    if (!validateInfo()) {
      return;
    }
    setIsProxy(false);
    modalRef.current.show();
    // history.push(REQUEST_PATH.step02);
  };

  const onClickProxy = () => {
    if (!validateInfo()) {
      return;
    }
    setIsProxy(true);
    modalRef.current.show();
  };

  // const isSsnInvalid = !isSsnFrontCorrect || !isSsnEndCorrect;

  const onNameInputBlur = (value: string) => {
    setIsNameCorrect(RequestAuthForm.isNameValid(value));
    if (!RequestAuthForm.isNameValid(value)) {
      setToast({
        isShow: true,
        message: '이름을 입력해 주세요',
      });
    }
  };

  const onPhoneBlur = (value: string) => {
    setIsPhoneCorrect(RequestAuthForm.isPhoneValid(value));
    if (!RequestAuthForm.isPhoneValid(value)) {
      setToast({
        isShow: true,
        message: '올바르지 않은 형식입니다.',
      });
    }
  };

  const onSsnBlur = (value: string) => {
    // setIsSsnEndCorrect(RequestAuthForm.isSsnValid(value));
    if (!RequestAuthForm.isSsnValid(value)) {
      setToast({
        isShow: true,
        message: '올바르지 않은 형식입니다.',
      });
    }
  };


  const checkAll = () => {
    setIsNameCorrect(RequestAuthForm.isNameValid(request.name));
    setIsPhoneCorrect(RequestAuthForm.isPhoneValid(request.phone));
    // setIsSsnEndCorrect(RequestAuthForm.isSsnValid(request.SSN1 + request.SSN2));
    setIsSsnInvalid(RequestAuthForm.isSsnValid(request.SSN1 + ssn2));
    // if (!(RequestAuthForm.isNameValid(request.name) && RequestAuthForm.isPhoneValid(request.phone) && RequestAuthForm.isSsnValid(request.SSN1 + request.SSN2))) {
    if (!(RequestAuthForm.isNameValid(request.name) && RequestAuthForm.isPhoneValid(request.phone) && RequestAuthForm.isSsnValid(request.SSN1 + ssn2))) {
      setToast({
        isShow: true,
        message: '올바른 정보를 입력해주세요',
      });
    }
  };

  // const handleEnter = (e: any) => {
  //   if (e.target == null) {
  //     return;
  //   }
  //   if (e.key === 'Enter') {
  //     onClick();
  //   }
  // };
  //
  // useEffect(() => {
  //   document.addEventListener('keydown', handleEnter);
  //   return () => document.removeEventListener('keydown', handleEnter);
  // }, []);

  const onAgree = () => {
    setRequest({ isProxy });
    if (isProxy) {
      setIsLoading(true);
      KakaoSimpleAuthorizer
        .saveNoCertUser(
          {
            name: request.name,
            phone: request.phone,
            socialSecurityNo: encodeBase64(request.SSN1 + ssn2),
            isProxy,
            keyword,
          })
        .finally(() => setIsLoading(false))
        .then((customerId) => {
          setRequest({ customerId });
          setTimeout(() => history.push(REQUEST_PATH.step01_1), 100);
        });
      return;
    }
    history.push(REQUEST_PATH.step02);
  };

  return (
    <RequestWrapperWithHeader
      onClickBack={() => history.goBack()}
      className={css(styles.outline)}
    >
      <ModalView ref={modalRef} onAgree={() => onAgree()}/>
      <DropDownOptionsModalView/>
      <div className={css(styles.wrapper)}>
        <p className={css(RequestFont.TITLE, RequestStyle.ROW_CENTER, styles.title)}>
          {/*<Image src={Kakao} alt={'kakao'} className={css(styles.kakao_img)}/>*/}
          {/*간편인증 요청을 위해<br/>*/}
          {/*아래의 정보를 입력해주세요*/}
          연락받으실 분의<br/>
          정보를 입력해주세요.
        </p>
        {/*<p className={css(RequestFont.DESCRIPTION_TITLE)}>*/}
        {/*  고객님의 정확한 환급액 조회를 위해<br/>*/}
        {/*  아래 정보를 입력해주세요.*/}
        {/*</p>*/}
        <div className={css(styles.content)}>
          <FormInputWithLabel
            labelText={'이름'}
            placeholder={'이름'}
            addStyle={styles.space}
            onChange={value => setRequest({ name: value })}
            value={request.name}
            id={'request_name'}
            onFocus={() => setIsNameCorrect(true)}
            refObject={nameInputRef}
            // onBlur={handleBlur}
            addInputStyle={!isNameCorrect && styles.invalid}
            // onBlur={value => onNameInputBlur(value)}
            isValid={isNameCorrect}
          />
          <FormPhoneInputWithLabel
            className={css(styles.space, !isPhoneCorrect && styles.invalid)}
            labelText={'휴대전화 번호'}
            placeholder={'휴대전화 번호'}
            onChange={value => setRequest({ phone: value })}
            // onBlur={value => onPhoneBlur(value)}
            isInvalid={!isPhoneCorrect}
            value={request.phone}
            id={'request_phone'}
            // onFocus={onScrollUp}
            onFocus={() => setIsPhoneCorrect(true)}
            refObject={phoneInputRef}
          />
          <FormSSNInputWithLabel
            labelText={'주민등록번호'}
            className={css(styles.space, isSsnInvalid && styles.invalid)}
            ssn1Value={request.SSN1 ?? ''}
            ssn2Value={ssn2}
            onSsn1Change={value => setRequest({ SSN1: value })}
            onSsn2Change={value => {
              setSsn2(value);
              // if (value.match(/\d{0,7}/)?.[0]?.length === 7) {
              // const isSsnValid = SocialSecurityNumberPolicy.isValid(request.SSN1 + value);
              // }
            }}
            // onSsnBlur={value => onSsnBlur(value)}
            isValid={!isSsnInvalid}
            ref={ssnInputRef}
            onFocus={() => {
              // setIsSsnFrontCorrect(true);
              // setIsSsnEndCorrect(true);
              setIsSsnInvalid(false);
            }}
          />
        </div>
      </div>
      <RequestButtonWrapper>
        <RequestVerticalButtons
          items={[
            {
              title: '환급받으실 분이 본인이신가요?',
              onClick,
              marginBottom: 16,
            },
            {
              title: '대리신청이신가요?',
              subTitle: '(가족 및 지인 등)',
              onClick: onClickProxy,
              backgroundColor: 'white',
              border: `solid 1px ${RequestColor.BLUE}`,
              color: RequestColor.BLUE
            },
          ]}
        />
      </RequestButtonWrapper>
    </RequestWrapperWithHeader>
  );
};

const styles = CreateBetaStyle({
  outline: {
    paddingBottom: '200px',
  },
  title: {
    width: '100%',
    // marginBottom: '8px',
  },
  popup__title: {
    fontSize: '22px',
    lineHeight: '30px',
    color: RequestColor.GRAY_100,
    fontWeight: 700,

    marginBottom: '12px',
  },
  popup__content: {
    fontSize: '16px',
    lineHeight: '28px',
    color: RequestColor.GRAY_60,
  },
  wrapper: {
    width: '100%',
    // height: '725px',

    marginTop: '8px',
    // paddingBottom: '100px',
  },
  kakao_img: {
    width: '32px',
    height: '30px',

    marginRight: '8px',
    // marginTop: '5px',
  },
  content: {
    paddingTop: '16px',
  },
  invalid: {
    border: '1px solid red',
  },
  space: {
    marginBottom: '16px',
  },
  ssn: {
    width: '100%',
    height: '56px',

    backgroundColor: RequestColor.GRAY_10,

    borderRadius: '15px',

    display: 'flex',
    alignItems: 'center',

    paddingLeft: '16px',

    fontSize: '20px',
  },
  ssn__front_input: {
    width: '20%',
    height: '100%',

    backgroundColor: 'transparent',

    marginRight: '20px',

    fontSize: '18px',
    color: RequestColor.GRAY_100,

    '::placeholder': {
      color: RequestColor.GRAY_50,
    },
  },
  ssn__end_input: {
    position: 'absolute',
    // display: 'none',
    opacity: 0,
  },
  ssn__end: {
    width: '30%',

    marginLeft: '17px',
  },
  ssn__end__hidden: {
    width: '100%',

    display: 'flex',
    justifyContent: 'space-between',
  },
  ssn__end__dot: {
    width: '8px',
    height: '8px',

    borderRadius: '100%',

    backgroundColor: RequestColor.GRAY_40,
  },
  ssn__end__dot__written: {
    backgroundColor: RequestColor.GRAY_100,
  },
});

export default SimpleAuthRequest;
