import React, { useEffect } from 'react';

import MainSection from './1-main/MainSection';
import FaqSection from './9-faq/FaqSection';
import BenefitSection from './3-benefit/BenefitSection';
import RequestSection from './10-request/RequestSection';
import ReviewSection from './8-review/ReviewSection';
import Footer from '../shared/Footer';
import DiscountSection from './5-discount/DiscountSection';
import SimpleBenefitSection from './2-simple_benefit/SimpleBenefitSection';
import ProcessSection from './6-process/ProcessSection';
import PriceSection from './7-price/PriceSection';
import Header from '../shared/Header';
import FloatingButtons from '../../../shared/presentation/floating-kakao-button/FloatingButtons';
import ServiceSection from './4-service/ServiceSection';
import PeriodPopUp, { isPeriodPopUpState } from '../popup/PeriodPopUp';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { isMobileState, isTabletState } from '../../../shared/presentation/MobileFrame';
import { withHome } from '../../../shared/WithHome';

const SECTION_LIST = [
  'main',
  'simple_benefit',
  'benefit',
  'service',
  'discount',
  'process',
  'price',
  'review',
  'faq',
  'request',
];

function Home() {
  const isMobile = useRecoilValue(isMobileState);
  const isTablet = useRecoilValue(isTabletState);
  const setIsPeriodPopUp = useSetRecoilState(isPeriodPopUpState);

  useEffect(() => {
    setIsPeriodPopUp(true);
  }, [])

  return (
    <div>
      <FloatingButtons isCallNotShown={true}/>
      {(!isMobile && !isTablet) && <PeriodPopUp/>}
      <Header/>
      <div id={'main'}>
        <MainSection/>
      </div>
      <div id={'simple_benefit'}>
        <SimpleBenefitSection/>
      </div>
      <div id={'benefit'}>
        <BenefitSection/>
      </div>
      <div id={'service'}>
        <ServiceSection/>
      </div>
      <div id={'discount'}>
        <DiscountSection/>
      </div>
      <div id={'process'}>
        <ProcessSection/>
      </div>
      <div id={'price'}>
        <PriceSection/>
      </div>
      <div id={'review'}>
        <ReviewSection/>
      </div>
      <div id={'faq'}>
        <FaqSection/>
      </div>
      <div id={'request'}>
        <RequestSection/>
      </div>
      <Footer/>
    </div>
  );
}

export default withHome(Home, SECTION_LIST);
