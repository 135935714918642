import React from 'react';

export const AdditionalInfo = () => {
  return (
    <div>
      <div style={{display: 'flex'}}>
        <div style={{marginRight: '5px'}}>*</div>
        <div>다른 소득 발생 시 추가금액 : 5만원</div>
      </div>
      <div style={{display: 'flex'}}>
        <div style={{marginRight: '5px'}}>*</div>
        <div>
          개인사업자 복식부기 신고대행 : 50만원 부터
        </div>
      </div>
      <div style={{display: 'flex'}}>
        <div style={{marginRight: '5px'}}>*</div>
        <div>
          개인사업자 간편장부 신고대행 : 10만원 추가
        </div>
      </div>
      <div style={{display: 'flex'}}>
        <div style={{marginRight: '5px'}}>*</div>
        <div>
          연말정산 추가금액 : 5만원
        </div>
      </div>
      <div style={{display: 'flex'}}>
        <div style={{marginRight: '5px'}}>*</div>
        <div>
          모든 수수료는 VAT 별도임
        </div>
      </div>
    </div>
  );
};