import { atom, DefaultValue, selector } from 'recoil';
import axios from 'axios';
import { injectable } from 'inversify';

import { LoginPolicy } from './LoginPolicy';
import { HometaxCookieProps } from '../../shared/HometaxCookieState';

type LoginIdPwd = {
  id: string;
  password: string;
  customerId: string;
}

export type responseFormat = {
  resultCode: string;
  message?: string;
}

export interface IIdPwdInputProps {
  id?: IInputProps;
  password?: IInputProps;
}

export interface IInputProps {
  value: string,
  isValid: boolean,
}

export const homeTaxIdLoginState = selector<IIdPwdInputProps>({
  key: 'homeTaxIdLoginState',
  get: ({ get }) => {
    return {
      id: get(idState),
      password: get(pwdState),
    };
  },
});

export const idState = atom<IInputProps>({
  key: 'idState',
  default: {
    value: '',
    isValid: true,
  }
});

export const pwdState = atom<IInputProps>({
  key: 'pwdState',
  default: {
    value: '',
    isValid: false,
  }
});

export const newPwdState = atom<string>({
  key: 'newPwdState',
  default: '',
});

export const confirmPwdState = atom<string>({
  key: 'confirmPwdState',
  default: '',
});

export const isNewPwdValidState = atom<boolean>({
  key: 'isNewPwdValidState',
  default: true,
});

export const isConfirmPwdValidState = atom<boolean>({
  key: 'isConfirmPwdValidState',
  default: true,
});

export const newPwdInfoState = selector<IInputProps>({
  key: 'newPwdInfoState',
  get: ({ get }) => {
    return {
      value: get(newPwdState),
      isValid: get(isNewPwdValidState),
    };
  },
  set: ({ set, reset }, value) => {
    if (value instanceof DefaultValue) {
      reset(newPwdState);
      reset(isNewPwdValidState);
      return;
    }
    set(newPwdState, value.value);
    set(isNewPwdValidState, value.isValid);
  }
});

export const confirmPwdInfoState = selector<IInputProps>({
  key: 'confirmPwdInfoState',
  get: ({ get }) => {
    return {
      value: get(confirmPwdState),
      isValid: get(isConfirmPwdValidState),
    };
  },
  set: ({ set, reset }, value) => {
    if (value instanceof DefaultValue) {
      reset(confirmPwdState);
      reset(isConfirmPwdValidState);
      return;
    }
    set(confirmPwdState, value.value);
    set(isConfirmPwdValidState, value.isValid);
  }
});

export const comparePwdState = selector<boolean>({
  key: 'comparePwdState',
  get: ({ get }) => {
    return LoginPolicy.isComparePassword(get(newPwdInfoState), get(confirmPwdInfoState));
  }
});


export const TOTAL_INCOME_TAX_HOME_TAX_LOGIN_SERVICE = Symbol('TOTAL_INCOME_TAX_HOME_TAX_LOGIN_SERVICE');

@injectable()
export class HomeTaxLoginService {
  async sendLoginRequestToHomeTax(loginInfo: LoginIdPwd): Promise<responseFormat> {
    const { data } = await axios.post(
      process.env.REACT_APP_API_BASE_URL + '/total-income-tax/id-login/hometax',
      { ...loginInfo },
    );

    return { resultCode: data.resultCode };
  }

  async sendLoginPasswordResetToHomeTax(password: string, cookie: HometaxCookieProps, customerId: string) {
    const { data } = await axios.post(
      process.env.REACT_APP_API_BASE_URL + '/total-income-tax/change-password/hometax',
      { password, cookie, customerId }
    );
    switch (data.status) {
      case 200:
        return {
          resultCode: data.resultCode,
          message: '비밀번호가 변경되었습니다.',
        };
      case 401:
        return {
          resultCode: data.resultCode,
          message: data.message,
        };
      case 500 :
      default:
        return {
          resultCode: data.resultCode,
          message: data.message,
        };
    }
  }
}


