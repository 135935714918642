export class SocialSecurityNumberPolicy {
  static isValid(_ssn: string): boolean {
    const ssn = _ssn.replace(/-/g, '');
    //자릿수 오류
    if (ssn.length !== 13) {
      return false;
    }
    //20년대 이후생
    if (Number(ssn.slice(0, 2)) >= 20 && Number(ssn.slice(0, 2)) <= 24 ) {
      if (ssn[6] === '4' || ssn[6] === '3' || ssn[6] === '7' || ssn[6] === '8') {
        return true;
      }
    }
    const multiNumber = [2, 3, 4, 5, 6, 7, 8, 9, 2, 3, 4, 5];
    var sum = 0;
    for (var i = 0; i < 12; i++) {
      const multi = parseInt(ssn[i]) * multiNumber[i];
      sum = sum + multi;
    }
    if (ssn[6] === '5' || ssn[6] === '6' || ssn[6] === '7' || ssn[6] === '8') {
      const check = (13 - (sum % 11));
      if (check === 13) {
        return parseInt(ssn[12]) === 3;
      }
      if (check === 12) {
        return parseInt(ssn[12]) === 2;
      }
      if (check === 11) {
        return parseInt(ssn[12]) === 1;
      }
      if (check === 10) {
        return parseInt(ssn[12]) === 0;
      }
      return parseInt(ssn[12]) === check;
      // return ((13 - (sum % 11)) > 9 ? (1 - (sum % 11)) : (13 - (sum % 11))) === parseInt(ssn[12]);
    }
    const check = (11 - (sum % 11));
    if (check === 11) {
      return parseInt(ssn[12]) === 1;
    }
    if (check === 10) {
      return parseInt(ssn[12]) === 0;
    }
    return parseInt(ssn[12]) === check;
    // return ((11 - (sum % 11)) > 9 ? (1 - (sum % 11)) : (11 - (sum % 11))) === parseInt(ssn[12]);
  }
}