import React from 'react';
import { css } from 'aphrodite';
import { useRecoilValue } from 'recoil';
import { useHistory } from 'react-router-dom';

import ManualPage from '../../../shared/presentation/manual/ManualPage';
import { CreateStyle } from '../../../shared/presentation/shared/CreateStyle';
import { Image } from '../../../shared/css/Image';
import Logo from '../../assets/images/logo_blue.png';
import { spoqa } from '../../../shared/css/css-utils';
import Footer from '../shared/Footer';
import { ReactComponent as LogoBlue } from '../../assets/svg/logo_unIE.svg';
import { isIE, moveToHome } from '../../../shared/helper/Util';
import { isMobileState } from '../../../shared/presentation/MobileFrame';
import { IHistory } from '../../../shared/presentation/HistoryHelper';

const goToHomeTax = () => window.open('https://www.hometax.go.kr/websquare/websquare.html?w2xPath=/ui/pp/index.xml');
const goToGov = () => window.open('https://www.gov.kr/portal/main');

function Manual() {
  const isMobile = useRecoilValue(isMobileState);
  const styles = ManualStyle(isMobile);
  const history = useHistory<IHistory>();

  return (
    <>
      <div className={css(styles.header)}>
        {isIE() ? (
          <Image
            src={Logo}
            alt={'logo'}
            className={css(styles.header__logo)}
            onClick={() => moveToHome(history)}
          />
        ) : (
          <LogoBlue
            className={css(styles.header__logo)}
            onClick={() => moveToHome(history)}
          />
        )}
        {!isMobile && (
          <div className={css(styles.header__menu_box)}>
            <div
              className={css(
                styles.header__menu__element,
                styles.header__menu__element_right_border
              )}
              onClick={goToHomeTax}
            >
              홈택스 바로가기
            </div>
            <div
              className={css(styles.header__menu__element)}
              onClick={goToGov}
            >
              정부24 바로가기
            </div>
          </div>
        )}
      </div>
      {/*<ManualPage headerHeight={58}/>*/}
      <Footer/>
    </>
  );
}

const ManualStyle = (isMobile: boolean) => CreateStyle({
  header: {
    width: '100%',
    height: '58px/90px',

    backgroundColor: 'white',

    fontFamily: spoqa,

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    paddingLeft: '20px/404px',
    paddingRight: '18px/396px',

    borderBottom: 'solid 1px rgba(228, 233, 239, 0.65)',

    position: 'fixed',
    top: 0,

    zIndex: 50,
  },
  header__logo: {
    width: '142px/220px',
    height: '26px/42px',

    cursor: 'pointer',

    fill: process.env.REACT_APP_BASE_COLOR,

    // objectFit: 'contain',
  },
  header__menu_box: {

    display: 'flex',
  },
  header__menu__element: {

    height: '/19px',

    fontWeight: 400,
    fontSize: '/16px',
    letterSpacing: '/-0.44px',
    color: '#2c2d2e',

    marginLeft: '/26px',
    paddingBottom: '/3px',

    cursor: 'pointer',
  },
  header__menu__element_right_border: {
    borderRight_: '/solid 1px rgba(235, 237, 238, 0.88)',
    paddingRight: '/26px',
  },
}, isMobile);

export default Manual;
