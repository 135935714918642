import React from 'react';
import { css } from 'aphrodite';
import { useRecoilValue } from 'recoil';

import { CreateStyle } from '../../../shared/presentation/shared/CreateStyle';
import { spoqa } from '../../../shared/css/css-utils';
import { ReactComponent as KakaoLink } from '../../assets/svgs/kakao_link.svg';
import { ReactComponent as Close } from '../../../shared/assets/svgs/close-slim.svg';
import { totalScreenState } from '../../../shared/presentation/MobileFrame';

interface Props {
  title: string,
  content: string,
  button: string,
  isKakaoLink?: boolean,
  isHomeTaxLink?: boolean,
  buttonOnClick: any,
  closeOnClick: any,
}

const goToHomeTax = () => window.open('https://www.hometax.go.kr/websquare/websquare.wq?w2xPath=/ui/pp/index.xml&tmIdx=0&tm2lIdx=&tm3lIdx=');
const goToKakaoCenter = () => window.open('https://cs.kakao.com/helps?articleId=1073198565&service=8&category=664&device=2539&locale=ko');

function CertificationIncompletePopup(input: Props) {
  const { isMobile, isTablet } = useRecoilValue(totalScreenState);

  const styles = CertificationIncompletePopupStyle(isMobile, isTablet);
  return (
    <div className={'popup background'}>
      <div
        className={css(styles.popup_frame,
          (input.isKakaoLink) &&
          styles.popup_frame_height,
          input.isHomeTaxLink &&
          styles.popup_frame_hometax_link_height,
        )}>
        <Close
          className={css(styles.popup__close_button)}
          onClick={input.closeOnClick}
        />
        <div className={css(styles.popup__title)}>
          {input.title}
        </div>
        <div
          className={
            css(styles.popup__content,
              !input.isKakaoLink
              && styles.popup__content__margin
            )}>
          {input.content}
        </div>
        <div
          className={css(styles.popup__confirm_button)}
          onClick={input.buttonOnClick}
        >
          {input.button}
        </div>
        {input.isKakaoLink &&
        <div
          className={css(styles.popup__kakao_link_box)}
          onClick={goToKakaoCenter}
        >
          <div>카카오 고객센터</div>
          <div className={css(styles.popup__kakao_link__link)}>
            바로가기
            <KakaoLink className={css(styles.popup__kakao_link__link_image)}/>
          </div>
        </div>
        }
        {input.isHomeTaxLink &&
        <div
          className={css(styles.popup__kakao_link_box)}
          onClick={goToHomeTax}
        >
          <div>홈택스</div>
          <div className={css(styles.popup__kakao_link__link)}>
            바로가기
            <KakaoLink className={css(styles.popup__kakao_link__link_image)}/>
          </div>
        </div>
        }
      </div>
    </div>
  );
}

const CertificationIncompletePopupStyle = (isMobile: boolean, isTablet: boolean) => CreateStyle({
  popup_frame: {
    width: '274px/480px@445px',
    height: '233px/320px@357px',

    backgroundColor: 'white',
    borderRadius: '3px/5px@5px',

    paddingTop: '43px/48px@55px',
    marginTop: '-110px/@-30px',

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    position: 'relative',

    fontFamily: spoqa,
  },
  popup_frame_height: {
    height: '270px/370px@382px',
  },
  popup_frame_hometax_link_height: {
    height: '270px/370px@412px',
  },
  popup__close_button: {
    width: '13px/23px@20px',
    height: '13px/23px@20px',

    position: 'absolute',
    top: '16px/28px@26px',
    right: '14px/24px@23px',

    cursor: 'pointer',
  },
  popup__title: {
    fontWeight: 500,
    fontSize: '16px/23px@26px',
    color: '#006AFF',
    lineHeight: '17px/30px@28px',

    marginBottom: '14px/22px@22px',
    whiteSpace: 'pre',
  },
  popup__content: {
    fontWeight: 300,
    fontSize: '13px/17px@21px',
    color: '#333333',
    lineHeight: '22px/33px@35px',

    whiteSpace: 'pre',
    textAlign: 'center',

    marginBottom: '27px/33px@34px',
  },
  popup__content__margin: {
    marginBottom: '22px/24px@38px',
  },
  popup__confirm_button: {
    width: '220px/180px@357px',
    height: '44px/60px@71px',

    borderRadius: '4px/5px@6px',
    border: 'solid 1px #006AFF',

    backgroundColor: '#006AFF',

    fontWeight: 500,
    fontSize: '13px/17px@21px',
    color: 'white',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    cursor: 'pointer',

    paddingBottom: '2px/@2px',

    ':hover': {
      color: '#006AFF',
      backgroundColor: 'white',
      transition: '0.3s',
    },
  },
  popup__kakao_link_box: {
    display: 'flex',
    alignItems: 'center',

    marginTop: '25px/36px@34px',

    fontWeight: 400,
    fontSize: '11px/15px@17px',
    color: '#333333',

    cursor: 'pointer',
  },
  popup__kakao_link__title: {},
  popup__kakao_link__link: {
    color: '#006AFF',
    fontWeight: 700,

    marginLeft: '5px/3px@4px',
    marginBottom: '/1px',

    display: 'flex',
    alignItems: 'center',
  },
  popup__kakao_link__link_image: {
    width: '10px/16px@16px',
    height: '10px/16px@16px',

    marginLeft: '4px/6px@6px',
  },
}, isMobile, isTablet);

export default CertificationIncompletePopup;
