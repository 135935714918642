import { atom, useRecoilState } from 'recoil';
import React, { useCallback, useMemo } from 'react';
import { css } from 'aphrodite';

import { CreateBetaStyle } from '../../../shared/presentation/shared/CreateStyle';
import { RequestFont } from '../shared/RequestFont';

type AlertType = {
  message: string | undefined;
  onClick?: () => void;
}

export const alertMessageState = atom<AlertType>({
  key: 'alertMessageState',
  default: {
    message: undefined,
    onClick: () => {},
  },
});

export const AlertFrame: React.FC = () => {
  const [alert, setAlert] = useRecoilState(alertMessageState);
  const isShow = useMemo(() => alert.message != null, [alert.message]);
  const clickHandler = useCallback(async () => {
    await alert.onClick?.();
    setAlert({message: undefined});
  }, [alert]);

  return <>
    {isShow && (
      <div className={css(styles.wrapper)}>
        <div className={css(styles.alertBox)}>
          <p className={css(RequestFont.ALERT_CONTENT, styles.textBox)}>{alert.message}</p>
          <button
            type={'button'}
            className={css(RequestFont.HIGHLIGHT, styles.button)}
            onClick={clickHandler}
          >
            확인
          </button>
        </div>
      </div>
    )}
  </>;
};

const styles = CreateBetaStyle({
  wrapper: {
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 500,

    width: '100vw',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(32, 35, 40, 0.3)',
  },
  alertBox: {
    position: 'relative',
    padding: '28px 28px 100px',
    width: 'calc(100vw - 48px)',
    maxWidth: '350px',
    // minHeight: '206px',

    borderRadius: '4px',
    backgroundColor: '#FFF',

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  textBox: {
    width: '100%',
    whiteSpace: 'pre-wrap',
    wordBreak: 'keep-all',
  },
  button: {
    position: 'absolute',
    bottom: 0,

    width: 'calc(100% - 40px)',
    height: '72px',
    background: '#FFF',

    cursor: 'pointer',

    borderTop: '1px solid #F5F6F9',
  }
});
