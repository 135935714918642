import React from 'react';
import { css } from 'aphrodite';
import { useRecoilValue } from 'recoil';

import { SharedAssets } from '../../../shared/assets/SharedAssets';
import { FooterStyle } from './FooterStyle';
import { totalScreenState } from '../../../shared/presentation/MobileFrame';
import { useMyHistory } from '../../../shared/presentation/HistoryHelper';
import { PAYMENT_PATH } from '../../../taxagent-shared/payment-page/pages/PaymentConstant';

const goToFaceBook = () => window.open('https://www.facebook.com/%EC%84%B8%EB%AC%B4%EA%B7%B8%EB%A3%B9-%EC%84%B8%EC%9D%B4%EB%B8%8C%ED%83%9D%EC%8A%A4-646673358836552/');
const goToBlog = () => window.open('https://blog.naver.com/savetax16');

export function Footer() {
  const { isMobile, isTablet } = useRecoilValue(totalScreenState);
  const styles = FooterStyle(isMobile, isTablet);
  const goToTermsOfService = () => window.open('/terms_of_service');
  const goToPrivacyPolicy = () => window.open('/privacy-policy');
  const history = useMyHistory();

  const onClickKakao = () => {
    if (isMobile || isTablet) {
      window.open(process.env.REACT_APP_KAKAO_MOBILE_PAGE_URL);
      return;
    }
    window.open(process.env.REACT_APP_KAKAO_MOBILE_PAGE_URL + '/chat');
  };

  return (
    <div className={css(styles.background)}>
      <div className={css(styles.left_div)}>
        <div className={css(styles.left__working_time)}>
          평일: 오전9시 - 오후10시 <br/>
          주말: 오전10시 - 오후7시
        </div>
        {/*<div className={css(styles.left__service_center)}>*/}
        {/*  고객센터 1644-9108*/}
        {/*</div>*/}
        <div
          className={css(styles.left__payment_button)}
          onClick={() => history.push(PAYMENT_PATH.step01)}
        >
          개인결제창
        </div>
        {/*<div className={css(styles.left__terms_of_service)}>*/}
        {/*  <span onClick={() => goToPrivacyPolicy()}>이용약관</span>*/}
        {/*  {' | '}<span onClick={() => goToTermsOfService()}>개인정보 취급방침</span>*/}
        {/*</div>*/}
        <div className={css(styles.left__terms_of_service)}>
          <span onClick={() => goToPrivacyPolicy()} className={css(styles.button)}>이용약관</span> |
          <span onClick={() => goToTermsOfService()} className={css(styles.button)}> 개인정보 취급방침</span><br/>
          사업자명 : 세무법인 세이브택스 | 사업자번호 : 682-81-02186 <br/>
          고객상담 : 1644-9108 | 이메일: help@taxagent.co.kr <br/>
          대표자 : 윤준영 | 주소 : 서울특별시 강남구 테헤란로 70길 12, 2층<br/>
          통신판매업신고번호 : 2022-서울강남-02292<br/>
          {isMobile && <br/>}
          © 세이브택스 all rights reserved
        </div>
      </div>

      <div className={css(styles.right_div)}>
        <div className={css(styles.right__icon_box)}>
          <div className={css(styles.right__icon__background, styles.right__icon__margin_right)}>
            <SharedAssets.Kakao
              className={css(styles.right__icon__logo)}
              onClick={() => onClickKakao()}
            />
          </div>
          <div className={css(styles.right__icon__background, styles.right__icon__margin_right)}>
            <SharedAssets.Facebook
              className={css(styles.right__icon__logo)}
              onClick={() => goToFaceBook()}
            />
          </div>
          <div className={css(styles.right__icon__background)}>
            <SharedAssets.Blog
              className={css(styles.right__icon__logo)}
              onClick={() => goToBlog()}
            />
          </div>
        </div>
        {/*<div className={css(styles.right__company_address)}>*/}
        {/*  서울특별시 강남구 학동로 42길 25, 2F<br/>*/}
        {/*  help@taxagent.co.kr*/}
        {/*</div>*/}
        {/*<div className={css(styles.right__company_contact)}>*/}
        {/*  /!*사업자 등록번호 : 682-81-02186 {(!isMobile && !isTablet) && '| '}{(isMobile || isTablet) && <br/>}*!/*/}
        {/*  © 세이브택스 all rights reserved*/}
        {/*</div>*/}
      </div>
    </div>
  );
}

export default Footer;
