import { CreateStyle } from '../../../shared/presentation/shared/CreateStyle';
import { spoqa } from '../../../shared/css/css-utils';

export const HeaderStyle = (
  isMobile: boolean,
  isPopup: boolean,
  isTop: boolean,
  isTablet: boolean
) => CreateStyle({
  background: {
    width: '100%',
    // height: '42px/98px',
    height: '42px/90px@100px',

    zIndex: 99,

    paddingLeft: '14px/95px@37px',
    paddingRight: '/47px',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    color: 'white',
    fontFamily: spoqa,

    position: 'absolute',
    top: isPopup ? '/49px' : 0,

    // borderBottom: isMobile
    //   ? isTop
    //     ? 'solid 1px rgba(255,255,255,0.2)'
    //     : 'solid 1px rgba(255,255,255,1)'
    //   : isTablet
    //     ? 'solid 1px rgba(0,0,0,0.2)'
    //     : undefined,

    borderBottom: (isMobile || isTablet)
      ? isTop
        ? 'solid 1px rgba(255,255,255,0.2)'
        : 'solid 1px rgba(0,0,0,0.2)'
      : undefined,

  },
  background_border: {
    borderBottom: 'solid 1px rgba(0,0,0,0.1)',
  },
  background__menu_on: {
    backgroundColor: '#2e6af4',
    borderBottom: 'solid 1px rgba(255,255,255,0.7)',
  },
  background__fixed: {
    position: 'fixed',
    top: (isTop && isPopup) ? '/47px' : 0,
    backgroundColor: 'white',

    color: '#2e6af4',

    transition: 'background-color 0.8s, color 0.5s',

    borderBottom: (isMobile || isTablet) ? 'solid 1px rgba(0,0,0,0.2)' : undefined,
  },
  logo: {
    width: '130px/220px@330px',
    height: '24px/42px@63px',

    // objectFit: 'contain',

    cursor: 'pointer',

    fill: isTop ? 'white' : '#2e6af4',
  },
  logo__fill_blue: {
    fill: '#2e6af4',
  },
  logo__fill_white: {
    fill: 'white',
  },
  menu_div: {
    height: '100%/@100%',

    display: 'flex',
    alignItems: 'center',

    fontWeight: 500,
    fontSize: '/15px',
  },
  menu__blue_font: {
    color: '#2e6af4',
  },
  menu__list: {
    marginRight: '/38px',
    paddingBottom: '/1px',

    cursor: 'pointer',

    ':hover': {
      color: '#2e6af4',

      transition: '0.3s',
    },
  },
  menu__list__border: {
    width: '/245px',
    height: '/50px',

    border: 'solid 1px #006AFF',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    ':hover': {
      backgroundColor: '#006AFF',
      color: 'white',
      transition: '0.3s',
    }
  },
  menu__request_button: {
    width: '/190px',
    height: '/48px',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    border: 'solid 1px #2e6af4',

    cursor: 'pointer',

    paddingBottom: '/3px@',

    ':hover': {
      backgroundColor: '#2e6af4',
      color: 'white',

      transition: '0.3s',
    }
  },
}, isMobile, isTablet);
