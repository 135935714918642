import React, { useMemo } from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';

import { RequestFont } from '../shared/RequestFont';
import { RequestColor } from '../assets/ColorContents';

export interface RequestButtonProps {
  id?: string;
  onClick?: () => void;
  isActive?: boolean;
  backgroundColor?: string;
  color?: string;
  title?: string;
  subTitle?: string;
  className?: string;
  isLoading?: boolean;
  marginBottom?: number;
  border?: string;
}

export const RequestButton: React.FC<RequestButtonProps> = (
  {
    onClick,
    isActive = true,
    backgroundColor = RequestColor.BLUE,
    color = RequestColor.WHITE,
    title = '확인',
    className,
    isLoading = false,
    marginBottom = 24,
    border = undefined,
    subTitle= '',
  }) => {
  const styles = useMemo(() => StyleSheet.create({
    wrapper: {
      marginBottom: marginBottom,
      width: '100%',

      border: border,
      maxWidth: '598px',
      height: 56,
      minHeight: 56,
      borderRadius: 5,

      alignSelf: 'center',

      backgroundColor: isActive && !isLoading ? backgroundColor : RequestColor.GRAY_30,
      color,

      cursor: isActive ? 'pointer' : 'default',
      transition: 'opacity .3s',
      ':hover': {
        opacity: 0.8,
      },
    }
  }), [color, backgroundColor, isActive]);

  return <button
    className={[css(styles.wrapper, RequestFont.BUTTON), className].join(' ')}
    type="button"
    onClick={isActive ? onClick : () => {
    }}
  >
    {title} <span style={{fontSize: '14px'}}>{subTitle}</span>
  </button>;
};

