import React, { useState } from 'react';
import { atom, useRecoilValue } from 'recoil';
import { css } from 'aphrodite';

import { CreateStyle } from '../../../shared/presentation/shared/CreateStyle';
import Dog from '../../assets/images/request-success.png';
import DogMobile from '../../assets/images/request-success-mobile.png';
import { Image } from '../../../shared/css/Image';
import { spoqa } from '../../../shared/css/css-utils';
import { ReactComponent as Close } from '../../../shared/assets/svgs/close.svg';
import history from '../../../shared/presentation/history';
import { isMobileState, isTabletState } from '../../../shared/presentation/MobileFrame';

export const isRequestSuccessState = atom<boolean>({
  key: 'isRequestSuccessState',
  default: false,
})

function RequestSuccessPopUp() {
  const isMobile = useRecoilValue(isMobileState);
  const isTablet = useRecoilValue(isTabletState);

  const styles = PopUpStyle(isMobile, isTablet);
  const [isShowSuccessPopUp, setIsShowSuccessPopUp] = useState(false);

  function onClickConfirm() {
    setIsShowSuccessPopUp(false);
    history.push('/');
    window.scrollTo(0, 0);
  }

  return (
    <>
      {isShowSuccessPopUp && (
        <div className={'popup background'}>
          <div className={css(styles.popup_frame)}>
            <Close
              className={css(styles.close_button)}
              onClick={() => setIsShowSuccessPopUp(false)}
            />
            <div className={css(styles.text_box)}>
              <div className={css(styles.first_line)}>
                <>
              <span style={{fontWeight: 700}}>프리득 {(isMobile || isTablet) && <br/>}
                개이득!</span><br/>
                </>
                <div className={css(styles.first__sub)}>
                  신청완료 {(isMobile || isTablet) && <br/>}
                  감사합니다.
                </div>
              </div>
              {(!isMobile && !isTablet) &&
              <div className={css(styles.second_line)}>
                빠른 연락드리겠습니다.
              </div>}
              <div className={css(styles.info)}>
                상담신청을 위하여 남겨주신 개인정보는 개인정보취급방침에<br/>
                의거하여 100% 안전하게 보호됩니다.
              </div>
            </div>
            <Image
              src={(isMobile || isTablet) ? DogMobile : Dog}
              alt={'dog'}
              className={css(styles.dog_image)}
            />
            <div
              className={css(styles.confirm_button)}
              onClick={() => onClickConfirm()}
            >
              확인
            </div>
          </div>
        </div>
      )}
    </>
  );
}

const PopUpStyle = (isMobile: boolean, isTablet: boolean) => CreateStyle({
  popup_frame: {
    width: '365px/1090px@497px',
    height: '350px/633px@497px',

    backgroundColor: 'white',

    color: '#3466fe',
    fontFamily: spoqa,
    fontWeight: 300,

    paddingTop: '44px/59px@65px',
    paddingLeft: '44px/70px@61px',
    marginTop: '-120px/@-100px',

    borderRadius: '5px/@7px',

    position: 'relative',
  },
  close_button: {
    width: '20px/21px@20px',
    height: '20px/21px@20px',

    position: 'absolute',
    right: '21px/21px@26px',
    top: '21px/24px@32px',

    zIndex: 10,

    cursor: 'pointer',

    fill: 'black',
  },
  text_box: {
    position: 'relative',

    zIndex: 10,
  },
  first_line: {
    fontSize: '30px/38px@40px',
    lineHeight: '1.45/1.45@61px',

    marginBottom: '/22px@',
  },
  first__sub: {
    fontSize: '23px/@30px',
    lineHeight: '/@48px',

    marginTop: '31px/@43px',
  },
  second_line: {
    fontSize: '/18px',
    lineHeight: '/@48px',

    marginBottom: '/306px',
  },
  info: {
    fontSize: '13px/14px',
    lineHeight: 1.86,
    textAlign_: 'center/@center',

    position_: 'absolute/@absolute',
    bottom: '-200px/@-270px',
    left: '-20px/',

    color_: 'white/@white',
  },
  dog_image: {
    width: '365px/1046px@490px',
    height: '342px/609px@490px',

    position: 'absolute',
    bottom: 0,
    right: 0,

    zIndex: 5,
  },
  confirm_button: {
    width: '300px/400px@409px',
    height: '55px/55px@75px',

    backgroundColor: 'white',

    position: 'absolute',
    right: '32px/60px',
    bottom: '30px/57px@41px',
    zIndex: 10,

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    border: 'solid 2px #3466fe',
    borderRadius: '27.2px/27.5px@37px',

    fontWeight: 500,
    fontSize: '/@22px',

    cursor: 'pointer',

    ':hover': {
      backgroundColor: '#3466fe',
      color: 'white',
      transition: '0.3s',
    }
  },
}, isMobile, isTablet);

export default RequestSuccessPopUp;
