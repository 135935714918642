export class RemainTimePolicy {
  static calcRemainTimes(time: number): { minute: number, second: number } {
    if (time < 0) {
      return { minute: 0, second: 0 };
    }
    const ONE_MINUTE = 60;
    const ONE_SECOND = 1000;
    const totalSeconds = Math.floor(time / ONE_SECOND);
    const minute = Math.floor(totalSeconds / ONE_MINUTE);
    const second = totalSeconds % ONE_MINUTE;
    return {
      minute, second,
    };
  }
}
