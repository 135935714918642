import React, { useCallback, useEffect, useState } from 'react';
import { atom, useRecoilState } from 'recoil';
import { css } from 'aphrodite';

import { CreateBetaStyle } from '../../../../shared/presentation/shared/CreateStyle';
import { useMyHistory } from '../../../../shared/presentation/HistoryHelper';
import { useHometaxCookieValue } from '../../shared/HometaxCookieState';
import { useIsHometaxSessionValid } from '../../shared/PermissionState';
import { useRequestFormState } from '../../shared/RequestContents';
import { RequestWrapperWithHeader } from '../../shared-component/RequestWrapperWithHeader';
import { RequestFont } from '../../shared/RequestFont';
import { RequestFloatingButton } from '../../shared-component/RequestFloatingButton';
import Horse from '../../assets/gif/horse.gif';
import Helicopter from '../../assets/gif/helicopter.gif';
import Running from '../../assets/gif/person_running.gif';
import Vehicle from '../../assets/gif/sport_utility_vehicle.gif';
import Biking from '../../assets/gif/woman_biking.gif';
import { Image } from '../../../../shared/css/Image';
import { RequestStyle } from '../../shared/RequestStyle';

export interface RefundResult {
  refunds: any[];
  commission: number;
  isReady: boolean;
  totalRefund: number;
}

export const refundResultState = atom<RefundResult>({
  key: 'refundResultState',
  default: {
    refunds: [],
    commission: 0,
    isReady: false,
    totalRefund: 0,
  }
});

export const refundOrderIdState = atom<string>({
  key: 'refundOrderIdState',
  default: '',
});

const GIF_LIST = [Running, Biking, Horse, Vehicle, Helicopter];

export const LoadingForConfirmRefundView: React.FC = () => {
  const [refundResult, setRefundResult] = useRecoilState(refundResultState);
  const cookie = useHometaxCookieValue();
  const [{ customerId, orderId }, setRequest] = useRequestFormState();
  const history = useMyHistory();
  const state = useIsHometaxSessionValid();
  const [isSend, setIsSend] = useState(false);
  const [order, setOrder] = useState(0);
  const orderCountUp = () => {
    if (order > 3) {
      setOrder(0);
      return;
    }
    setOrder(order + 1);
  }

  useEffect(() => {
    const interval = setInterval(() => orderCountUp(), 3000);
    return () => clearInterval(interval);
  });

  useEffect(() => {
    // if (state.loading) {
    //   return;
    // }
    // if (state.isSessionValid === false) {
    //   history.push(REQUEST_PATH.step01);
    //   return;
    // }
    // if (isSend) {
    //   return;
    // }
    // setIsSend(true);
    // axios.post(
    //   'https://api.ailabscorp.com/the-refund/refund',
    //   {
    //     customerId,
    //     cookie,
    //   })
    //   // .finally(() => setIsLoading(false))
    //   .then(({ data: id }) => {
    //     setRequest({ orderId: id });
    //   });
  }, [cookie, state, isSend, setIsSend]);

  const getRefunds = useCallback(async () => {
    // const { data } = await axios.get(`https://api.ailabscorp.com/the-refund/refund?orderId=${orderId}`);
    // if (!data.isReady) {
    //   return;
    // }
    // setRefundResult(data);
    // setTimeout(() => history.push(REQUEST_PATH.step05), 500);
  }, [orderId]);

  useEffect(() => {
    // if (orderId !== '') {
    //   const interval = setInterval(getRefunds, 1000);
    //   return () => clearInterval(interval);
    // }
  }, [orderId]);

  useEffect(() => {
    // if (!refundResult.isReady) {
    //   return;
    // }
    // // if (refundResult.totalRefund === 0 && cardPoints.points.length === 0) {
    // //   history.push('empty-refund');
    // //   return;
    // // }
    // history.push(REQUEST_PATH.step05);
  }, [refundResult]);

  return (
    <RequestWrapperWithHeader className={css(styles.wrapper)}>
      <p className={css(RequestFont.TITLE, styles.title)}>
        안내
      </p>
      <p className={css(RequestFont.DESCRIPTION, styles.description)}>
        고객님의 신고정보를 분석중입니다.<br/>
        최대 30초가 소요될 수 있습니다.<br/>
        잠시만 기다려 주세요.
      </p>
      <div className={css(styles.gif_frame, RequestStyle.COLUMN_CENTER)}>
        <Image src={GIF_LIST[order]} alt={'test'} className={css(styles.gif)}/>
      </div>
      {/*<VerticalSpace/>*/}
      <RequestFloatingButton
        title={'네, 동의할게요'}
        isLoading
      />
    </RequestWrapperWithHeader>
  );
};


const styles = CreateBetaStyle({
  wrapper: {
    minHeight: '100vh',
  },
  title: {
    marginBottom: '8px',
  },
  description: {
    marginBottom: '106px',
  },
  gif_frame: {
    width: '100%',
  },
  gif: {
    width: '128px',
    height: '128px',
  },
});

