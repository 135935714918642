import { useSetRecoilState } from 'recoil';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { css } from 'aphrodite';

import { CreateBetaStyle } from '../../../../shared/presentation/shared/CreateStyle';
import { RequestWrapperWithHeader } from '../../shared-component/RequestWrapperWithHeader';
import { RequestFont } from '../../shared/RequestFont';
import { RequestButton } from '../../shared-component/RequestButton';
import { RequestColor } from '../../assets/ColorContents';
import RequestVerticalButtons from '../../shared-component/RequestVerticalButtons';
import { useMyHistory } from '../../../../shared/presentation/HistoryHelper';
import { REQUEST_PATH } from '../SharedConstants';
import { VerticalSpace } from '../../../../taxagent/pages/test-input/component/VerticalSpace';
import { RequestButtonWrapper } from '../../shared-component/RequestButtonWrapper';
import { useRequestFormValue } from '../../shared/RequestContents';
import { PersonalInfoPopup } from './PersonalInfoPopup';
import { requestAlertMessageState } from '../../shared-component/RequestAlertFrame';
import { ReactComponent as Arrow } from '../../assets/svgs/Arrow.svg';
import { ReactComponent as Check } from '../../assets/svgs/Check.svg';
import { isLoadingState } from '../../../../shared/presentation/LoadingFrame';

const AddSupportFamily = () => {
  const history = useMyHistory();
  const setIsLoading = useSetRecoilState(isLoadingState);
  const request = useRequestFormValue();
  const [supportFamily, setSupportFamily] = useState<any[]>([]);
  const [isPopUp, setIsPopup] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [popupInfo, setPopupInfo] = useState({
    name: '',
    relationship: '',
    ssn: '',
    isDisabled: false,
    customerId: '',
  });
  const showAlert = useSetRecoilState(requestAlertMessageState);
  const getSupportFamily = () => {
    setIsLoading(true);
    axios
      .get(`https://api.ailabscorp.com/total-income/support-family/get?customerId=${request.customerId}`)
      .finally(() => setIsLoading(false))
      .then(({ data: family }) => {
        setSupportFamily(family);
      });
  };
  useEffect(() => {
    if (request.customerId === '') {
      history.push(REQUEST_PATH.step01);
    }
    getSupportFamily();
  }, []);

  const deleteAll = async () => {
    setIsLoading(true);
    await axios.get(`https://api.ailabscorp.com/total-income/support-family/delete-all?customerId=${request.customerId}`)
      .finally(() => setIsLoading(false));
    window.location.reload();
  };

  const saveIsDisabled = async () => {
    setIsLoading(true);
    const ssn = supportFamily.find((v: any) => v.relationship === '본인')?.ssn ?? '';
    const body = [
      {
        customerId: request.customerId,
        ssn,
        name: request.name,
        isDisabled,
      }
    ];
    await axios
      .post('https://api.ailabscorp.com/total-income/support-family/save',
        {
          family: body,
        })
      .finally(() => setIsLoading(false));
    await axios.post('https://api.ailabscorp.com/total-income/support-family/done',
      {
        customerId: request.customerId,
      });
    history.push(REQUEST_PATH.step12);
  };

  const onClickSend = () => {
    showAlert({
      title: '추가정보를 제출합니다.',
      message: '입력하신 추가 정보를 제출하려면 확인버튼을 \n' +
        '눌러주세요. 제출하신 정보에 따라 신고를 \n진행합니다.',
      button: [
        {
          title: '취소',
        },
        {
          title: '확인',
          onClick: () => saveIsDisabled(),
        }
      ]
    });
  };

  const showPersonalPopup = (value: { name: string, relationship: string, ssn: string, isDisabled: boolean, customerId: string }) => {
    setPopupInfo(value);
    setIsPopup(true);
  };

  return <>
    {isPopUp && (
      <PersonalInfoPopup
        info={popupInfo}
        onClickHeader={() => setIsPopup(false)}
      />
    )}
    <RequestWrapperWithHeader className={css(styles.wrapper)}>
      <p className={css(RequestFont.TITLE, styles.title)}>부양가족 정보 입력</p>
      <p className={css(RequestFont.DESCRIPTION_TITLE, styles.desc_title)}>본인 포함 부양가족</p>
      <div className={css(styles.desc_box)}>
        <p className={css(RequestFont.DESCRIPTION_TITLE, styles.desc)}>{request.name}</p>
        <p className={css(RequestFont.DESCRIPTION_TITLE)}>소득자 본인</p>
        <div
          className={css(styles.desc__disabled)}
          onClick={() => setIsDisabled(!isDisabled)}
        >
          장애 해당여부
          <Check className={css(styles.desc__disabled_img, isDisabled && styles.desc__disabled_img_checked)}/>
        </div>
      </div>
      {supportFamily.map((item: any, index: number) => item.relationship !== '본인' && (
        <div
          key={'support_' + index}
          className={css(styles.desc_box)}
          onClick={() => showPersonalPopup({ ...item, customerId: request.customerId })}
        >
          <p className={css(RequestFont.DESCRIPTION_TITLE, styles.desc)}>{item.name}</p>
          <p className={css(RequestFont.DESCRIPTION_TITLE)}>{item.relationship}</p>
          <Arrow className={css(styles.arrow)}/>
        </div>
      ))}
      <RequestButton
        title={'부양가족 추가하기'}
        backgroundColor={RequestColor.WHITE}
        color={RequestColor.BLUE}
        border={'1px solid' + RequestColor.BLUE}
        onClick={() => history.push(REQUEST_PATH.step09)}
      />
      <VerticalSpace/>
      <RequestButtonWrapper>
        <RequestVerticalButtons
          items={[
            {
              title: '제출하기',
              onClick: () => onClickSend(),
              marginBottom: 12,
            },
            {
              title: '부양가족 전체 삭제하기',
              onClick: () => showAlert({
                title: '부양가족 정보를 전체 삭제합니다.',
                message: '삭제를 원하시면 확인 버튼을 눌러주세요. \n' +
                  '부양가족 정보는 다시 입력할 수 있습니다.',
                button: [
                  {
                    title: '취소',
                  },
                  {
                    title: '삭제',
                    onClick: () => deleteAll(),
                  }
                ]
              }),
              color: RequestColor.GRAY_60,
              backgroundColor: RequestColor.WHITE,
            }]}/>
      </RequestButtonWrapper>
    </RequestWrapperWithHeader>;
  </>;
};

export default AddSupportFamily;

const styles = CreateBetaStyle({
  wrapper: {
    paddingBottom: '150px',
  },
  title: {
    marginBottom: '24px',
  },
  desc_title: {
    color: RequestColor.GRAY_100,
    fontWeight: 500,
    fontSize: '18px',
    marginBottom: '16px',
  },
  desc_box: {
    width: '100%',

    marginBottom: '24px',
    backgroundColor: RequestColor.GRAY_30,
    height: '86px',
    borderRadius: '5px',

    padding: '16px',

    position: 'relative',
  },
  desc: {
    color: RequestColor.GRAY_100,
    fontWeight: 500,
  },
  arrow: {
    width: '14px',
    height: '14px',

    position: 'absolute',
    top: '50%',
    right: '16px',
    transform: 'translateY(-50%)',
  },
  desc__disabled: {
    position: 'absolute',
    top: '50%',
    right: '16px',
    transform: 'translateY(-50%)',

    display: 'flex',
  },
  desc__disabled_img: {
    width: '18px',
    height: '18px',

    marginLeft: '8px',

    stroke: '#B3B7BA'
  },
  desc__disabled_img_checked: {
    stroke: RequestColor.BLUE_100,
  },
});
