import React, { useEffect, useState } from 'react';
import { css, StyleSheet } from 'aphrodite';
import { RouteComponentProps, useLocation, withRouter } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { spoqa } from '../../css/css-utils';
import { ReactComponent as KakaoIcon } from '../icons/kakao.svg';
import { ReactComponent as CallIcon } from '../icons/call.svg';
import { totalScreenState } from '../MobileFrame';
import { kakaoPage, useMyHistory } from '../HistoryHelper';

const styles = StyleSheet.create({
  outline: {
    position: 'fixed',
    width: '92px',

    right: 0,
    top: '50%',
    transform: 'translateY(-50%)',

    fontFamily: spoqa,
    fontWeight: 500,
    fontSize: '13px',
    lineHeight: 2.15,

    zIndex: 80,
  },
  button: {
    display: 'flex',
    height: '92px',

    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '18px 0 11px',
  },
  kakao: {
    cursor: 'pointer',
    backgroundColor: '#fbe950',
    color: '#34373a',

    ':hover': {
      opacity: .7,
    },
    fill: 'black',
  },
  call: {
    backgroundColor: '#34373a',
    color: 'white',
    fill: 'white',
  },
  icon: {
    fill: 'inherit',
    height: '30px'
  }
});

interface Props extends RouteComponentProps {
  isCallNotShown?: boolean,
}

function FloatingButtons({ isCallNotShown }: Props) {
  const { isMobile, isTablet } = useRecoilValue(totalScreenState);
  const [isShown, setIsShown] = useState(true);
  const location = useLocation();
  const history = useMyHistory();

  useEffect(() => {
    checkPath();
  });

  function checkPath() {
    setIsShown(
      !(location.pathname === '/service1'
        || location.pathname === '/service2'
        || location.pathname === '/kakao/chat'
        || location.pathname === '/manual/hts'
        || location.pathname === '/manual/card'
        || location.pathname === '/hr')
    );
  }

  return (
    <>
      {((!isMobile && !isTablet) && isShown) && (
        <div className={css(styles.outline)}>
          <div
            className={css(styles.button, styles.kakao)}
            onClick={() => history.push(kakaoPage())}
            id={'kakao_fixed_button'}
          >
            <KakaoIcon className={css(styles.icon)}/>
            <p>상담하기</p>
          </div>
          {!isCallNotShown && (
            <div className={css(styles.button, styles.call)}>
              <CallIcon className={css(styles.icon)}/>
              <p>{process.env.REACT_APP_SERVICE_CENTER_NUMBER}</p>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default withRouter(FloatingButtons);
