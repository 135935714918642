import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import axios, { AxiosResponse } from 'axios';
import { css } from 'aphrodite';
import { selectorFamily, useRecoilState, useRecoilValueLoadable, useSetRecoilState } from 'recoil';

import { CreateStyle } from '../../../shared/presentation/shared/CreateStyle';
import { MetaTaxInput } from './component/MetaTaxInput';
import { PhoneAgencyButtons } from './component/PhoneAgencyButtons';
import { isLoadingState } from '../../../shared/presentation/LoadingFrame';
import { spoqa } from '../../../shared/css/css-utils';
import { MetaTaxHeader } from './component/MetaTaxHeader';
import { step03State } from './MetaTaxTestLoginStep02';
import { useMyHistory } from '../../../shared/presentation/HistoryHelper';
import { MetaTaxSsnInput } from './component/MetaTaxSsnInput';
import { VerticalSpace } from './component/VerticalSpace';
import { MetaTaxButton } from './component/MetaTaxButton';
import { metaTaxNameState, metaTaxBirthState } from './MetaTaxTestBank';

const YushinLoginRequestState = selectorFamily<AxiosResponse, string>({
  key: 'YushinLoginRequestState',
  get: (value: string) => async () => {
    return axios.get<any>('https://api.ailabscorp.com/yushin/login/step01/' + value);
  }
});

export const MetaTaxTestInputPage: React.FC = () => {
  const [agencyIndex, setAgencyIndex] = useState(0);
  const setIsLoading = useSetRecoilState(isLoadingState);
  const setStep03 = useSetRecoilState(step03State);
  const styles = Styles();
  // const loadable = useRecoilValueLoadable(YushinLoginRequestState(phoneAgency));
  const [name, setName] = useRecoilState(metaTaxNameState);
  const [phone, setPhone] = useState('');
  const [ssn1, setSsn1] = useRecoilState(metaTaxBirthState);
  const [ssn2, setSsn2] = useState('');
  const [smsCode, setSmsCode] = useState('');
  const [captcha, setCaptcha] = useState<any>({});
  const [cert, setCert] = useState<any>({});
  const [sessionId, setSessionId] = useState<any>({});
  const smsInput = useRef<HTMLInputElement>(null);

  const history = useMyHistory();
  const phoneAgency = useMemo(() => {
    return (agencyIndex + 1).toString().padStart(2, '0');
  }, [agencyIndex]);
  const init = useCallback(async (value: string) => {
    setIsLoading(true);
    try {
      const { data: { cert, captcha, sessionId } } = await axios.get<any>('https://api.ailabscorp.com/yushin/login/step01/' + value);
      setCert(cert);
      setCaptcha(captcha);
      setSessionId(sessionId);
    } catch (e) {
      alert(e.message);
      init(phoneAgency);
    } finally {
      setIsLoading(false);
    }
  }, [setCert, setCaptcha, setSessionId]);

  useEffect(() => {
    init(phoneAgency);
  }, [phoneAgency]);

  const isValid = useMemo(() => {
    return name !== '' && phone !== '' && ssn1.length === 6 && ssn2 !== '' && smsCode !== '';
  }, [name, phone, ssn1, ssn2, smsCode]);

  const onClickButton = async () => {
    if (!isValid) {
      alert('정보를 입력해주세요.');
      return;
    }
    setIsLoading(true);
    try {
      const { data: step02Result } = await axios.post(
        'https://api.ailabscorp.com/yushin/login/step02',
        {
          captcha: { ...captcha, code: smsCode, background: 1 },
          cert,
          user: {
            name, phone, ssn1, ssn2, phoneAgency,
          },
          sessionId,
        }
      );
      if (step02Result.status !== 200) {
        switch (step02Result.status) {
          case 412:
            alert(step02Result.result);
            setCaptcha(step02Result.captcha);
            setCert(step02Result.cert)
            smsInput.current!.value = '';
            break;
          case 406:
            alert('그림문자가 일치하지 않습니다.');
            setCaptcha(step02Result.captcha);
            smsInput.current!.value = '';
            break;
          default:
            alert('알수 없는 오류가 발생했습니다');
        }
        return;
      }

      history.push('/meta-tax/test-input/02');
      setStep03({
        cert,
        phoneAgency,
        sessionId: step02Result.sessionId,
      });
    } finally {
      setIsLoading(false);
    }
  };
  console.log(captcha)

  return <div className={css(styles.wrapper)}>
    <MetaTaxHeader/>
    <p className={css(styles.description)}>
      내 숨은 카드포인트를 찾고<br/>
      현금으로 입금 받으세요
    </p>
    <MetaTaxInput placeholder={'성함'} onChange={setName} />
    <MetaTaxSsnInput onChangeSsn1={setSsn1} onChangeSsn2={setSsn2} />
    <MetaTaxInput placeholder={'연락처'} onChange={setPhone} />
    <PhoneAgencyButtons
      options={['SKT', 'KT', 'LG U+', '알뜰폰\nSKT', '알뜰폰\nKT', '알뜰폰\nLG U+']}
      onChange={setAgencyIndex}
      value={agencyIndex}
    />
    {
      captcha.image != null && (
        <div className={css(styles.captcha)}>
          <img alt={'captcha'} className={css(styles.image)} src={`data:image/jpeg;base64,${captcha.image}`}/>
          <MetaTaxInput
            reference={smsInput}
            label={'확인 번호'}
            placeholder={'보안문자 입력'}
            onChange={setSmsCode}
            className={css(styles.captchaInput)}
          />
        </div>
      )
    }
    <VerticalSpace />
    <MetaTaxButton title={'다음'} onClick={onClickButton} />
  </div>;
};

const Styles = () => CreateStyle({
  wrapper: {
    width: '100vw',
    minHeight: '100vh',
    color: 'black',
    padding: '83px 30px 30px/',

    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'center',
  },
  description: {
    fontFamily: spoqa,
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '38px',
    marginBottom: '30px',
  },
  captcha: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '20px',
  },
  image: {
    marginRight: '30px',
    width: '161px/',
    height: '67px/',
    border: '1px solid black',
    borderRadius: '10px',
  },
  captchaInput: {
    height: '67px/',
    flexGrow: 1,
    marginBottom: 0,
  },
}, true, false, { isNoRatio: true });
