import React, { useState } from 'react';
import { css } from 'aphrodite';
import * as _ from 'lodash';
import axios from 'axios';

import { IResizeFrame, withResizeFrame } from '../../../shared/WithResizeFrame';
import { CreateStyle } from '../../../shared/presentation/shared/CreateStyle';
import { MetaTaxDropDown } from './component/MetaTaxDropDown';
import { MetaTaxHeader } from './component/MetaTaxHeader';
import { MetaTaxDescription } from './component/MetaTaxDescription';
import { spoqa } from '../../../shared/css/css-utils';
import { MetaTaxInput } from './component/MetaTaxInput';
import { VerticalSpace } from './component/VerticalSpace';
import { MetaTaxButton } from './component/MetaTaxButton';
import { META_TAX_BANKS } from './component/MetaTaxBankDummy';
import { atom, useRecoilValue, useSetRecoilState } from 'recoil';
import { cardPointState, metaTaxSessionIdState } from './MetaTaxTestCardPoints';
import { isLoadingState } from '../../../shared/presentation/LoadingFrame';
import { resultPointsState } from './MetaTaxTestResult';
import { useMyHistory } from '../../../shared/presentation/HistoryHelper';

export const metaTaxNameState = atom<string>({
  key: 'metaTaxNameState',
  default: '',
})

export const metaTaxBirthState = atom<string>({
  key: 'metaTaxBirthState',
  default: '',
})

const MetaTaxTestBank: React.FC<IResizeFrame> = (props) => {
  const setIsLoading = useSetRecoilState(isLoadingState);
  const name = useRecoilValue(metaTaxNameState);
  const birth = useRecoilValue(metaTaxBirthState);
  const { points } = useRecoilValue(cardPointState);
  const sessionId = useRecoilValue(metaTaxSessionIdState);
  const [bankName, setBankName] = useState('');
  const [bankAccountNo, setBankAccountNo] = useState('');
  const setResult = useSetRecoilState(resultPointsState);
  const history = useMyHistory();

  const isValid = bankName !== '' && bankAccountNo !== '';

  const onClickButton = async () => {
    setIsLoading(true);
    try {
      const { data: results } = await axios.post(
        'https://api.ailabscorp.com/yushin/point/retrieve',
        {
          points,
          bank: {
            name,
            birth,
            accountNo: bankAccountNo,
            bankCode: META_TAX_BANKS[bankName],
          },
          sessionId,
        }
      );
      const mapped = points.map(point => {
        const result = results.find((v: any) => v.cardName === point.cardName);
        return {
          cardName: point.cardName,
          result: result.result,
          isSuccess: result.isSuccess,
          value: point.pntEncashReqArr,
        }
      })
      setResult(mapped);
      history.push('result');
    } catch (e) {
      switch (e.response.status) {
        case 406:
          alert('잘못된 계좌번호를 입력했습니다.');
          break;
        default:
          alert('통신 오류가 발생했습니다. 다시 한번 신청 부탁드립니다.');
      }
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div className={css(styles.wrapper)}>
      <MetaTaxHeader/>
      <MetaTaxDescription text={'입금 받으실 계좌를\n입력해주세요'}/>
      <p className={css(styles.inputLabel)}>계좌정보 입력</p>
      <MetaTaxDropDown
        options={_.keys(META_TAX_BANKS)}
        onChange={setBankName}
        value={bankName}
        placeholder={'은행선택'}
        className={css(styles.dropdown)}
        customWidth={'160px/160px'}
        customHeight={'62px/62px'}
        isNoRatio={true}
        {...props}
      />
      <MetaTaxInput placeholder={'계좌번호'} onChange={setBankAccountNo} />
      <VerticalSpace />
      <MetaTaxButton title={'확인'} onClick={onClickButton} isDisabled={!isValid} />
    </div>
  )
}

export default withResizeFrame(MetaTaxTestBank);

const styles = CreateStyle({
  wrapper: {
    minHeight: '100vh',
    color: 'black',
    padding: '83px 30px 30px/',

    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'center',
  },
  inputLabel: {
    marginBottom: '15px',
    fontFamily: spoqa,
    fontSize: '18px',
    color: '#838A9A',
  },
  dropdown: {
    marginBottom: '9px',
  }
}, true, false, { isNoRatio: true });
