import React, { useState } from 'react';
import { css } from 'aphrodite';
import Slider from 'react-slick';
import { useRecoilValue } from 'recoil';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { Image } from '../../../../shared/css/Image';
import NextArrow from '../../../assets/images/arrow.png';
import { REVIEW_LIST } from '../../shared/Constant';
import { ReviewStyle } from './ReviewStyle';
import { totalScreenState } from '../../../../shared/presentation/MobileFrame';

function ReviewSection() {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const { isMobile, isTablet } = useRecoilValue(totalScreenState);
  const [slider, setSlider] = useState<any>();

  const setIndex = (index: number) => {
    slider?.slickGoTo(index);
    setSelectedIndex(index);
  };

  function nextIndex() {
    return selectedIndex + 1;
  }

  const sliderSettings = {
    autoplay: true,
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    autoplaySpeed: 6000,
    swipeToSlide: false,
    touchMove: true,
    beforeChange: (oldIndex: number, newIndex: number) => setIndex(newIndex)
  };

  const styles = ReviewStyle(isMobile, isTablet);
  return (
    <div className={css(styles.background)}>
      <Slider
        ref={c => setSlider(c)}
        {...sliderSettings}
      >
        {REVIEW_LIST(isMobile, isTablet).map((item, index) =>
          <div key={'review-' + index}>
            <div className={css(styles.review_section)}>
              <div className={css(styles.review_title)}>
                {item.title01}<span className={css(styles.blue_text)}>{item.title02}</span>{item.title03}
              </div>
              <div className={css(styles.review_content)}>
                {item.content}
              </div>
              <div className={css(styles.review_info)}>
                {item.info}
              </div>
              <Image
                src={item.image}
                alt={'interview'}
                className={css(styles.review_image)}
              />
            </div>
          </div>
        )}
      </Slider>
      <div className={css(styles.dot_box)}>
        {REVIEW_LIST(isMobile, isTablet).map((item, index) =>
          <div key={'review-' + index}>
            <div
              className={css(styles.dot, selectedIndex === index && styles.dot_fill)}
              onClick={() => setIndex(index)}
            />
          </div>
        )}
      </div>
      {(!isMobile && !isTablet) &&
      <Image
        src={NextArrow}
        alt={'arrow'}
        className={css(styles.next_button)}
        onClick={() => setIndex(nextIndex())}
      />}
    </div>
  );
}

export default ReviewSection;
