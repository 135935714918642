import React from 'react';
import { css } from 'aphrodite/no-important';

import { RequestWrapper } from '../request-page-2022/shared-component/RequestWrapper';
import { Image } from '../../shared/css/Image';
import Taxagent from '../assets/svgs/taxagentKakaoInapp.svg';
import Freeget from '../assets/svgs/freegetKakaoInapp.svg';
import { CreateBetaStyle } from '../../shared/presentation/shared/CreateStyle';
import { RequestFont } from '../request-page-2022/shared/RequestFont';

export const IosPopup: React.FC = () => {
  const blockImg = mapBlockKakaoInappImg(process.env.REACT_APP_AIRTABLE_SITE_NAME ?? '');
  return (
    <div className={css(styles.outline)}>
      <RequestWrapper className={css(styles.wrapper)}>
        <p className={css(RequestFont.TITLE, styles.title)}>
          아래 방법으로<br/>
          링크에 접속해주세요
        </p>
        <div className={css(styles.img_frame)}>
          <Image src={blockImg} alt={'kakao-inapp-block'}/>
        </div>
      </RequestWrapper>
    </div>
  );
};

const styles = CreateBetaStyle({
  outline: {
    width: '100%',
    height: '100%',

    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 500,
  },
  wrapper: {},
  title: {
    width: '100%',

    textAlign: 'center',

    display: 'flex',
    justifyContent: 'center',

    marginTop: '68px',

    fontWeight: 700,
  },
  img_frame: {
    width: '100%',

    display: 'flex',
    justifyContent: 'center',

    marginTop: '39px',
  },
});


function mapBlockKakaoInappImg(siteName: string): string {
  switch (siteName) {
    case 'taxagent':
      return Taxagent;
    case 'freeget':
      return Freeget;
    default:
      return '';
  }
}