import React, { RefObject } from 'react';
import { css } from 'aphrodite/no-important';

import { CreateStyle } from '../../../../shared/presentation/shared/CreateStyle';
import { spoqa } from '../../../../shared/css/css-utils';

interface Props {
  label?: string;
  placeholder?: string;
  onChange?: (value: string) => void;
  className?: string;
  reference?: RefObject<any>;
}

export const MetaTaxInput: React.FC<Props> = ({ placeholder, onChange, className, reference }) => {
  return <input
    ref={reference}
    className={[css(styles.input_box), className].join(' ')}
    placeholder={placeholder}
    onChange={e => onChange?.(e.target.value)}
  />
}

const styles = CreateStyle({
  input_box: {
    flexShrink: 1,
    width: '100%',
    height: '62px/',

    border: '1.5px solid #E9EBF1',
    borderRadius: '10px/',

    paddingLeft: '14px/',
    fontFamily: spoqa,
    fontSize: '18px/',
    marginBottom: '15px/'
  },
}, true, false, { isNoRatio: true })
