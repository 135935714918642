import { css } from 'aphrodite';
import React, { RefObject } from 'react';

import { CreateBetaStyle } from '../../../../shared/presentation/shared/CreateStyle';
import { RequestColor } from '../../assets/ColorContents';

interface Props {
  addStyle?: Object,
  addInputStyle?: Object,
  placeholder?: string,
  children?: any
  onFocus?: () => void,
  onBlur?: (value: any) => void,
  onChange?: (value: any) => void,
  onKeydown?: (e: any) => void,
  value?: string,
  refObject?: RefObject<HTMLInputElement>,
  id?: string,
  isNumeric?: boolean,
  inputType?: string,
  isValid?: boolean,
  maxLength?: number,
  isDisabled?: boolean,
}

export function FormInput(
  {
    addStyle,
    placeholder,
    children,
    onFocus,
    onBlur,
    onChange,
    value,
    onKeydown,
    refObject,
    id,
    isNumeric,
    inputType = 'text',
    addInputStyle,
    isValid,
    maxLength,
    isDisabled = false
  }: Props) {
  const getMaxLength = (e: any) => {
    return e.target.value.substring(0, maxLength);
  };

  return (
    <div
      className={css(styles.wrapper, addStyle, isDisabled && styles.disable)}
    >
      <input
        className={css(styles.input, addInputStyle, isDisabled && styles.disable_color)}
        placeholder={placeholder}
        onFocus={onFocus}
        onBlur={e => onBlur?.(e.target.value)}
        onChange={e => onChange?.(maxLength ? getMaxLength(e) : e.target.value)}
        value={value}
        onKeyDown={e => onKeydown?.(e)}
        ref={refObject}
        onClick={e => e.stopPropagation()}
        id={id}
        inputMode={isNumeric ? 'numeric' : 'text'}
        type={inputType}
        disabled={isDisabled}
      />
      {children}
    </div>
  );
};

const styles = CreateBetaStyle({
  wrapper: {
    position: 'relative',

    borderRadius: '4px',
  },
  disable: {
    pointerEvents: 'none',
  },
  disable_color: {
    color: RequestColor.GRAY_40,
  },
  input: {
    width: '100%',
    height: '48px',
    display: 'flex',
    alignItems: 'center',
    padding: '0px 15px 0px 15px',

    borderRadius: '4px',
    border: ['1px', 'solid', RequestColor.GRAY_30].join(' '),

    fontSize: '16px',
    lineHeight: '24px',
    transition: 'all 0.3s',
    '::placeholder': {
      color: RequestColor.GRAY_60,
    },
  }
});
