import React, { useRef } from 'react';
import { css } from 'aphrodite';
import NumberFormat from "react-number-format";

import { CreateStyle } from '../../shared/presentation/shared/CreateStyle';
import { IResizeFrame } from '../../shared/WithResizeFrame';
import { spoqa } from '../../shared/css/css-utils';

interface Props {
  onChangeSsn1: (value: string) => void;
  onChangeSsn2: (value: string) => void;
  ssn1: string,
  ssn2: string
}

export const SocialSecurityNoInput: React.FC<IResizeFrame & Props> = ({ onChangeSsn1, onChangeSsn2, ssn1, ssn2, ...props }) => {
  const styles = Styles(props);
  const personalNumberFrontRef = useRef<HTMLInputElement>(null);
  const personalNumberEndRef = useRef<HTMLInputElement>(null);

  function goToSsn2Input(key: string) {
    if (key.length > 1) {
      return;
    }
    const ssn2Input = personalNumberEndRef?.current;
    if (ssn1.length >= 6 && ssn2Input) {
      ssn2Input.value = '';
      ssn2Input.focus();
    }
  }

  return <div className={css(styles.wrapper)}>
    <NumberFormat<any>
      className={css(styles.socialSecurityNo1)}
      allowNegative={false}
      autoComplete={'off'}
      allowLeadingZeros
      getInputRef={personalNumberFrontRef}
      inputMode={'numeric'}
      maxLength={6}
      onChange={e => onChangeSsn1(e.target.value)}
      onKeyUp={(e) => goToSsn2Input(e.key)}
      placeholder={!props.isDesktop ? '앞 6자리' : '주민번호 앞 6자리'}
      value={ssn1}
    />
    -
    <NumberFormat<any>
      className={css(styles.socialSecurityNo2)}
      allowNegative={false}
      allowLeadingZeros
      autoComplete={'off'}
      getInputRef={personalNumberEndRef}
      inputMode={'numeric'}
      maxLength={7}
      onChange={e => onChangeSsn2(e.target.value)}
      type={'password'}
      placeholder={'● ● ● ● ● ● ●'}
      value={ssn2}
    />
  </div>
}

const Styles = ({ isMobile, isTablet }: IResizeFrame) => CreateStyle({
  wrapper: {
    display: 'flex',
    alignItems: 'center',

    fontSize: '11px/',
  },
  socialSecurityNo1: {
    width: '48px/120px@71px',
    minWidth: '58px/85px@80px',

    marginRight: '6px/11px@7px',

    fontFamily: spoqa,
    fontWeight: 300,
    fontSize: '11px/16px@17px',

    '::placeholder': {
      color: 'rgba(52, 55, 58, 0.2)',
    },
  },
  socialSecurityNo2: {
    width: '82px/126px@115px',
    minWidth: '90px/85px@140px',

    marginLeft: '6px/14px@20px',

    fontFamily: spoqa,
    fontWeight: 300,
    fontSize: '9px/16px@14px',

    '::placeholder': {
      color: 'rgba(52, 55, 58, 0.2)',
      letterSpacing: '2.5px/',
    },
  },
}, isMobile, isTablet, {mobileWidth: 320, isNoRatio: !isMobile })
