import * as _ from 'lodash';

const TITLE_LIST: {[key: string]: string} = {
  'wooricard': '우리카드',
  'kbcard': '국민카드',
  'shinhancard': '신한카드',
  'samsungcard': '삼성카드',
  'bccard': '비씨카드',
  'lottecard': '롯데카드',
  'hanaskcard': '하나카드',
  'hyundaicard': '현대카드',
  'nonghyup': '농협카드',
  'citi': '씨티카드',
  'epost': 'E포스트카드',
}

export class RemainCardPoint {
  constructor(props: Partial<RemainCardPoint>) {
    Object.assign(this, props);
  }

  pntEncashReqArr = 0;
  cardCdArr = '';
  pntGbArr = '';
  pntCdArr = '';
  bangTrnfChkArr = '';
  allEncash = '';
  cardName = '';

  get title(): string {
    const key = _.keys(TITLE_LIST).find(v => _.includes(this.cardName, v.replace('card', '')));
    return key != null ? TITLE_LIST[key] : '알수없는 카드';
  }

  get point(): number {
    return this.pntEncashReqArr;
  }

  static sum(points: RemainCardPoint[]): number {
    return _.chain(points).map(v => v.point).sum().value()
  }
}
