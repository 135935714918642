import { spoqa } from '../../css/css-utils';
import { CreateStyle } from '../shared/CreateStyle';

interface Props {
  isMobile: boolean,
  isTop: boolean,
  baseColor?: string,
  isRequestPage?: boolean,
  isTablet?: boolean,
  tabletHeight?: number,
}

export const HeaderButtonStyleForRequestPage = (
  {
    isMobile,
    isTop,
    baseColor = '#1b315d',
    isRequestPage = false,
    isTablet = false,
    tabletHeight = 77,
  }: Props) => CreateStyle({
  header: {
    zIndex: 50,
    position: 'fixed',
    width: '100%',
    height: 'calc(env(safe-area-inset-top) + 50.5px)/96px',
    top: 0,

    paddingTop: 'env(safe-area-inset-top)',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: isTop ? 'rgba(255, 255, 255, 0)' : 'rgba(255, 255, 255, 1)',
    borderBottom: `solid 1px ${isTop ? 'rgba(255, 255, 255, 0.25)' : '#d8d8d8'}`,
  },
  headerIsPopUp: {
    top: '85px/75px',
  },
  transitionStyle: {
    transition: '.3s',
  },
  logo: {
    height: '23px/36px',
    cursor: 'pointer',
    paddingLeft: '4.8vw',
  },
  logoIcon: {
    width: '150px/300px',
    height: '100%',
    fill: isTop ? 'white' : baseColor,
  },
  buttons: {
    display: 'inline-flex',
    height: '100%'
  },
  button: {
    width: `56px/@${tabletHeight}px`,
    height: '100%',

    border: 'none',
    borderLeft: `solid 1px ${isTop ? isRequestPage ? '#d8d8d8' : 'rgba(255, 255, 255, 0.25)' : '#d8d8d8'}`,

    paddingLeft: '/42px',
    paddingRight: '/42px',

    display: 'flex',
    alignItems: 'center',
    justifyContent_: 'center/flex-start@center',

    backgroundColor: 'inherit',
    fontFamily: spoqa,
    fontWeight: 500,
    fontSize: isMobile ? '3.14vw' : '0.94vw',
    color: isTop ? 'white' : baseColor,
    fill: isTop ? isRequestPage ? baseColor : 'white' : baseColor,

    cursor: 'pointer',
    whiteSpace: 'pre',
  },
  text: {
    whiteSpace: 'pre' as 'pre',
  },
  buttonIcon: {
    width: `23px/25px@${(tabletHeight !== 77) ? tabletHeight * 0.4 : 26}px`,
    height: `23px/25px@${(tabletHeight !== 77) ? tabletHeight * 0.4 : 26}px`,
    fill: 'inherit',
  },
  closeIcon: {
    marginRight: '15px/',
    marginTop: '5px/',
    cursor: 'pointer',
  },
}, isMobile, isTablet);
