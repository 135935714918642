import React from 'react';
import { css } from 'aphrodite';
import { useRecoilValue } from 'recoil';
import { Link } from 'react-router-dom';

import { ReactComponent as CloseIcon } from '../icons/close.svg';
import { HeaderButtonStyles } from './HeaderButtonStyle';
import { defaultPage } from '../HistoryHelper';
import { totalScreenState } from '../MobileFrame';

interface Props {
  baseColor: string,
  tabletHeight?: number,
}

export function MobileCloseButton({ baseColor, tabletHeight }: Props) {
  const { isMobile, isTablet } = useRecoilValue(totalScreenState);
  const styles = HeaderButtonStyles({
    isMobile,
    isTop: false,
    baseColor: baseColor,
    isTablet,
    tabletHeight,
  });
  return (
    <Link
      className={css(styles.button)}
      to={defaultPage}
    >
      <CloseIcon className={css(styles.buttonIcon)}/>
    </Link>
  );
}
