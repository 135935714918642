import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import SimpleAuthRequest from './pages/1_간편인증/SimpleAuthRequest';
import Toast from './shared-component/toast/Toast';
import { RequestAlertFrame } from './shared-component/RequestAlertFrame';
import { AlertFrame } from './shared-component/ShowAlertFrame';
import { useSetRequestFormState } from './shared/RequestContents';

export const RegisterCustomerPage: React.FC = () => {
  const { customerCode } = useParams<any>();
  const setRequest = useSetRequestFormState();
  useEffect(() => {
    if (customerCode != null && customerCode !== '') {
      setRequest({ customerCode })
    }
  }, [customerCode]);

  return (
    <>
      <Toast/>
      <AlertFrame/>
      <RequestAlertFrame/>
      <SimpleAuthRequest/>
    </>
  );
};
