import { CreateStyle } from '../../../shared/presentation/shared/CreateStyle';
import { spoqa } from '../../../shared/css/css-utils';

export const FooterStyle = (isMobile: boolean, isTablet: boolean) => CreateStyle({
  background: {
    width: '100%',
    height: '351px/458px@813px',

    backgroundColor: process.env.REACT_APP_BASE_COLOR,

    paddingTop: '38px/61px@81px',
    paddingBottom: '/32px',
    paddingLeft: '20px/335px@89px',
    paddingRight: '20px/339px',

    display_: '/flex@',
    justifyContent_: '/space-between',

    position_: 'relative/@relative',

    fontFamily: spoqa,
  },
  left_div: {
    width: '/600px',
    height: '/192px',

    display_: '/flex',
    flexDirection_: '/column',
    // justifyContent_: '/space-between',
  },
  left__working_time: {
    width: '277px/235px@300px',
    height: '45px/68px@auto',

    fontWeight: 300,
    fontSize: '13px/22px@28px',
    lineHeight: isMobile ? 1.62 : 1.55,
    color: 'white',

    marginBottom: '8px/17px@18px',
  },
  left__service_center: {
    width: '/308px',
    height: '30px/49px@60px',

    fontWeight: 300,
    fontSize: '20px/35px@43px',
    lingHeight: 1.4,
    color: 'white',

    marginBottom: '0px/34px@36px',
  },
  left__payment_button: {
    width: '85px',
    height: '34px',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    borderRadius: '4px',
    border: '1px solid white',

    position_: 'absolute/',
    right: '78px/',
    top: '96px/@ss',

    color: 'white',
    fontSize: '13px',
    lineHeight: '15px',
    letterSpacing: '-0.2px',

    cursor: 'pointer',

    marginBottom: '44px',
  },
  left__terms_of_service: {
    width: '85%/',
    height: '/28px',

    opacity: 0.5,
    fontWeight: 500,
    fontSize: '10px/13px@21px',
    lineHeight: isMobile ? 2.2 : 2.15,
    color: 'white',

    position_: 'absolute/@absolute',
    bottom: '38px/@182px',

    '-webkit-tap-highlight-color': 'transparent',
  },
  button: {
    cursor: 'pointer',
  },
  right_div: {
    height: '/192px',

    flexDirection_: '/column',

    marginTop: '9px/',
  },
  right__icon_box: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent_: '/flex-end@',
  },
  right__icon__background: {
    width: '34px/40px@72px',
    height: '34px/40px@72px',

    borderRadius: '50%/50%@50%',

    backgroundColor: 'white',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  right__icon__margin_right: {
    marginRight: '17px/16px@36px',
  },
  right__icon__logo: {
    width: '23px/27px@50px',

    marginTop: '2px/2px@2px',

    fill: process.env.REACT_APP_BASE_COLOR,
  },
  right__company_address: {
    height: '/57px',

    fontWeight: 300,
    fontSize: '11px/16px@23px',
    lineHeight: isMobile ? 1.91 : 1.75,
    color: 'white',

    textAlign_: 'left/right@',

    left: '20px/',
    marginTop: '30px/17px@32px',
  },
  right__company_contact: {
    height: '/28px',

    opacity: 0.5,
    fontWeight: 300,
    fontSize: '10px/16px@21px',
    lineHeight: `${2}/${1.75}@43px`,
    color: 'white',
    textAlign_: 'left/right@',

    left: '20px/',
    marginTop: '150px/50px@290px',
  },
}, isMobile, isTablet);
