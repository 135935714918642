import React, { useEffect, useState } from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';

import { RequestWrapper } from './RequestWrapper';
import RequestHeader from './header/RequestHeader';

interface Props {
  children: any,
  className?: string,
  onClickBack?: () => void,
  isVisibleAskBtn?: boolean,
}

export const RequestWrapperWithHeader: React.FC<Props> = (
  {
    children,
    className,
    onClickBack,
    isVisibleAskBtn,
  }) => {
  const [innerHeight, setInnerHeight] = useState(window.innerHeight);
  useEffect(() => {
    const interval = setInterval(() => setInnerHeight(window.innerHeight), 100);
    return () => clearInterval(interval);
  }, [])
  const styles = WrapperStyle(innerHeight)
  return <RequestWrapper className={[css(styles.wrapper), className].join(' ')}>
    <RequestHeader onClick={onClickBack} isVisibleAskBtn={isVisibleAskBtn}/>
    <div className={css(styles.space)}/>
    {children}
  </RequestWrapper>;
};

const WrapperStyle = (innerHeight: number) => StyleSheet.create({
  wrapper: {
    // minHeight: '812px',
    minHeight: `${innerHeight}px`,

    display: 'flex',
    alignItems: 'center',
  },
  space: {
    height: 15,
  }
});
