import { atom, useRecoilState } from 'recoil';
import React, { useCallback, useEffect, useMemo } from 'react';
import { css } from 'aphrodite';

import { CreateBetaStyle } from '../../../shared/presentation/shared/CreateStyle';
import { RequestFont } from '../shared/RequestFont';
import { RequestColor } from '../assets/ColorContents';

type AlertType = {
  title?: string;
  message: string | undefined;
  button: {
    title: string;
    onClick?: () => void;
  }[];
  description?: string | undefined;
}

export const requestAlertMessageState = atom<AlertType>({
  key: 'requestAlertMessageState',
  default: {
    message: undefined,
    button: [{
      title: '',
      onClick: () => {
      },
    }],
    description: undefined,
  },
});

export const RequestAlertFrame: React.FC = () => {
  const [alert, setAlert] = useRecoilState(requestAlertMessageState);
  const isShow = useMemo(() => alert.message != null, [alert.message]);
  const clickHandler = useCallback(async (onClick?: () => void) => {
    await onClick?.();
    setAlert({
      message: undefined,
      button: [],
      description: undefined,
    });
  }, [alert]);

  const isButtonCouple = alert.button.length === 2;

  return <>
    {isShow && (
      <div className={css(styles.wrapper)}>
        <div className={css(styles.alert_box)}>
          <div className={css(styles.alert__contents)}>
            {alert.title != null && (
              <p className={css(styles.alter__title)}>
                {alert.title}
              </p>
            )}
            <p className={css(styles.alter__description, alert.title == null && styles.alter__description__only)}>
              {alert.message}
            </p>
          </div>
          <div className={css(styles.button_frame)}>
            <button
              type={'button'}
              className={css(RequestFont.ALTER_BUTTON, styles.button, !isButtonCouple && RequestFont.BLUE_100)}
              onClick={() => clickHandler(alert.button[0].onClick)}
            >
              {alert.button[0].title}
            </button>
            {alert.button[1] != null && (
              <button
                type={'button'}
                className={css(RequestFont.ALTER_BUTTON, RequestFont.BLUE_100, styles.button)}
                onClick={() => clickHandler(alert.button[1].onClick)}
              >
                {alert.button[1].title}
              </button>
            )}
          </div>
          {alert.description != null && (
            <p className={css(styles.alter__floating_description)}>
              {alert.description}
            </p>
          )}
        </div>
      </div>
    )}
  </>;
};

const styles = CreateBetaStyle({
  wrapper: {
    width: '100vw',
    height: '100vh',

    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 600,

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    backgroundColor: 'rgba(32, 35, 40, 0.3)',
  },
  alert_box: {
    position: 'relative',
    padding: '28px 20px 0px',
    width: 'calc(100vw - 48px)',
    maxWidth: '350px',
    // minHeight: '206px',

    borderRadius: '4px',
    backgroundColor: '#FFF',

    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'center',
  },
  alter__floating_description: {
    position: 'absolute',
    bottom: '-70px',
    left: '-50%',
    transform: 'translateX(100%)',

    textAlign: 'center',

    fontSize: '14px',
    color: RequestColor.GRAY_30,
    lineHeight: '24px',
    whiteSpace: 'pre',
  },
  alert__contents: {
    width: '100%',
  },
  alter__title: {
    fontSize: '20px',
    color: RequestColor.GRAY_100,
    fontWeight: 500,

    marginBottom: '8px',
  },
  alter__description: {
    // width: '100%',
    fontSize: '15px',
    color: RequestColor.GRAY_80,
    letterSpacing: '-0.2px',
    lineHeight: '26px',

    marginBottom: '28px',
    whiteSpace: 'pre',
  },
  alter__description__only: {
    fontSize: '18px',
    lineHeight: '26px',
    color: RequestColor.GRAY_100,
  },
  button_frame: {
    width: '100%',

    borderTop: '1px solid #F5F6F9',

    display: 'flex',
    justifyContent: 'flex-end',

    paddingTop: '20px',
    paddingBottom: '24px',
  },
  button: {
    backgroundColor: 'transparent',

    marginLeft: '20px',

    cursor: 'pointer',
  }
});
