import React from 'react';
import { css } from 'aphrodite/no-important';

import { RequestWrapperWithHeader } from '../../shared-component/RequestWrapperWithHeader';
import { RequestFont } from '../../shared/RequestFont';
import Single from '../../assets/svgs/single.svg';
import Twins from '../../assets/svgs/twins.svg';
import { Image } from '../../../../shared/css/Image';
import { CreateBetaStyle } from '../../../../shared/presentation/shared/CreateStyle';
import { RequestColor } from '../../assets/ColorContents';
import { RequestStyle } from '../../shared/RequestStyle';
import { useMyHistory } from '../../../../shared/presentation/HistoryHelper';
import { REQUEST_PATH } from '../SharedConstants';

export const SelectRequestType: React.FC = () => {
  const history = useMyHistory();
  const REQUEST_TYPE = [
    {
      title: '개인접수',
      description: '본인신청 / 대리신청 (가족 및 지인)',
      img: Single,
      onClick: () => history.push(REQUEST_PATH.step01),
    },
    {
      title: '단체접수',
      description: '5명 이상 신청하는 경우',
      img: Twins,
      onClick: () => history.push(REQUEST_PATH.groupStep01),
    },
  ];
  return (
    <RequestWrapperWithHeader onClickBack={() => history.push('/')}>
      <p className={css(RequestFont.TITLE, styles.title)}>
        접수유형을 선택해주세요.
      </p>
      {REQUEST_TYPE.map((item, index) =>
        <div
          key={'request-type_' + index}
          className={css(styles.type_box)}
          onClick={item.onClick}
        >
          <div>
            <p className={css(styles.type_title)}>{item.title}</p>
            <p className={css(styles.type_description)}>{item.description}</p>
          </div>
          <div className={css(styles.type_img_box, RequestStyle.CENTER)}>
            <Image src={item.img} alt={'request-type' + index} className={css(styles.type_img)}/>
          </div>
        </div>
      )}
    </RequestWrapperWithHeader>
  );
};

const styles = CreateBetaStyle({
  title: {
    width: '100%',

    marginBottom: '35px',
  },
  type_box: {
    width: '100%',
    height: '112px',

    padding: '0px 20px',

    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    borderRadius: '4px',
    border: `1px solid ${RequestColor.GRAY_30}`,

    marginBottom: '16px',

    boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.06)',

    cursor: 'pointer',

    ':hover': {
      borderColor: RequestColor.BLUE,
      transition: '0.3s',
    },
  },
  type_box_select: {
    borderColor: RequestColor.BLUE,
  },
  type_title: {
    fontSize: '18px',
    fontWeight: 500,
    lineHeight: '30px',
    letterSpacing: '-0.2px',
    color: RequestColor.GRAY_100,

    marginBottom: '6px',
  },
  type_description: {
    fontSize: '12px',
    lineHeight: '18px',
    color: RequestColor.GRAY_80,
    fontWeight: 400,
  },
  type_img_box: {
    width: '95px',
    height: '70px',
    backgroundColor: RequestColor.GRAY_10,

    borderRadius: '4px',
  },
  type_img: {},
});
