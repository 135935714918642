import React from 'react';
import Dropdown from 'react-dropdown';
import { css } from 'aphrodite';

import { IResizeFrame } from '../../../../shared/WithResizeFrame';
import { CreateStyle } from '../../../../shared/presentation/shared/CreateStyle';
import { spoqa } from '../../../../shared/css/css-utils';

interface IDropDown extends IResizeFrame {
  options: string[],
  placeholder?: string,
  className?: string,
  onChange?: (value: any) => void,
  value?: string,
  isError?: boolean,
  disabled?: boolean,
  required?: boolean;
  customWidth?: string,
  customHeight?: string,
  isNoRatio?: boolean;
}

export function MetaTaxDropDown(props: IDropDown) {
  const {className, placeholder = '선택', onChange, value, disabled, required, ...rest} = props;
  const styles = Styles(props);
  const isInvalid = required && value === '';
  return (
    <Dropdown
      className={[css(styles.base), className].join(' ')}
      controlClassName={css(styles.control, isInvalid && styles.invalid)}
      placeholderClassName={css(value === '' ? styles.placeholder : styles.default)}
      arrowClassName={css(styles.arrow, value === '' && styles.arrow_shadow)}
      menuClassName={css(styles.menu)}
      onChange={(option) => onChange?.(option.value)}
      value={value}
      disabled={disabled}
      {...rest}
      placeholder={placeholder}
    />
  );
}

const Styles = ({ isMobile, isTablet, isError, customWidth, customHeight, isNoRatio }: IDropDown) => CreateStyle({
  base: {
    ':nth-child(1n).is-open > div': {
      ':first-child': {
        border: '1.5px solid #006AFF/1.5px solid #006AFF',
      }
    },
    transformOrigin: 'top left',
  },
  control: {
    position: 'relative',
    width: customWidth ?? '160px/190px',
    height: customHeight ?? '52px/52px',
    borderRadius: '10px/10px',
    border: isError ? '1.5px solid red/1.5px solid red' : '1.5px solid #E9EBF1/1.5px solid #E9EBF1',

    display: 'flex',
    alignItems: 'center',

    fontFamily: 'Spoqa',
    fontWeight: 500,
    fontSize: '17px/16px',
    lineHeight: '21px/30px',
    paddingLeft: '20px/20px',
    cursor: 'pointer',
  },
  invalid: {
    border: '1.5px solid red/1.5px solid red',
  },
  placeholder: {
    fontFamily: spoqa,
    fontSize: '18px/16px',
    fontWeight: 400,
    color: '#838A9A',
  },
  default: {
    fontFamily: spoqa,
    fontSize: '18px/16px',
    fontWeight: 400,
    color: '#454F5D',
  },
  menu: {
    position: 'absolute',
    width: customWidth ?? '160px/190px',
    maxHeight: '400px',
    overflow: 'scroll',

    transform: 'translateY(8px)/translateY(8px)',
    borderRadius: '10px/10px',
    border: '1.5px solid #E9EBF1/1px solid #E9EBF1',
    boxShadow: '0px 20px 20px rgba(69, 79, 93, 0.05), 0px 0px 2px rgba(69, 79, 93, 0.1)/0px 20px 20px rgba(69, 79, 93, 0.05), 0px 0px 2px rgba(69, 79, 93, 0.1)',

    fontFamily: spoqa,
    fontSize: '17px/17px',
    backgroundColor: '#FFF',

    ':nth-child(1n) > div': {
      paddingLeft: '17px/19px',
      borderBottom: '1px solid rgba(233, 235, 241, 0.5)/1px solid rgba(233, 235, 241, 0.5)',
      height: '48px/43px',
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      ':hover': {
        backgroundColor: 'rgba(233, 235, 241, 0.15)',
        color: '#006AFF',
      },
      ':first-child': {
        paddingTop: '9px/9px',
        height: '52px/52px',
      },
      ':last-child': {
        paddingBottom: '9px/9px',
        height: '52px/52px',
        borderBottom: 'none',
      },
    },
  },
  arrow_default: {
    top: '50%',
    transform: 'translateY(-50%)',
  },
  arrow: {
    position: 'absolute',
    right: '24px/24px',
    borderLeft: '6px solid transparent/6px solid transparent',
    borderRight: '6px solid transparent/6px solid transparent',
    borderTop: '7.5px solid #454F5D/7.5px solid #454F5D',
    borderRadius: '2.5px/2.5px',
    transform: 'translateY(-50%)',
  },
  arrow_shadow: {
    borderTop: '7.5px solid #838A9A/7.5px solid #454F5D',
  },
  arrow_closed: {
    top: '40%',
    transform: 'translateY(-50%) rotate(135deg)',
  },
  arrow_open: {
    top: '55%',
    transform: 'translateY(-50%) rotate(-45deg)',
  },
}, isMobile, isTablet, { isNoRatio: isNoRatio });
