import React from 'react';
import { css } from 'aphrodite/no-important';

import { CreateBetaStyle } from '../../../../shared/presentation/shared/CreateStyle';
import Party from '../../../../taxagent-shared/request-page-2022/assets/gif/party_popper.gif';
import { Image } from '../../../../shared/css/Image';
import { useMyHistory } from '../../../../shared/presentation/HistoryHelper';
import { RequestWrapperWithHeader } from '../../../request-page-2022/shared-component/RequestWrapperWithHeader';
import { RequestFont } from '../../../request-page-2022/shared/RequestFont';
import { RequestButtonWrapper } from '../../../request-page-2022/shared-component/RequestButtonWrapper';
import RequestVerticalButtons from '../../../request-page-2022/shared-component/RequestVerticalButtons';
import { RequestStyle } from '../../../request-page-2022/shared/RequestStyle';
import { usePaymentFormValue } from '../../PaymentContents';

export const PaymentSuccess: React.FC = () => {
  const history = useMyHistory();
  const customer = usePaymentFormValue();
  return (
    <RequestWrapperWithHeader>
      <p className={css(RequestFont.TITLE, styles.title, styles.pre)}>
        {customer.type === 'vbank'
          ? '가상계좌가 발송되었습니다'
          : '결제가 완료되었습니다'}
      </p>
      <p className={css(RequestFont.DESCRIPTION, styles.pre)}>
        {customer.type === 'vbank'
          ? '종합소득세 신고대리 접수가 완료되었습니다.\n' +
          '가상 계좌로 입금해 주시면\n' +
          '순차로 신고 도와드리겠습니다.'
          : '종합소득세 신고대리 접수가 완료되었습니다.\n' +
          '순차로 신고 도와드리겠습니다.'
        }
      </p>
      <div className={css(styles.gif_frame, RequestStyle.COLUMN_CENTER)}>
        <Image src={Party} alt={'party'} className={css(styles.gif)}/>
      </div>
      <RequestButtonWrapper>
        <RequestVerticalButtons items={[
          {
            title: '홈으로 이동',
            marginBottom: 10,
            onClick: () => history.push('/'),
          }]}
        />
      </RequestButtonWrapper>
    </RequestWrapperWithHeader>
  );
};

const styles = CreateBetaStyle({
  title: {
    width: '100%',

    marginBottom: '8px',
  },
  gif_frame: {
    width: '100%',

    marginTop: '65px',
  },
  gif: {
    width: '208px',
    height: '208px',
  },
  pre: {
    whiteSpace: 'pre',
  },
});
