import React, { useState } from 'react';
import * as _ from 'lodash';
import axios from 'axios';
import { css } from 'aphrodite';
import { atom, useRecoilValue, useSetRecoilState } from 'recoil';

import { CreateStyle } from '../../../shared/presentation/shared/CreateStyle';
import { isLoadingState } from '../../../shared/presentation/LoadingFrame';
import { spoqa } from '../../../shared/css/css-utils';
import { MetaTaxHeader } from './component/MetaTaxHeader';
import { MetaTaxInput } from './component/MetaTaxInput';
import { cardPointState, metaTaxSessionIdState } from './MetaTaxTestCardPoints';
import { useMyHistory } from '../../../shared/presentation/HistoryHelper';
import { VerticalSpace } from './component/VerticalSpace';

export const step03State = atom<any>({
  key: 'step03State',
  default: undefined,
});

export const MetaTaxTestInputPage02: React.FC = () => {
  const { cert, phoneAgency, sessionId } = useRecoilValue(step03State) ?? {};
  const setSessionId = useSetRecoilState(metaTaxSessionIdState);
  const setIsLoading = useSetRecoilState(isLoadingState);
  const styles = Styles();
  const [confirmCode, setConfirmCode] = useState('');
  const [certCount, setCertCount] = useState(0);
  const [reSendCount, setReSendCount] = useState(0);
  const [reSendYN, setReSendYN] = useState('N');
  const history = useMyHistory();

  const onClickButton = async () => {
    setIsLoading(true);
    try {
      const { data: result } = await axios.post(
        'https://api.ailabscorp.com/yushin/login/step03',
        {
          cert,
          confirm: {
            code: confirmCode,
            certCount,
            reSendCount,
            reSendYN,
          },
          phoneAgency,
          sessionId,
        }
      );
      setSessionId(result);
      history.push('./point');
    } catch (e) {
      switch (e.response.status) {
        case 401:
          setCertCount(certCount + 1);
          alert('인증번호 입력이 실패했습니다.');
          break;
        default:
          alert('알수 없는 오류가 발생했습니다: ' + e.response);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return <div className={css(styles.wrapper)}>
    <MetaTaxHeader/>
    <p className={css(styles.description)}>
      문자로 온 인증번호를<br/>
      입력해주세요
    </p>
    <p className={css(styles.inputLabel)}>
      숫자 6자리
    </p>
    <MetaTaxInput onChange={setConfirmCode}/>
    <VerticalSpace/>
    <button className={css(styles.button)} onClick={onClickButton}>
      인증완료
    </button>
  </div>;
};

const Styles = () => CreateStyle({
  wrapper: {
    minHeight: '100vh',
    color: 'black',
    padding: '83px 30px 30px/',

    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'center',
  },
  description: {
    fontFamily: spoqa,
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '38px',
    marginBottom: '36px',
  },
  inputLabel: {
    marginBottom: '13px',
    fontFamily: spoqa,
    fontSize: '18px',
    lineHeight: '22.5px',
    color: '#838A9A',
  },
  ssn: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    // justifyContent: 'space-between',
    marginBottom: '15px',
  },
  ssn1: {
    marginBottom: 0,
    flexShrink: 1,
    flexGrow: 1,
  },
  ssn2: {
    marginBottom: '0px',
    marginRight: '14px',
    width: '48px',
  },
  bar: {
    flexShrink: 0,
    borderBottom: '1px solid #E9EBF1',
    margin: '12px',
    width: '18px',
  },
  star: {
    marginTop: '10px/',
    fontFamily: spoqa,
    fontSize: '24px/',
    letterSpacing: '6px',
    color: '#838A9A',
  },
  captcha: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '20px',
  },
  image: {
    marginRight: '30px',
    width: '161px/',
    height: '67px/',
    border: '1px solid black',
    borderRadius: '10px',
  },
  captchaInput: {
    height: '67px/',
    flexGrow: 1,
    marginBottom: 0,
  },
  space: {
    flexGrow: 1,
    flexShrink: 1,
  },
  smsButton: {
    height: '50px',
    width: '100%',
    borderRadius: '10px',
    backgroundColor: '#434343',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    fontSize: '20px',

    marginBottom: '20px',
  },
  button: {
    width: '100%',
    height: '62px',
    borderRadius: '10px',
    backgroundColor: '#0094FF',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    fontSize: '17px',

    // marginBottom: '20px',
  }
}, true, false, { isNoRatio: true });
