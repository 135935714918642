import React, { useEffect, useState } from 'react';
import { css } from 'aphrodite';
import { atom, useRecoilState } from 'recoil';

import { CreateBetaStyle } from '../../../../../shared/presentation/shared/CreateStyle';
import { RequestColor } from '../../../assets/ColorContents';

interface DropDownOptions {
  isShow: boolean,
  options?: string[],
  value?: string,
  onChange?: (value: string) => void,
}

export const dropDownOptionsState = atom<DropDownOptions>({
  key: 'dropDownOptionsState',
  default: {
    isShow: false,
    options: [],
    value: '',
  },
})

export const DropDownOptionsModalView: React.FC = () => {
  const [{ isShow, options = [], onChange, value = '' }, setDropDown] = useRecoilState(dropDownOptionsState);
  const [innerHeight, setInnerHeight] = useState(window.innerHeight);
  useEffect(() => {
    const interval = setInterval(() => setInnerHeight(window.innerHeight), 100);
    return () => clearInterval(interval);
  }, [])

  useEffect(() => {
    document.body.style.overflow = isShow ? 'hidden' : 'auto';
    return () => { document.body.style.overflow = 'auto' };
  }, [isShow])

  return <>
    {isShow && (
      <div className={css(styles.wrapper)} style={{ height: innerHeight }}>
        <div className={css(styles.box)}>
          {options.map((item, index) =>
            <div
              key={'option-' + index}
              className={css(styles.list, item === value && styles.list__selected)}
              onClick={() => {
                onChange?.(item);
                setDropDown({ isShow: false });
              }}
            >
              {item}
            </div>
          )}
          <div
            className={css(styles.list__cancel)}
            onClick={() => setDropDown({ isShow: false })}
          >
            취소
          </div>
        </div>
      </div>
    )}
  </>
}

const styles = CreateBetaStyle({
  wrapper: {
    position_: 'fixed',
    top: 0,
    left: 0,

    width: '100vw',

    display: 'flex',
    justifyContent: 'center',
    touchAction: 'none',
    userSelect: 'none',

    backgroundColor: 'rgba(7, 7, 8, 0.5)',

    zIndex: 500,
  },
  box: {
    width: '100vw',
    maxWidth: '640px',
    height: '448px',
    backgroundColor: 'white',

    border: '2px solid #E9EBF1',
    borderTopRightRadius: '30px',
    borderTopLeftRadius: '30px',
    borderBottomRightRadius: '/10px',
    borderBottomLeftRadius: '/10px',

    position: 'absolute',
    bottom: 0,

    zIndex: 15,

    padding: '32px 34px 15px/32px 0 15px',
  },
  box_header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    marginBottom: '21px',
  },
  list: {
    width: '100%',
    height: '56px',

    fontSize: '16px',
    color: RequestColor.GRAY_100,

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    cursor: 'pointer',
  },
  list__selected: {
    // color: RequestColor.BLUE_100,
  },
  list__cancel: {
    width: '100%',

    display: 'flex',
    justifyContent: 'center',

    fontSize: '18px',
    color: RequestColor.GRAY_60,
    fontWeight: 700,

    marginTop: '23px',
  },
})
