import * as _ from 'lodash';
import { useCookies } from 'react-cookie';
import { COOKIE_MAX_AGE } from '../pages/SharedConstants';

const COOKIE_LIST = ['hometaxId'];

export const useHometaxState = (): [HometaxForm, (props: Partial<HometaxForm>) => void, () => void] => {
  const [cookie, setCookie, resetCookie] = useCookies(COOKIE_LIST);
  const form = _.chain(COOKIE_LIST)
    .map(v => ({
      key: v,
      value: cookie[v],
    }))
    .filter(v => v.value != null)
    .mapKeys(v => v.key)
    .mapValues(v => v.value)
    .value();
  return [
    new HometaxForm(form),
    (props: Partial<HometaxForm>) => _.forEach(props, (value, key) => setCookie(key, value, { path: '/', maxAge: COOKIE_MAX_AGE })),
    () => {
      COOKIE_LIST.forEach(v => resetCookie(v, { path: '/' }))
    },
  ];
};

export const useHometaxValue = (): HometaxForm => {
  const [cookie] = useCookies(COOKIE_LIST);
  const form = _.chain(COOKIE_LIST)
    .map(v => ({
      key: v,
      value: cookie[v],
    }))
    .filter(v => v.value != null)
    .mapKeys(v => v.key)
    .mapValues(v => v.value)
    .value();
  return new HometaxForm(form);
}

export class HometaxForm {
  hometaxId = '';

  constructor(props: Partial<HometaxForm>) {
    Object.assign(this, props);
  }

  get isIdValid(): boolean {
    return this.hometaxId.length > 0;
  }
}

