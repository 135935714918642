import { CreateStyle } from '../../../shared/presentation/shared/CreateStyle';
import { spoqa } from '../../../shared/css/css-utils';

export const SharedRequestStyle = (
  isMobile: boolean,
  isTablet?: boolean,
  naviHeight?: number,
) => CreateStyle({
  background: {
    minHeight: (isMobile || isTablet) ? naviHeight ? `${naviHeight < 500 ? 600 : naviHeight}px` : '100vh' : '100vh',
    height: (isMobile || isTablet) ? `${naviHeight}px` : naviHeight ? '100vh' : '',
    backgroundColor: '#fafafa',

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    paddingTop: '74px/166px@145px',
    paddingBottom: '41px/105px@78px',

    color: '#34373A',
    fontFamily: spoqa,

    position: 'relative',
  },
  flex_background: {
    minHeight: (isMobile || isTablet) ? `${window.innerHeight}px` : '100vh',
    height: (isMobile || isTablet) ? `${window.innerHeight}px` : '100vh',
    backgroundColor: '#fafafa',

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '74px/166px@145px',
    paddingBottom: '41px/105px@78px',

    color: '#34373A',

    position: 'relative',
  },
  section_frame: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  kakao_image: {
    width: '25px/40px@41px',
    height: '24px/37px@38px',

    marginRight: '8px/14px@9px',

    fill: '#2E6AF4',
  },
  title: {
    fontWeight: 300,
    fontSize: '19px/35px@30px',
    lineHeight: isMobile ? 1.6 : 1.57,
    color: '#006AFF',

    marginBottom: '10px/10px@13px',

    display: 'flex',
    alignItems: 'center',
  },
  timer_box: {
    width: '71px/120px@120px',
    height: '29px/50px@50px',

    backgroundColor: 'rgba(46, 106, 244, 0.06)',

    borderRadius: '4px/6px@7px',

    fontWeight: 500,
    fontSize: '13px/22px@22px',
    letterSpacing: '/0.64px@1px',
    color: '#006AFF',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  timer__red: {
    backgroundColor: 'rgba(255, 0, 0, 0.04)',
    color: 'rgb(255,0,0)',
  },
  timer__split: {
    fontSize: '8px/13px@14px',

    marginLeft: '5px/8px@10px',
    marginRight: '5px/8px@10px',
  },
  info: {
    fontWeight: 300,
    fontSize: '12px/16px@17px',
    lineHeight: '20px/28px@32px',

    textAlign: 'center',

    marginBottom: '27px/53px@40px',
    whiteSpace: 'pre',
  },
  info__bold: {
    fontSize: '11px/16px',
    fontWeight: 700,
  },
  fail_style: {
    width: '47px/59px@60px',
    height: '47px/59px@60px',
  },
  loading_box: {
    width: '47px/65px@65px',
    height: '47px/65px@65px',

    position: 'relative',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loading_style: {
    width: '47px/65px@65px',
    height: '47px/65px@65px',

    position: 'absolute',
    top: 0,
    left: 0,
  },
  loading_style__text: {
    fontWeight: 500,
    color: '#006AFF',
    fontSize: '11px/15px@15px',
  },
  input_box: {
    width: '279px/820px@447px',

    paddingTop: '23px/40px@37px',
    paddingLeft: '22px/51px@40px',
    paddingBottom: '33px/55px@50px',

    marginBottom: '15px/28px@24px',

    backgroundColor: 'white',

    fontWeight: 300,
    fontSize: '11px/16px@17px',

    boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.08)',

    position: 'relative',
  },
  input_box__padding: {
    paddingTop: '13px/31px@18px',
    paddingBottom: '23px/42px@39px',
  },
  input__data_view: {
    width: '65px/105px@104px',
    height: '22px/29px@35px',

    minHeight: '/20px',
    minWidth: '/80px',

    backgroundColor: 'rgba(227, 239, 255, 0.8)',

    borderRadius: '16.5px/14.5px@26px',

    fontWeight: 500,
    fontSize: '9px/11px',
    color: '#2e6af4',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    position: 'absolute',
    right: '19px/53px@30px',
    top: '21px/43px@35px',

    paddingBottom: '/@1px',

    cursor: 'pointer',

    whiteSpace: 'pre',

    ':hover': {
      backgroundColor: 'rgba(206, 227, 255, 0.8)',
      transition: '0.3s',
    }
  },
  input__data_view__arrow: {
    width: '6px/6px@8px',
    height: '9px/11px@14px',

    marginLeft: '4px/3px@5px',
    marginTop: '/1px@3px',
  },
  input__padding_bottom: {
    paddingBottom: '/46px',
  },
  input_style: {
    height: '/30px@30px',
    width: '165px/',

    fontFamily: 'spoqa',
    fontWeight: 300,
    fontSize: '11px/16px@17px',

    ':focus::placeholder': {
      color: 'transparent',
    },
    '::placeholder': {
      color: '#B3B3B3',
    }
  },
  input__background_color: {
    backgroundColor: 'rgba(0,0,0,0.2)',
  },
  input__title_box: {
    display: 'flex',
    alignItems: 'center',
  },
  input__title: {
    fontSize: '15px/22px@25px',
    color: '#006AFF',
  },
  input__title__explanation: {
    fontSize: '/15px',
    color: 'rgba(0, 106, 255, 0.5)',

    marginLeft: '/112px',
  },
  input__title__info: {
    marginLeft: '120px/221px@172px',

    color: '#a6a6a6',
  },
  input__content: {
    lineHeight: 1.75,

    whiteSpace: 'pre',
  },
  input__sub_content: {
    width: '215px/',

    lineHeight: (isMobile || isTablet) ? 1.77 : undefined,
    fontSize: '10px/13px@16px',
    opacity: 0.5,

    whiteSpace: 'pre',
  },
  input__check_box: {
    width: '/600px',
  },
  input__file_upload_box: {
    width: '234px/715px@375px',
    height: '120px/152px@192px',

    border: 'dashed 1px #d1d1d1',

    paddingTop: '19px/25px@33px',

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  input__file__image: {
    width: '21px/30px@30px',
    height: '18px/26px@26px',

    marginBottom: '10px/10px@12px',
  },
  input__file__comment: {
    fontWeight: 400,
    fontSize: '10px/14px',
    color: '#8c8c8c',
    letterSpacing: '-0.29px/-0.4px',

    marginBottom: '12px/12px@20px',
  },
  input__file__upload_button: {
    width: '93px/104px@149px',
    height: '28px/33px@45px',
    backgroundColor: '#006AFF',
    borderRadius: '15px/16px@24px',

    fontWeight: 500,
    fontSize: '10px/12px@16px',
    color: 'white',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    paddingBottom: '/2px@2px',

    cursor: 'pointer',

    ':hover': {
      backgroundColor: '#0043ad',
      transition: '0.3s',
    }
  },
  input__uploaded_file: {},
  input__uploaded_file__list: {
    width: '234px/715px',
    height: '40px/60px',

    border: 'solid 1px #f2f2f2',

    paddingLeft: '7px/16px',

    display: 'flex',
    alignItems: 'center',

    position: 'relative',
  },
  input__uploaded_file__list__border_top: {
    borderTop: 'solid 1px transparent',
  },
  input__uploaded_file__list__image__outline: {
    width: '31px/48px',
    height: '31px/48px',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    marginRight: '7px/20px',
  },
  input__uploaded_file__list__image: {
    width: '30px/40px',
    height: '30px/40px',

    objectFit: 'contain',
  },
  input__uploaded_file__list__image_default: {
    width: '10px/16px',
    height: '13px/20px',

    objectFit: 'contain',
  },
  input__uploaded_file__list__title: {
    width: '150px/',

    fontWeight: 300,
    fontSize: '10px/14px',
    color: 'black',
  },
  input__uploaded_file__list__close_image: {
    width: '10px/15px',
    height: '10px/15px',

    position: 'absolute',
    right: '13px/24px',
    top: '14px/21px',

    opacity: 0.5,

    cursor: 'pointer',
  },
  input__list: {
    width: '231px/715px@370px',
    height: '46px/73px@74px',

    display: 'flex',
    alignItems: 'center',

    borderBottom: 'solid 1px rgba(0,0,0,0.1)',
  },
  input__list__title: {
    width: '82px/158px@100px',
    minWidth: '88px/@60px',

    marginRight: '10px/@30px',

    whiteSpace: 'pre',
  },
  input__select: {
    width: '160px/200px',
    height: '30px/40px',

    display: 'flex',
    alignItems: 'center',

    paddingLeft: '10px/20px',

    border: 'solid 1px rgba(151, 151, 151, 0.3)',

    position: 'relative',

    fontWeight: 300,
    fontSize: '12px/16px',
    color: '#34373A',

    cursor: 'pointer',
  },
  input__select__option: {
    width: '120px/150px',
    height: '100px/200px',
    overflow: 'scroll',

    position: 'absolute',
    top: '35px/45px',
    left: 0,

    zIndex: 105,

    backgroundColor: 'white',
  },
  input__select__option_list: {
    width: '120px/150px',
    height: '25px/40px',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    backgroundColor: 'rgba(0,0,0,0.1)',

    borderBottom: 'solid 1px rgb(255,255,255)',

    cursor: 'pointer',
  },
  request_button: {
    width: '279px/400px@447px',
    height: '42.5px/55px@76px',

    backgroundColor_: '#006AFF/@#006AFF',

    borderRadius_: '5px/5px@9px',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    fontWeight: 500,
    fontSize: '11.5px/16px@21px',
    whiteSpace: 'pre',

    color_: 'white/#006AFF@white',

    border: 'solid 1px #006AFF',

    cursor: 'pointer',

    paddingBottom: '1px/2px@1px',

    ':hover': {
      backgroundColor: '#006AFF',
      color: 'white',
      transition: '0.3s',
    },
  },
  request_button__position_bottom: {
    position_: 'absolute/@absolute',
    bottom: '19px/@65px',
  },
  request_button__no_input: {
    backgroundColor: '#E5E5E5',
    color: '#A5A5A5',
    border: 'solid 1px #E5E5E5',

    ':hover': {
      backgroundColor: '#E5E5E5',
      color: '#A5A5A5',
      border: 'solid 1px #E5E5E5',
    },
  },
  late_night_contact_box: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    marginTop: '22px/',
  },
  late_night_contact_check: {
    display: 'flex',

    alignItems: 'center',

    fontWeight: 500,
    fontSize: '12px/18px@19px',

    marginBottom: '10px/15px@16px',
  },
  late_night_contact_moon: {
    width: '14.76px/21px@23px',
    height: '15.46px/22px@24px',

    marginRight: '6.22px/6px@10px',
  },
  late_night_contact_title: {
    marginRight: '8px/12px@12px',
  },
  late_night_contact_content: {
    fontSize: '11px/15px@17px',
    fontWeight: 300,
    color: '#818181',
    textAlign: 'center',

    lineHeight: '21px/30px@33px',

    marginBottom: '24px/54px@45px',
  },
  hometax_info_title: {
    fontWeight: 300,
    fontSize: '12px/16px@20px',

    marginBottom: '6.5px/12px@9px',
  },
  hometax_login_error: {
    fontWeight: 300,
    fontSize: '11px/13px@17px',
    lineHeight: '18px/',
    color: '#E02020',

    marginTop: '-6px/-10px@-23px',
    marginBottom: '15px/28px@35px',
  },
  hometax_privacy_policy_box: {
    display: 'flex',
    alignItems: 'center',

    marginBottom: '25.7px/30px@45px',
    marginTop: '10.3px/51px@1px',
  },
  hometax_privacy_policy_content: {
    fontWeight: 300,
    fontSize: '11px/16px@17px',
    color: '#34373A',

    paddingBottom: '/3px@2px',
  },
  hometax_privacy_policy_box_link: {
    borderBottom: 'solid 1px #424242',
    paddingBottom: '2px/3px@4px',

    cursor: 'pointer',
  },
  hometax_info_dont_know: {
    fontWeight: 300,
    fontSize: '11px/14px',
    color: '#ABAFB9',

    paddingBottom: '2px/3px@4px',

    marginTop: '23px/30px@35px',

    borderBottom: 'solid 1px rgba(151, 151, 151, 0.7)',

    cursor: 'pointer',
  },
  code_style: {
    display: 'flex',
    alignItems: 'center',

    width: '232px/715px',
    height: '35px/40px',

    border: 'solid 1px rgba(151, 151, 151, 0.3)',
    backgroundColor: 'rgba(151, 151, 151, 0.2)',

    paddingLeft: '8px/12px',

    borderRadius: '0px',
  },
}, isMobile, isTablet, {mobileWidth: 320, isNoRatio: !isMobile });
