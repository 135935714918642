import React from 'react';
import { css } from 'aphrodite';
import { useRecoilValue } from 'recoil';

import { CreateStyle } from '../../../../shared/presentation/shared/CreateStyle';
import { spoqa } from '../../../../shared/css/css-utils';
import BackgroundImage from '../../../assets/images/Inquiry_background.jpg';
import { Image } from '../../../../shared/css/Image';
import { totalScreenState } from '../../../../shared/presentation/MobileFrame';
import { useInjection } from '../../../../shared/presentation/InversifyProvider';
import { LOCATION_SERVICE, LocationService } from '../../../../shared/store/LocationStore';

function DiscountSection() {
  const { isMobile, isTablet } = useRecoilValue(totalScreenState);
  const locationService = useInjection<LocationService>(LOCATION_SERVICE);

  const showRequestOrCall = () => {
    if (isMobile) {
      locationService.callToService();
      return;
    }
  };

  const styles = DiscountStyle(isMobile, isTablet);
  return (
    <div className={css(styles.background)}>
      <Image src={BackgroundImage} alt={'background'} className={css(styles.background_image)}/>
      <div className={css(styles.title)}>
        단체 할인! 뭉치면 더 싸다!<br/>
        2인이상 함께 신청시 모두 {(isMobile || isTablet) && <br/>}
        10,000씩 할인
      </div>
      <div className={css(styles.second_title)}>
        단체 할인은 전화로 신청해주세요. {(isTablet || isMobile) && <br/>}
        이미 신청하신 분도 추천해 주시면 할인적용 가능합니다.
      </div>
      {/*<div className={css(styles.inquiry)} onClick={this.showRequestOrCall}>*/}
      {/*  단체문의 : {process.env.REACT_APP_SERVICE_CENTER_NUMBER}*/}
      {/*</div>*/}
    </div>
  );
}

const DiscountStyle = (isMobile: boolean, isTablet: boolean) => CreateStyle({
  background: {
    height: '400px/650px@864px',

    position: 'relative',

    color: 'white',
    fontFamily: spoqa,

    paddingTop: '35px/133px@84px',
    paddingLeft: '20px/335px@83px',

    backgroundColor_: 'rgba(0,0,0,0.1)/@rgba(0,0)',
  },
  background_image: {
    position: 'absolute',
    width: '100%',
    height: '100%',

    top: 0,
    left: 0,
    zIndex: -2,

    objectFit: 'cover',
    objectPosition_: '40% 30%/@40% 30%',
  },
  title: {
    lineHeight: '1.5/1.4@1.4',
    fontSize: '20px/35px@43px',
    fontWeight: 300,

    marginBottom: '10px/12px@22px',
  },
  second_title: {
    lineHeight: 1.85,
    fontSize: '11px/16px@23px',
    fontWeight: 300,

    marginBottom: '20px/156px',
  },
  inquiry: {
    width: '280px/@602px',
    height: '42px/90px',

    border_: 'solid 1px white/',

    display_: 'flex/',
    justifyContent_: 'center/',
    alignItems_: 'center/',

    lineHeight: 2.36,
    fontSize: '/22px@30px',
    fontWeight: 300,
  },
}, isMobile, isTablet);

export default DiscountSection;
