import React from 'react';
import { css } from 'aphrodite/no-important';

import { CreateBetaStyle } from '../../../shared/presentation/shared/CreateStyle';
import { RequestColor } from '../assets/ColorContents';

interface Props {
  children: any,
  className?: string,
}

export const RequestWrapper: React.FC<Props> = (
  {
    children,
    className,
  }) => {
  return <div className={[css(styles.wrapper), className].join(' ')}>
    {children}
  </div>;
};

const styles = CreateBetaStyle({
  wrapper: {
    position: 'relative',
    margin: '0 auto',
    maxWidth: 640,
    minWidth: 375,
    padding: '0px 24px',
    overflow: 'hidden',
    transform: 'translateX(calc(50vw - 50%))/none@none',

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    backgroundColor: RequestColor.WHITE,
    borderRadius: '0/30px@0',

    ':nth-child(2)': {
      borderRadius: '0/0 0 30px 30px@0'
    }
  },
});
