import React from 'react';
import { css } from 'aphrodite/no-important';

import { CreateStyle } from '../../../shared/presentation/shared/CreateStyle';
import { spoqa } from '../../../shared/css/css-utils';
import { IResizeFrame, withResizeFrame } from '../../../shared/WithResizeFrame';

function TaxReturnRequestHeader(props: IResizeFrame) {
  const styles = Style(props);
  const goToMain = () => {
    window.open('https://the-refunds.ubpages.com/main/', '_self')
  }
  return (
    <div className={css(styles.frame)}>
      <div
        className={css(styles.frame__title)}
        onClick={() => goToMain()}
      >
        더 환급
      </div>
    </div>
  )
}

const Style = ({ isMobile, isTablet }: IResizeFrame) => CreateStyle({
  frame: {
    width: '100%',
    height: '50px/100px',
    backgroundColor: 'white',

    display: 'flex',
    alignItems: 'center',

    paddingLeft: '28px',

    position: 'fixed',
    top: 0,
    zIndex: 100,

    borderBottom: 'solid 1px #D8D8D8',
  },
  frame__title: {
    fontFamily: spoqa,
    fontWeight: 700,
    fontSize: '15px/30px',
    color: '#006aff',
  },
}, isMobile, isTablet, { mobileWidth: 320, isNoRatio: !isMobile });

export default withResizeFrame(TaxReturnRequestHeader);
