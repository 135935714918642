import React, { useEffect, useState } from 'react';
import { atom, useRecoilState, useRecoilValue } from 'recoil';
import { css } from 'aphrodite';

import { CreateStyle } from '../../../shared/presentation/shared/CreateStyle';
import Close from '../../../shared/presentation/icons/close.svg';
import { Image } from '../../../shared/css/Image';
import { spoqa } from '../../../shared/css/css-utils';
import { countDeadline, getDeadLine } from './DeadlinePolicy';
import { isMobileState } from '../../../shared/presentation/MobileFrame';

export const isPeriodPopUpState = atom<boolean>({
  key: 'isPeriodPopUpState',
  default: true,
});

function PeriodPopUp() {
  const isMobile = useRecoilValue(isMobileState);
  const [isPeriodPopUp, setIsPeriodPopUp] = useRecoilState(isPeriodPopUpState);
  const [deadLineTime, setDeadLineTime] = useState(getDeadLine());

  useEffect(() => {
    const handle = setInterval(() => setDeadLineTime(getDeadLine()), 500);
    return () => clearInterval(handle);
  }, []);

  const styles = PeriodStyle(isMobile);
  const date = new Date();

  return (
    <>
      {isPeriodPopUp &&
      <div className={css(styles.background)}>
        <div className={css(styles.title_text, styles.margin_right)}>
          종합소득세 신고: <span className={css(styles.day_text)}>{date.getFullYear()}년 5월 31일까지</span>
        </div>
        <div className={css(styles.title_text)}>
          D-DAY {(countDeadline().day < 0) ? '0' : countDeadline().day}일 &nbsp;
          <span className={css(styles.day_text)}>
            {deadLineTime}
          </span>
        </div>
        <Image
          src={Close}
          alt={'close'}
          className={css(styles.close_icon)}
          onClick={() => setIsPeriodPopUp(false)}
        />
      </div>}
    </>
  );
}

const PeriodStyle = (isMobile: boolean) => CreateStyle({
  background: {
    width: '100%',
    height: '/49px',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    backgroundColor: process.env.REACT_APP_BASE_COLOR,

    color: 'white',
    fontFamily: spoqa,

    position_: '/relative',

    zIndex: 99,

    position: 'absolute',
    top: 0,
  },
  title_text: {
    fontWeight: 500,
    fontSize: '/16px',
  },
  margin_right: {
    marginRight: '/13px'
  },
  day_text: {
    fontWeight: 300,
    fontSize: '/16px',
  },
  close_icon: {
    width: '/14px',
    height: '/14px',

    position_: '/absolute',
    right: '/40px',
    top: '/19px',

    cursor: 'pointer',
  },
}, isMobile);

export default PeriodPopUp;
