import React, { useMemo } from 'react';
import { css } from 'aphrodite';

import { RequestButton } from './RequestButton';
import { CreateBetaStyle } from '../../../shared/presentation/shared/CreateStyle';

interface Props {
  id?: string;
  onClick?: () => void;
  isActive?: boolean;
  backgroundColor?: string;
  color?: string;
  title?: string;
  className?: string;
  isLoading?: boolean;
}

export const RequestFloatingButton: React.FC<Props> = (props) => {
  const styles = useMemo(() => CreateBetaStyle({
    floating: {
      width: '100%',
      position: 'fixed',
      left: 0,
      bottom: 0,
      padding: '28px 24px',
      background: 'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 28.13%)',

      display: 'flex',
      // alignItems: 'center',
      justifyContent: 'center',
    },
    button: {
      marginBottom: 0,
    }
  }), [])

  return <div className={css(styles.floating)}>
    <RequestButton {...props} className={css(styles.button)} />
  </div>;
}

