import React from 'react';
import { css } from 'aphrodite';
import { useRecoilValue } from 'recoil';
import { Link } from 'react-router-dom';

import { HeaderButtonStyles } from './HeaderButtonStyle';

import { ReactComponent as MenuIcon } from '../icons/menu.svg';
import { HeaderButtonStyleForRequestPage } from './HeaderButtonStyleForRequestPage';
import { totalScreenState } from '../MobileFrame';
import { menuPage } from '../HistoryHelper';

interface Props {
  baseColor?: string
  isRequestPage?: boolean,
  tabletHeight?: number,
}

export function MenuButton({baseColor, isRequestPage, tabletHeight}: Props) {
  const {isMobile, isTablet, isTop} = useRecoilValue(totalScreenState);

  const styles = isRequestPage
    ? HeaderButtonStyleForRequestPage({
      isMobile,
      isTop: isTop!,
      baseColor: baseColor ?? 'black',
      isRequestPage: isRequestPage,
      isTablet,
      tabletHeight,
    })
    : HeaderButtonStyles({
      isMobile,
      isTop: isTop!,
      baseColor: baseColor ?? 'black',
      isTablet,
      tabletHeight,
    });

  return (
    <>
      {(isTablet || isMobile) && (
        <Link
          className={css(styles.button)}
          to={menuPage}
        >
          <MenuIcon className={css(styles.buttonIcon)}/>
        </Link>
      )}
    </>
  );
}
