import React from 'react';
import { css } from 'aphrodite';

import { MetaTaxInput } from './MetaTaxInput';
import { CreateStyle } from '../../../../shared/presentation/shared/CreateStyle';
import { spoqa } from '../../../../shared/css/css-utils';

interface Props {
  onChangeSsn1: (value: string) => void,
  onChangeSsn2: (value: string) => void,
}

export const MetaTaxSsnInput: React.FC<Props> = ({ onChangeSsn1, onChangeSsn2 }) => {
  return <div className={css(styles.ssn)}>
    <MetaTaxInput
      placeholder={'주민번호 앞자리'}
      onChange={onChangeSsn1}
      className={css(styles.ssn1)}
    />
    <div className={css(styles.bar)}/>
    <div className={css(styles.other)}>
      <MetaTaxInput
        onChange={onChangeSsn2}
        className={css(styles.ssn2)}
      />
      <p className={css(styles.star)}>******</p>
    </div>
  </div>
}

const styles = CreateStyle({
  ssn: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    // justifyContent: 'space-between',
    marginBottom: '15px',
  },
  ssn1: {
    marginBottom: 0,
    flexShrink: 1,
    flexGrow: 1,
  },
  ssn2: {
    marginBottom: '0px',
    marginRight: '14px',
    width: '48px',
  },
  bar: {
    flexShrink: 0,
    borderBottom: '1px solid #E9EBF1',
    margin: '12px',
    width: '18px',
  },
  star: {
    marginTop: '10px/',
    fontFamily: spoqa,
    fontSize: '24px/',
    letterSpacing: '6px',
    color: '#838A9A',
  },
  other: {
    width: '100%',
    flexShrink: 1,
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
  }
}, true, false, { isNoRatio: true });
