import React from 'react';
import { css } from 'aphrodite';

import { CreateBetaStyle } from '../../../../shared/presentation/shared/CreateStyle';
import { RequestColor } from '../../assets/ColorContents';

interface IncomeProps {
  incomes: {
    company: string;
    price: number;
  }[];
  total: number;
  description?: string;
}

export function IncomeList({ incomes, description, total }: IncomeProps) {
  return (
    <div>
      <div className={css(styles.table)}>
        <div className={css(styles.table__top)}>
          {incomes.map((item, index) =>
            <div
              key={'income_' + index}
              className={css(styles.table__top_list)}
            >
              <p>{item.company}</p>
              <p>{item.price.toLocaleString()}원</p>
            </div>
          )}
        </div>
        <div className={css(styles.table__bottom)}>
          <p>소득 합계</p>
          <p>{total.toLocaleString()}원</p>
        </div>
      </div>
      <p className={css(styles.description)}>
        {description}
      </p>
    </div>
  );
}

const styles = CreateBetaStyle({
  table: {
    width: '100%',
    height: 'auto',

    backgroundColor: RequestColor.GRAY_10,

    borderRadius: '5px',
    border: ['solid', '1px', '#EDEFF4'].join(' '),

    marginBottom: '16px',
  },
  table__top_list: {
    display: 'flex',
    justifyContent: 'space-between',

    padding: '8px 0px',

    fontSize: '16px',
    color: RequestColor.GRAY_60,
  },
  table__top: {
    width: '100%',

    padding: '12px 5% 9px',

    borderBottom: '1px solid #DFE2EA',
  },
  table__top__company: {
    fontSize: '16px',
    color: RequestColor.GRAY_60,
  },
  table__bottom: {
    padding: '19px 5% 20px',

    display: 'flex',
    justifyContent: 'space-between',

    fontSize: '16px',
    color: RequestColor.BLUE,
    fontWeight: 500,
  },
  description: {
    fontSize: '14px',
    lineHeight: '24px',
    color: RequestColor.GRAY_60,
    letterSpacing: '-0.2px',

    wordBreak: 'keep-all',
  },
});