import React from 'react';
import { CreateStyle } from '../../../../shared/presentation/shared/CreateStyle';
import { ReactComponent as Logo } from './logo.svg';
import { css } from 'aphrodite';

export const MetaTaxHeader: React.FC = () => {
  return <div className={css(styles.wrapper)}>
    <Logo className={css(styles.logo)}/>
  </div>;
}

const styles = CreateStyle({
  wrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '68px',
    padding: '30px',
    // display: 'flex',
    // flexDirection: 'column',
  },
  logo: {
    alignSelf: 'flex-start',
    marginBottom: '15px',
  },
}, true);
