import React from 'react';
import { css } from 'aphrodite';
import { useRecoilValue } from 'recoil';

import { CreateStyle } from '../../../../shared/presentation/shared/CreateStyle';
import { spoqa } from '../../../../shared/css/css-utils';
import { PROCESS_LIST } from '../../shared/Constant';
import { totalScreenState } from '../../../../shared/presentation/MobileFrame';

function ProcessSection() {
  const { isMobile, isTablet } = useRecoilValue(totalScreenState);

  const styles = MainStyle(isMobile, isTablet);
  return (
    <div className={css(styles.background)}>
      <div className={css(styles.title)}>
        간편한 신고대행 <span className={css(styles.blue_color)}>업무 프로세스</span>
      </div>
      <div className={css(styles.process_div)}>
        {PROCESS_LIST(isMobile, isTablet).map((item, index) =>
          <div key={'process-' + index}>
            <div className={css(styles.process_list)}>
              <div className={css(styles.process__step_box)}>
                <div className={css(styles.process__step)}>
                  {(!isMobile && !isTablet) && item.step}
                </div>
                {!item.isLast && <div className={css(styles.process__border)}/>}
              </div>
              <div>
                <div className={css(styles.process__title)}>
                  {(isMobile || isTablet) && item.step + ' : '}{item.title}
                </div>
                <div className={css(styles.process__content)}>{item.content}</div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

const MainStyle = (isMobile: boolean, isTablet: boolean) => CreateStyle({
  background: {
    height: '355px/387px@795px',

    paddingTop: '35px/76px@83px',
    paddingLeft: '/335px',

    display_: 'flex/@flex',
    flexDirection_: 'column/@column',
    alignItems_: 'center/@center',

    fontFamily: spoqa,
  },
  title: {
    fontSize: '20px/35px@43px',
    lineHeight: 1.4,
    fontWeight: 300,
    color: '#333333',

    marginBottom: '27px/53px@53px',
  },
  blue_color: {
    color: process.env.REACT_APP_BASE_COLOR,
  },
  process_div: {
    display_: '/flex',
  },
  process_list: {
    width: '/318px',

    display_: 'flex/@flex',

    position: 'relative',
  },
  process__border: {
    height: '48px/@113px',
    width: '/255px@',

    transform_: 'translateX(30%)/translateY(45%)@translateX(22%)',

    borderTop_: '/2px solid rgba(46,106,244,0.3)',
    borderLeft_: '7px solid rgba(166, 166, 166, 0.3)/@15px solid rgba(166, 166, 166, 0.3)',
  },
  process__step_box: {
    display_: '/flex',

    marginBottom: '/19px',
    marginRight: '16px/@45px',
  },
  process__step: {
    width: '13px/82px@27px',
    height: '13px/30px@27px',

    fontSize: '/13px',
    fontWeight: 500,
    lineHeight: 2.15,
    color: 'white',

    paddingLeft: '/20px',
    paddingTop: '/1px',

    backgroundColor: process.env.REACT_APP_BASE_COLOR,

    borderRadius: '15px/15px@33px',
  },
  process__title: {
    fontSize: '15px/22px@33px',
    fontWeight: 300,
    lineHeight: isMobile ? undefined : 1.64,
    color: process.env.REACT_APP_BASE_COLOR,

    marginBottom: '5px/2px',
    marginTop: '-3px/@-12px',
  },
  process__content: {
    fontSize: '11px/16px@23px',
    fontWeight: 300,
    lineHeight: 1.75,
  },
}, isMobile, isTablet);

export default ProcessSection;
