import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';

import { useHometaxCookieValue } from './HometaxCookieState';

interface IsSessionValidForm {
  loading: boolean,
  isSessionValid: boolean | null,
  error: boolean
}

export const useIsHometaxSessionValid = (time = new Date().getMinutes()): IsSessionValidForm => {
  const cookie = useHometaxCookieValue();
  const [state, dispatch] = useState<IsSessionValidForm>({
    loading: true,
    isSessionValid: null,
    error: false,
  });

  const fetchData = useCallback(() => {
    dispatch({
      loading: true,
      isSessionValid: null,
      error: false,
    });
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + '/total-income-tax/permission',
        {cookie},
      )
      .then(({data}) => {
        dispatch({
          loading: false,
          isSessionValid: data.status === 200,
          error: false,
        });
      })
      .catch(e => {
        dispatch({
          loading: false,
          isSessionValid: false,
          error: true,
        });
      });
  }, [fetch, cookie]);

  useEffect(() => {
    fetchData();
  }, [time]);

  return state;
};
