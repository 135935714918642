import { CreateStyle } from '../../../../shared/presentation/shared/CreateStyle';
import { spoqa } from '../../../../shared/css/css-utils';

export const ReviewStyle = (isMobile: boolean, isTablet: boolean) => CreateStyle({
  background: {
    width: '100%',
    height: '548px/519px@1173px',

    position: 'relative',

    backgroundColor: '#fafafa',

    fontFamily: spoqa,
  },
  review_section: {
    width: '100%',
    height: '548px/519px@1173px',

    paddingTop: '38px/115px@79px',
    paddingLeft: '20px/335px@87px',

    position: 'relative',
  },
  review_title: {
    fontWeight: 300,
    fontSize: '20px/35px@43px',
    lineHeight: 1.4,

    whiteSpace: 'pre',

    marginBottom: '9px/11px@15px',
  },
  review_content: {
    width: '280px/560px',

    fontWeight: 300,
    fontSize: '11px/16px@23px',
    lineHeight: isMobile ? 1.73 : 1.75,

    marginBottom: '7px/9px@9px',

    whiteSpace: 'pre',
  },
  review_info: {
    fontWeight: 300,
    fontSize: '11px/16px@23px',
    color: '#a6a6a6',
  },
  review_image: {
    width: '95%/450px@80%',
    height: '295px/',

    objectFit_: 'contain/cover@contain',

    position: 'absolute',
    bottom: 0,

    right: '0px/450px',
  },
  dot_box: {
    position: 'absolute',
    bottom: '24px/86px@52px',
    left: '130px/345px@322px',

    display: 'flex',

    zIndex: 10,
  },
  dot: {
    width: '6px/9px@13px',
    height: '6px/9px@13px',
    border_: 'solid 1px #fff/solid 1px #2e6af4@solid 1px #fff',
    borderRadius: '50%',

    marginRight: '20px/18px@46px',

    cursor: 'pointer',
  },
  dot_fill: {
    backgroundColor_: `#fff/${process.env.REACT_APP_BASE_COLOR}@#fff`,
  },
  next_button: {
    width: '/40px',
    height: '/40px',
    position: 'absolute',
    bottom: '/51px',
    right: '/320px',

    cursor: 'pointer',
  },
  blue_text: {
    color: process.env.REACT_APP_BASE_COLOR,
  },
}, isMobile, isTablet);