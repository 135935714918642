import axios from 'axios';

interface LoginInput {
  name: string;
  phone: string;
  socialSecurityNo: string;
  isProxy: boolean;
  keyword?: string;
}

export class KakaoSimpleAuthorizer {
  static async request(options: LoginInput): Promise<{ cert: Record<string, string>, customerId: string }> {
    const { data: { cert, customerId } } = await axios.post(
      'https://api.ailabscorp.com/total-income-tax/kakao-cert/request',
      options,
    );
    return { cert, customerId };
  }

  static async finish(cert: Record<string, string>): Promise<Record<string, string>> {
    const { data: cookie } = await axios.post(
      'https://api.ailabscorp.com/total-income-tax/kakao-cert/finish',
      { ...cert },
    );
    return cookie;
  }

  static async saveUser(cookie: Record<string, string>, user: LoginInput & { customerCode?: string }): Promise<string> {
    const customerId = await this.registerUser(cookie, user);
    await axios.post('https://api.ailabscorp.com/total-income/isProxy', {
      data: {
        id: customerId,
        isProxy: user.isProxy,
      }
    });
    await axios.post('https://api.ailabscorp.com/total-income/funnel', {
      data: {
        id: customerId,
        site: process.env.REACT_APP_AIRTABLE_SITE_TYPE,
        funnel: '홈페이지',
      }
    });
    await axios.post('https://api.ailabscorp.com/total-income/isCertified', {
      data: {
        id: customerId,
        isCertified: true,
      }
    });
    await axios.post('https://api.ailabscorp.com/total-income/request-history',
      {
        customerId
      });
    return customerId;
  }

  private static async registerUser(cookie: Record<string, string>, user: LoginInput & { customerCode?: string }): Promise<string> {
    if (user.customerCode != null) {
      const { data: customerId } = await axios.post(
        'https://api.ailabscorp.com/total-income-tax/old-user',
        { cookie, ...user },
      );
      return customerId;
    }
    const { data: customerId } = await axios.post(
      'https://api.ailabscorp.com/total-income-tax/user',
      { cookie, ...user },
    );
    return customerId;
  }

  static async saveNoCertUser(user: LoginInput): Promise<string> {
    const { data: customerId } = await axios.post(
      'https://api.ailabscorp.com/total-income-tax/no-cert-user',
      { ...user },
    );
    await axios.post('https://api.ailabscorp.com/total-income/isProxy', {
      data: {
        id: customerId,
        isProxy: user.isProxy,
      }
    });
    await axios.post('https://api.ailabscorp.com/total-income/funnel', {
      data: {
        id: customerId,
        site: process.env.REACT_APP_AIRTABLE_SITE_TYPE,
        funnel: '홈페이지',
      }
    });
    await axios.post('https://api.ailabscorp.com/total-income/request-history',
      {
        customerId
      });
    return customerId;
  }
}
