import React from 'react';
import { css } from 'aphrodite';
import { useRecoilValue } from 'recoil';

import { CreateStyle } from '../../../shared/presentation/shared/CreateStyle';
import { totalScreenState } from '../../../shared/presentation/MobileFrame';

interface Props {
  mobileHeight?: number,
  height?: number,
  tabletHeight?: number,
  mobileProps?: { isMobile: boolean, isTablet: boolean }
}

export function CustomerSpace(input: Props) {
  const { height, mobileHeight, tabletHeight, mobileProps } = input;
  const { isMobile, isTablet } = useRecoilValue(totalScreenState);
  const styles = SpaceStyle(
    isMobile,
    isTablet,
    mobileHeight,
    height,
    tabletHeight
  );

  return (
    <div className={css(styles.style)}/>
  );
}

const SpaceStyle = (
  isMobile: boolean,
  isTablet: boolean,
  mobileHeight?: number,
  height?: number,
  tabletHeight?: number,
) => CreateStyle({
  style: {
    height: `${mobileHeight}px/${height}px@${tabletHeight}px`
  },
}, isMobile, isTablet, { mobileWidth: 320 });
