import React, { useEffect, useState } from 'react';
import { css } from 'aphrodite';
import Cookies from 'universal-cookie';
import * as _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { withRouter } from 'react-router';

import { SharedRequestStyle } from './shared/SharedRequestStyle';
import { ReactComponent as Kakao } from '../../shared/assets/svgs/kakao.svg';
import { CustomerSpace } from './shared/CustomSpace';
import { crawlingIncomes } from '../helper/KakaoLoginHelper';
import CertificationIncompletePopup from './popup/CertificationIncompletePopup';
import { IHistory } from '../../shared/presentation/HistoryHelper';
import { isMobileState, isTabletState } from '../../shared/presentation/MobileFrame';
import {
  authIdState,
  TAX_AGENT_REQUEST_SERVICE,
  TaxagentRequestService,
  taxagentRequestState
} from '../store/TaxagentRequestStore';
import { useInjection } from '../../shared/presentation/InversifyProvider';
import { isLoadingState } from '../../shared/presentation/LoadingFrame';

const MINUTE = 60 * 1000;

const TIMER_TIME = 60 * 5 * 1000;

export const setTimer = (cookies: Cookies) => {
  const date = new Date();
  cookies.remove('finish_time');
  cookies.set('finish_time', date.getTime() + TIMER_TIME, { maxAge: TIMER_TIME, path: '/' });
};

export const getTimer = (cookies: Cookies) => {
  return Number(cookies.get('finish_time') ?? '0');
}

function HomeTaxCertificationTimer() {
  const cookies = new Cookies();
  const isMobile = useRecoilValue(isMobileState);
  const isTablet = useRecoilValue(isTabletState);
  const setIsLoading = useSetRecoilState(isLoadingState);
  const [timerMinute, setTimerMinute] = useState(5);
  const [timerSecond, setTimerSecond] = useState(0);
  const [isFailPopup, setIsFailPopup] = useState(false);
  const [isNotRegisteredPopup, setIsNotRegisteredPopup] = useState(false);
  const [isNotAuthorizedYetPopup, setIsNotAuthorizedYetPopup] = useState(false);
  const recordId = cookies.get('customer_record_id');
  const finishTime = 0//Number(cookies.get('finish_time'));
  const history = useHistory<IHistory>();
  const authId = useRecoilValue(authIdState);
  const request = useRecoilValue(taxagentRequestState);
  const requestService = useInjection<TaxagentRequestService>(TAX_AGENT_REQUEST_SERVICE);

  if (authId == null || recordId == null) {
    alert('상담 신청 정보가 만료되었습니다. 다시 신청해주세요.');
    history.replace('/request/0');
  }

  function closeAuthorizeFailPopup() {
    setIsFailPopup(false);
    cookies.remove('finish_time');
    setTimerMinute(0);
    setTimerSecond(0);
  }

  // function closeNotRegistered();
  // {
  //   this.isNotRegistered = false;
  // }

  function isFinish() {
    return timerMinute === 0 && timerSecond === 0;
  }

  function getTimer() {
    if (_.isNaN(finishTime)) {
      setTimerSecond(0);
      setTimerMinute(0);
      return;
    }
    const diff = finishTime - new Date().getTime();
    setTimerMinute(Math.max(0, Math.floor(diff / MINUTE)));
    setTimerSecond(Math.max(0, Math.floor(diff % MINUTE / 1000)));
    if (isFinish()) {
      cookies.remove('finish_time');
    }
  }

  const goToNext = async () => {
    if (isFinish()) {
      history.push('/request/1');
      return;
    }

    if (recordId == null) {
      alert('상담 신청 정보가 만료되었습니다. 다시 신청해주세요.');
      return;
    }

    setIsLoading(true);
    try {
      const result = await crawlingIncomes({
        authId,
        recordId,
      });
      console.log(result);

      switch (result) {
        case 'not authorized yet':
          setIsNotRegisteredPopup(true);
          break;
        case 'authorize failed':
          setIsFailPopup(true);
          break;
        case 'not registered':
          setIsNotRegisteredPopup(true);
          break;
        default:
          await requestService.sendConfirmRequestMessage(recordId, request);
        // this.props.taxagentRequestStore!.openSuccessPopUp();
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  function goToReAuthorize() {
    setIsFailPopup(false);
    history.push('/request/1');
  }

  useEffect(() => {
    getTimer();
    setInterval(() => getTimer(), 500);
  }, []);

  const styles = SharedRequestStyle(isMobile, isTablet, window.innerHeight);
  return (
    <div className={css(styles.background)}>
      {isNotAuthorizedYetPopup && (
        <CertificationIncompletePopup
          title={'간편인증 미완료'}
          content={isTablet
            ? '카카오톡에서 간편인증을 \n완료해주세요.'
            : '카카오톡에서 간편인증을 완료해주세요.'}
          button={'확인'}
          isKakaoLink={true}
          buttonOnClick={() => setIsNotAuthorizedYetPopup(false)}
          closeOnClick={() => setIsNotAuthorizedYetPopup(false)}
        />
      )}
      {isFailPopup && (
        <CertificationIncompletePopup
          title={'인증 실패'}
          content={isMobile
            ? '입력정보 오류로 인증에 실패하였습니다.\n성함과 주민등록번호가 일치하는지 \n확인하여 ' +
            '다시 시도해주세요.'
            : '입력정보 오류로 인증에 실패하였습니다.\n성함과 주민등록번호가 일치하는지 확인하여\n' +
            '다시 시도해주세요.'}
          button={'확인'}
          buttonOnClick={() => goToReAuthorize()}
          closeOnClick={() => closeAuthorizeFailPopup()}
        />
      )}
      {isNotRegisteredPopup && (
        <CertificationIncompletePopup
          title={'미등록 고객'}
          content={'홈택스 등록된 정보가 없어 \n로그인에 실패했습니다. \n홈택스 회원가입 후 다시 시도해주세요.'}
          button={'확인'}
          buttonOnClick={() => goToReAuthorize()}
          closeOnClick={() => setIsNotRegisteredPopup(false)}
          isHomeTaxLink={true}
        />
      )}
      <div className={css(styles.section_frame)}>
        <CustomerSpace height={47} mobileHeight={70} tabletHeight={106}/>
        <div className={css(styles.title)} style={{ whiteSpace: 'pre' }}>
          <Kakao className={css(styles.kakao_image)}/>
          간편인증 진행중
        </div>
        <div className={css(styles.info)}>
          {isFinish()
            ? '인증 유효 시간이 만료되었습니다.\n다시 인증해주세요.'
            : '카카오 안내에 따라 진행 후 \n아래 인증완료 버튼을 눌러주세요.'}
        </div>
        <div className={css(styles.timer_box, isFinish() && styles.timer__red)}>
          {'0' + timerMinute}
          <span className={css(styles.timer__split)}> : </span>
          {timerSecond > 9 ? timerSecond : '0' + timerSecond}
        </div>
        <CustomerSpace height={377}/>
        <div
          className={css(styles.request_button, styles.request_button__position_bottom)}
          onClick={() => goToNext()}
        >
          {isFinish() ? '재인증' : '인증완료'}
        </div>
      </div>
    </div>
  );
}

export default withRouter(HomeTaxCertificationTimer);
