import { useCookies } from 'react-cookie';
import { useCallback } from 'react';

const COOKIE_LIST = ['ads_keyword'];

export const useKeywordState = (): [
  string,
  (props: string) => void,
  any
] => {
  const [cookie, setCookie, resetCookie] = useCookies(COOKIE_LIST);
  const keyword = cookie['ads_keyword'];
  return [
    keyword,
    useCallback((keyword: string) => setCookie('ads_keyword', keyword, { path: '/' }), [setCookie]),
    useCallback(() => resetCookie('ads_keyword', { path: '/' }), [resetCookie]),
  ];
};

export const useKeywordValue = (): string => {
  const [cookie] = useCookies(COOKIE_LIST);
  return cookie['ads_keyword'];
};
