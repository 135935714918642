import React, { useEffect, useState } from 'react';
import { css } from 'aphrodite';
import { useSetRecoilState } from 'recoil';
import { useParams } from 'react-router-dom';
import axios from 'axios';

import { DropDownOptionsModalView } from '../../../request-page-2022/pages/1_간편인증/component/DropDownOptionsModalView';
import { RequestFont } from '../../../request-page-2022/shared/RequestFont';
import { RequestStyle } from '../../../request-page-2022/shared/RequestStyle';
import { FormInputWithLabel } from '../../../request-page-2022/shared-component/input/FormInputWithLabel';
import { FormPhoneInputWithLabel } from '../../../request-page-2022/shared-component/input/FormPhoneInputWithLabel';
import { FormSSNInputWithLabel } from '../../../request-page-2022/shared-component/input/FormSSNInputWithLabel';
import { RequestButtonWrapper } from '../../../request-page-2022/shared-component/RequestButtonWrapper';
import { RequestButton } from '../../../request-page-2022/shared-component/RequestButton';
import { RequestWrapperWithHeader } from '../../../request-page-2022/shared-component/RequestWrapperWithHeader';
import { CreateBetaStyle } from '../../../../shared/presentation/shared/CreateStyle';
import { requestAlertMessageState } from '../../../request-page-2022/shared-component/RequestAlertFrame';
import { PaymentForm, usePaymentFormState } from '../../PaymentContents';
import { useMyHistory } from '../../../../shared/presentation/HistoryHelper';
import { PAYMENT_PATH } from '../PaymentConstant';
import { alertMessageState } from '../../../request-page-2022/shared-component/ShowAlertFrame';
import { isLoadingState } from '../../../../shared/presentation/LoadingFrame';

interface IRefund {
  year: string,
  message: string,
  refund: string | number
}

export const InquireRequest: React.FC = () => {
  const setAlert = useSetRecoilState(alertMessageState);
  const [customer, setCustomer, resetCustomer] = usePaymentFormState();
  const [ssn2, setSsn2] = useState('');
  const [isSsnValid, setIsSsnValid] = useState(true);
  const [isNameValid, setIsNameValid] = useState(true);
  const [isPhoneValid, setIsPhoneValid] = useState(true);
  const setIsLoading = useSetRecoilState(isLoadingState);
  const history = useMyHistory();
  const { recordId } = useParams<any>();

  useEffect(() => {
    // resetCustomer();
    if (recordId == null && customer) {
      return;
    }
    setIsLoading(true);
    axios.get<{ name: string; phone: string; fee: string; status: boolean; refundList: IRefund[]; msg?: string }>(`https://api.ailabscorp.com/total-income-the-refund/fee?recordId=${recordId}`)
      .then(({ data }) => {
        if (data == null) {
          return;
        }
        if (!data.status) {
          if (data.msg === '환급 가능 대상자가 아닙니다.') {
            setAlert({ message: '환급 가능 대상자가 아닙니다.' });
            return;
          }
          setAlert({ message: '결제가 완료된 고객입니다.' });
          history.push(PAYMENT_PATH.step01);
          return;
        }
        setCustomer({
          recordId,
          fee: data.fee,
          name: data.name,
          phone: data.phone,
          refundList: data.refundList.map((v: IRefund) => `${v.year}:${v.message}:${v.refund}`).join('/')
        });
        history.push(PAYMENT_PATH.step02);
      })
      .catch((e) => {
        setAlert({ message: '결제 정보가 유효하지 않습니다.' + e.message });
      })
      .finally(() => setIsLoading(false));
  }, []);

  const findUser = async () => {
    // if (!PaymentForm.isPhoneValid(customer.phone)) {
    //   setIsPhoneValid(false);
    //   return;
    // }
    // if (!SocialSecurityNumberPolicy.isValid(customer.SSN1 + ssn2)) {
    //   setIsSsnValid(false);
    //   return;
    // }
    // if (!PaymentForm.isNameValid(customer.name)) {
    //   setIsNameValid(false);
    //   return;
    // }
    if (!(customer.phone === '010-0000-0000' && customer.name === '김테스트' && customer.SSN1 === '870230' && ssn2 === '1111111')) {
      alert('정확한 정보를 입력해주세요.');
      return;
    }
    // const { data } = await axios.post('https://api.ailabscorp.com/total-income-tax/payment/user',
    //   {
    //     user: {
    //       name: customer.name,
    //       phone: customer.phone.replace(/-/g, ''),
    //       socialSecurityNo: customer.SSN1 + ssn2
    //     }
    //   });
    // if (data == null || data === '') {
    //   console.log('test');
    //   setAlert({
    //     title: '신청정보 조회에 실패했습니다.',
    //     message: '입력하신 정보가 맞는지 확인해주세요.',
    //     button: [
    //       {
    //         title: '홈페이지로 이동',
    //         onClick: () => history.push('/'),
    //       },
    //       {
    //         title: '재입력',
    //       }
    //     ]
    //   });
    //   return;
    // }
    // setCustomer({ recordId: data });
    setCustomer({ fee: '110000' });
    history.push(PAYMENT_PATH.step02);
  };

  return (
    <RequestWrapperWithHeader>
      <DropDownOptionsModalView/>
      <div className={css(styles.wrapper)}>
        <p className={css(RequestFont.TITLE, RequestStyle.ROW_CENTER, styles.title)}>
          신청정보 조회를 위해<br/>
          아래의 정보를 입력해주세요
        </p>
        <p className={css(RequestFont.DESCRIPTION_TITLE)}>
          고객님의 정확한 결제정보 조회를 위해<br/>
          아래 정보를 입력해주세요.
        </p>
        <div className={css(styles.content)}>
          <FormPhoneInputWithLabel
            labelText={'휴대전화 번호'}
            placeholder={'휴대전화 번호'}
            value={customer.phone}
            onChange={e => setCustomer({ phone: e })}
            isInvalid={!isPhoneValid}
            className={css(!isPhoneValid && styles.invalid)}
            onFocus={() => setIsPhoneValid(true)}
          />
          <FormSSNInputWithLabel
            labelText={'주민등록번호'}
            className={css(!isSsnValid && styles.invalid)}
            ssn1Value={customer.SSN1}
            ssn2Value={ssn2}
            onSsn1Change={e => setCustomer({ SSN1: e })}
            onSsn2Change={e => setSsn2(e)}
            onFocus={() => setIsSsnValid(true)}
            isValid={isSsnValid}
          />
          <FormInputWithLabel
            labelText={'이름'}
            placeholder={'이름'}
            value={customer.name}
            onChange={e => setCustomer({ name: e })}
            isValid={isNameValid}
            addStyle={!isNameValid && styles.invalid}
            onFocus={() => setIsNameValid(true)}
          />
        </div>
      </div>
      <RequestButtonWrapper>
        <RequestButton
          onClick={() => findUser()}
        />
      </RequestButtonWrapper>
    </RequestWrapperWithHeader>
  );
};

const styles = CreateBetaStyle({
  wrapper: {
    width: '100%',

    paddingBottom: '100px',
  },
  title: {
    marginBottom: '8px',
  },
  content: {},
  invalid: {
    border: '1px solid red',
  },
});
