import * as _ from 'lodash';
import { useCookies } from 'react-cookie';
import { useCallback } from 'react';

import { useInjection } from '../../shared/presentation/InversifyProvider';
import { COOKIE_MAX_AGE } from '../request-page-2022/pages/SharedConstants';
import { PHONE_FORMAT_POLICY, PhoneFormatPolicy } from '../../shared/domain/PhoneFormatPolicy';
import { SocialSecurityNumberPolicy } from '../../shared/domain/SocialSecurityNumberPolicy';

const COOKIE_LIST = ['payment02_name', 'payment02_phone', 'payment02_SSN1', 'payment02_recordId', 'payment02_bankName', 'payment02_bankAccountNo', 'payment02_bankCode', 'payment02_recordId', 'payment02_fee', 'payment02_refundList', 'payment02_type'];

export const usePaymentFormState = (): [PaymentForm, (props: Partial<PaymentForm>) => void, () => void] => {
  const [cookie, setCookie, resetCookie] = useCookies(COOKIE_LIST);
  const phoneFormatPolicy = useInjection<PhoneFormatPolicy>(PHONE_FORMAT_POLICY);
  const form = _.chain(COOKIE_LIST)
    .map(v => ({
      key: v,
      value: cookie[v],
    }))
    .filter(v => v.value != null)
    .mapKeys(v => v.key.replace('payment02_', ''))
    .mapValues(v => v.value)
    .value();
  form.phone = phoneFormatPolicy?.changeFormat(form.phone ?? '');
  return [
    new PaymentForm(form),
    useCallback((props: Partial<PaymentForm>) =>
      _.forEach(props, (value, key) =>
        setCookie('payment02_' + key, value, {
          path: '/',
          maxAge: COOKIE_MAX_AGE
        })), [setCookie]),
    useCallback(() => {
      COOKIE_LIST.forEach(v => resetCookie(v, { path: '/' }));
    }, [resetCookie])
  ];
};

export const useSetPaymentFormState = (): (props: Partial<PaymentForm>) => void => {
  const [, setCookie] = useCookies(COOKIE_LIST);
  return useCallback((props: Partial<PaymentForm>) =>
    _.forEach(props, (value, key) =>
      setCookie(key, value, {
        path: '/',
        maxAge: COOKIE_MAX_AGE
      })), [setCookie]);
};

export const usePaymentFormValue = (): PaymentForm => {
  const [cookie] = useCookies(COOKIE_LIST);
  const phoneFormatPolicy = useInjection<PhoneFormatPolicy>(PHONE_FORMAT_POLICY);
  const form = _.chain(COOKIE_LIST)
    .map(v => ({
      key: v,
      value: cookie[v],
    }))
    .filter(v => v.value != null)
    .mapKeys(v => v.key.replace('payment02_', ''))
    .mapValues(v => v.value)
    .value();
  form.phone = phoneFormatPolicy?.changeFormat(form.phone ?? '');
  return new PaymentForm(form);
};

export class PaymentForm {
  phone = '';
  SSN1 = '';
  name = '';
  bankName = '';
  bankCode = '';
  bankAccount = '';
  recordId = '';
  fee = '';
  refundList = '';
  type = '';

  constructor(props: Partial<PaymentForm>) {
    Object.assign(this, props);
  }

  static isPhoneValid(phone: string): boolean {
    return phone?.replace(/ |-/g, '').length === 10 || phone?.replace(/ |-/g, '').length === 11;
  }

  static isSsnValid(ssn: string): boolean {
    return SocialSecurityNumberPolicy.isValid(ssn);
  }

  static isNameValid(name: string): boolean {
    return name.length !== 0;
  }

  static isValidBasicInfo({ phone, ssn, name }: { phone: string, ssn: string, name: string }): boolean {
    return PaymentForm.isPhoneValid(phone)
      && PaymentForm.isSsnValid(ssn)
      && PaymentForm.isNameValid(name);
  }
}

