import React, { useState } from 'react';
import { css } from 'aphrodite';
import { useRecoilValue } from 'recoil';

import { CreateStyle } from '../../../../shared/presentation/shared/CreateStyle';
import { spoqa } from '../../../../shared/css/css-utils';
import { sharedShape } from '../../../../shared/css/SharedStyleSheet';
import { FAQ_LIST } from '../../shared/Constant';
import { totalScreenState } from '../../../../shared/presentation/MobileFrame';

function FaqSection() {
  const { isMobile, isTablet } = useRecoilValue(totalScreenState);
  const [selectedMenu, setSelectedMenu] = useState(0);

  const [isTriggerAnimate, setIsTriggerAnimate] = useState([true, false, false, false, false]);

  function onSelectMenu(index: number) {
    if (selectedMenu !== index) {
      setSelectedMenu(index);
    }
    executeAnimate(index);
  }

  const animatePlay = (index: number) => {
    setIsTriggerAnimate(isTriggerAnimate.map((v, _index) => _index === index));
  };

  const executeAnimate = (index: number) => {
    setTimeout(() => animatePlay(index), 50);
  };

  const styles = FaqStyle(isMobile, isTablet);

  return (
    <div className={css(styles.background)}>
      <div className={css(styles.title)}>
        자주 묻는 질문
      </div>
      <div className={css(styles.faq_div)}>
        {FAQ_LIST(isMobile, isTablet).map((item, index) => (
          <div key={'faq-' + index}>
            <div onClick={() => onSelectMenu(index)}>
              <div
                className={
                  css(
                    styles.faq__title,
                    selectedMenu === index
                      ? styles.blue_color
                      : sharedShape.default
                  )}>
                {item.title}
              </div>
              <div
                className={
                  css(
                    styles.faq__content,
                    isTriggerAnimate[index]
                      ? styles.transition_basic
                      : styles.box_hidden
                  )}>
                {item.content}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

const FaqStyle = (isMobile: boolean, isTablet: boolean) => CreateStyle({
  background: {
    width: '100%',
    height: '469px/564px@998px',

    display_: '/flex',
    justifyContent: 'space-between',

    paddingLeft: '17px/335px@81px',
    paddingRight: '/380px',
    paddingTop: '35px/92px@97px',

    fontFamily: spoqa,
  },
  title: {
    height: '49px/49px',

    fontWeight: 300,
    fontSize: '22px/35px@43px',
    lineHeight: isMobile ? 2.23 : 1.4,

    marginBottom: '/@55px'
  },
  faq_div: {
    width: '270px/562px',
    height: '/160px',

    display: 'flex',
    flexDirection: 'column',
  },
  blue_color: {
    color: process.env.REACT_APP_BASE_COLOR,
  },
  faq__title: {
    width: '218px/',

    marginTop: '8px/',
    marginBottom: '5px/5px@10px',

    fontWeight: 300,
    fontSize: '15px/22px@33px',
    lineHeight: isMobile ? 1.6 : 1.82,
    color: '#a6a6a6',

    cursor: 'pointer',

    whiteSpace_: 'pre/@pre',
  },
  faq__content: {
    height: '86px/134px@200px',

    marginTop: '/6px',
    marginBottom: '4px/',

    fontWeight: 300,
    fontSize: '11px/16px@23px',
    lineHeight: isMobile ? 1.73 : 1.75,
    color: 'black',

    whiteSpace_: 'pre',
  },
  box_hidden: {
    opacity: 0,
    height: '0px/0px@2px',
    overflow: 'hidden',
    transition: 'height 0.8s cubic-bezier(0.0, 0.0, 0.2, 1) 0s, opacity 0.8s cubic-bezier(0.0, 0.0, 0.2, 1) 0s',
  },
  transition_basic: {
    transition: 'height 0.8s cubic-bezier(0.0, 0.0, 0.2, 1) 0s, opacity 0.8s cubic-bezier(0.0, 0.0, 0.2, 1) 0s',
  },
}, isMobile, isTablet);

export default FaqSection;
