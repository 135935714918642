import React from 'react';
import { css } from 'aphrodite';
import CountUp from 'react-countup';
import { useRecoilValue } from 'recoil';

import BackgroundImage from '../../../assets/images/main_background.jpeg';
import { Image } from '../../../../shared/css/Image';
import { MENU_LIST, NUMBERS } from '../../shared/Constant';
import { MainStyle } from './MainStyle';
import { goToRequestPage } from '../../../../shared/store/TaxagentStore';
import { totalScreenState } from '../../../../shared/presentation/MobileFrame';
import { isPeriodPopUpState } from '../../popup/PeriodPopUp';

function MainSection() {
  const { isMobile, isTablet } = useRecoilValue(totalScreenState);
  const isPeriodPopUp = useRecoilValue(isPeriodPopUpState);
  const naviHeight = window.innerHeight;

  const styles = MainStyle(isMobile, isTablet, isPeriodPopUp, naviHeight);
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();


  function calculateRequestNumber(month: number, day: number) {
    const standardDay = 20;
    const standardMonth = 4;

    if (month === standardMonth) {
      if (day < standardDay) {
        return 0;
      }
      return day - standardDay;
    }
    if (month > 5) {
      return 0;
    }
    return day + 10;
  }

  return (
    <div className={css(styles.background)}>
      <Image src={BackgroundImage} alt={'background'} className={css(styles.background__image)}/>
      <div className={css(styles.main_div)}>
        <div className={css(styles.main__title)}>
          국내최대 종합소득세<br/>
          신고대행 전문 서비스
        </div>
        <div style={{ display: 'flex', position: 'relative' }}>
          <div className={css(styles.main__request_number)}>
            <CountUp
              end={process.env.REACT_APP_ACTIVE === 'FALSE'
                ? 0
                : NUMBERS[calculateRequestNumber(month, day)] + 1000}
              duration={2}
            />
            <span className={css(styles.main__request_number__unit)}>
                명
              </span>
          </div>
        </div>
        <div className={css(styles.main__standard)}>
          {process.env.REACT_APP_ACTIVE === 'FALSE'
            ? `${year}년 0월 0일 00:00 기준 신청 수`
            : `${year}년 ${month}월 ${day}일 00:00 기준 신청 수`}
        </div>
        <div
          className={css(styles.main__request_button)}
          onClick={goToRequestPage}
        >
          {process.env.REACT_APP_ACTIVE === 'FALSE'
            ? '신청이 마감되었습니다'
            : '간편 신청하기'
          }
        </div>
        {isMobile && (
          <div className={css(styles.main__center_number)}>
            {/*고객센터*/}
            {/*{process.env.REACT_APP_SERVICE_CENTER_NUMBER}*/}
          </div>
        )}
      </div>
      <div className={css(styles.sub_div)}>
        {MENU_LIST(isMobile, isTablet).map((item, index) =>
          <div key={'menu-' + index}>
            <div className={css(styles.sub__list)}>
              <Image src={item.image} alt={'icon'} className={css(styles.sub__list__image)}/>
              <div>
                {item.text}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default MainSection;
