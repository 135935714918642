import React, { RefObject } from 'react';
import NumberFormat from 'react-number-format';
import { css } from 'aphrodite';

import { CreateBetaStyle } from '../../../../shared/presentation/shared/CreateStyle';
import { pretendard } from '../../../../shared/css/css-utils';
import { RequestColor } from '../../assets/ColorContents';
import { labelStyle } from './FormInputWithLabel';

interface Props {
  id?: string;
  className?: string;
  onChange?: (value: string) => void;
  value?: string;
  onFocus?: () => void;
  onBlur?: (value: string) => void;
  onKeyDown?: () => void;
  placeholder?: string;
  refObject?: RefObject<HTMLInputElement>;
  isInvalid?: boolean;
  isDisabled?: boolean;
  labelText: string;
}

export const FormPhoneInputWithLabel: React.FC<Props> = (
  {
    onChange,
    className,
    value,
    onFocus,
    onBlur,
    onKeyDown,
    id,
    placeholder = '010 1234 5678',
    refObject,
    isInvalid = false,
    isDisabled = false,
    labelText
  }) => {
  const format = value?.length === 13 ? '### #### ####' : '### ### #####';
  return (
    <div className={css(styles.wrapper, isDisabled && styles.disable)}>
      <div className={css(labelStyle.label, isInvalid && styles.color_red)}>{labelText}</div>
      <NumberFormat<any>
        id={id}
        className={[css(styles.input, isDisabled && styles.disable_color), className].join(' ')}
        onChange={e => onChange?.(e.target.value)}
        placeholder={placeholder}
        format={format}
        value={value}
        allowNegative={false}
        allowLeadingZeros
        onFocus={onFocus}
        onBlur={e => onBlur?.(e.target.value)}
        onKeyDown={onKeyDown}
        getInputRef={refObject}
      >
      </NumberFormat>
    </div>
  );

};

const styles = CreateBetaStyle({
  wrapper: {
    position: 'relative',
  },
  disable: {
    pointerEvents: 'none',
  },
  disable_color: {
    color: RequestColor.GRAY_40,
  },
  input: {
    width: '100%',
    height: '48px',
    display: 'flex',
    alignItems: 'center',
    padding: '0px 15px 0px 15px',

    borderRadius: '4px',
    border: ['1px', 'solid', RequestColor.GRAY_30].join(' '),

    fontSize: '16px',
    lineHeight: '24px',
    transition: 'all 0.3s',
    '::placeholder': {
      color: RequestColor.GRAY_60,
    },
  },
  error: {
    width: '20px',
    height: '20px',

    borderRadius: '100%',

    backgroundColor: RequestColor.RED_100,

    fontFamily: pretendard,
    color: 'white',
    fontWeight: 700,
    fontSize: '14px',

    position: 'absolute',
    right: '16px',
    top: '50%',
    transform: 'translateY(-50%)'
  },
  color_red: {
    color: RequestColor.RED,
  }
});
