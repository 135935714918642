import * as CryptoJS from 'crypto-js';

export function decodeBase64(value: string): string {
  try {
    return CryptoJS.enc.Base64.parse(value).toString(CryptoJS.enc.Utf8);
  } catch (e) {
    throw new Error('올바른 Base64 형식이 아닙니다! input: ' + value);
  }
}

export function encodeBase64(value: string): string {
  return CryptoJS.enc.Utf8.parse(value).toString(CryptoJS.enc.Base64);
}
