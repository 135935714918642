import React, { useEffect, useState } from 'react';
import { css } from 'aphrodite';

import { ReactComponent as Arrow } from '../../../request-page-2022/assets/svgs/dropdown-arrow.svg';
import { useMediaQueryContext } from '../../../../shared/presentation/view/MediaQueryProvider';
import { RequestFont } from '../../../request-page-2022/shared/RequestFont';
import { CreateStyle } from '../../../../shared/presentation/shared/CreateStyle';
import { RequestColor } from '../../../request-page-2022/assets/ColorContents';

interface BankProps {
  briefName: string;
  bankName: string;
  bankCode: string;
}

interface DropProps {
  placeholder: string,
  options: BankProps[],
  value?: any,
  setValue: (value: any) => void,
  isDisabled: boolean,

}

export function RefundFormDropDown({ placeholder, options, value, setValue, isDisabled }: DropProps) {
  const [innerHeight, setInnerHeight] = useState(window.innerHeight);

  useEffect(() => {
    setInterval(() => setInnerHeight(window.innerHeight), 300);
  }, []);

  const { isMobile, isTablet } = useMediaQueryContext();
  const styles = FormDropDownStyle({ isMobile, isTablet, innerHeight });
  const [isOption, setIsOption] = useState(false);

  return (
    <>
      {isOption && (
        <div className={css(styles.frame__drop_down_box_background)}>
          <div className={css(styles.frame__drop_down_box, styles.frame__drop_down_box__bank)}>
            <div className={css(styles.frame__drop_down_box__bank_list)}>
              {options.map((item, index) =>
                <div
                  key={'option-' + index}
                  className={css(styles.frame__drop_down__list, item === value && styles.frame__drop_down__list__selected, styles.frame__drop_down__list__bank)}
                  onClick={() => {
                    setValue(item);
                    setIsOption(false);
                  }}
                >
                  {item.bankName}
                </div>
              )}
            </div>
            <div
              className={css(styles.frame__drop_down__list__cancel, styles.frame__drop_down__list__cancel_bank)}
              onClick={() => setIsOption(false)}
            >
              취소
            </div>
          </div>
        </div>
      )}
      <div
        className={css(styles.frame, isDisabled && styles.disable)}
        onClick={() => setIsOption(!isOption)}
      >
        <div className={css(RequestFont.INPUT_TEXT, value === '' && styles.frame__text__placeholder, isDisabled && styles.disable_color)}>
          {value !== '' ? value : placeholder}
        </div>
        {!isDisabled && <Arrow/>}
      </div>
    </>
  );
}

const FormDropDownStyle = (
  {
    isMobile,
    innerHeight
  }: {
    isMobile: boolean,
    isTablet: boolean,
    innerHeight: number
  }) => CreateStyle({
  frame: {
    width: '100%',
    height: '48px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0px 15px 0px 15px',

    borderRadius: '4px',
    border: ['1px', 'solid', RequestColor.GRAY_30].join(' '),

    fontSize: '18px',
    lineHeight: '24px',
    transition: 'all 0.3s',

    position: 'relative',

    cursor: 'pointer',

    ':hover': {
      filter: 'drop-shadow(4px 6px 16px rgba(73, 74, 79, 0.06))',
      '-webkit-filter': 'drop-shadow(4px 6px 16px rgba(73, 74, 79, 0.06))',
      transition: 'filter 0.3s',
    },

    zIndex: 50,
  },
  frame__text__placeholder: {
    color: RequestColor.GRAY_60
  },
  frame__drop_down_box_background: {
    width: '100vw',
    height: `${innerHeight}px`,

    position_: 'fixed',
    top: '0px',
    left: '0px',

    display: 'flex',
    justifyContent: 'center',

    backgroundColor: 'rgba(7, 7, 8, 0.5)',

    zIndex: 200,
  },
  frame__drop_down_box: {
    width: '100vw',
    maxWidth: '640px',

    backgroundColor: 'white',

    display: 'flex',
    flexDirection: 'column',

    position_: 'absolute',
    bottom: '0px',

    zIndex: 15,

    padding: '39px 24px 24px 24px',
  },
  frame__drop_down__list__bank: {
    width: '50%',
    height: '46px',

    marginBottom: 0,

    justifyContent: 'initial',
  },
  frame__drop_down_box_header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    marginBottom: '21px',
  },
  frame__drop_down__list: {
    width: '100%',
    height: '28px',

    fontSize: '16px',
    color: RequestColor.GRAY_100,

    display: 'flex',
    alignItems: 'center',
    justifyContent_: 'center/',

    marginBottom: 16,

    cursor: 'pointer',
  },
  frame__drop_down__list__selected: {
    color: RequestColor.BLUE,
  },
  frame__drop_down__list__cancel: {
    width: '100%',
    maxWidth: '598px',
    height: 56,
    minHeight: 56,
    borderRadius: 5,

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    alignSelf: 'center',

    border: '1px solid' + RequestColor.BLUE,

    fontSize: '16px',
    lineHeight: '28px',
    color: RequestColor.BLUE,
    fontWeight: 500,

    marginTop: '38px',
  },
  frame__drop_down__list__cancel_bank: {
    marginTop: '25px',
  },
  frame__drop_down_box__bank: {
    height: '624px',
    maxWidth: '640px',

    justifyContent: 'space-between',

    paddingTop: '15px',
    paddingBottom: '43px',
    paddingLeft: '6vw',
    paddingRight: '6vw',
  },
  frame__drop_down_box__bank_list: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  disable: {
    backgroundColor: 'rgba(239, 239, 239, 0.3)',

    pointerEvents: 'none',
  },
  disable_color: {
    color: 'rgb(203, 208, 214)',
  },
}, isMobile);
