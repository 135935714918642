import { StyleSheet } from 'aphrodite/no-important';
import { RequestColor } from '../assets/ColorContents';

export const RequestStyle = StyleSheet.create({
  CENTER: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  COLUMN_CENTER: {
    display: 'flex',
    justifyContent: 'center',
  },
  ROW_CENTER: {
    display: 'flex',
    alignItems: 'center',
  },
  POPUP_BACKGROUND: {
    width: '100vw',
    height: '100vh',

    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 500,

    backgroundColor: 'rgba(32, 35, 40, 0.6)',
  },
  INPUT_ERROR: {
    border: ['2px', 'solid', RequestColor.RED].join(' '),
  }
});
