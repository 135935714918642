import React, { useCallback, useEffect, useState } from 'react';
import { atom, useRecoilValue, useSetRecoilState } from 'recoil';
import { css } from 'aphrodite';

import { RequestWrapperWithHeader } from '../../shared-component/RequestWrapperWithHeader';
import { RequestFont } from '../../shared/RequestFont';
import { CreateBetaStyle } from '../../../../shared/presentation/shared/CreateStyle';
import Step01 from '../../assets/svgs/step01.svg';
import Step02 from '../../assets/svgs/step02.svg';
import Step03 from '../../assets/svgs/step03.svg';
import { RequestColor } from '../../assets/ColorContents';
import { spoqa } from '../../../../shared/css/css-utils';
import { Image } from '../../../../shared/css/Image';
import { useMyHistory } from '../../../../shared/presentation/HistoryHelper';
import { VerticalSpace } from '../../../../taxagent/pages/test-input/component/VerticalSpace';
import RequestVerticalButtons from '../../shared-component/RequestVerticalButtons';
import { isLoadingState } from '../../../../shared/presentation/LoadingFrame';
import { RemainTimePolicy } from '../1_간편인증/RemainTimePolicy';
import { KakaoSimpleAuthorizer } from './KakaoSimpleAuthorizer';
import { useRequestFormState } from '../../shared/RequestContents';
import { useHometaxCookieState } from '../../shared/HometaxCookieState';
import { HOME_TAX_URL, REQUEST_PATH } from '../SharedConstants';
import { SSN2State } from '../1_간편인증/SimpleAuthRequest';
import { alertMessageState } from '../../shared-component/ShowAlertFrame';
import { encodeBase64 } from '../../utill/base64';
import { modalMessageState } from '../../shared-component/ModalPopup';
import { requestAlertMessageState } from '../../shared-component/RequestAlertFrame';
import { useKeywordValue } from '../../shared/KeywordState';

// const SERVICE_CHECK_ALERT = '지금은 서비스 점검시간입니다.\n이용 가능한 시간에 다시 시도해주세요.\n(서비스 점검시간 : 00시 ~ 09시)';
const NOT_COMPETED_ALERT = '지갑인증이 완료되지 않았습니다.\n카카오톡에서 인증 후 인증완료 \n버튼을 눌러주세요.';
const COUNT_TIME_EXPIRED = '인증 시간이 만료되었습니다.\n재요청을 눌러 다시 인증해주세요.';
const NOT_HOMETAX_MEMBER = '홈택스 회원가입 이후에 진행이\n가능합니다. 안내에 따라 등록 후 다시 신청해주세요.';
const NOT_MATCHED_INFO = '본인 인증 정보를 찾을 수 없습니다.';
const KAKAO_AUTH_ERROR = '인증이 실패했습니다!: OACX_SERVICE_ERROR';

const FIVE_MINUTE = 60 * 5 * 1000;

const UNSUBSCRIBED_KAKAO_WALLET = '카카오 인증 서비스에 가입한 후\n다시 시도해 주시기 바랍니다.';

const makeAlertMessage = (message: string) => {
  return message === NOT_MATCHED_INFO ? UNSUBSCRIBED_KAKAO_WALLET : message;
};

export const customerIdState = atom<string>({
  key: 'customerIdState',
  default: '',
});

const LIST = [
  {
    img: Step01,
    content: '1. 카카오톡 앱에서',
    desc: '인증요청 메시지 확인',
  },
  {
    img: Step02,
    content: '2. 인증 진행',
    desc: '카카오 My 비밀번호 입력',
  },
  {
    img: Step03,
    content: '3. 서비스로 돌아오기',
    desc: '인증 완료 후, 하단의 인증완료 선택',
  }
];

export const KakaoAuthView: React.FC = () => {
  const setIsLoading = useSetRecoilState(isLoadingState);
  const [expiredTime, setExpiredTime] = useState(new Date().getTime() + FIVE_MINUTE);
  const [remainTime, setRemainTime] = useState(FIVE_MINUTE);
  const { minute, second } = RemainTimePolicy.calcRemainTimes(remainTime);
  const [cert, setCert] = useState({});
  const [cookie, setCookie] = useHometaxCookieState();
  const history = useMyHistory();
  const [{ name, phone, SSN1, customerCode }, setRequest] = useRequestFormState();
  const SSN2 = useRecoilValue(SSN2State);
  // const [step, setStep] = useStepState();
  const showAlert = useSetRecoilState(alertMessageState);
  const showRequestAlert = useSetRecoilState(requestAlertMessageState);
  const showModal = useSetRecoilState(modalMessageState);
  const [isProceeding, setIsProceeding] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const keyword = useKeywordValue();

  useEffect(() => {
    if (SSN2 === '') {
      showAlert({
        message: '주민등록번호가 입력되지 않았습니다.\n다시 시도해주세요.',
        onClick: () => history.push(REQUEST_PATH.step01)
      });
      return;
    }
  }, [SSN2]);

  useEffect(() => setIsProceeding(true), []);

  const requestKakaoCert = useCallback(() => {
    if (!isProceeding) {
      return;
    }
    setIsLoading(true);
    KakaoSimpleAuthorizer
      .request({
        name,
        phone,
        socialSecurityNo: encodeBase64(SSN1 + SSN2),
        isProxy: false,
      })
      .finally(() => setIsLoading(false))
      .then(({ cert }) => {
        setCert(cert);
      })
      .catch(e => {
        switch (e.response?.status) {
          case 401:
            if (e.response?.data?.message === KAKAO_AUTH_ERROR) {
              return;
            }
            // showAlert({ message: NOT_HOMETAX_MEMBER });
            showRequestAlert({
              message: NOT_HOMETAX_MEMBER,
              button: [
                {
                  title: '홈택스 바로가기',
                  onClick: () => window.open(HOME_TAX_URL),
                },
                {
                  title: '확인',
                }
              ]
            });
            return;
          case 400:
            // showAlert({
            //   message: makeAlertMessage(e.response.data.message),
            //   onClick: () => history.push(REQUEST_PATH.step01)
            // });
            showModal({
              title: '카카오톡 간편인증에 \n실패하셨습니다.',
              message: makeAlertMessage(e.response.data.message),
              button: [
                {
                  title: '다시 인증하기',
                  onClick: () => history.push(REQUEST_PATH.step01),
                },
                {
                  title: '간편인증 건너뛰기',
                  onClick: onClickNextWithoutCertification,
                }
              ]
            });
            return;
        }
      });
  }, [name, phone, SSN1, SSN2, isProceeding]);

  useEffect(() => requestKakaoCert(), [requestKakaoCert]);

  const onClickComplete = useCallback(() => {
    if (!isProceeding) {
      return;
    }
    setIsLoading(true);
    KakaoSimpleAuthorizer
      .finish(cert)
      .finally(() => setIsLoading(false))
      .then(cookie => {
        setCookie(cookie);
        setIsProceeding(false);
        setIsSuccess(true);
      })
      .catch(e => {
        switch (e.response?.status) {
          case 406:
            showAlert({ message: NOT_COMPETED_ALERT });
            return;
          case 401:
            if (e.response?.data?.message === KAKAO_AUTH_ERROR) {
              return;
            }
            // showAlert({ message: NOT_HOMETAX_MEMBER });
            showRequestAlert({
              message: NOT_HOMETAX_MEMBER,
              button: [
                {
                  title: '홈택스 바로가기',
                  onClick: () => window.open(HOME_TAX_URL),
                },
                {
                  title: '확인',
                }
              ]
            });
            return;
          case 400:
            showAlert({
              message: makeAlertMessage(e.response.data.message),
              onClick: () => history.push(REQUEST_PATH.step01)
            });
            return;
        }
      });
  }, [cert]);

  const waitAuth = useCallback(() => {
    if (!isProceeding) {
      return;
    }
    KakaoSimpleAuthorizer
      .finish(cert)
      .then(cookie => {
        setCookie(cookie);
        setIsProceeding(false);
        setIsSuccess(true);
      })
      .catch(e => {
        if (e.response == null) {
          console.log(e);
          return;
        }
        switch (e.response?.status) {
          case 406:
            return;
          case 401:
            if (e.response?.data?.message === KAKAO_AUTH_ERROR) {
              return;
            }
            // showAlert({ message: NOT_HOMETAX_MEMBER });
            showRequestAlert({
              message: NOT_HOMETAX_MEMBER,
              button: [
                {
                  title: '홈택스 바로가기',
                  onClick: () => window.open(HOME_TAX_URL),
                },
                {
                  title: '확인',
                }
              ]
            });
            return;
          default:
            requestKakaoCert();
        }
      });
  }, [cert, requestKakaoCert]);

  const restart = useCallback(() => {
    setExpiredTime(new Date().getTime() + FIVE_MINUTE);
    setRemainTime(FIVE_MINUTE);
    setIsProceeding(true);
  }, []);

  useEffect(() => {
    if (Object.keys(cert).length !== 0 && isProceeding) {
      const interval = setInterval(waitAuth, 2000);
      return () => clearInterval(interval);
    }
  }, [waitAuth, isProceeding]);

  useEffect(() => {
    if (Object.keys(cert).length > 0 && isProceeding) {
      const interval = setInterval(() => {
        setRemainTime(expiredTime - new Date().getTime());
      }, 500);
      return () => clearInterval(interval);
    }
  }, [cert, isProceeding]);

  useEffect(() => {
    if (remainTime <= 500 && isProceeding) {
      setIsProceeding(false);
      // showAlert({ message: COUNT_TIME_EXPIRED });
      showModal({
        title: '인증시간이 만료되었습니다.\n다시 인증해주세요.',
        message: COUNT_TIME_EXPIRED,
        button: [
          {
            title: '다시 인증하기',
            onClick: () => history.push(REQUEST_PATH.step01),
          },
          {
            title: '간편인증 건너뛰기',
            onClick: onClickNextWithoutCertification,
          }
        ]
      });
      return;
    }
  }, [remainTime, isProceeding]);

  useEffect(() => {
    if (isSuccess) {
      setIsLoading(true);
      KakaoSimpleAuthorizer
        .saveUser(
          cookie,
          {
            name,
            phone,
            socialSecurityNo: encodeBase64(SSN1 + SSN2),
            isProxy: false,
            customerCode,
            keyword,
          })
        .finally(() => setIsLoading(false))
        .then((customerId) => {
          setRequest({ customerId });
          setTimeout(() => history.push(REQUEST_PATH.step03), 100);
        });
    }
  }, [isSuccess, customerCode]);

  const onClickNextWithoutCertification = () => {
    setIsLoading(true);
    KakaoSimpleAuthorizer
      .saveNoCertUser(
        {
          name,
          phone,
          socialSecurityNo: encodeBase64(SSN1 + SSN2),
          isProxy: false,
          keyword,
        })
      .finally(() => setIsLoading(false))
      .then((customerId) => {
        setRequest({ customerId });
        setTimeout(() => history.push(REQUEST_PATH.step03), 100);
      });
  };

  return (
    <RequestWrapperWithHeader className={css(styles.wrapper)}>
      <p className={css(RequestFont.TITLE, RequestFont.PRE, styles.title)}>
        카카오톡으로<br/>
        지갑인증 요청을 보냈습니다
      </p>
      <div className={css(styles.noticeBox)}>
        {LIST.map((item, index) => {
          return (
            <div key={'step' + index}
                 className={css(styles.step)}
            >
              <Image src={item.img} alt={'step' + (index + 1)}/>
              <div className={css(styles.step_content)}>
                {item.content}<br/>
                <span className={css(styles.content_desc)}>{item.desc}</span>
              </div>
            </div>
          );
        })
        }
      </div>
      <VerticalSpace/>
      <div className={css(RequestFont.ALERT_TITLE, styles.countdown)}>
        {minute.toString().padStart(2, '0')}:{second.toString().padStart(2, '0')}
      </div>
      <RequestVerticalButtons
        items={[
          {
            backgroundColor: RequestColor.BLUE,
            color: RequestColor.WHITE,
            title: '인증완료',
            marginBottom: 12,
            onClick: onClickComplete,
            isActive: isProceeding,
          },
          {
            backgroundColor: RequestColor.WHITE,
            color: RequestColor.GRAY_60,
            title: '간편인증 재요청',
            marginBottom: 12,
            onClick: restart,
            isActive: !isProceeding,
          },
        ]}
      />
    </RequestWrapperWithHeader>
  );
};

const styles = CreateBetaStyle({
  wrapper: {
    height: '100vh',
    color: RequestColor.GRAY_100,
    fontFamily: spoqa,
  },
  title: {
    marginBottom: '32px',
  },
  noticeBox: {
    width: '100%',
    height: '272px',
    marginBottom: '116px',

    display: 'flex',
    flexDirection: 'column',
  },
  step: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '16px',
  },
  step_content: {
    marginLeft: '24px',
    fontSize: '16px',
    lineHeight: '26px',
  },
  countdown: {
    marginBottom: '16px',
    width: '100%',
    height: '72px',
    borderRadius: '15px',

    display: 'flex',
    justifyContent: 'center',
    color: RequestColor.BLUE,
  },
  content_desc: {
    color: RequestColor.GRAY_80,
    fontSize: '14px',
    lineHeight: '24px',
  }
});

