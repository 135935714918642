import { css } from 'aphrodite';
import React, { useImperativeHandle, useRef, useState } from 'react';
import NumberFormat from 'react-number-format';
import * as _ from 'lodash';

import { CreateBetaStyle } from '../../../../shared/presentation/shared/CreateStyle';
import { InputError } from '../InputError';
import { RequestStyle } from '../../shared/RequestStyle';
import { RequestColor } from '../../assets/ColorContents';
import { labelStyle } from './FormInputWithLabel';

interface Props {
  ssn1Value: string;
  ssn2Value: string;
  onSsn1Change?: (value: string) => void;
  onSsn2Change?: (value: string) => void;
  onSsnBlur?: (value: string) => void;
  className?: string;
  isValid?: boolean;
  onFocus?: () => void;
  isDisabled?: boolean;
  labelText: string;
}

export const FormSSNInputWithLabel = React.forwardRef<any, Props>((
  {
    ssn1Value, ssn2Value,
    onSsn1Change, onSsn2Change,
    className, isValid = false,
    onFocus, onSsnBlur, isDisabled = false, labelText
  }, ref) => {
  const [isSsn1Focused, setIsSsn1Focused] = useState(false);
  const [isSsn2Focused, setIsSsn2Focused] = useState(false);
  const ssn1InputRef = useRef<HTMLInputElement>(null);
  const ssn2InputRef = useRef<HTMLInputElement>(null);
  const isFocused = isSsn1Focused || isSsn2Focused;
  const ssn2Length = ssn2Value.match(/\d{1,7}/)?.[0]?.length ?? 0;
  const onFinishChange = (value: string) => {
    const length = value.match(/\d{0,6}/)?.[0]?.length ?? 0;
    const [, filtered, remain] = value.match(/(\d{0,6})(.*)/) ?? ['', '', ''];
    onSsn1Change?.(filtered);
    onSsn2Change?.(remain);
    if (length >= 6 && isSsn1Focused) {
      ssn2InputRef.current?.focus();
    }
  };

  const onFocusInput = () => {
    if (ssn1Value.length === 6) {
      ssn2InputRef.current?.focus();
      onFocus?.();
      return;
    }
    ssn1InputRef.current?.focus();
    onFocus?.();
  };

  useImperativeHandle(ref, () => ({
    focus: onFocusInput,
  }));

  const handleKeyDown = (e: any) => {
    // alert(e.key);
    if (e.key === 'Backspace' && e.target.value === '') {
      onSsn1Change?.(ssn1Value.substr(0, 5));
      ssn1InputRef.current?.focus();
    }
  };

  return (
    <div className={css(styles.wrapper)}>
      <div className={css(labelStyle.label, !isValid && styles.red_color)}>{labelText}</div>
      <div
        className={css(styles.frame, isDisabled && styles.disable)}
        onClick={onFocusInput}
      >
        <NumberFormat<any>
          placeholder={'앞자리 6자'}
          className={css(styles.front_input, isDisabled && styles.disable_color, !isValid && styles.invalid)}
          getInputRef={ssn1InputRef}
          value={ssn1Value}
          onFocus={() => setIsSsn1Focused(true)}
          onBlur={() => setIsSsn1Focused(false)}
          onBlurCapture={e => onSsnBlur?.(ssn1Value + ssn2Value)}
          onChange={e => {
            onFinishChange(e.target.value);
          }}
          format={'######'}
          // onChange={value => onChangeHandler({SSN1: value})}
        />
        <div className={css(styles.end, RequestStyle.ROW_CENTER, !isValid && styles.invalid)}>
          <NumberFormat<any>
            getInputRef={ssn2InputRef}
            className={css(styles.end_input)}
            value={ssn2Value}
            onFocus={() => setIsSsn2Focused(true)}
            onBlur={() => setIsSsn2Focused(false)}
            onBlurCapture={e => {
              onSsnBlur?.(ssn1Value + ssn2Value);
            }}
            onChange={e => onSsn2Change?.(e.target.value.substr(0, 7))}
            format={'#######'}
            onKeyDown={handleKeyDown}
          />
          <div
            className={css(RequestStyle.ROW_CENTER, styles.end__hidden)}
            onClick={() => ssn2InputRef.current?.focus()}
          >
            {_.range(8).map((item, index) =>
              <div key={'ssn_end_' + item} style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
                {index === ssn2Length && document.activeElement === ssn2InputRef.current &&
                <div className={css(styles.password_cursor)}/>}
                {index !== 7 && <div className={css(styles.end__dot, index < ssn2Length && styles.end__dot__written, isDisabled && styles.end__dot_disable)}/>}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
});

const cursorAni = {
  '0%': {
    opacity: 0,
  },
  '49%': {
    opacity: 0,
  },
  '50%': {
    opacity: 1,
  },
  '100%': {
    opacity: 1,
  },
};


const styles = CreateBetaStyle({
  wrapper: {
    width: '100%',

    position: 'relative',
  },
  invalid: {
    border: '1px solid red',
  },
  red_color: {
    color: RequestColor.RED
  },
  frame: {
    width: '100%',
    height: '48px',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    position: 'relative',

    fontSize: '20px',
    // ':hover': {
    //   backgroundColor: RequestColor.GRAY_20,
    //   borderColor: RequestColor.GRAY_10,
    //   cursor: 'pointer',
    // },
    // ':active': {
    //   border: 'solid 2px black',
    //   backgroundColor: RequestColor.WHITE,
    //   ':hover': {
    //     backgroundColor: RequestColor.WHITE,
    //   },
    // },
  },
  disable: {
    pointerEvents: 'none',
  },
  disable_color: {
    color: RequestColor.GRAY_40,
  },
  focus: {
    // backgroundColor: RequestColor.WHITE,
    // border: ['2px', 'solid', RequestColor.GRAY_100].join(' '),
    // ':hover': {
    //   border: ['2px', 'solid', RequestColor.GRAY_100].join(' '),
    //   backgroundColor: RequestColor.WHITE,
    // },
  },
  front_input: {
    // width: '20%',
    width: '49%',
    minWidth: '85px',
    height: '100%',
    cursor: 'pointer',
    // userSelect: 'none',
    // pointerEvents: 'none',

    backgroundColor: 'transparent',

    paddingLeft: '15px',

    borderRadius: '4px',
    border: ['1px', 'solid', RequestColor.GRAY_30].join(' '),

    // marginRight: '24px',

    fontSize: '16px',
    color: RequestColor.GRAY_100,

    '::placeholder': {
      color: RequestColor.GRAY_50,
    },
  },
  end_input: {
    position: 'absolute',
    cursor: 'pointer',
    // display: 'none',
    opacity: 0,
  },
  end: {
    width: '49%',
    height: '48px',

    paddingLeft: '15px',

    position: 'relative',

    border: ['1px', 'solid', RequestColor.GRAY_30].join(' '),
    borderRadius: '4px',
  },
  end__hidden: {
    width: '105px',

    display: 'flex',
    justifyContent: 'space-between',
  },
  end__dot: {
    width: '8px',
    height: '8px',

    borderRadius: '100%',

    backgroundColor: RequestColor.GRAY_40,
  },
  end__dot_disable: {
    backgroundColor: RequestColor.GRAY_40,
  },
  end__dot__written: {
    backgroundColor: RequestColor.GRAY_100
  },
  password_cursor: {
    width: '1px',
    height: '17px',

    borderRight: '1px solid black',

    position: 'absolute',
    left: -4,

    animationName: [cursorAni],
    animationDuration: '1s',
    animationIterationCount: 'infinite',
  },
});
