import React from 'react';
import { css } from 'aphrodite';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import SharedStyleSheet from '../../css/SharedStyleSheet';
import { HeaderButtonStyles } from './HeaderButtonStyle';
import { ReactComponent as Kakao } from '../icons/kakao.svg';
import { HeaderButtonStyleForRequestPage } from './HeaderButtonStyleForRequestPage';

import { isMobileState, isTabletState } from '../MobileFrame';
import { kakaoPage } from '../HistoryHelper';

interface Props {
  isTop?: boolean,
  baseColor?: string,
}

export function KakaoButton({ baseColor, isTop }: Props) {
  const isMobile = useRecoilValue(isMobileState);

  const styles = HeaderButtonStyles({
    isMobile: isMobile,
    isTop: isTop!,
    baseColor: baseColor ?? 'black',
  });
  const shared = SharedStyleSheet(isMobile);
  return (
    <Link
      className={css(styles.button, shared.onlyDesktop)}
      to={kakaoPage()}
      id={'kakao_request_button'}
      rel={'kakao_request'}
    >
      <Kakao className={css(styles.buttonIcon)}/>
      <p className={css(styles.text, shared.iconSpace)}>카카오톡 상담</p>
    </Link>
  );
}

interface MobileProps {
  baseColor?: string,
  isRequestPage: boolean,
  isTop?: boolean,
  tabletHeight?: number,
}

export function MobileKakaoButton({ baseColor, isRequestPage, isTop, tabletHeight }: MobileProps) {
  const isMobile = useRecoilValue(isMobileState);
  const isTablet = useRecoilValue(isTabletState);

  const styles = isRequestPage
    ? HeaderButtonStyleForRequestPage({
      isMobile,
      isTop: isTop!,
      baseColor: baseColor ?? 'black',
      isRequestPage: isRequestPage,
      isTablet,
      tabletHeight,
    })
    : HeaderButtonStyles({
      isMobile,
      isTop: isTop!,
      baseColor: baseColor ?? 'black',
      isTablet,
      tabletHeight
    });

  return (
    <>
      {(isMobile || isTablet) && (
        <Link
          className={css(styles.button)}
          to={kakaoPage()}
        >
          <Kakao className={css(styles.buttonIcon)}/>
        </Link>
      )}
    </>
  );
}
