import React, { useCallback, useEffect, useRef, useState } from 'react';
import { css } from 'aphrodite';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { CreateBetaStyle } from '../../../../shared/presentation/shared/CreateStyle';
import { VerticalSpace } from '../../../../taxagent/pages/test-input/component/VerticalSpace';
import { RequestButton } from '../../shared-component/RequestButton';
import { RequestWrapperWithHeader } from '../../shared-component/RequestWrapperWithHeader';
import { RequestFont } from '../../shared/RequestFont';
import { REQUEST_PATH } from '../SharedConstants';
import { useMyHistory } from '../../../../shared/presentation/HistoryHelper';
import { FormInputWithLabel } from '../../shared-component/input/FormInputWithLabel';
import {
  comparePwdState,
  confirmPwdState,
  HomeTaxLoginService,
  isConfirmPwdValidState,
  isNewPwdValidState,
  newPwdState,
  TOTAL_INCOME_TAX_HOME_TAX_LOGIN_SERVICE
} from './HomeTaxLoginStore';
import { isLoadingState } from '../../../../shared/presentation/LoadingFrame';
import { useIsHometaxSessionValid } from '../../shared/PermissionState';
import { useInjection } from '../../../../shared/presentation/InversifyProvider';
import { useHometaxCookieValue } from '../../shared/HometaxCookieState';
import { LoginPolicy } from './LoginPolicy';
import { RequestStyle } from '../../shared/RequestStyle';
import { useRequestFormValue } from '../../shared/RequestContents';
import { alertMessageState } from '../../shared-component/ShowAlertFrame';

const HomeTaxPasswordReset = () => {
  const [newPwd, setNewPwd] = useRecoilState(newPwdState);
  const [confirmPwd, setConfirmPwd] = useRecoilState(confirmPwdState);
  const [isNewPwdValid, setIsNewPwdValid] = useRecoilState(isNewPwdValidState);
  const [isConfirmPwdValid, setIsConfirmPwdValid] = useRecoilState(isConfirmPwdValidState);
  const isEqual = useRecoilValue(comparePwdState);
  const [btnActive, setBtnActive] = useState(false);
  const newPwdRef = useRef<HTMLInputElement>(null);
  const confirmPwdRef = useRef<HTMLInputElement>(null);
  const setIsLoading = useSetRecoilState(isLoadingState);
  const [isSuccess, setIsSuccess] = useState(false);
  const history = useMyHistory();
  const setAlert = useSetRecoilState(alertMessageState);
  const hometaxState = useIsHometaxSessionValid();
  const {customerId} = useRequestFormValue();

  const loginService = useInjection<HomeTaxLoginService>(TOTAL_INCOME_TAX_HOME_TAX_LOGIN_SERVICE);

  const cookie = useHometaxCookieValue();

  const newPwdInput = (value: string) => {
    setNewPwd(value);
    setIsNewPwdValid(LoginPolicy.isValidPassword(value));
  };

  const confirmPwdInput = (value: string) => {
    setConfirmPwd(value);
    setIsConfirmPwdValid(LoginPolicy.isValidPassword(value));
  };

  const clickHandler = useCallback(async () => {
    showToastMessage();
    if (cookie) {
      await loginService?.sendLoginPasswordResetToHomeTax(
        Buffer.from(newPwd, 'utf-8').toString('base64'),
        cookie,
        customerId
      )
        .finally(() => setIsLoading(false))
        .then(({resultCode, message}) => {
          if (resultCode !== 'Success') {
            setAlert({message});
            return;
          }
          setAlert({message: message});
          setIsSuccess(true);
        })
        .catch((e) => {
          setAlert({message: '잘못된 호출입니다.'});
        });
    }
  }, [newPwd]);

  const showToastMessage = useCallback(() => {
    if (!isNewPwdValid || !isConfirmPwdValid) {
      setAlert({message: '올바르지 않은 형식입니다.'});
      return;
    }

    if (!isEqual) {
      setAlert({message: '비밀번호가 일치하지 않습니다.'});
      return;
    }
  }, [isNewPwdValid, isConfirmPwdValid, isEqual]);

  useEffect(() => {
    if (isEqual) {
      setBtnActive(true);
    }

    if (!isEqual) {
      setBtnActive(false);
    }
  }, [isNewPwdValid, isConfirmPwdValid, isEqual]);

  useEffect(() => {
    if (isSuccess) {
      history.push(REQUEST_PATH.step03);
    }

    if (hometaxState.isSessionValid === false) {
      history.push(REQUEST_PATH.step01);
    }
  }, [isSuccess, hometaxState]);

  return (
    <RequestWrapperWithHeader
      className={css(styles.wrapper)}
    >
      <p className={css(RequestFont.TITLE, RequestFont.PRE, styles.title)}>
        홈택스 비밀번호 재설정
      </p>
      <FormInputWithLabel
        labelText={'새로운 비밀번호'}
        placeholder={'새로운 비밀번호'}
        inputType={'password'}
        onChange={(val: string) => setNewPwd(val)}
        isValid={isNewPwdValid}
        value={newPwd}
        maxLength={15}
        refObject={newPwdRef}
        addInputStyle={!isNewPwdValid && RequestStyle.INPUT_ERROR}
        onFocus={() => setIsNewPwdValid(true)}
        onBlur={(val: string) => {
          newPwdInput(val);
        }}
        onKeydown={(e) => {
          if (isNewPwdValid && e.key === 'Enter') {
            confirmPwdRef.current?.focus();
          }
        }}
      />
      <FormInputWithLabel
        labelText={'새로운 비밀번호 확인'}
        placeholder={'새로운 비밀번호 확안'}
        inputType={'password'}
        onChange={(val: string) => setConfirmPwd(val)}
        isValid={isConfirmPwdValid}
        value={confirmPwd}
        maxLength={15}
        refObject={confirmPwdRef}
        addInputStyle={!isConfirmPwdValid && RequestStyle.INPUT_ERROR}
        onFocus={() => setIsConfirmPwdValid(true)}
        onBlur={(val: string) => {
          confirmPwdInput(val);
          showToastMessage();
        }}
      />
      <div className={css(RequestFont.DESCRIPTION, styles.description)}>
        - 영문, 숫자, 특수문자를 조합하여 9 ~ 15자리로 설정합니다.<br/>
        - 특수문자는 ~!@#$%^&*-+ 만 가능합니다.
      </div>
      <VerticalSpace/>
      <RequestButton title={'다음'} onClick={clickHandler} isActive={btnActive}/>
    </RequestWrapperWithHeader>
  );
};

export default HomeTaxPasswordReset;
const styles = CreateBetaStyle({
  wrapper: {
    height: '100vh',
  },
  title: {
    marginBottom: '16px',
  },
  description: {
    marginTop: '32px',
    letterSpacing: '-0.5px',
  }
});
