import { CreateStyle } from '../../../../shared/presentation/shared/CreateStyle';
import { spoqa } from '../../../../shared/css/css-utils';

export const ServiceStyle = (isMobile: boolean, isTablet: boolean) => CreateStyle({
  background: {
    height: '512px/599px@1100px',
    width: '100%',

    paddingTop: '41px/91px@90px',
    paddingLeft: '0px/335px@88px',

    fontFamily: spoqa,
  },
  title: {
    marginLeft: '20px/',
    marginBottom: '/@10px',

    fontSize: '20px/35px@43px',
    fontWeight: 300,

    lineHeight: 1.4,
  },
  blue_text: {
    color: process.env.REACT_APP_BASE_COLOR,
  },
  main_div: {
    display: 'flex',
    flexDirection_: 'column/@column',

    marginTop: '5px/63px',
    marginLeft: '20px/'
  },
  main__service: {
    width: '285px/398px@597px',
    height: '/297px@260px',

    display_: 'flex/@flex',
    alignItems_: '/@center',

    position: 'relative',
  },
  margin_left: {
    marginLeft: '/52px',
  },
  border_right: {
    borderRight: '1px solid rgba(151,151,151,0.3)',
  },
  border_bottom: {
    borderBottom: '1px solid rgba(151,151,151,0.3)',
  },
  main__service__image_div: {
    width: '112px/348px@266px',
    height: '85px/245px@181px',

    position: 'relative',

    marginTop: '20px/',
    marginBottom: '20px/22px',
  },
  main__service__image_style: {
    width: '112px/348px@238px',
    height: '85px/245px@181px',
  },
  main__service__content: {
    color: process.env.REACT_APP_BASE_COLOR,

    fontSize: '15px/22px@33px',
    lineHeight: 1.64,
    fontWeight: 300,

    marginTop: '20px/',
    marginLeft: '13px/',
  },
  main__service__mobile__content: {
    fontSize: '11px/11px@23px',
    fontWeight: 300,
    lineHeight: 1.73,
    color: '#000',

    paddingTop: '10px',

    whiteSpace: 'pre',
  },
  main__service__image__hover: {
    width: '/348px',
    height: '/245px',

    position: 'absolute',
    left: 0,
    bottom: 0,

    fontSize: '/15px',
    fontWeight: 300,
    lineHeight: 2,
    color: 'white',

    textAlign: 'center',

    whiteSpace: 'pre',

    background: process.env.REACT_APP_BASE_COLOR,

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  box_hidden: {
    opacity: 0,
    height: '/0px',
  },
  transition_basic: {
    transition: 'height 0.8s cubic-bezier(0.0, 0.0, 0.2, 1) 0s, opacity 0.8s cubic-bezier(0.0, 0.0, 0.2, 1) 0s',
  },

}, isMobile, isTablet);