import { atom } from 'recoil';

type Toast = {
  message?: string;
  isShow: boolean;
}

export const toastState = atom<Toast>({
  key: 'toastState',
  default: {
    message: '올바르지 않은 형식입니다',
    isShow: false,
  },
});
