import React, { useRef, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import axios from 'axios';
import { css } from 'aphrodite';

import { CreateBetaStyle } from '../../../../shared/presentation/shared/CreateStyle';
import { RequestWrapperWithHeader } from '../../shared-component/RequestWrapperWithHeader';
import { FormInputLabel, FormInputWithLabel } from '../../shared-component/input/FormInputWithLabel';
import { FormSSNInputWithLabel } from '../../shared-component/input/FormSSNInputWithLabel';
import { SocialSecurityNumberPolicy } from '../../../../shared/domain/SocialSecurityNumberPolicy';
import { RequestColor } from '../../assets/ColorContents';
import { RequestAuthForm, useRequestFormValue } from '../../shared/RequestContents';
import { toastState } from '../../store/RequestStore';
import { isLoadingState } from '../../../../shared/presentation/LoadingFrame';
import { alertMessageState } from '../../shared-component/ShowAlertFrame';
import { useMyHistory } from '../../../../shared/presentation/HistoryHelper';
import { RequestFont } from '../../shared/RequestFont';
import { FormDropDown } from '../../shared-component/FormDropDown';
import { useRequestSupportFamilyFormState } from '../../shared/RequestSupportFamilyContents';
import { RequestButton } from '../../shared-component/RequestButton';
import { VerticalSpace } from '../../../../taxagent/pages/test-input/component/VerticalSpace';
import { REQUEST_PATH } from '../SharedConstants';
import { RequestButtonWrapper } from '../../shared-component/RequestButtonWrapper';
import { encodeBase64 } from '../../utill/base64';

export const RELATIONSHIP_LIST = [
  '본인의 부모님(조부모 포함)',
  '배우자의 부모님(조부모 포함)',
  '배우자',
  '자녀(입양자 포함)',
  '자녀(입양자 제외)',
  '형제자매',
  '수급자',
  '위탁아동',
];

export const AddSupportFamilyForm = () => {
  const setIsLoading = useSetRecoilState(isLoadingState);
  const setAlert = useSetRecoilState(alertMessageState);
  const [request, setRequest, resetRequest] = useRequestSupportFamilyFormState();
  const { customerId } = useRequestFormValue();
  const [ssn2, setSsn2] = useState('');

  // const requestRef = useRef<RequestAuthForm>(request);
  const modalRef = useRef<any>(null);
  const ssnInputRef = useRef<HTMLInputElement>(null);
  const nameInputRef = useRef<HTMLInputElement>(null);

  const history = useMyHistory();

  const [isSsnFrontCorrect, setIsSsnFrontCorrect] = useState(true);
  const [isSsnEndCorrect, setIsSsnEndCorrect] = useState(true);
  const [isNameCorrect, setIsNameCorrect] = useState(true);
  const setToast = useSetRecoilState(toastState);
  const [isSsnInvalid, setIsInvalid] = useState(false);

  // const isSsnInvalid = !isSsnFrontCorrect || !isSsnEndCorrect;

  const onNameInputBlur = (value: string) => {
    setIsNameCorrect(RequestAuthForm.isNameValid(value));
    if (!RequestAuthForm.isNameValid(value)) {
      setToast({
        isShow: true,
        message: '이름을 입력해 주세요',
      });
    }
  };

  const onSsnBlur = (value: string) => {
    setIsSsnEndCorrect(RequestAuthForm.isSsnValid(value));
    if (!RequestAuthForm.isSsnValid(value)) {
      setToast({
        isShow: true,
        message: '올바르지 않은 형식입니다.',
      });
    }
  };

  const clickHandler = async () => {
    if (request.relation === '') {
      setToast({
        isShow: true,
        message: '관계를 선택해주세요.'
      });
      return;
    }
    if (request.name === '') {
      setIsNameCorrect(false);
      setToast({
        isShow: true,
        message: '이름을 적어주세요.'
      });
      return;
    }
    if (!SocialSecurityNumberPolicy.isValid(request.SSN1 + ssn2)) {
      setToast({
        isShow: true,
        message: '올바른 주민등록번호를 입력해주세요.',
      });
      setIsInvalid(true);
      // axios.post('https://api.ailabscorp.com/slack/message', {
      //   message: {
      //     type: 'error',
      //     text: request.name + '님의 주민번호 오류: ' + request.SSN1 + ssn2 + '(부양가족 Step)',
      //     channel: 'total-income-ssn-error'
      //   }
      // });
      return;
    }
    if (request.isDisabled === '') {
      setToast({
        isShow: true,
        message: '장애인 여부를 선택해주세요.'
      });
      return;
    }
    try {
      const body = [
        {
          customerId,
          ssn: encodeBase64(request.SSN1 + ssn2),
          relationship: request.relation,
          name: request.name,
          isDisabled: request.isDisabled === '해당',
        }
      ];
      // save record and history go to 부양가족 정보입력
      await axios.post(
        'https://api.ailabscorp.com/total-income/support-family/save',
        {
          family: body,
        }
      );
    } catch (e) {
      console.log(e);
    } finally {
      resetRequest();
      history.push(REQUEST_PATH.step08);
    }
  };

  return (
    <RequestWrapperWithHeader>
      <p className={css(RequestFont.TITLE)}>부양가족 추가하기</p>
      <div className={css(styles.content)}>
        <FormInputLabel label={'관계'}/>
        <FormDropDown
          placeholder={'관계를 선택해주세요'}
          options={RELATIONSHIP_LIST}
          value={request.relation}
          setValue={value => setRequest({ relation: value })}
        />
        <FormInputWithLabel
          labelText={'이름'}
          placeholder={'이름'}
          addStyle={styles.space}
          onChange={value => setRequest({ name: value })}
          value={request.name}
          id={'request_name'}
          // onFocus={onScrollUp}
          onFocus={() => setIsNameCorrect(true)}
          refObject={nameInputRef}
          // onBlur={handleBlur}
          addInputStyle={!isNameCorrect && styles.invalid}
          // onBlur={value => onNameInputBlur(value)}
          // onKeydown={(e) => {
          //   if (e.key === 'Enter') {
          //     captchaInputRef.current?.focus();
          //   }
          // }}
          isValid={isNameCorrect}
        />
        <FormSSNInputWithLabel
          labelText={'주민등록번호'}
          className={css(styles.space)}
          ssn1Value={request.SSN1 ?? ''}
          ssn2Value={ssn2}
          onSsn1Change={value => {
            setRequest({ SSN1: value });
          }}
          onSsn2Change={value => setSsn2(value)}
          // onSsnBlur={value => onSsnBlur(value)}
          isValid={!isSsnInvalid}
          ref={ssnInputRef}
          onFocus={() => {
            setIsInvalid(false);
            // setIsSsnFrontCorrect(true);
            // setIsSsnEndCorrect(true);
          }}
        />
        <FormInputLabel label={'장애인 여부'}/>
        <FormDropDown
          placeholder={'선택'}
          options={['해당', '비해당']}
          value={request.isDisabled}
          setValue={value => setRequest({ isDisabled: value })}
        />
      </div>
      <VerticalSpace/>
      <RequestButtonWrapper>
        <RequestButton onClick={clickHandler}/>
      </RequestButtonWrapper>
    </RequestWrapperWithHeader>
  );
};

const styles = CreateBetaStyle({
  content: {
    width: '100%',

    paddingBottom: '100px',
  },
  invalid: {
    border: '1px solid red',
  },
  space: {
    marginBottom: '16px',
  },
  ssn: {
    width: '100%',
    height: '56px',

    backgroundColor: RequestColor.GRAY_10,

    borderRadius: '15px',

    display: 'flex',
    alignItems: 'center',

    paddingLeft: '16px',

    fontSize: '20px',
  },
  ssn__front_input: {
    width: '20%',
    height: '100%',

    backgroundColor: 'transparent',

    marginRight: '20px',

    fontSize: '18px',
    color: RequestColor.GRAY_100,

    '::placeholder': {
      color: RequestColor.GRAY_50,
    },
  },
  ssn__end_input: {
    position: 'absolute',
    // display: 'none',
    opacity: 0,
  },
  ssn__end: {
    width: '30%',

    marginLeft: '17px',
  },
  ssn__end__hidden: {
    width: '100%',

    display: 'flex',
    justifyContent: 'space-between',
  },
  ssn__end__dot: {
    width: '8px',
    height: '8px',

    borderRadius: '100%',

    backgroundColor: RequestColor.GRAY_40,
  },
  ssn__end__dot__written: {
    backgroundColor: RequestColor.GRAY_100,
  },
});
