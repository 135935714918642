
export function getDeadLine() {
  const deadline = countDeadline();
  const hour = getTimeFormat(deadline.hour);
  const minute = getTimeFormat(deadline.minute);
  const second = getTimeFormat(deadline.second);

  return `${hour} 시간 ${minute} 분 ${second} 초`;
}

function getTimeFormat(value: number) {
  return (value < 0)
    ? '00'
    : (value < 10)
    ? '0' + value
    : value;
}

export function countDeadline() {
  const deadline = new Date(2022, 4, 31, 18, 0, 0);
  const current = new Date();

  const totalTime = deadline.getTime() - current.getTime();
  const day = Math.floor(totalTime / (1000 * 60 * 60 * 24));
  const hour = Math.floor((totalTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minute = Math.floor((totalTime % (1000 * 60 * 60)) / (1000 * 60));
  const second = Math.floor((totalTime) % (1000 * 60) / 1000);
  return {
    day,
    hour,
    minute,
    second
  };
};
