import { useCookies } from 'react-cookie';
import * as _ from 'lodash';
import { useCallback } from 'react';
import { COOKIE_MAX_AGE } from '../pages/SharedConstants';


const COOKIE_LIST = ['NTS_LOGIN_SYSTEM_CODE_P', 'TXPPsessionID', 'WMONID'];

export interface HometaxCookieProps extends Record<string, string> {
  NTS_LOGIN_SYSTEM_CODE_P: string;
  TXPPsessionID: string;
  WMONID: string;

  [key: string]: string;
}

export const useHometaxCookieState = (): [
  HometaxCookieProps,
  (props: Partial<HometaxCookieProps>) => void,
  any
] => {
  const [cookie, setCookie, resetCookie] = useCookies(COOKIE_LIST);
  const form = _.chain(COOKIE_LIST)
    .map(v => ({
      key: v,
      value: cookie[v],
    }))
    .filter(v => v.value != null)
    .mapKeys(v => v.key)
    .mapValues(v => v.value)
    .value() as HometaxCookieProps;
  return [
    form,
    useCallback((props: Partial<HometaxCookieProps>) => _.forEach(props, (value, key) => setCookie(key, value, {
      path: '/',
      maxAge: COOKIE_MAX_AGE
    })), [setCookie]),
    useCallback(() => {
      COOKIE_LIST.forEach(v => resetCookie(v, { path: '/' }));
    }, [resetCookie])
  ];
};

export const useHometaxCookieValue = (): HometaxCookieProps => {
  const [cookie] = useCookies(COOKIE_LIST);
  return _.chain(COOKIE_LIST)
    .map(v => ({
      key: v,
      value: cookie[v],
    }))
    .filter(v => v.value != null)
    .mapKeys(v => v.key)
    .mapValues(v => v.value)
    .value() as HometaxCookieProps;
};
