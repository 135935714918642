import React, { useEffect, useImperativeHandle, useState } from 'react';
import { css } from 'aphrodite';

import { CreateBetaStyle } from '../../../../../shared/presentation/shared/CreateStyle';
import { VerticalSpace } from '../../../../../taxagent/pages/test-input/component/VerticalSpace';
import { RequestColor } from '../../../assets/ColorContents';
import { RequestButton } from '../../../shared-component/RequestButton';

interface Props {
  onAgree?: () => void,
}

export const ModalView = React.forwardRef(({ onAgree }: Props, ref) => {
  const [isShow, setIsShow] = useState(false);
  const [innerHeight, setInnerHeight] = useState(window.innerHeight);
  useEffect(() => {
    document.body.style.overflow = isShow ? 'hidden' : 'auto';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isShow]);

  useEffect(() => {
    const interval = setInterval(() => setInnerHeight(window.innerHeight), 100);
    return () => clearInterval(interval);
  }, []);

  useImperativeHandle(ref, () => ({
    show: () => setIsShow(true),
    hide: () => setIsShow(false),
  }));

  const onClickNext = () => {
    onAgree?.();
    setIsShow(false);
  };

  // const handleEnter = (e: any) => {
  //   if (e.target == null) {
  //     return;
  //   }
  //   if (e.key === 'Enter') {
  //     onClickNext();
  //   }
  // };
  //
  // useEffect(() => {
  //   document.addEventListener('keydown', handleEnter);
  //   return () => document.removeEventListener('keydown', handleEnter);
  // }, []);
  const styles = ModalStyle(innerHeight);

  return <>
    {isShow && (
      <div className={css(styles.wrapper)}>
        <div className={css(styles.frame)}>
          <div className={css(styles.title)}>
            서비스 이용을 위해<br/>
            모두 동의합니다.
          </div>
          <div className={css(styles.content)}>
            [필수] 개인정보 이용 동의<br/>
            [필수] 서비스 이용 약관 동의<br/>
            [필수] 고유식별정보 처리 동의<br/>
            [필수] 제3자 정보제공 동의<br/>
          </div>
          <VerticalSpace/>
          <RequestButton
            title={'동의 후 계속'}
            onClick={() => onClickNext()}
          />
        </div>
      </div>
    )}
  </>;
});

const ModalStyle = (innerHeight: number) => CreateBetaStyle({
  wrapper: {
    width: '100vw',
    height: innerHeight,

    backgroundColor: 'rgba(32, 35, 40, 0.6)',

    position: 'fixed',
    left: 0,
    top: 0,
    zIndex: 500,

    display: 'flex',
    justifyContent: 'center',
  },
  frame: {
    width: '100%',
    maxWidth: '640px',
    height: '356px',

    padding: '32px 6vw 0',

    backgroundColor: RequestColor.WHITE,

    position: 'absolute',
    bottom: 0,

    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
  },
  title: {
    fontSize: '22px',
    lineHeight: '30px',
    color: RequestColor.GRAY_100,
    fontWeight: 500,

    marginBottom: '12px',
  },
  content: {
    fontSize: '16px',
    lineHeight: '32px',
    color: RequestColor.GRAY_60,
  },
});
