import React, { useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import { css } from 'aphrodite';
import { atom, useRecoilState, useRecoilValue } from 'recoil';

import { CreateStyle } from '../../../shared/presentation/shared/CreateStyle';
import { spoqa } from '../../../shared/css/css-utils';
import { MetaTaxHeader } from './component/MetaTaxHeader';
import * as _ from 'lodash';
import { VerticalSpace } from './component/VerticalSpace';
import { MetaTaxButton } from './component/MetaTaxButton';
import { useMyHistory } from '../../../shared/presentation/HistoryHelper';
import { MetaTaxDescription } from './component/MetaTaxDescription';
import { RemainCardPoint } from '../../../the-refund/pages/request/5_환급액_확인/component/RemainCardPoint';

export const cardPointState = atom<{ points: any[], sessionId: string, bankCodes: { [key: string]: string } }>({
  key: 'cardPointState',
  default: {
    points: [],
    sessionId: '',
    bankCodes: {},
  },
});

export const metaTaxSessionIdState = atom<string>({
  key: 'metaTaxSessionIdState',
  default: '',
});

export const MetaTaxTestCardPoints: React.FC = () => {
  const sessionId = useRecoilValue(metaTaxSessionIdState);
  const [{ points }, setCardPoints] = useRecoilState(cardPointState);
  const cardPoints = points.map(v => new RemainCardPoint(v));
  const [isLoading, setIsLoading] = useState(false);
  const styles = Styles();
  const totalPoint = useMemo(() => _.chain(cardPoints).map(v => v.pntEncashReqArr).sum().value(), [cardPoints]);
  const history = useMyHistory();
  useEffect(() => {
    if (sessionId === '') {
      return;
    }
    fetchPoints(sessionId);
  }, [sessionId]);

  const fetchPoints = async (_sessionId: string) => {
    setIsLoading(true);
    try {
      const { data: result } = await axios.get('https://api.ailabscorp.com/yushin/point/' + _sessionId);
      setCardPoints(result);
    } catch (e) {
      alert(e.message);
    } finally {
      setIsLoading(false);
    }
  };

  const onClickButton = () => {
    history.push('bank');
  };

  return <div className={css(styles.wrapper)}>
    <MetaTaxHeader/>
    {isLoading ? (
      <>
        <MetaTaxDescription text={'숨은 카드포인트를\n찾고 있어요'}/>
      </>
    ) : cardPoints.length === 0 ? (
      <>
        <MetaTaxDescription
          text={'카드포인트를\n찾지 못했어요'}
          className={css(styles.notFound)}
        />
        <p className={css(styles.comment)}>
          한달 뒤에 다시 신청하시면 잠들어 있는<br/>
          포인트를 찾으실 수 있어요!
        </p>
        <VerticalSpace/>
        <MetaTaxButton
          title={'한달 뒤에 알림 받기'}
          className={css(styles.notFound)}
        />
        <MetaTaxButton
          title={'아니오. 괜찮습니다'}
          className={css(styles.cancel)}
        />
      </>
    ) : (
      <>
        <MetaTaxDescription
          text={'숨은 카드포인트를\n찾았어요!'}
          className={css(styles.description)}
        />
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '48px'}}>
          <p className={css(styles.totalPoint)}>{totalPoint.toLocaleString()}원 </p>
          <p className={css(styles.money)}>💵</p>
        </div>
        <div className={css(styles.cardList)}>
          {cardPoints.map((point, index) => (
            <div className={css(styles.cardItem)} key={`cardItem-${index}`}>
              <div>{point.title}</div>
              <VerticalSpace/>
              <div>{point.pntEncashReqArr.toLocaleString()}원</div>
            </div>
          ))}
          <div className={css(styles.cardItem)}>
            <div>포인트 합계</div>
            <VerticalSpace/>
            <strong>{totalPoint.toLocaleString()}원</strong>
          </div>
        </div>
        <VerticalSpace/>
        <MetaTaxButton title={'내 계좌로 입금받기'} onClick={onClickButton}/>
      </>
    )}
  </div>;
};

const Styles = () => CreateStyle({
  wrapper: {
    minHeight: '100vh',
    color: 'black',
    padding: '83px 30px 30px/',

    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'center',
  },
  description: {
    marginBottom: '45px',
  },
  notFound: {
    marginBottom: '15px',
  },
  comment: {
    fontFamily: spoqa,
    fontSize: '16px',
    lineHeight: '26px',
    color: '#838A9A',
  },
  cancel: {
    backgroundColor: '#E0E0E0',
  },
  totalPoint: {
    fontFamily: spoqa,
    fontWeight: 700,
    fontSize: '33px',
  },
  money: {
    marginTop: '5px',
    marginLeft: '15px',
    fontSize: '40px',
  },
  cardList: {
    borderRadius: '10px',
    border: '2px solid #F0F2F4',
  },
  cardItem: {
    margin: '0 21px',
    borderTop: '2px solid #F0F2F4',
    height: '61px',

    display: 'flex',
    alignItems: 'center',
    fontFamily: spoqa,
    fontSize: '16px',
    color: '#838A9A',

    ':nth-child(1n) > strong': {
      color: '#006AFF',
    },

    ':first-child': {
      borderTop: '0px solid #F0F2F4',
    },

    ':last-child': {
      margin: '0',
      padding: '0 21px',
      borderTop: '2px solid #F0F2F4',
      backgroundColor: '#F9FAFB',

      fontWeight: 700,
      color: '#454F5D',
    }
  }
}, true, false, { isNoRatio: true });
