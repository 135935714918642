import React, { useEffect, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { css } from 'aphrodite';

import { RequestWrapperWithHeader } from '../../shared-component/RequestWrapperWithHeader';
import { RequestFont } from '../../shared/RequestFont';
import { CreateBetaStyle } from '../../../../shared/presentation/shared/CreateStyle';
import { RequestColor } from '../../assets/ColorContents';
import RequestVerticalButtons from '../../shared-component/RequestVerticalButtons';
import { RequestButtonWrapper } from '../../shared-component/RequestButtonWrapper';
import { IncomeList } from './IncomeList';
import { requestAlertMessageState } from '../../shared-component/RequestAlertFrame';

const DUMMY = [
  {
    company: '컴퍼니랩',
    price: 12000000,
  },
  {
    company: '컴퍼니랩',
    price: 12000000,
  },
  {
    company: '컴퍼니랩',
    price: 12000000,
  },
  {
    company: '컴퍼니랩',
    price: 12000000,
  },
  {
    company: '컴퍼니랩',
    price: 12000000,
  },
];

export const IncomeResult: React.FC = () => {
  const [totalPrice, setTotalPrice] = useState(0);
  useEffect(() => {
    let total = 0;
    DUMMY.map(v => {
      total += v.price;
    });
    setTotalPrice(total);
  }, []);
  const showAlert = useSetRecoilState(requestAlertMessageState);
  showAlert({
    title: '심야연락에 동의하시나요?',
    message: '‘동의’시 상담원이 심야시간(밤9시-자정)에 전화연락을 드립니다. ‘미동의’ 시 업무시간이 넘어갈 경우 익일 연락될 예정입니다.',
    button: [
      {
        title: '미동의',
        onClick: () => {
        },
      },
      {
        title: '동의',
        onClick: () => {
        },
      },
    ]
  });
  return (
    <RequestWrapperWithHeader>
      <p className={css(RequestFont.TITLE, styles.title)}>
        홍길동님의<br/>
        2021년도 소득(세전)은<br/>
        <span className={css(RequestFont.BLUE_100)}>{totalPrice.toLocaleString()}</span>원 입니다
      </p>
      <p className={css(styles.table_title)}>2021년도 소득</p>
      {/*<div className={css(styles.table)}>*/}
      {/*  <div className={css(styles.table__top)}>*/}
      {/*    {DUMMY.map((item, index) =>*/}
      {/*      <div*/}
      {/*        key={'income_' + index}*/}
      {/*        className={css(styles.table__top_list)}*/}
      {/*      >*/}
      {/*        <p>{item.name}</p>*/}
      {/*        <p>{item.price.toLocaleString()}원</p>*/}
      {/*      </div>*/}
      {/*    )}*/}
      {/*  </div>*/}
      {/*  <div className={css(styles.table__bottom)}>*/}
      {/*    <p>소득 합계</p>*/}
      {/*    <p>123,455원</p>*/}
      {/*  </div>*/}
      {/*</div>*/}
      {/*<p className={css(styles.description)}>*/}
      {/*  * 위 소득은 현 시점에 신고된 지급명세서상의 금액 기준입니다. 금액을 확인한 이후 하단의 버튼을 눌러주세요.*/}
      {/*</p>*/}
      <IncomeList
        incomes={DUMMY}
        total={totalPrice}
        description={'* 위 소득은 현 시점에 신고된 지급명세서상의 금액 기준입니다. 금액을 확인한 이후 하단의 버튼을 눌러주세요.'}
      />
      <RequestButtonWrapper>
        <RequestVerticalButtons items={[
          {
            title: '네, 확인했습니다.',
            marginBottom: 10,
          },
          {
            title: '실제 지급받은 금액과 다릅니다.',
            color: RequestColor.BLUE,
            border: `1px solid ${RequestColor.BLUE}`,
            backgroundColor: RequestColor.WHITE,
          }]}/>
      </RequestButtonWrapper>
    </RequestWrapperWithHeader>
  );
};

const styles = CreateBetaStyle({
  title: {
    marginBottom: '24px',
  },
  table_title: {
    color: RequestColor.GRAY_100,
    fontSize: '18px',
    fontWeight: 500,

    marginBottom: '16px',
    marginLeft: '4px',
  },
  table: {
    width: '100%',
    height: 'auto',

    backgroundColor: RequestColor.GRAY_10,

    borderRadius: '5px',
    border: ['solid', '1px', '#EDEFF4'].join(' '),

    marginBottom: '16px',
  },
  table__top_list: {
    display: 'flex',
    justifyContent: 'space-between',

    padding: '8px 0px',

    fontSize: '16px',
    color: RequestColor.GRAY_60,
  },
  table__top: {
    width: '100%',

    padding: '12px 5% 9px',

    borderBottom: '1px solid #DFE2EA',
  },
  table__top__company: {
    fontSize: '16px',
    color: RequestColor.GRAY_60,
  },
  table__bottom: {
    padding: '19px 5% 20px',

    display: 'flex',
    justifyContent: 'space-between',

    fontSize: '16px',
    color: RequestColor.BLUE,
    fontWeight: 500,
  },
  description: {
    fontSize: '14px',
    lineHeight: '24px',
    color: RequestColor.GRAY_60,
    letterSpacing: '-0.2px',
  },
  button: {},
});
