import React, { useEffect } from 'react';
import { css } from 'aphrodite';
import { useRecoilState } from 'recoil';

import { CreateBetaStyle } from '../../../../shared/presentation/shared/CreateStyle';
import { RequestFont } from '../../shared/RequestFont';
import { toastState } from '../../store/RequestStore';

const Toast = () => {
  const [toast, setToast] = useRecoilState(toastState);
  const { isShow, message } = toast;

  useEffect(() => {
    const interval = setInterval(() => {
      setToast({ isShow: false });
    }, 3000);

    return () => clearInterval(interval);
  }, [toast.isShow, setToast]);

  return (
    <div className={css(style.wrapper, isShow ? style.show : style.hide)}>
      <p className={css(RequestFont.TOAST_MESSAGE, style.message)}>{message}</p>
    </div>
  );
};

export default Toast;

const toastSlideIn = {
  from: {
    display: 'block',
    top: '-50%',
  },
  to: {
    top: '4px',
  }
};

const toastSlideOut = {
  from: {
    top: '4px',
  },
  to: {
    display: 'none',
    top: '-50%',
  }
};

const style = CreateBetaStyle({
  wrapper: {
    position: 'fixed',
    left: '50%',
    transform: 'translateX(-50%)',
    zIndex: 999,

    width: 'max-content',
    height: '48px',

    backgroundColor: 'rgba(67, 79, 92, 0.9)',
    backdropFilter: 'blur(4px)',
    borderRadius: '50px',

    padding: '0px 15px',
  },
  show: {
    animationName: toastSlideIn,
    animationIterationCount: 1,
    animationDuration: '1s',
    animationFillMode: 'forwards',
  },
  hide: {
    animationName: toastSlideOut,
    animationIterationCount: 1,
    animationDuration: '1s',
    animationFillMode: 'forwards',
  },
  message: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '48px',
  }
});
