import React, { useCallback } from 'react';
import { css } from 'aphrodite';

import { CreateBetaStyle } from '../../../../shared/presentation/shared/CreateStyle';
import { RequestWrapperWithHeader } from '../../shared-component/RequestWrapperWithHeader';
import { RequestFont } from '../../shared/RequestFont';
import { RequestColor } from '../../assets/ColorContents';
import { VerticalSpace } from '../../../../taxagent/pages/test-input/component/VerticalSpace';
import RequestVerticalButtons from '../../shared-component/RequestVerticalButtons';
import { useMyHistory } from '../../../../shared/presentation/HistoryHelper';
import { REQUEST_PATH } from '../SharedConstants';
import { RequestButtonWrapper } from '../../shared-component/RequestButtonWrapper';
import axios from 'axios';
import { useRequestFormValue } from '../../shared/RequestContents';

const SupportFamily = () => {
  const history = useMyHistory();
  const { customerId } = useRequestFormValue();
  const onClickNoSupportFamily = useCallback(async () => {
    await axios.post('https://api.ailabscorp.com/total-income/support-family/done',
      {
        customerId,
      });
    history.push(REQUEST_PATH.step12);
  }, [history]);

  return (
    <RequestWrapperWithHeader>
      <div className={css(RequestFont.TITLE, styles.title)}>
        <p>마지막 단계에요!</p>
        <p>부양가족이 있으신가요?</p>
      </div>
      <p className={css(RequestFont.DESCRIPTION_TITLE)}>부양가족을 입력하시면 환급액이 늘어나요!</p>
      <div className={css(styles.description_box)}>
        <p className={css(RequestFont.CONTENT_TITLE)}>부양가족 기준</p>
        <div className={css(RequestFont.CONTENT)}>
          <p>
            • 배우자, 부모/조부모, 자녀 또는 동거 중인
          </p>
          <p className={css(styles.description_indent)}>형제자매</p>
          <p>
            • 만 20세 이하 또는 만 60세 이상
          </p>
          <p>
            • 연 소득금액 100만원 이하
          </p>
          <p>
            • 단, 장애인/수급자/위탁아동의
          </p>
          <p className={css(styles.description_indent)}>경우 나이와 상관 없이 연 소득금액</p>
          <p className={css(styles.description_indent)}>100만원 이하</p>
          <p>
            • 본인이 장애인일 경우
          </p>
        </div>
      </div>
      <VerticalSpace/>
      <RequestButtonWrapper>
        <RequestVerticalButtons items={[
          {
            title: '부양가족 입력하기',
            marginBottom: 12,
            onClick: () => history.push(REQUEST_PATH.step08),
          },
          {
            title: '부양가족 입력안함',
            backgroundColor: RequestColor.WHITE,
            color: RequestColor.BLUE,
            border: '1px solid' + RequestColor.BLUE,
            onClick: onClickNoSupportFamily,
          }
        ]}
        />
      </RequestButtonWrapper>
    </RequestWrapperWithHeader>
  );
};

export default SupportFamily;

const styles = CreateBetaStyle({
  wrapper: {
    height: '100vh',
  },
  title: {
    marginBottom: '8px',
  },
  description_box: {
    marginTop: '24px',

    backgroundColor: RequestColor.GRAY_30,
    // width: '327px',
    width: '100%',
    height: 'max-content',
    borderRadius: '5px',

    padding: '16px 19px 24px 16px',
    marginBottom: '200px',
  },
  description_indent: {
    textIndent: '18px',
  }
});
