import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { AlertFrame } from '../request-page-2022/shared-component/ShowAlertFrame';
import { PaymentSuccess } from './pages/4_결제완료/PaymentSuccess';
import { InquireRequest } from './pages/1_신청정보_조회/InquireRequest';
import { ConfirmIncome } from './pages/2_0_소득정보/ConfirmIncome';
import { RefundAccount } from './pages/3_환불계좌_입력/RefundAccount';
import { RequestAlertFrame } from '../request-page-2022/shared-component/RequestAlertFrame';
import { MobilePaymentComplete } from './pages/2_1_모바일_결제_결과/MobilePaymentComplete';

export const TheRefundPaymentPage: React.FC = () => {
  const { step } = useParams<any>();

  const StepNode = useMemo(() => mapToStep(step), [step]);
  return (
    <>
      <AlertFrame/>
      <RequestAlertFrame/>
      {StepNode}
    </>
  );
};

const mapToStep = (step: string) => {
  switch (step) {
    case '0':
      return <InquireRequest/>;
    case '1':
      return <ConfirmIncome/>;
    // case '1-1':
    //   return <PaymentInfo/>;
    case '2':
      return <RefundAccount/>;
    case '3':
      return <PaymentSuccess/>;
    case 'complete':
      return <MobilePaymentComplete/>;
    default:
      return <InquireRequest/>;
  }
};
