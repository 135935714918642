import React, { useEffect, useState } from 'react';
import { css } from 'aphrodite/no-important';
import { useSetRecoilState } from 'recoil';
import axios from 'axios';

import { RequestFont } from '../../shared/RequestFont';
import { CreateBetaStyle } from '../../../../shared/presentation/shared/CreateStyle';
import Party from '../../assets/gif/party_popper.gif';
import { Image } from '../../../../shared/css/Image';
import { RequestStyle } from '../../shared/RequestStyle';
import RequestVerticalButtons from '../../shared-component/RequestVerticalButtons';
import { RequestButtonWrapper } from '../../shared-component/RequestButtonWrapper';
import { GroupConsultPopup } from './GroupConsultPopup';
import { useMyHistory } from '../../../../shared/presentation/HistoryHelper';
import { RequestWrapperWithHeader } from '../../shared-component/RequestWrapperWithHeader';
import { requestAlertMessageState } from '../../shared-component/RequestAlertFrame';
import { isLoadingState } from '../../../../shared/presentation/LoadingFrame';
import { useGroupRequestFormState } from '../../shared/RequestGroupContents';

export const GroupRequestSuccess: React.FC = () => {
  const history = useMyHistory();
  const [isInquire, setIsInquire] = useState(true);
  const [request, setRequest] = useGroupRequestFormState();
  const showAlert = useSetRecoilState(requestAlertMessageState);
  const setIsLoading = useSetRecoilState(isLoadingState);

  const onClick = async (isLateNight: boolean) => {
    setIsLoading(true);
    await axios.post('https://api.ailabscorp.com/total-income-tax/group-reception/late',
      {
        id: request.id,
        isLateNight,
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    showAlert({
      title: '심야연락에 동의하시나요?',
      message: '‘동의’시 상담원이 심야시간(밤9시-자정)에 전화\n연락을 드립니다. ‘미동의’ 시 업무시간이 넘어갈 \n경우 익일 연락될 예정입니다.',
      button: [
        {
          title: '미동의',
          onClick: () => onClick(false),
        },
        {
          title: '동의',
          onClick: () => onClick(true),
        },
      ]
    });
  }, []);
  return (
    <>
      {isInquire && (
        <GroupConsultPopup
          onClickBottom={() => setIsInquire(false)}
          onClickTop={() => setIsInquire(false)}
        />
      )}
      <RequestWrapperWithHeader>
        <p className={css(RequestFont.TITLE, styles.title)}>
          신청이 완료되었습니다
        </p>
        <p className={css(RequestFont.DESCRIPTION)}>
          신청 완료되었습니다.<br/>
          빠른 시일 내에 연락드리도록 하겠습니다.
        </p>
        <div className={css(styles.gif_frame, RequestStyle.COLUMN_CENTER)}>
          <Image src={Party} alt={'party'} className={css(styles.gif)}/>
        </div>
        <RequestButtonWrapper>
          <RequestVerticalButtons items={[
            {
              title: '홈으로 이동',
              marginBottom: 10,
              onClick: () => history.push('/'),
            }]}
          />
        </RequestButtonWrapper>
      </RequestWrapperWithHeader>
    </>
  );
};

const styles = CreateBetaStyle({
  title: {
    marginBottom: '8px',
  },
  gif_frame: {
    width: '100%',

    marginTop: '65px',
  },
  gif: {
    width: '208px',
    height: '208px',
  },
});
