import React, { useEffect, useState } from 'react';
import { css } from 'aphrodite';
import { withRouter } from 'react-router';
import { useRecoilValue } from 'recoil';
import { useHistory } from 'react-router-dom';
import * as _ from 'lodash';

import WhiteLogo from '../../assets/images/logo_white.png';
import BlueLogo from '../../assets/images/logo_blue.png';
import { ReactComponent as Logo } from '../../assets/svg/logo_unIE.svg';
import { Image } from '../../../shared/css/Image';
import { MenuButton } from '../../../shared/presentation/header/MenuButton';
import { MobileCloseButton } from '../../../shared/presentation/header/CloseButton';
import { HeaderStyle } from './HeaderStyle';
import { isIE, moveToHome } from '../../../shared/helper/Util';
import { MobileKakaoButton } from '../../../shared/presentation/header/KakaoButton';
import { isMobileState, isTabletState } from '../../../shared/presentation/MobileFrame';
import { IHistory, isShowMenu, isShowRequestPopup } from '../../../shared/presentation/HistoryHelper';
import { goToManualPage, goToRequestPage, goToSavetax } from '../../../shared/store/TaxagentStore';
import { isPeriodPopUpState } from '../popup/PeriodPopUp';

function Header() {
  const isMobile = useRecoilValue(isMobileState);
  const isTablet = useRecoilValue(isTabletState);
  const isPeriodPopUp = useRecoilValue(isPeriodPopUpState);
  const [scrollLength, setScrollLength] = useState(0);
  const [isTop, setIsTop] = useState(true);
  const [headerFixed, setHeaderFixed] = useState(false);
  const history = useHistory<IHistory>();

  const detectScroll = () => {
    setScrollLength(window.pageYOffset || document.documentElement.scrollTop);
    setIsTop(scrollLength <= (isMobile ? 400 : 980));
    setHeaderFixed(scrollLength > (isMobile ? 400 : 980));
  };

  useEffect(() => {
    window.addEventListener('scroll', _.throttle(detectScroll, 45));
    return () => window.removeEventListener('scroll', _.throttle(detectScroll, 45));
  }, []);

  const isMenu = isShowMenu(history);
  const isRequest = isShowRequestPopup(history);
  const styles = HeaderStyle(
    isMobile,
    isPeriodPopUp,
    isMenu ? false : isTop,
    isTablet,
  );

  return (
    <div className={css(
      styles.background,
      (isRequest || headerFixed) && styles.background__fixed,
      isRequest && styles.background_border,
      isMenu && styles.background__menu_on,
    )}>
      {isIE()
        ? <Image
          src={(isRequest || headerFixed)
            ? isMenu
              ? WhiteLogo
              : BlueLogo
            : WhiteLogo}
          alt={'logo'}
          className={css(styles.logo)}
          onClick={() => moveToHome(history)}
        />
        : <Logo
          className={
            css(styles.logo,
              (isMenu || isTop) && styles.logo__fill_white,
              isRequest && styles.logo__fill_blue,
              isMenu && styles.logo__fill_white
            )}
          onClick={() => moveToHome(history)}
        />
      }
      {(isMobile || isTablet) ?
        <div className={css(styles.menu_div)}>
          {/*<RequestButton*/}
          {/*  isRequestPage={isRequest}*/}
          {/*  baseColor={isRequest*/}
          {/*    ? isShowMenu*/}
          {/*      ? 'white'*/}
          {/*      : process.env.REACT_APP_BASE_COLOR*/}
          {/*    : this.headerFixed*/}
          {/*      ? isShowMenu*/}
          {/*        ? 'white'*/}
          {/*        : 'black'*/}
          {/*      : 'white'*/}
          {/*  }*/}
          {/*  {...this.props}*/}
          {/*/>*/}
          <MobileKakaoButton
            baseColor={
              isRequest
                ? isMenu
                ? 'white'
                : process.env.REACT_APP_BASE_COLOR
                : headerFixed
                ? isMenu
                  ? 'white'
                  : 'black'
                : 'white'}
            isRequestPage={isRequest}
            tabletHeight={100}
          />
          {isMenu ?
            <MobileCloseButton
              baseColor={'white'}
              tabletHeight={100}
            />
            : <MenuButton
              isRequestPage={isRequest}
              baseColor={isRequest
                ? process.env.REACT_APP_BASE_COLOR
                : headerFixed
                  ? 'black'
                  : 'white'}
              tabletHeight={100}
            />
          }
        </div>
        : <div
          className={css(styles.menu_div, headerFixed && styles.menu__blue_font)}
        >
          <div
            className={css(styles.menu__list)}
            onClick={goToSavetax}
          >
            세이브택스 홈페이지
          </div>
          {/*<div*/}
          {/*  className={css(styles.menu__list, isRequest && styles.menu__list__border)}*/}
          {/*  onClick={goToManualPage}*/}
          {/*>*/}
          {/*  종합소득세 자료 조회방법*/}
          {/*</div>*/}
          {!isRequest && headerFixed && (
            <div
              className={css(styles.menu__request_button)}
              onClick={goToRequestPage}
            >
              {process.env.REACT_APP_ACTIVE === 'FALSE'
                ? '신청이 마감되었습니다'
                : '간편 신청하기'}
            </div>
          )}
        </div>
      }
    </div>
  );
}

export default withRouter(Header);
