import React from 'react';
import { css } from 'aphrodite';
import { useRecoilValue } from 'recoil';

import { CreateStyle } from '../../../../shared/presentation/shared/CreateStyle';
import { spoqa } from '../../../../shared/css/css-utils';
import { TABLE_LIST } from '../../shared/Constant';
import { AdditionalInfo } from '../../../../taxagent-shared/component/PriceSectionFunc';
import { totalScreenState } from '../../../../shared/presentation/MobileFrame';

function PriceSection() {
  const { isMobile, isTablet } = useRecoilValue(totalScreenState);

  const styles = PriceStyle(isMobile, isTablet);
  return (
    <div className={css(styles.background)}>
      <div className={css(styles.left_box)}>
        <div className={css(styles.left__title)}>
          종합소득세 신고대리 수수료
        </div>
        {(!isMobile && !isTablet) &&
        <div className={css(styles.left__additional_info)}>
          {AdditionalInfo()}
        </div>}
      </div>
      <div className={css(styles.table_box)}>
        {TABLE_LIST.map((item, index) =>
          <div key={'table-' + index}>
            <div
              className={
                css(styles.table__list,
                  !item.isLast &&
                  styles.table__border_bottom,
                  (isTablet && item.isFirst) && styles.table__align_item_none,
                )}>
              <div className={css(styles.table__title, item.isFirst && styles.table__title__opacity)}>
                {item.title}
              </div>
              <div className={css(styles.table__old_price)}>
                {!item.isFirst && !item.isLast &&
                <div className={css(styles.table__old_price__text_decoration)}/>}
                {item.oldPrice}
              </div>
              <div className={css(styles.table__new_price)}>
                {item.newPrice}
              </div>
              {!item.isFirst && !item.isLast && <div className={css(styles.table__notice)}>
                <div className={css(styles.table__notice__dot)}/>
                특가 할인
              </div>}
            </div>
          </div>
        )}
      </div>
      {(isMobile || isTablet) &&
      <div className={css(styles.left__additional_info)}>
        {AdditionalInfo()}
      </div>}
    </div>
  );
}

const PriceStyle = (isMobile: boolean, isTablet: boolean) => CreateStyle({
  background: {
    height: '520px/605px@1192px',
    backgroundColor: process.env.REACT_APP_BASE_COLOR,

    color: 'white',
    fontFamily: spoqa,

    display_: '/flex',
    justifyContent_: '/space-between',

    paddingTop: '35px/91px@84px',
    paddingLeft: '20px/335px@85px',
    paddingRight: '/337px@',
  },
  left_box: {
    height: '/415px',

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  left__title: {
    fontWeight: 300,
    fontSize: '20px/35px@43px',
    lineHeight: 1.4,

    marginBottom: '14px/@50px',
  },
  left__additional_info: {
    fontWeight: 300,
    fontSize: '11px/16px@23px',
    lineHeight: 1.75,
    opacity: 0.5,
  },
  table_box: {
    fontWeight: 300,
    fontSize: '11px/16px@23px',

    marginTop: '/45px',
    marginBottom: '10px/@53px',
  },
  table__list: {
    width: '279px/612px@607px',
    height: '44px/54px@93px',

    display: 'flex',
    alignItems: 'center',
  },
  table__align_item_none: {
    alignItems: 'flex-start',
    paddingTop: '2vw',
  },
  table__border_bottom: {
    borderBottom: 'solid 1px rgba(225,225,225,0.35)',
  },
  table__title: {
    width: '127px/310px@280px',
  },
  table__title__opacity: {
    opacity: 0.5,
  },
  table__old_price: {
    width: '48px/70px@104px',
    opacity: 0.5,

    whiteSpace: 'pre',

    position: 'relative',
  },
  table__old_price__text_decoration: {
    width: '40px/55px@85px',

    borderBottom: 'solid 1.5px rgba(255,255,255,0.5)',

    position: 'absolute',
    top: '50%',
    left: '-50%',
    transform_: 'translateX(59%)/translateX(60%)@translateX(57%)',
  },
  table__new_price: {
    width: '52px/150px@107px',

    whiteSpace: 'pre',
  },
  table__notice: {
    display: 'flex',
    alignItems: 'center',

    fontSize: '9px/@22px',
  },
  table__notice__dot: {
    width: '7px/8px@17px',
    height: '7px/8px@17px',
    borderRadius: '50%',
    backgroundColor: 'white',

    marginRight: '6px/10px@12px',
  },
}, isMobile, isTablet);

export default PriceSection;
