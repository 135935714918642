import React from 'react';
import { css } from 'aphrodite';
import { useRecoilState, useRecoilValue } from 'recoil';

import { CreateStyle } from '../../../shared/presentation/shared/CreateStyle';
import SuccessImage from '../../assets/images/request_succes_background.png';
import { Image } from '../../../shared/css/Image';
import { ReactComponent as Logo } from '../../assets/svg/company_logo.svg';
import { spoqa } from '../../../shared/css/css-utils';
import { isMobileState } from '../../../shared/presentation/MobileFrame';
import { isRequestSuccessState } from '../../../freeget/pages/popup/RequestSuccessPopUp';

function RequestSuccessPopUp() {
  const isMobile = useRecoilValue(isMobileState);
  const [isShowSuccessPopUp, setIsShowSuccessPopUp] = useRecoilState(isRequestSuccessState);
  ;

  const styles = PopUpStyle(isMobile);

  function onClickConfirmButton() {
    setIsShowSuccessPopUp(false);
    // this.props.taxagentRequestStore!.closePopUp();
    window.open('/', '_self');
  }

  return (
    <>
      {isShowSuccessPopUp &&
      SuccessPopUpFrame({
        isMobile,
        onClick: () => onClickConfirmButton(),
        text: '종합소득세 신고대리 신청이 \n정상적으로 완료되었습니다. 감사합니다.',
      })

      }
    </>
  );
}

export function SuccessPopUpFrame({isMobile, onClick, text}: { isMobile: boolean, onClick: () => any, text: string }) {
  const styles = PopUpStyle(isMobile);
  return (
    <div className={'popup background'}>
      <div className={css(styles.popup_frame)}>
        {!isMobile && (
          <div className={css(styles.popup__left_div)}>
            <Image
              src={SuccessImage}
              alt={'company'}
              className={css(styles.popup__left_background)}
            />
            <Logo className={css(styles.popup__left_logo)}/>
          </div>
        )}
        <div className={css(styles.popup__right_div)}>
          <div className={css(styles.popup__right__title)}>
            신청완료<br/>
            {!isMobile && '감사합니다.'}
          </div>
          {!isMobile &&
          <div className={css(styles.popup__right__info)}>
            빠른 연락드리겠습니다.
          </div>}
          {isMobile &&
          <div className={css(styles.popup__right__info)}>
            {text}
          </div>
          }
          <div className={css(styles.popup__right__content)}>
            상담신청을 위하여 남겨주신 개인정보는 <br/>
            개인정보취급방침에 의거하여 100% 안전하게 보호됩니다.
          </div>
          <div className={css(styles.popup__right__request_button)}
               onClick={() => onClick()}>
            확인
          </div>
        </div>
      </div>
    </div>
  );
}

const PopUpStyle = (isMobile: boolean) => CreateStyle({
  popup_frame: {
    width: '274px/860px',
    height: '200px/500px',

    display: 'flex',

    color: '#3466fe',

    borderRadius: '3px/',

    marginTop: '-140px/',

    fontFamily: spoqa,
  },
  popup__left_div: {
    width: '/450px',
    height: '/500px',

    paddingTop: '/201px',
    paddingLeft: '/80px',

    position: 'relative',
  },
  popup__left_background: {
    position: 'absolute',
    width: '100%',
    height: '100%',

    top: 0,
    left: 0,
    zIndex: -2,

    objectFit: 'cover',
  },
  popup__left_logo: {
    height: '/50px',
    width: '/283px'
  },
  popup__right_div: {
    width: '274px/410px',
    height: '200px/500px',

    borderRadius: '3px/',

    paddingLeft: '/52px',
    paddingTop: '31px/57px',

    display_: 'flex/',
    flexDirection_: 'column/',
    alignItems_: 'center/',

    backgroundColor: 'white',

    position: 'relative',
  },
  popup__right__close: {
    width: '14px/21px',
    height: '14px/21px',

    fill: 'black',

    position: 'absolute',
    right: '14px/21px',
    top: '16px/24px',

    cursor: 'pointer',
  },
  popup__right__title: {
    fontWeight: 500,
    fontSize: '16px/25px',
    lineHeight: isMobile ? 1.6 : 1.45,

    marginBottom: '13px/20px',

    textAlign_: 'center/',
  },
  popup__right__info: {
    fontWeight: 300,
    fontSize: '13px/17px',
    lineHeight: isMobile ? 1.72 : 1.67,
    textAlign_: 'center/',

    marginBottom: '22px/155px',

    whiteSpace: 'pre',
  },
  popup__right__content: {
    fontWeight: 300,
    fontSize: '10px/12px',
    lineHeight: isMobile ? 2 : 1.86,
    color_: 'white/',
    textAlign_: 'center/',

    marginBottom: '/32px',

    position_: 'absolute/',
    bottom: '-60px/',
  },
  popup__right__request_button: {
    width: '220px/310px',
    height: '44px/50px',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    cursor: 'pointer',

    backgroundColor_: '#387DFF/',

    borderRadius: '3.5px/6.6px',

    border: 'solid 1px #387DFF',

    fontWeight: 500,
    fontSize: '12px/15px',
    color_: 'white/',

    ':hover': {
      background: '#387DFF',
      color: 'white',
      transition: '0.3s',
    },
  },

}, isMobile);

export default RequestSuccessPopUp;
