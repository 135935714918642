import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { css } from 'aphrodite/no-important';
import { useSetRecoilState } from 'recoil';

import { CreateBetaStyle } from '../../../../shared/presentation/shared/CreateStyle';
import { RequestWrapperWithHeader } from '../../../request-page-2022/shared-component/RequestWrapperWithHeader';
import { RequestFont } from '../../../request-page-2022/shared/RequestFont';
import { RequestColor } from '../../../request-page-2022/assets/ColorContents';
import { FormInputWithLabel, labelStyle } from '../../../request-page-2022/shared-component/input/FormInputWithLabel';
import { usePaymentFormState } from '../../PaymentContents';
import { RequestButtonWrapper } from '../../../request-page-2022/shared-component/RequestButtonWrapper';
import RequestVerticalButtons from '../../../request-page-2022/shared-component/RequestVerticalButtons';
import { useMyHistory } from '../../../../shared/presentation/HistoryHelper';
import { PAYMENT_PATH } from '../PaymentConstant';
import { isLoadingState } from '../../../../shared/presentation/LoadingFrame';
import { RefundFormDropDown } from './RefundFormDropDown';

const DESCRIPTION_LIST = [
  '관할 세무서를 통해 국세 환급금이 먼저 입금됩니다. 이후, 국세 환급금의 10%에 해당하는 지방세 환급금이 관할 지자체를 통해 추가로 입금됩니다.',
  '환급금은 2주에서 최대 3개월 정도 소요됩니다.',
  '환급 가능한 계좌가 없을 경우, 우체국에서 현금으로 수령 가능합니다.',
  '수수료 환불은 내용 확인 후  즉시 ~ 최대 5영업일 정도 소요됩니다.',
];

export const RefundAccount: React.FC = () => {
  const [customer, setCustomer] = usePaymentFormState();
  const [bankAccountNo, setBankAccountNo] = useState('');
  const [bankList, setBankList] = useState<{ briefName: string, bankName: string, bankCode: string }[]>([]);
  const setIsLoading = useSetRecoilState(isLoadingState);
  const history = useMyHistory();
  const [isEdit, setIsEdit] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    axios.get('https://api.ailabscorp.com/total-income-tax/util/refund-bank')
      .then(({ data }) => {
        setBankList(data.filter((v: any) => v.bankName !== '해당없음'));
      })
      .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    if (customer.bankName === '' || customer.bankAccount === '') {
      setIsLoading(true);
      axios.get(`https://api.ailabscorp.com/total-income-the-refund/find/refund?recordId=${customer.recordId}`)
        .then(({ data }) => {
          setCustomer({ bankName: data.refundBank });
          setBankAccountNo(data.refundAccount);
          setIsEdit(false);
        })
        .finally(() => setIsLoading(false));
    }
  }, []);

  const onClickRequest = async () => {
    setIsLoading(true);
    axios.post('https://api.ailabscorp.com/total-income-the-refund/save/refund', {
      recordId: customer.recordId,
      refundBank: customer.bankName,
      refundAccount: bankAccountNo,
      bankCode: customer.bankCode,
    })
      .catch(e => console.log(e))
      .then(() => history.push(PAYMENT_PATH.step05))
      .finally(() => setIsLoading(false));
  };

  const onClickWithoutRefundAccount = async () => {
    setCustomer({ bankName: '직접수령' });
    setIsLoading(true);
    axios.post('https://api.ailabscorp.com/total-income-the-refund/save/refund', {
      recordId: customer.recordId,
      refundBank: '직접수령',
      refundAccount: '',
      bankCode: '',
    })
      .catch(e => console.log(e))
      .then(() => history.push(PAYMENT_PATH.step05))
      .finally(() => setIsLoading(false));
  };

  const onChangeRefundAccount = (value: string) => {
    return setBankAccountNo(value.replace(/[^0-9]/g, ''));
  };

  return (
    <RequestWrapperWithHeader>
      <p
        className={css(RequestFont.HEADER, styles.edit_button)}
        onClick={() => setIsEdit(true)}
      >
        수정하기
      </p>
      <p className={css(RequestFont.TITLE)}>
        환급 및 환불 계좌 입력
      </p>
      <div className={css(styles.content)}>
        <div className={css(labelStyle.label)}>은행</div>
        <RefundFormDropDown
          placeholder={'은행 선택하기'}
          options={bankList}
          setValue={(value: any) => setCustomer(
            {
              bankName: value.bankName,
              bankCode: value.bankCode,
            })}
          value={customer.bankName}
          isDisabled={!isEdit}
        />
      </div>
      <FormInputWithLabel
        labelText={'계좌번호'}
        placeholder={'본인 소유의 계좌만 이용가능'}
        isNumeric={true}
        // isValid={isAccouniu89tValid}
        onChange={value => onChangeRefundAccount(value)}
        // onFocus={() => setIsAccountValid(true)}
        // onBlur={value => onAccountBlur(value)}
        value={bankAccountNo}
        // addInputStyle={!isAccountValid && styles.invalid}
        isDisabled={!isEdit}
      />
      <div className={css(styles.description_frame)}>
        {DESCRIPTION_LIST.map((item, index) =>
          <div key={'description_' + index} className={css(styles.description_list)}>
            <p>-</p>
            <p>{item}</p>
          </div>
        )}
      </div>
      <RequestButtonWrapper>
        <RequestVerticalButtons
          items={[
            {
              title: '신청하기',
              marginBottom: 10,
              onClick: () => onClickRequest(),
            },
            {
              title: '계좌 없음',
              color: RequestColor.GRAY_60,
              border: `none`,
              backgroundColor: RequestColor.WHITE,
              onClick: () => onClickWithoutRefundAccount(),
            }]}
        />
      </RequestButtonWrapper>
    </RequestWrapperWithHeader>
  );
};

const styles = CreateBetaStyle({
  content: {
    width: '100%',
  },
  description_frame: {
    color: RequestColor.GRAY_60,
    fontSize: '14px',
    lineHeight: '26px',

    marginTop: '23px',
    marginBottom: '200px',
  },
  description_list: {
    display: 'flex',

    ':nth-child(n) > p:nth-child(1)': {
      marginRight: '4px',
    }
  },
  edit_button: {
    position: 'absolute',
    right: 20,
    top: 17,

    color: RequestColor.BLUE,

    cursor: 'pointer',
  },
});