import * as _ from 'lodash';
import { useCookies } from 'react-cookie';
import { useCallback } from 'react';

import { PHONE_FORMAT_POLICY, PhoneFormatPolicy } from '../../../shared/domain/PhoneFormatPolicy';
// import { PHONE_AGENCY_LIST } from '../1_간편인증/SimpleAuthRequest';
import { useInjection } from '../../../shared/presentation/InversifyProvider';
// import { BANK_CODE_MAP, TheRefundBankNames } from '../request/7_환급계좌/BankConstant';
import { SocialSecurityNumberPolicy } from '../../../shared/domain/SocialSecurityNumberPolicy';
import { COOKIE_MAX_AGE } from '../pages/SharedConstants';

// const COOKIE_LIST = ['name', 'phone', 'SSN1', 'SSN2', 'phoneAgency', 'yushinSessionId', 'customerId', 'orderId', 'bankName', 'bankAccountNo'];
const COOKIE_LIST = ['name', 'phone', 'SSN1', 'phoneAgency', 'yushinSessionId', 'customerId', 'orderId', 'bankName', 'bankAccountNo', 'customerCode', 'isProxy', 'hometaxName', 'hometaxPhone', 'hometaxSSN1'];

export const useRequestFormState = (): [RequestAuthForm, (props: Partial<RequestAuthForm>) => void, () => void] => {
  const [cookie, setCookie, resetCookie] = useCookies(COOKIE_LIST);
  const phoneFormatPolicy = useInjection<PhoneFormatPolicy>(PHONE_FORMAT_POLICY);
  const form = _.chain(COOKIE_LIST)
    .map(v => ({
      key: v,
      value: cookie[v],
    }))
    .filter(v => v.value != null)
    .mapKeys(v => v.key)
    .mapValues(v => v.value)
    .value();
  form.phone = phoneFormatPolicy?.changeFormat(form.phone ?? '');
  form.hometaxPhone = phoneFormatPolicy?.changeFormat(form.hometaxPhone ?? '');
  return [
    new RequestAuthForm(form),
    useCallback((props: Partial<RequestAuthForm>) => _.forEach(props, (value, key) => setCookie(key, value, {
      path: '/',
      maxAge: COOKIE_MAX_AGE
    })), [setCookie]),
    useCallback(() => {
      COOKIE_LIST.forEach(v => resetCookie(v));
    }, [resetCookie])
  ];
};

export const useSetRequestFormState = (): (props: Partial<RequestAuthForm>) => void => {
  const [, setCookie] = useCookies(COOKIE_LIST);
  return useCallback((props: Partial<RequestAuthForm>) => _.forEach(props, (value, key) => setCookie(key, value, {
    path: '/',
    maxAge: COOKIE_MAX_AGE
  })), [setCookie]);
};

export const useRequestFormValue = (): RequestAuthForm => {
  const [cookie] = useCookies(COOKIE_LIST);
  const phoneFormatPolicy = useInjection<PhoneFormatPolicy>(PHONE_FORMAT_POLICY);
  const form = _.chain(COOKIE_LIST)
    .map(v => ({
      key: v,
      value: cookie[v],
    }))
    .filter(v => v.value != null)
    .mapKeys(v => v.key)
    .mapValues(v => v.value)
    .value();
  form.phone = phoneFormatPolicy?.changeFormat(form.phone ?? '');
  form.hometaxPhone = phoneFormatPolicy?.changeFormat(form.hometaxPhone ?? '');
  return new RequestAuthForm(form);
};

export class RequestAuthForm {
  phone = '';
  SSN1 = '';
  phoneAgency = 'SKT';
  name = '';
  customerId = '';
  yushinSessionId = '';
  orderId = '';
  customerCode?: string;
  isProxy = false;
  hometaxName = '';
  hometaxPhone = '';
  hometaxSSN1 = '';

  constructor(props: Partial<RequestAuthForm>) {
    Object.assign(this, props);
    this.isProxy = this.isProxy?.toString() === 'true';
  }

  get phoneAgencyCode(): string {
    // const phoneAgencyIndex = PHONE_AGENCY_LIST.indexOf(this.phoneAgency ?? 'SKT');
    // return (phoneAgencyIndex + 1).toString().padStart(2, '0');
    return '';
  }

  static isPhoneValid(phone: string): boolean {
    return phone?.replace(/ |-/g, '').length === 10 || phone?.replace(/ |-/g, '').length === 11;
  }

  // static isSsn1Valid(ssn1: string): boolean {
  //   return ssn1?.replace(/ /g, '').length === 6 && (Number(ssn1.slice(2, 4)) <= 12 && Number(ssn1?.slice(4, 6)) <= 31);
  // }
  //
  // static isSsn2Valid(ssn2: string): boolean {
  //   return ssn2?.replace(/ /g, '').length === 7 && Number(ssn2.slice(0, 1)) <= 4 && Number(ssn2.slice(0, 1)) > 0;
  // }

  static isSsnValid(ssn: string): boolean {
    return SocialSecurityNumberPolicy.isValid(ssn);
  }

  static isNameValid(name: string): boolean {
    return name.length !== 0;
  }

  static isValidBasicInfo({phone, ssn, name}: { phone: string, ssn: string, name: string }): boolean {
    return RequestAuthForm.isPhoneValid(phone)
      && RequestAuthForm.isSsnValid(ssn)
      && RequestAuthForm.isNameValid(name);
  }
}

