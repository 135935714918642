import React, { useState } from 'react';
import { css } from 'aphrodite';
import { useRecoilValue } from 'recoil';

import { Image } from '../../../../shared/css/Image';
import { SERVICE_LIST } from '../../shared/Constant';
import { ServiceStyle } from './ServiceStyle';
import { totalScreenState } from '../../../../shared/presentation/MobileFrame';

function ServiceSection() {
  const { isMobile, isTablet } = useRecoilValue(totalScreenState);
  const [mouseHover, setMouseHover] = useState([false, false, false]);

  const [isTriggerAnimate, setIsTriggerAnimate] = useState([false, false, false]);

  const onMouseHover = (index: number) => {
    executeAnimate(index);

    setMouseHover(mouseHover.map((v, _index) => _index === index));
    console.log(index + 'hover');
  };

  const outMouseHover = () => {
    setMouseHover([false, false, false]);
  };

  const animatePlay = (index: number) => {
    if (!isTriggerAnimate[index]) {
      setIsTriggerAnimate(isTriggerAnimate.map((v, _index) => _index === index));
    }
  };

  const executeAnimate = (index: number) => {
    setTimeout(() => animatePlay(index), 500);
  };

  const styles = ServiceStyle(isMobile, isTablet);

  return (
    <div className={css(styles.background)}>
      <div className={css(styles.title)}>
        종합소득세 신고대행 {(isMobile || isTablet) && <br/>}
        <span className={css(styles.blue_text)}> 서비스 대상자</span>
      </div>
      <div className={css(styles.main_div)}>
        {SERVICE_LIST().map((item, index) =>
          <div key={'service-' + index}>
            <div
              className={css(styles.main__service,
                (isMobile || isTablet)
                  ? !item.isLast && styles.border_bottom
                  : (!item.isLast && !isMobile) && styles.border_right,
                !item.isFirst && styles.margin_left
              )}
              onMouseEnter={() => onMouseHover(index)}
              onMouseLeave={() => outMouseHover()}
            >
              <div className={css(styles.main__service__image_div)}>
                <Image
                  src={item.image}
                  alt={'image'}
                  className={css(styles.main__service__image_style)}
                />
                {(!isMobile && !isTablet) &&
                <div
                  className={css(styles.main__service__image__hover,
                    mouseHover[index]
                      ? styles.transition_basic
                      : styles.box_hidden)}
                >
                  {item.content}
                </div>}
              </div>
              <div className={css(styles.main__service__content)}>
                {item.title}
                {(isMobile || isTablet) && (
                  <div className={css(styles.main__service__mobile__content)}>
                    {item.content}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ServiceSection;
