import React, { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import axios from 'axios';

import { alertMessageState } from '../../../request-page-2022/shared-component/ShowAlertFrame';
import { useMyHistory } from '../../../../shared/presentation/HistoryHelper';
import { PAYMENT_PATH } from '../PaymentConstant';
import { usePaymentFormState, usePaymentFormValue } from '../../PaymentContents';

export const MobilePaymentComplete: React.FC = () => {
  const setAlert = useSetRecoilState(alertMessageState);
  const history = useMyHistory();
  const { recordId } = usePaymentFormValue();
  const [customer, setCustomer] = usePaymentFormState();

  const query = window.location.search;
  const param = new URLSearchParams(query);
  const imp_success = param.get('imp_success');
  const imp_uid = param.get('imp_uid');
  const merchant_uid = param.get('merchant_uid');
  const error_msg = param.get('error_msg');
  useEffect(() => {
    if (imp_success === 'false') {
      setAlert({
        message: '결제에 실패했습니다. 다시 시도해주세요.' + error_msg,
        onClick: () => history.push(PAYMENT_PATH.step02),
      });
      axios.post(
        'https://api.ailabscorp.com/total-income-the-refund/status', {
          recordId: recordId,
          paidStatus: '결제오류',
          message: error_msg,
        });
      return;
    }
    axios.post('https://api.ailabscorp.com/total-income-the-refund/verify', {
      paymentId: imp_uid,
      merchantId: merchant_uid,
    }).then(({ data }) => {
      if (data.status === 'failed') {
        setAlert({
          message: `결제에 실패했습니다.\n${data.message}`,
          onClick: () => history.push(PAYMENT_PATH.step02)
        });
        axios.post(
          'https://api.ailabscorp.com/total-income-the-refund/status', {
            recordId,
            paidStatus: '결제오류',
            message: data.message,
          });
        return;
      }
      axios.post('https://api.ailabscorp.com/total-income-the-refund/find/payment', {
        paymentId: imp_uid,
        merchantId: merchant_uid,
      }).then(({ data: payment }) => {
        setCustomer({ type: payment.payMethod });
        if (payment.status === 'ready') {
          if (payment.payMethod === 'vbank') {
            axios.post(
              'https://api.ailabscorp.com/total-income-the-refund/save/vbank', {
                recordId: recordId,
                paidType: '가상계좌',
                paidStatus: '결제대기',
                virtualAccount: payment.bankName + ': ' + payment.bankAccount,
              });
            axios.get(`https://api.ailabscorp.com/total-income-the-refund/find/alimtalk?recordId=${recordId}`)
              .then(({ data: alimtalk }) => {
                axios.post(
                  'https://api.ailabscorp.com/alimtalk-api', {
                    phone: alimtalk.phone,
                    templateCode: 'the-refund_2022_account2',
                    values: [
                      alimtalk.name,
                      payment.bankName + ' ' + payment.bankAccount,
                      payment.amount.toLocaleString(),
                      alimtalk.managerName,
                      alimtalk.managerPhone
                    ],
                    tel: '07043225312',
                  });
              });
            history.push(PAYMENT_PATH.step04);
            return;
          }
        }
        axios.get(`https://api.ailabscorp.com/total-income-the-refund/date?recordId=${recordId}`);
        axios.post(
          'https://api.ailabscorp.com/total-income-the-refund/type', {
            recordId,
            paidType: '카드결제',
          });
        axios.post(
          'https://api.ailabscorp.com/total-income-the-refund/status', {
            recordId,
            paidStatus: '결제완료',
          });
      });
      history.push(PAYMENT_PATH.step04);
    });
  }, []);
  return (
    <></>
  );
};