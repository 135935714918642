import { css } from 'aphrodite/no-important';
import axios from 'axios';
import { useSetRecoilState } from 'recoil';
import React from 'react';

import { CreateBetaStyle } from '../../../../shared/presentation/shared/CreateStyle';
import { RequestWrapperWithHeader } from '../../shared-component/RequestWrapperWithHeader';
import { RequestFont } from '../../shared/RequestFont';
import { RequestColor } from '../../assets/ColorContents';
import { RequestStyle } from '../../shared/RequestStyle';
import { RequestButtonWrapper } from '../../shared-component/RequestButtonWrapper';
import { RequestButton } from '../../shared-component/RequestButton';
import { requestAlertMessageState } from '../../shared-component/RequestAlertFrame';
import { decodeBase64 } from '../../utill/base64';

interface IProps {
  info: {
    customerId: string;
    relationship: string;
    name: string;
    ssn: string;
    isDisabled: boolean;
  },
  onClickHeader: () => void;
}

export function PersonalInfoPopup(props: IProps) {
  const { name, relationship, ssn, isDisabled, customerId } = props.info;
  const showAlert = useSetRecoilState(requestAlertMessageState);
  const onClickDelete = async () => {
    axios.post('https://api.ailabscorp.com/total-income/support-family/delete',
      {
        family: {
          customerId,
          name,
        }
      }
    ).then(({ data }) => {
      console.log(data)
    });
    window.location.reload();
  };
  const PERSONAL_INFO = [
    {
      title: '관계',
      value: relationship,
    },
    {
      title: '이름',
      value: name,
    },
    {
      title: '주민등록번호',
      value: decodeBase64(ssn).slice(0,6),
    },
    {
      title: '장애인 여부',
      value: isDisabled ? '해당' : '비해당',
    },
  ];
  return (
    <div className={css(RequestStyle.POPUP_BACKGROUND)}>
      <RequestWrapperWithHeader className={css(styles.wrapper)} onClickBack={props.onClickHeader}>
        <p className={css(RequestFont.TITLE, styles.title)}>{name}</p>
        <div className={css(styles.info_frame)}>
          {PERSONAL_INFO.map((item, index) =>
            <div key={'personal_' + index}>
              <p className={css(styles.info_title)}>{item.title}</p>
              {index === 2
                ? <p className={css(styles.info_value)}>{item.value}-*******</p>
                : <p className={css(styles.info_value)}>{item.value}</p>
              }
            </div>
          )}
        </div>
        <RequestButtonWrapper addStyle={css(styles.button_frame)}>
          <RequestButton
            title={'삭제하기'}
            backgroundColor={RequestColor.WHITE}
            color={RequestColor.BLUE}
            border={'1px solid' + RequestColor.BLUE}
            onClick={() => showAlert({
              title: '부양가족 정보를 삭제합니다.',
              message: '삭제를 원하시면 확인 버튼을 눌러주세요. \n' +
                '부양가족 정보는 다시 입력할 수 있습니다.',
              button: [
                {
                  title: '취소',
                },
                {
                  title: '삭제',
                  onClick: () => onClickDelete(),
                },
              ]
            })}
          />
        </RequestButtonWrapper>
      </RequestWrapperWithHeader>
    </div>
  );
}

const styles = CreateBetaStyle({
  wrapper: {
    height: '100vh',
  },
  title: {
    marginBottom: '24px',
  },
  info_frame: {
    width: '100%',
  },
  info_title: {
    fontSize: '14px',
    color: RequestColor.GRAY_100,

    marginBottom: '6px',
    paddingLeft: '7px',
  },
  info_value: {
    width: '100%',
    height: '48px',

    border: `solid 1px ${RequestColor.GRAY_30}`,

    fontSize: '16px',
    color: RequestColor.GRAY_100,

    display: 'flex',
    alignItems: 'center',

    marginBottom: '16px',
    paddingLeft: '15px',
  },
  button: {
    backgroundColor: 'white',
  },
  button_frame: {
    background: 'none',
  }
});