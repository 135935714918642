export const RequestColor = {
  MAIN_COLOR: '#006AFF',
  BLUE: '#006AFF',
  BLUE_100: '#0094FF',

  RED: '#FB4444',
  RED_100: '#F63030',

  GRAY_DISABLE: '#EDEEEF',
  GRAY_100: '#292C2F',
  GRAY_80: '#626B75',
  GRAY_60: '#A3A9AE',
  GRAY_50: '#A1A8B0',
  GRAY_40: '#CBD0D6',
  GRAY_30: '#EDEEEF',
  GRAY_20: '#E2E6EA',
  GRAY_10: '#F5F6F9',

  WHITE: '#FFF',
};

