import axios from 'axios';
import React, { useEffect } from 'react';
import { css } from 'aphrodite/no-important';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { RequestWrapperWithHeader } from '../../shared-component/RequestWrapperWithHeader';
import { RequestFont } from '../../shared/RequestFont';
import { Image } from '../../../../shared/css/Image';
import { CreateBetaStyle } from '../../../../shared/presentation/shared/CreateStyle';
import { RequestStyle } from '../../shared/RequestStyle';
import { RequestButtonWrapper } from '../../shared-component/RequestButtonWrapper';
import { RequestButton } from '../../shared-component/RequestButton';
import Face from '../../assets/gif/face_with_monocle.gif';
import { requestAlertMessageState } from '../../shared-component/RequestAlertFrame';
import { hometaxPasswordState } from '../3_홈택스_로그인/HomeTaxLogin';
import { REQUEST_PATH } from '../SharedConstants';
import { alertMessageState } from '../../shared-component/ShowAlertFrame';
import { useMyHistory } from '../../../../shared/presentation/HistoryHelper';
import { isLoadingState } from '../../../../shared/presentation/LoadingFrame';
import { useHometaxValue } from '../../shared/HometaxState';
import { useRequestFormValue } from '../../shared/RequestContents';

export const ConsentPage: React.FC = () => {
  const showRequestAlert = useSetRecoilState(requestAlertMessageState);
  const showAlert = useSetRecoilState(alertMessageState);
  const setIsLoading = useSetRecoilState(isLoadingState);
  const history = useMyHistory();
  const hometaxPassword = useRecoilValue(hometaxPasswordState);
  const { hometaxId } = useHometaxValue();
  const { customerId } = useRequestFormValue();
  useEffect(() => {
    if (hometaxPassword === '') {
      showAlert({
        message: '주민등록번호가 입력되지 않았습니다.\n다시 시도해주세요.',
        onClick: () => history.push(REQUEST_PATH.step03)
      });
      return;
    }
  }, [hometaxPassword]);

  const onClickDismissal = () => {
    axios.post('https://api.ailabscorp.com/total-income-tax/agent-request/request', {
      customerId,
    });
    history.push(REQUEST_PATH.step07);
  };

  const onClick = () => {
    setIsLoading(true);
    axios.post('https://api.ailabscorp.com/total-income-tax/agent-request/has-others', {
      id: hometaxId,
      password: Buffer.from(hometaxPassword, 'utf-8').toString('base64'),
    })
      .then(({ data }) => {
        if (data) {
          showRequestAlert({
            message: '이미 수임 중인 세무대리인이 있습니다. \n해임 후 신고 진행을 도와드릴까요?',
            button: [
              {
                title: '취소',
              },
              {
                title: '해임하기',
                onClick: onClickDismissal,
              }
            ],
            description: '*홈택스 > 나의 세무대리인 해임 메뉴에서 \n' +
              '언제든지 변경하실 수 있어요.',
          });
          return;
        }
        history.push(REQUEST_PATH.step07);
      })
      .finally(() => setIsLoading(false));
  };
  return (
    <RequestWrapperWithHeader>
      <div className={css(styles.title)}>
        <p className={css(RequestFont.TITLE)}>홈택스 조회 동의</p>
        <p className={css(RequestFont.DESCRIPTION)}>
          세무대리인이 홈택스 자료를 조회하여<br/>
          복잡한 신고업무 진행을 도와드려요.
        </p>
      </div>
      <div className={css(styles.img_frame, RequestStyle.COLUMN_CENTER)}>
        <Image src={Face} alt={'face'} className={css(styles.face_img)}/>
      </div>
      <RequestButtonWrapper>
        <RequestButton
          title={'네, 동의할게요'}
          onClick={() => onClick()}
        />
      </RequestButtonWrapper>
    </RequestWrapperWithHeader>
  );
};

const styles = CreateBetaStyle({
  title: {
    width: '100%',

    marginBottom: '125px',
  },
  img_frame: {
    width: '100%',
  },
  face_img: {
    width: '122px',
    height: '122px',
  }
});