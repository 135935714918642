import { useCookies } from 'react-cookie';
import * as _ from 'lodash';
import { useCallback } from 'react';

import { useInjection } from '../../../shared/presentation/InversifyProvider';
import { PHONE_FORMAT_POLICY, PhoneFormatPolicy } from '../../../shared/domain/PhoneFormatPolicy';
import { COOKIE_MAX_AGE } from '../pages/SharedConstants';

const COOKIE_LIST = ['family_relation', 'family_name', 'family_phone', 'family_SSN1', 'family_isDisabled'];

export const useRequestSupportFamilyFormState = (): [RequestSupportFamilyForm, (props: Partial<RequestSupportFamilyForm>) => void, () => void] => {
  const [cookie, setCookie, resetCookie] = useCookies(COOKIE_LIST);
  const phoneFormatPolicy = useInjection<PhoneFormatPolicy>(PHONE_FORMAT_POLICY);
  const form = _.chain(COOKIE_LIST)
    .map(v => ({
      key: v,
      value: cookie[v],
    }))
    .filter(v => v.value != null)
    .mapKeys(v => v.key.replace('family_', ''))
    .mapValues(v => v.value)
    .value();
  form.phone = phoneFormatPolicy?.changeFormat(form.phone ?? '');
  return [
    new RequestSupportFamilyForm(form),
    useCallback((props: Partial<RequestSupportFamilyForm>) =>
      _.forEach(props, (value, key) =>
        setCookie('family_' + key, value, {
          path: '/',
          maxAge: COOKIE_MAX_AGE
        })), [setCookie]),
    useCallback(() => {
      COOKIE_LIST.forEach(v => resetCookie(v, { path: '/' }));
    }, [resetCookie])
  ];
};

export const useSetRequestSupportFamilyFormState = (): (props: Partial<RequestSupportFamilyForm>) => void => {
  const [, setCookie] = useCookies(COOKIE_LIST);
  return useCallback((props: Partial<RequestSupportFamilyForm>) => _.forEach(props, (value, key) => setCookie(key, value, {
    path: '/',
    maxAge: COOKIE_MAX_AGE
  })), [setCookie]);
};

export const useRequestSupportFamilyFormValue = (): RequestSupportFamilyForm => {
  const [cookie] = useCookies(COOKIE_LIST);
  const phoneFormatPolicy = useInjection<PhoneFormatPolicy>(PHONE_FORMAT_POLICY);
  const form = _.chain(COOKIE_LIST)
    .map(v => ({
      key: v,
      value: cookie[v],
    }))
    .filter(v => v.value != null)
    .mapKeys(v => v.key)
    .mapValues(v => v.value)
    .value();
  form.phone = phoneFormatPolicy?.changeFormat(form.phone ?? '');
  return new RequestSupportFamilyForm(form);
};

export class RequestSupportFamilyForm {
  relation: string = '';
  name: string = '';
  phone: string = '';
  SSN1: string = '';
  // ssn2: string = '';
  isDisabled: string = '';

  constructor(props: Partial<RequestSupportFamilyForm>) {
    Object.assign(this, props);
  }

  get disabledOrNot(): boolean {
    // console.log('this: ', this.isDisabled);
    // return this.isDisabled ? '해당' : '비해당';
    return this.isDisabled === '해당';
  }
}
