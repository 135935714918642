import React, { useEffect, useMemo, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { css } from 'aphrodite/no-important';
import axios from 'axios';

import { CreateBetaStyle } from '../../../../shared/presentation/shared/CreateStyle';
import { RequestStyle } from '../../shared/RequestStyle';
import RequestVerticalButtons from '../../shared-component/RequestVerticalButtons';
import { RequestColor } from '../../assets/ColorContents';
import { spoqa } from '../../../../shared/css/css-utils';
import { useRequestFormValue } from '../../shared/RequestContents';
import { isLoadingState } from '../../../../shared/presentation/LoadingFrame';

interface IConsultProps {
  onClickTop: () => any;
  onClickBottom: () => any;
}

export function ConsultPopup({ onClickTop, onClickBottom }: IConsultProps) {
  const [note, setNote] = useState('');
  const [recommendCode, setRecommendCode] = useState('');
  const setIsLoading = useSetRecoilState(isLoadingState);
  const request = useRequestFormValue();
  const isCustomerCodeExist = useMemo(() => request.customerCode != null && request.customerCode !== '', [request.customerCode]);
  useEffect(() => {
    if (isCustomerCodeExist) {
      setRecommendCode(request.customerCode ?? '');
    }
  }, [request.customerCode]);
  const saveInfo = async () => {
    setIsLoading(true);
    await axios.post('https://api.ailabscorp.com/total-income/note',
      {
        data: {
          id: request.customerId,
          note,
          recommendCode,
        }
      })
      .finally(() => setIsLoading(false))
  };
  return (
    <div className={css(RequestStyle.POPUP_BACKGROUND)}>
      <div className={css(styles.frame)}>
        <p className={css(styles.title)}>상담원 문의하기</p>
        <textarea
          className={css(styles.textarea, styles.input_common)}
          placeholder={'추가 문의사항이 있으면 남겨주세요.'}
          onChange={(v) => setNote(v.target.value)}
          value={note}
        />
        <p className={css(styles.title)}>추천인 코드(선택)</p>
        <input
          className={css(styles.input, styles.input_common)}
          // placeholder={'추가 문의사항이 있으면 남겨주세요.'}
          onChange={(v) => setRecommendCode(v.target.value)}
          disabled={isCustomerCodeExist}
          value={recommendCode}
        />
        <RequestVerticalButtons items={[
          {
            title: '보내기',
            marginBottom: 9,
            onClick: () => {
              saveInfo();
              onClickTop();
            },
          },
          {
            title: '문의사항이 없어요',
            color: RequestColor.GRAY_60,
            backgroundColor: RequestColor.WHITE,
            onClick: () => onClickBottom(),
          }]}/>
      </div>
    </div>
  );
}

const styles = CreateBetaStyle({
  frame: {
    width: '100%',
    maxWidth: '640px',
    height: '486px',

    position: 'fixed',
    bottom: 0,
    left: '0px/50%',
    transform: '/translateX(-50%)',

    backgroundColor: 'white',

    padding: '35px 24px 24px',
  },
  title: {
    fontSize: '18px',
    fontWeight: 500,
    color: '#30363D',

    marginBottom: '13px',
  },
  textarea: {
    width: '100%',
    height: '124px',

    marginBottom: '24px',
    padding: '14px 15px',

    resize: 'none',

  },
  input: {
    width: '100%',
    height: '48px',

    marginBottom: '31px',
    padding: '0px 15px',
  },
  input_common: {
    borderRadius: '5px',
    border: 'solid 1px #EDEFF4',

    fontSize: '16px',
    lineHeight: '28px',
    color: '#262B31',
    fontFamily: spoqa,

    '::placeholder': {
      color: RequestColor.GRAY_60,
    }
  },
});
