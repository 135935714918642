import React from 'react';
import { css } from 'aphrodite/no-important';

import { CreateBetaStyle } from '../../../shared/presentation/shared/CreateStyle';

interface Props {
  children?: any,
  className?: string,
  addStyle?: string,
}

export const RequestButtonWrapper: React.FC<Props> = (
  {
    children,
    className,
    addStyle
  }) => {
  return <div className={[css(styles.frame), addStyle].join(' ')}>
    <div className={[css(styles.wrapper), className].join(' ')}>
      {children}
    </div>
  </div>;
};

const styles = CreateBetaStyle({
  frame: {
    width: '100vw',

    position: 'fixed',
    bottom: 0,
    left: 0,
    zIndex: 100,

    padding: '0px 22px',

    background: 'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 28.13%)',
  },
  wrapper: {
    maxWidth: 640,
    // minWidth: 375,
    // height: 104,
    height: 'max-content',

    position: 'relative',
    margin: '0 auto',
    overflow: 'hidden',
    // backgroundColor: 'red/blue/yellow',
    // transform: 'translateX(calc(50vw - 50%))/none@none',

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
});
